import { Checkbox, initializeIcons, Text, ThemeProvider, createTheme, IconButton, Slider, Panel, PanelType, Label, ChoiceGroup, Toggle } from '@fluentui/react';
import React from 'react';
import Papa from 'papaparse';
import { groupBy } from 'lodash';
import Plot from 'react-plotly.js';

const colors = ["#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#b3d4ff", "#00bfa0", "#b30000", "#7c1158", "#4421af", "#1a53ff", "#0d88e6", "#00b7c7", "#5ad45a", "#8be04e", "#ebdc78", "#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#ffee65", "#beb9db", "#fdcce5", "#8bd3c7"];

initializeIcons();

const processRawData = (data) => {
  let holder = [];

  let keys = data.shift();

  keys[0] = 'num';

  data.forEach((row) => {
    let obj = {};
    keys.forEach((key, x) => {
      obj[key] = row[x];
    });
    holder.push(obj);
  });

  holder.pop();

  const grouped = groupBy(holder, row => row.port);

  const cleanData = Object.keys(grouped).map((portfolio, index) => {
    return {
      id: portfolio,
      name: portfolio,
      visible: true,
      // visible: index < 4,
      data: grouped[portfolio],
      color: colors[index]
    }
  });

  return cleanData;
  
}

function App(props) {

  const [data, setData] = React.useState(null);
  const [showSettings, toggleShowSettings] = React.useState(false);
  const [lineTension, setLineTension] = React.useState(1);
  // const [fontSize, setFontSize] = React.useState(20);
  const [chartData, setChartData] = React.useState([]);
  const [show, setShow] = React.useState([
    {key: 'N', visible: true},
    {key: '50%', linkedKeys: ['N.up50', 'N.low50'], visible: false, opacity: 70},
    {key: '80%', linkedKeys: ['N.up80', 'N.low80'], visible: false, opacity: 60},
    {key: '90%', linkedKeys: ['N.up90', 'N.low90'], visible: false, opacity: 50},
  ]);

  const [layout, setLayout] = React.useState(() => {
    return {
      showlegend: true,
      title: "Charty",
      hoverlabel: {
        bgcolor: '#fff', 
        font: {
          size: 20
        }
      }, 
      xaxis: {
        range: [1995, 2014],
        title: "Year"
      }, 
      yaxis: {
        type: 'log',
        title: "Population"
      }, 
      paper_bgcolor: 'transparent', 
      plot_bgcolor: 'transparent', 
      font: {
        color: props.theme === 'dark' ? '#fff' : '#333', 
        size: 20
      }
    }
  });

  React.useEffect(() => {
    setLayout(prev => ({...prev, font: {...prev.font, color: props.theme === 'dark' ? '#fff' : '#333'}}))
  }, [props.theme]);

  React.useEffect(() => {
    if(!data) {
      setChartData([]);
      return;
    }

    let traces = [];

    data.forEach(trace => {
      if(!trace.visible) return;
      show.forEach(stat => {
        if(!stat.visible) return;
        if(stat.linkedKeys){
          let upper = trace.data.map(d => d[stat.linkedKeys[0]]);
          let lower = trace.data.map(d => d[stat.linkedKeys[1]]).reverse();
          let upperX = trace.data.map(d => d.year);
          let lowerX = trace.data.map(d => d.year).reverse();

          traces.push({
            type: 'scatter',
            name: `${trace.name} - ${stat.key}`,
            id: trace.id,
            key: trace.id,
            legendgroup: trace.id,
            x: [...upperX, ...lowerX],
            y: [...upper, ...lower],
            fill: "tozerox",
            showlegend: false,
            fillcolor: `${trace.color}${stat.opacity}`,
            line: {color: 'transparent', shape: 'spline', smoothing: lineTension},
            // visible: false
          });

        } else {
          traces.push({
            type: 'scatter',
            name: `${trace.name}`,
            id: trace.id,
            key: trace.id,
            legendgroup: trace.id,
            x: trace.data.map(d => d.year),
            y: trace.data.map(d => d[`${stat.key}`]),
            showlegend: true,
            line: {color: trace.color, shape: 'spline', smoothing: lineTension, width: 5},
            visible: true
          });
        }
      })
    });
    setChartData(traces);
  }, [data, show]);

  React.useEffect(() => {

  }, [lineTension]);

  React.useEffect(() => {
    Papa.parse('https://listaassets.s3.amazonaws.com/all_portfolio_results.csv', {
      download: true,
      complete: ({data, errors}) => {
        setData(processRawData(data));
      }
    })
  }, []);

  const onUpdate = (fig) => {
    // console.log(fig.data);
    setChartData(fig.data);
  }

  return (
    <div className="ms-Grid" dir="ltr">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col ms-xl2" style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh', overflowY: 'auto'}}>
          <div style={{flex: '1'}}>
            {/* <Text block style={{marginBottom: 8}} variant="xLarge">Portfolios</Text> */}
              {/* {
                data && data.map(portfolio => {
                  return <Checkbox
                    styles={{root: {marginBottom: 8}}}
                    key={portfolio.id}
                    onDoubleClick={(e) => alert("DOUBLE CLICK")}
                    checked={portfolio.visible === true} 
                    label={portfolio.name}
                    onChange={(e, checked) => setData(prev => prev.map(por => por.id === portfolio.id ? {...por, visible: checked} : por))} />
                })
              } */}
            <Text block style={{marginBottom: 8}} variant="xLarge">Traces</Text>
            {
              show.map(option => {
                return <Checkbox
                  styles={{root: {marginBottom: 8}}} 
                  key={option.key}
                  checked={option.visible}
                  label={option.key}
                  onChange={(e, checked) => setShow(prev => prev.map(opt => opt.key === option.key ? {...opt, visible: checked} : opt))}
                />
              })
            }
          </div>
          <IconButton iconProps={{iconName: "Settings"}} onClick={() => toggleShowSettings(true)} />
        </div>
        <div className="ms-Grid-col ms-xl10" style={{height: '100vh'}}>
          <Plot
            data={chartData}
            onUpdate={onUpdate}
            layout={layout}
            style={{
              height: '100%', 
              width: '100%'
            }}
            useResizeHandler
            config={{
              responsive: true
            }}
          />
        </div>
      </div>
      <Panel styles={{overlay: {backgroundColor: 'ffffff00'}}} type={PanelType.smallFixedNear} isLightDismiss isOpen={showSettings} onDismiss={() => toggleShowSettings(false)}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
          <Label>Switch Theme</Label>
          <IconButton iconProps={{iconName: "Contrast"}} onClick={props.changeTheme} />
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <Label>Font Size</Label>
          <div>
            <IconButton iconProps={{iconName: "FontDecrease"}} onClick={() => setLayout(prev => ({...prev, font: {...prev.font, size: prev.font.size - 1}}))} />
            <IconButton iconProps={{iconName: "FontIncrease"}} onClick={() => setLayout(prev => ({...prev, font: {...prev.font, size: prev.font.size + 1}}))} />
          </div>
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Label>Line Smoothing</Label>
          <Slider styles={{root: {flex: 1}}} showValue={false} min={0} max={1.3} step={0.1} value={lineTension} onChange={(value) => setLineTension(value)} />
        </div>
        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Label>Show Legend</Label>
          <Toggle checked={layout.showlegend} onChange={(e, checked) => setLayout(prev => ({...prev, showlegend: checked}))} />
        </div>
        <div>
          <Label>Y Axis</Label>
          <ChoiceGroup 
            selectedKey={layout.yaxis.type} 
            options={[
              {key: '-', text: "Default"},
              {key: 'log', text: 'Log'}
            ]} 
            onChange={(e, o) => setLayout(prev => ({...prev, yaxis: {...prev.yaxis, type: o.key}}))}>
          </ChoiceGroup>
        </div>
      </Panel>
    </div>
  );
}

const darkTheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#262626',
    neutralLighter: '#2f2f2f',
    neutralLight: '#3d3d3d',
    neutralQuaternaryAlt: '#464646',
    neutralQuaternary: '#4d4d4d',
    neutralTertiaryAlt: '#6b6b6b',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralSecondaryAlt: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#1c1c1c',
  }});

const lightTheme = createTheme({
  palette: {
    themePrimary: '#0078d4',
    themeLighterAlt: '#eff6fc',
    themeLighter: '#deecf9',
    themeLight: '#c7e0f4',
    themeTertiary: '#71afe5',
    themeSecondary: '#2b88d8',
    themeDarkAlt: '#106ebe',
    themeDark: '#005a9e',
    themeDarker: '#004578',
    neutralLighterAlt: '#f3f3f3',
    neutralLighter: '#efefef',
    neutralLight: '#e5e5e5',
    neutralQuaternaryAlt: '#d6d6d6',
    neutralQuaternary: '#cccccc',
    neutralTertiaryAlt: '#c4c4c4',
    neutralTertiary: '#bab8b7',
    neutralSecondary: '#a3a2a0',
    neutralSecondaryAlt: '#a3a2a0',
    neutralPrimaryAlt: '#8d8b8a',
    neutralPrimary: '#323130',
    neutralDark: '#605e5d',
    black: '#494847',
    white: '#fafafa',
  }});

const Wrapper = () => {

  const [activeTheme, setTheme] = React.useState('light');

  const changeTheme = React.useCallback(() => {
    if(activeTheme === 'dark') setTheme('light');
    if(activeTheme === 'light') setTheme('dark');
  }, [activeTheme]);

  const theme = React.useMemo(() => {
    if(activeTheme === 'dark') return darkTheme;
    return lightTheme;
  }, [activeTheme]);
  

  return <ThemeProvider theme={theme}>
    <App changeTheme={changeTheme} theme={activeTheme} />
  </ThemeProvider>
}

export default Wrapper;
