import { MessageBar, PrimaryButton, TextField, MessageBarType, Link as Anchor, Spinner } from '@fluentui/react';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { MessageBarStyles } from './SignUp';

function ResetPassword(props){
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [resetSuccess, setResetSuccess] = React.useState(false);

  const submit = () => {
    setLoading(true);
    axios.post(`/api/auth/reset-password/${props.match.params.reset_hash}`, {password, confirmPassword})
    .then(res => {
      if(res.data.success){
        setResetSuccess(true);
        setPassword('');
        setConfirmPassword('');
        setLoading(false);
      } else {
        throw Error("Unable to reset password.")
      }
    })
    .catch(err => {
      setError(err.message);
      setPassword('');
      setConfirmPassword('');
      setLoading(false);
    })
  }

  return <div className='center'>
    <div className='signup-form'>
      <h1 className='logo'>Reset Password</h1>
      {
        error && <MessageBar
          styles={MessageBarStyles} 
          messageBarType={MessageBarType.error} 
          onDismiss={() => setError('')}>{error}
          </MessageBar>
      }
      {
        message && <MessageBar 
          styles={MessageBarStyles}
          messageBarType={MessageBarType.success} 
          onDismiss={() => setMessage('')}>{message}
          </MessageBar>
      }
      {
        resetSuccess ? (
          <MessageBar styles={MessageBarStyles} messageBarType={MessageBarType.success}>
            <div>Your password was reset. <Link to='/signin'><Anchor>Sign in now</Anchor></Link></div>
          </MessageBar>
        ) : (
          <>
            <TextField 
              autoComplete='off'
              type='password'
              label='Password' 
              value={password}
              styles={{root: {marginBottom: 16}}}
              onChange={(e, val) => setPassword(val)} />
            <TextField 
              autoComplete='off'
              type='password' 
              styles={{root: {marginBottom: 16}}}
              label='Confirm Password' 
              value={confirmPassword}
              onChange={(e, val) => setConfirmPassword(val)} />
            <PrimaryButton disabled={!password || (confirmPassword !== password)} text={loading ? <Spinner /> : "Submit"} onClick={submit} />
          </>
        )
      }
    </div>
  </div>
}

export default ResetPassword;