import { Icon, Label, getTheme, Dropdown, PrimaryButton, IconButton } from '@fluentui/react';
import React from 'react';
import DateRange from './DateRange';
import { LOOKUP_KEYS } from './Hydraviz';
import { MultiYearPicker } from './Spaghetti';

export default function ChartBuilderForm(props){

  const { startCalDay, alternatives=[], YEARS=[] } = props;

  const [state, setState] = React.useState({...props.defaultState});
  const [step, setStep] = React.useState(0);

  const [dateRangePickerHidden, toggleHidden] = React.useState(true);

  const toggleDateRangePickerHidden = (hidden) => toggleHidden(hidden);

  React.useEffect(() => {
    return () => {
      setStep(0);
      toggleHidden(true);
      setState(props.defaultState);
    }
  }, []);

  const minMax = React.useMemo(() => {
    let data = props.master.find(el => el.alternative === state.selectedAlt)?.data.find(el => el.location === state.location);
    if(data) return {minYear: data.minYear, maxYear: data.maxYear};
    else return {minYear: Math.min(...YEARS), maxYear: Math.max(...YEARS)};
  }, [props.master, state.location, state.selectedAlt, YEARS]);

  return <div>
    {
      step === 0 && <div>
        {
          [
            {
              key: 'Single Alternative',
              text: 'Single Alternative',
              // imageSrc: process.env.PUBLIC_URL + '/images/ChartType_SingleAlternative.JPG',
              // selectedImageSrc: process.env.PUBLIC_URL + '/images/ChartType_SingleAlternative.JPG',
              // imageSize: {width: 100, height: 100}
            },
            {
              key: 'Multiple Alternatives',
              text: 'Multiple Alternatives',
              // imageSrc: process.env.PUBLIC_URL + '/images/ChartType_MultipleAlternatives.JPG',
              // selectedImageSrc: process.env.PUBLIC_URL + '/images/ChartType_MultipleAlternatives.JPG',
              // imageSize: {width: 100, height: 100}
            },
            {
              key: 'Multiple Locations',
              text: 'Multiple Locations',
              // imageSrc: process.env.PUBLIC_URL + '/images/ChartType_PeriodofRecord.JPG',
              // selectedImageSrc: process.env.PUBLIC_URL + '/images/ChartType_PeriodofRecord.JPG',
              // imageSize: {height: 100, width: 100} 
            },
            {
              key: 'Period of Record',
              text: 'Period of Record',
              // imageSrc: process.env.PUBLIC_URL + '/images/ChartType_PeriodofRecord.JPG',
              // selectedImageSrc: process.env.PUBLIC_URL + '/images/ChartType_PeriodofRecord.JPG',
              // imageSize: {height: 100, width: 100} 
            }
          ].map(chart => {
            let isPicked = false;
            if(state.chartType === chart.key){
              isPicked = true
            }
            return <div 
              key={chart.key} 
              className='hover-label' 
              style={{
                marginBottom: 4, 
                padding: 8,
                cursor: 'pointer'
              }} 
              onClick={() => setState(prev => {
                return {
                  ...prev,
                  chartType: chart.key
                }
              })}>
                <Icon styles={{root: {marginRight: 12, color: isPicked ? 'green' : 'inherit' }}} iconName={isPicked ? 'SkypeCircleCheck' : 'CircleRing'} />
                {chart.text}
            </div>
          })
        }
          <PrimaryButton text="Next" onClick={() => setStep(prev => prev+1)} />
      </div>
    }

    {
      step === 1 && <React.Fragment>
        <div className='mb3'>
          {
            props.locations.map(l => {
              let isPicked = false;
              if(state.location === l.key && state.chartType !== 'Multiple Locations'){
                isPicked = true
              } else {
                if(state.selectedLocations.includes(l.key)){
                  isPicked = true
                }
              };
              return <div 
                key={l.key} 
                className='hover-label' 
                style={{
                  marginBottom: 4, 
                  padding: 8,
                  cursor: 'pointer'
                }} 
                onClick={() => setState(prev => {
                  if(prev.chartType === 'Multiple Locations'){
                    if(isPicked){
                      return {...prev, selectedLocations: prev.selectedLocations.filter(el => el !== l.key)}
                    } else {
                      return {...prev, selectedLocations: prev.selectedLocations.concat(l.key)}
                    }
                  } else {
                    return {...prev, location: l.key}
                  }
                })}>
                  <Icon styles={{root: {marginRight: 12, color: isPicked ? 'green' : 'inherit' }}} iconName={isPicked ? 'SkypeCircleCheck' : 'CircleRing'} />
                  {l.text} {l.measure}
              </div>
            })
          }
        </div>
        <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
        <PrimaryButton text="Next" onClick={() => setStep(prev => prev+1)} />
      </React.Fragment>
    }

    {
      step === 2 && <React.Fragment>
        {
          (state.chartType === 'Single Alternative' || state.chartType === 'Multiple Locations') && <div>
            <div className='mb3'>
              {
                alternatives.map(alt => {
                  const isPicked = state.selectedAlt === alt.key;
                  return <div 
                    key={alt.key}
                    onClick={() => setState(prev => ({
                      ...prev, 
                      selectedAlt: alt.key
                    }))}
                    className='hover-label' 
                    style={{
                      borderBottomColor: `${alt.color}`,
                      borderBottomWidth: 2, 
                      marginBottom: 4, 
                      padding: 8,
                      cursor: 'pointer'
                      }}>
                      <Icon styles={{root: {marginRight: 12, color: isPicked ? 'green' : 'inherit' }}} iconName={isPicked ? 'SkypeCircleCheck' : 'CircleRing'} /> {alt.text}
                    </div>
                })
              }
            </div>
            <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
            <PrimaryButton text="Next" onClick={() => setStep(prev => prev+1)} />
          </div>
        }
        {
          state.chartType === 'Multiple Alternatives' && <div>
            <div className='mb3'>
              {
                alternatives.map(alt => {
                  const isPicked = state.selectedAlts.includes(alt.key);
                  return <div 
                    key={alt.key}
                    onClick={() => setState(prev => ({
                      ...prev, 
                      selectedAlts: isPicked ? prev.selectedAlts.filter(a => a !== alt.key) : prev.selectedAlts.concat(alt.key)
                    }))}
                    className='hover-label' 
                    style={{
                      borderBottomColor: `${alt.color}`,
                      borderBottomWidth: 2, 
                      marginBottom: 4, 
                      padding: 8,
                      cursor: 'pointer'
                      }}>
                      <Icon styles={{root: {marginRight: 12, color: isPicked ? 'green' : 'inherit' }}} iconName={isPicked ? 'SkypeCircleCheck' : 'CircleRing'} /> {alt.text}
                    </div>
                })
              }
            </div>
            <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
            <PrimaryButton text="Next" onClick={() => setStep(prev => prev+1)} />
          </div>
        }
        {
          state.chartType === 'Period of Record' && <div>
            <div className='fr mb3'>
              <div className='f1' style={{marginRight: 12}}>
                <Label styles={{root: {color: getTheme().palette.neutralSecondary, fontSize: 16, fontWeight: 400}}}>Base Scenario</Label>
                {
                  alternatives.map(alt => {
                    const isPicked = state.altA === alt.key;
                    return <div 
                      key={alt.key}
                      onClick={() => setState(prev => ({
                        ...prev, 
                        altA: alt.key
                      }))}
                      className='ms-depth-4' 
                      style={{
                        borderBottom: `2px solid ${alt.color}`, 
                        marginBottom: 12, 
                        padding: 8,
                        cursor: 'pointer'
                        }}>
                        <Icon styles={{root: {marginRight: 12, color: isPicked ? 'green' : 'inherit' }}} iconName={isPicked ? 'SkypeCircleCheck' : 'CircleRing'} /> {alt.text}
                      </div>
                  })
                }
              </div>
              <div className='f1'>
                <Label styles={{root: {color: getTheme().palette.neutralSecondary, fontSize: 16, fontWeight: 400}}}>Compared Scenario</Label>
                {
                  alternatives.map(alt => {
                    const isPicked = state.altB === alt.key;
                    return <div 
                      key={alt.key}
                      onClick={() => setState(prev => ({
                        ...prev, 
                        altB: alt.key
                      }))}
                      className='ms-depth-4' 
                      style={{
                        borderBottom: `2px solid ${alt.color}`, 
                        marginBottom: 12, 
                        padding: 8,
                        cursor: 'pointer'
                        }}>
                        <Icon styles={{root: {marginRight: 12, color: isPicked ? 'green' : 'inherit' }}} iconName={isPicked ? 'SkypeCircleCheck' : 'CircleRing'} /> {alt.text}
                      </div>
                  })
                }
              </div>
            </div>
            <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
            <PrimaryButton text="Next" onClick={() => setStep(prev => prev+1)} />
          </div>
        }
      </React.Fragment>
    }

    {
      step === 3 && <React.Fragment>
        {
          state.chartType === 'Single Alternative' && <div>
            <div className='mb3'>
              <DateRange 
                hidden={dateRangePickerHidden}
                startCalDay={props.startCalDay}
                onRender={(data) => <div className='ms-fontSize-18' onClick={data.onClick}>{data.dateText}<IconButton iconProps={{iconName: 'Edit'}} /></div>}
                toggleDateRangePickerHidden={toggleDateRangePickerHidden} 
                startDay={state.startDay} 
                endDay={state.endDay} 
                onChange={(startDay, endDay) => setState(prev => ({...prev, startDay, endDay}))} />
              {/* <Dropdown
                label="Start Day"
                selectedKey={state.startDay} 
                options={ALL_DAYS.map(d => ({key: d.dayLookup, text: d.date}))} onChange={(e, o) => setState(prev => ({...prev, startDay: o.key}))} />
              <Dropdown
                label="End Day"
                selectedKey={state.endDay} 
                options={ALL_DAYS.map(d => ({key: d.dayLookup, text: d.date}))} onChange={(e, o) => setState(prev => ({...prev, endDay: o.key}))} /> */}
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {
                    YEARS.map(year => {
                      return <div className='hover-label' key={year} style={{marginRight: 4, marginBottom: 4, background: year === state.pickYear ? getTheme().palette.themeLight : '#fff', borderColor: state.pickYear === year ? getTheme().palette.themePrimary : ''}} onClick={() => setState(prev => ({...prev, pickYear: year}))}>{year}</div>
                    })
                  }
                </div>
              {/* <Dropdown 
                label="Year"
                selectedKey={state.pickYear}
                options={YEARS.map(y => ({key: y, text: y}))}
                onChange={(e, o) => setState(prev => ({...prev, pickYear: o.key}))} /> */}
            </div>
            <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
            <PrimaryButton text="Build Chart" onClick={() => {
                props.addChart(state);
                props.onDismiss();
              }} />
          </div>
        }
        {
          (state.chartType === 'Multiple Alternatives' || state.chartType === 'Multiple Locations') && <div>
            <div className='mb3'>
              {
                LOOKUP_KEYS
                .concat({key: 'pickYear', text: 'Pick Year', color: getTheme().palette.neutralSecondary})
                .map(item => {
                  const isPicked = state.lookupKey === item.key;
                  return <div 
                    key={item.key}
                    className='hover-label'
                    style={{
                      borderBottom: `2px solid ${item.color}`, 
                      marginBottom: 4, 
                      padding: 8,
                      cursor: 'pointer'
                    }} 
                    onClick={() => setState(prev => ({...prev, lookupKey: item.key}))}>
                    <Icon styles={{root: {marginRight: 12, color: isPicked ? 'green' : 'inherit' }}} iconName={isPicked ? 'SkypeCircleCheck' : 'CircleRing'} />
                    {item.text}
                  </div>
                })
              }
            </div>
            <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
            <PrimaryButton text="Next" onClick={() => setStep(prev => prev+1)} />
          </div>
        }
        {
          state.chartType === 'Period of Record' && <div>
            <div className='mb3'>
              <DateRange 
                hidden={dateRangePickerHidden}
                onRender={(data) => <div className='ms-fontSize-18' onClick={data.onClick}>{data.dateText}<IconButton iconProps={{iconName: 'Edit'}} /></div>}
                toggleDateRangePickerHidden={toggleDateRangePickerHidden} 
                startDay={state.startDay} 
                endDay={state.endDay} 
                onChange={(startDay, endDay) => setState(prev => ({...prev, startDay, endDay}))} />
            </div>
            <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
            <PrimaryButton text="Build Chart" onClick={() => {
                props.addChart(state);
                props.onDismiss();
              }} />
          </div>
        }
      </React.Fragment>
    }

    {
      step === 4 && <React.Fragment>
        {
          (state.chartType === 'Multiple Alternatives' || state.chartType === 'Multiple Locations') && <div>
            <div className='mb3'>
              <DateRange 
                hidden={dateRangePickerHidden}
                onRender={(data) => <div className='ms-fontSize-18' onClick={data.onClick}>{data.dateText}<IconButton iconProps={{iconName: 'Edit'}} /></div>}
                toggleDateRangePickerHidden={toggleDateRangePickerHidden} 
                startDay={state.startDay} 
                endDay={state.endDay} 
                onChange={(startDay, endDay) => setState(prev => ({...prev, startDay, endDay}))} />
              <div style={{display: state.lookupKey === 'pickYear' ? 'flex' : 'none', flexWrap: 'wrap'}}>
                {
                  YEARS.map(year => {
                    return <div className='hover-label' key={year} style={{marginRight: 4, marginBottom: 4, background: year === state.pickYear ? getTheme().palette.themeLight : '#fff', borderColor: state.pickYear === year ? getTheme().palette.themePrimary : ''}} onClick={() => setState(prev => ({...prev, pickYear: year}))}>{year}</div>
                  })
                }
              </div>
            </div>
            <PrimaryButton text="Back" className='mr2' onClick={() => setStep(prev => prev-1)} />
            <PrimaryButton 
              text="Build Chart" 
              onClick={() => {
                props.addChart(state);
                props.onDismiss();
              }} />

          </div>
        }
      </React.Fragment>
    }
    

    {/* <button disabled={step === 0} onClick={() => setStep(prev => prev - 1)}>Last</button>
    <button onClick={() => setStep(prev => prev + 1)}>Next</button> */}
  </div>
}