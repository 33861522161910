import React from 'react';
import { Label } from '@fluentui/react';
import { labelStyle } from './Hydraviz';

export default function ToggleButton(props){
  return <div>
    {props.label && <Label styles={labelStyle}>{props.label}</Label>}
    <div className='fr'>
      {
        props.buttons.map((button, index) => {
          return <div key={button.key} className='hover-label' style={{marginRight: 8, background: button.key === props.activeKey ? '#c7e0f4' : '#fff', borderColor: button.key === props.activeKey ? '#0078d4' : '#c8c6c4'}} onClick={button.onClick}>{button.text}</div>
        })
      }
    </div> 
  </div>
}