import React from 'react';
import { LOOKUP_KEYS, ChartPicker, AltPicker, LocationPicker, LookupKeyGroupPicker, ThresholdPicker, Plot, BASIC_LOOKUPS, ThresholdSummary } from './Hydraviz';

import { MultiYearPicker } from './Spaghetti';
import Plotly from 'plotly.js';

import _ from 'lodash';
import DateRange from './DateRange';
import { DEFAULT_COLORS }  from './lib';
import { trimSeries } from './lib/traces';
import { getTheme, Icon } from '@fluentui/react';
import { YAxisControls } from './MultiAlt';
import Help from './Help';

export const processShapes = (objects, cal) => {

  let MIN = Math.min(...cal.map(el => el.julian));
  let MAX = Math.max(...cal.map(el => el.julian));

  const getMinMaxDates = ({startWindow, endWindow}) => {

    let minDate = cal.find(el => el.julian === startWindow);
    let maxDate = cal.find(el => el.julian === endWindow);

    if(!minDate){
      minDate = cal.find(el => el.julian === MIN);
    }
    if(!maxDate){
      maxDate = cal.find(el => el.julian === MAX);
    }

    return {
      x0: minDate && minDate.date,
      x1: maxDate && maxDate.date
    }
  }

  return objects.map(el => {

    let { x1, x0 } = getMinMaxDates(el);

    return {
      key: el.key,
      visible: el.visible,
      type: 'rect',
      name: el.name,
      xref: 'x',
      yref: 'y',
      y0: el.lowerRange,
      y1: el.upperRange,
      x0,
      x1,
      fillcolor: el.fill ? el.color : 'none',
      opacity: el.fill ? el.opacity : 1,
      line: {
        width: 2,
        color: el.color
      }
    }
  })
}

export const resolveWindow = (dates, threshold) => {
  let min, max;
  let { startWindow, endWindow } = threshold;

  if(endWindow < startWindow){
    let end = dates.filter(el => el.julian <= endWindow);
    let start = dates.filter(el => el.julian >= startWindow);
    return [...start, ...end];
  }

  let minDate = Math.min(...dates.map(d => d.julian));
  let maxDate = Math.max(...dates.map(d => d.julian));
  if(minDate > startWindow){
    min = minDate;
  } else {
    min = startWindow;
  }
  if(maxDate < startWindow){
    max = maxDate;
  } else {
    max = endWindow
  }
  return dates.filter(el => el.julian >= min && el.julian <= max);
}

export default function SingleAlternative(props){
  
  const style = {width: '100%', height: '100%'};

  const [keys, setKeys] = React.useState(LOOKUP_KEYS.map(key => ({...key, visible: true})));
  const [thresholds, setThresholds] = React.useState([]);
  const [dateRangePickerHidden, toggleDateRangePickerHidden] = React.useState(true);
  
  const { ALL_DAYS, startCalDay, alternatives=[], YEARS=[], globalLayoutSettings, thresholdInfo, startDay, endDay, selectedAlt, location, pickYear } = props;
  
  React.useEffect(() => {
    // console.log(`ask the db for ${selectedAlt} at ${location} between ${startDay} and ${endDay}`);
  }, [startDay, endDay, selectedAlt, location, pickYear]);

  React.useEffect(() => {
    setThresholds(props.thresholds);
  }, [props.thresholds]);

  const dataSet = React.useMemo(() => {
    return props.master.find(item => item.key === props.selectedAlt);
  }, [props]);

  const measure = React.useMemo(() => {
    const { measure } = props.locations.find(el => el.key === props.location) || {measure: "Unknown"};
    return measure;
  }, [props.locations, props.location]);

  const minMax = React.useMemo(() => {
    let data = props.master.find(el => el.alternative === props.selectedAlt)?.data.find(el => el.location === props.location);
    if(data) return {minYear: data.minYear, maxYear: data.maxYear};
    else return {minYear: Math.min(...YEARS), maxYear: Math.max(...YEARS)};
  }, [props.master, props.location, props.selectedAlt, YEARS]);

  const COLOR_LOOKUP = React.useMemo(() => {
    let holder = {};
    let counter = 0;

    for(let i = minMax.minYear; i <= minMax.maxYear; i++){
      holder[i] = DEFAULT_COLORS[counter];
      counter++;
      if(counter === (DEFAULT_COLORS.length)){
        counter = 0;
      }
    }
    return holder;
  }, [minMax.minYear, minMax.maxYear]);

  const FILTERED_CAL = React.useMemo(() => ALL_DAYS.filter((d, index) => {
    let startIndex = ALL_DAYS.findIndex(d => d.julian === props.startDay);
    let endIndex = ALL_DAYS.findIndex(d => d.julian === props.endDay);
    return index >= Math.min(startIndex, endIndex) && index <= Math.max(startIndex, endIndex);
  }), [props.endDay, props.startDay, ALL_DAYS]);

  const FILTERED_CAL_REV = React.useMemo(() => [].concat(...FILTERED_CAL).reverse(),[FILTERED_CAL]);
  
  const chartData = React.useMemo(() => {

    let data = dataSet?.data.find(loc => loc.location === props.location);

    if(!data) return [];

    let traces = [];

    // const x = FILTERED_CAL.map((d) =>  `${d.month} ${d.day}`);
    const x = FILTERED_CAL.map((d) => d.calDate);

    let grouped = _.groupBy(keys, 'group');

    BASIC_LOOKUPS.forEach(({key, name, color}) => {

      if(key === 'median' || key === 'mean') return;

      const x = [...FILTERED_CAL.map((d) => d.calDate), ...FILTERED_CAL_REV.map((d) => d.calDate)];

      let y = [];

      grouped[key].forEach((set, index) => {
        if(!set.visible) return;
        if(index === 0){
          FILTERED_CAL.forEach((day) => {
            y.push(data.stats[day.julian][set.key]);
          })
        } else {
          FILTERED_CAL_REV.forEach((day) => {
            y.push(data.stats[day.julian][set.key]);
          }) 
        }
      });

      // this will mock the fill to self option, should add the default traces as calc but hide them
      traces.push({
        key: key,
        type: 'scatter',
        chartType: 'stat',
        mode: 'lines',
        name: name,
        fill: grouped[key].length > 1 ? 'toself' : 'none',
        hoverinfo: key !== 'median' ? 'none' : 'text',
        text: x.map((day, index) => `${name}, ${day}<br>${y[index]} ${measure}`),
        x: x,
        y: y,
        line: {
          color: color,
          width: 1,
        },
        color: color,
        fillcolor: color
      })
    });

    // END OF BASIC LOOKUPS


    LOOKUP_KEYS.forEach(item => {
      if(item.key === 'median' || item.key === 'mean') return;
      let { visible } = keys.find(el => el.key === item.key);
      traces.push({
        key: item.key,
        name: item.text,
        chartType: 'scenario',
        showlegend: false,
        hoverinfo: 'text',
        mode: 'lines',
        visible,
        x,
        y: FILTERED_CAL.map(({julian}) => data.stats[julian][item.key]),
        line: {
          color: item.color,
          width: 0.5
        }
      })
    });



    props.allYearsVisible && Object.keys(data.annualData).forEach(year => {
      if(parseFloat(year) === parseFloat(props.pickYear)) return;
      let y = trimSeries(FILTERED_CAL, data.annualData[year]);
      traces.push({
        type: 'scatter',
        mode: 'lines',
        showlegend: false,
        name: `${props.selectedAlt} - ${year}`,
        hoverinfo: 'none',
        x,
        y,
        opacity: 0.3,
        text: FILTERED_CAL.map((day, index) => `${props.selectedAlt}, ${day.date}<br>${y[index]}`),
        line: {
          color: getTheme().palette.themeTertiary,
          width: 1,
        }
      });
    });

    // add the median
    LOOKUP_KEYS.forEach(item => {
      if(item.key === 'median' || item.key === 'mean') {
        let { visible } = keys.find(el => el.key === item.key);
        const y = FILTERED_CAL.map(({julian}) => data.stats[julian][item.key]);
        traces.push({
          key: item.key,
          name: item.text,
          chartType: 'scenario',
          hoverinfo: 'text',
          mode: 'lines',
          visible,
          x,
          y,
          text: y.map((y, i) => `${props.selectedAlt} ${item.text}, ${x[i]}<br>${y} ${measure}`),
          line: {
            color: item.color,
            width: 3
          }
        })
      } else {
        return;
      }
    });

    // adds the selected years
    !props.pickYearVisible && props.pickYears.forEach(year => {

      if(!data.annualData[year]) return;

      let y = trimSeries(FILTERED_CAL, data.annualData[year]);
      traces.push({
        type: 'scatter',
        mode: 'lines',
        chartType: 'scenario',
        name: `${props.selectedAlt} - ${year}`,
        hoverinfo: 'text',
        visible: true,
        x,
        y,
        text: FILTERED_CAL.map((day, index) => `${props.selectedAlt}, ${day.date}<br>${year}`),
        line: {
          color: COLOR_LOOKUP[year],
          width: 2
        }
      });
    });

    if(props.pickYearVisible){
      // console.log('only add the pick year');
      let year = parseFloat(props.pickYear);
      if(data.annualData[year]){
        let y = trimSeries(FILTERED_CAL, data.annualData[year]);
  
        traces.push({
          type: 'scatter',
          mode: 'lines',
          chartType: 'scenario',
          name: `${props.selectedAlt} - ${year}`,
          hoverinfo: 'text',
          visible: true,
          x,
          y,
          text: FILTERED_CAL.map((day, index) => `${props.selectedAlt}, ${day.date}<br>${year}`),
          line: {
            color: getTheme().palette.magentaLight,
            width: 2
          }
        });
      };
    }


    let startIndex = FILTERED_CAL[0].dayLookup;
    let endIndex = FILTERED_CAL[FILTERED_CAL.length - 1].dayLookup ;

    thresholds.forEach(threshold => {

      let { isWindow, isRange, isStepped, steps, name, visible, color, fill, pattern, stepsLower, upperRange, lowerRange, value } = threshold.data;

      let y = [];
      let text = [];
      let x = [];

      if(isWindow){
        x = resolveWindow(FILTERED_CAL, threshold.data).map(d => d.calDate);
      } else {
        x = FILTERED_CAL.map((d) => d.calDate);
      }

      let firstHalfSteps = [].concat(...steps.filter(el => el.julian >= FILTERED_CAL[0].julian));
      let secondHalfSteps = [].concat(...steps.filter(el => el.julian < FILTERED_CAL[0].julian));
      let firstHalfStepsLower = [].concat(...stepsLower.filter(el => el.julian >= FILTERED_CAL[0].julian));
      let secondHalfStepsLower = [].concat(...stepsLower.filter(el => el.julian < FILTERED_CAL[0].julian));
      steps = [...firstHalfSteps, ...secondHalfSteps];
      stepsLower = [...firstHalfStepsLower, ...secondHalfStepsLower];
      
      if(isRange){
        if(isStepped){
          // upper first
          y = steps.slice(startIndex - 1, endIndex).map(({value}) => parseFloat(value));

          traces.push({
            key: `${threshold.id}-up`,
            name: `${name} Upper`,
            visible: visible,
            hoverinfo: 'name',
            x,
            y,
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })

          y = stepsLower.slice(startIndex - 1, endIndex).map(({value}) => parseFloat(value));

          traces.push({
            key: `${threshold.id}-low`,
            name: `${name} - Lower`,
            visible: visible,
            hoverinfo: 'name',
            x,
            y,
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })

          return;

        } else {
          //  upper first
          y = x.map((d) => upperRange);
          text = x.map((d) => `${name}, ${x[0]} - ${x[x.length - 1]}<br>${lowerRange} - ${upperRange} ${measure}`);
  
          traces.push({
            key: threshold.id,
            name: `${name} <br> ${lowerRange} - ${upperRange}`,
            visible: visible,
            x,
            y,
            text,
            hoverinfo: 'text',
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          });
  
          // then lower
          y = x.map((d) => lowerRange);
          text = x.map((d) => `${name}, ${x[0]} - ${x[x.length - 1]}<br>${lowerRange} - ${upperRange} ${measure}`);
          traces.push({
            key: threshold.id,
            name: `${name}`,
            visible: visible,
            x,
            y,
            fill: fill ? 'tonexty' : false,
            text,
            hoverinfo: 'text',
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          });
          return;
        }
      } else {
        if(isStepped){

          y = steps.filter(({dayLookup}) => dayLookup >= startIndex && dayLookup <= endIndex).map(({value}) => parseFloat(value));
          text = steps.map(({date, value}) => `${name}, ${date}<br>${value} ${measure}`);

        } else {

          y = x.map((d) => value);
          text = x.map((d) => `${name}, ${d}<br>${value} ${measure}`);

        }
      }
      
      traces.push({
        key: threshold.id,
        name: name,
        visible: visible,
        x,
        y,
        text,
        hoverinfo: 'text',
        mode: 'lines',
        line: {
          color: color,
          width: 2,
          dash: pattern
        }
      })
    });

    return traces;
  }, [dataSet, props.pickYear, props.location, keys, props.pickYearVisible, props.startDay, props.endDay, thresholds, measure, FILTERED_CAL, FILTERED_CAL_REV, props.selectedAlt, props.allYearsVisible, props.pickYears, COLOR_LOOKUP, props.pickYear, props.pickYearVisible]);


  const titleDate = React.useMemo(() => {
    if(props.pickYearVisible){
      return startCalDay === 1 ? `${FILTERED_CAL[0].date}, ${props.pickYear} - ${FILTERED_CAL[FILTERED_CAL.length - 1].date}, ${props.pickYear}` : `${FILTERED_CAL[0].date}, ${props.pickYear} - ${FILTERED_CAL[FILTERED_CAL.length - 1].date} ${props.pickYear + 1}`;
    } else {
      return `${FILTERED_CAL[0].date} - ${FILTERED_CAL[FILTERED_CAL.length - 1].date}`
    }
  }, [props.pickYear, startCalDay, FILTERED_CAL, props.pickYearVisible]);

  const tickformat = React.useMemo(() => {
    let numberOfDays = props.endDay - props.startDay;
    if(numberOfDays > 200){
      return '%b';
    }
    return '%b %d'
  }, [props.startDay, props.endDay]);

  const [layout, setLayout] = React.useState(() => {
    return {
      autosize: true,
      showlegend: false,
      hovermode: 'closest',
      title: `${props.location} - ${props.selectedAlt}<br>${titleDate}`,
      legend: {orientation: 'v'},
      yaxis: {
        automargin: true, 
        title: measure,
        autorange: true,
        linewidth: 1, 
        linecolor: '#333',
        zeroline: false,
        ticklen: 5
      },
      xaxis: {
        automargin: true,
        tickformat: tickformat,
        nticks: 12,
        linewidth: 1,
        linecolor: '#333',
        zeroline: false,
        ticklen: 5
      },
      font: {
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
        size: 14
      }
    }
  });

  const shapeThresholds = React.useMemo(() => {
    return thresholds.filter(el => el.isWindow && el.isRange);
  }, [thresholds]);

  React.useEffect(() => {
    const shapes = processShapes(shapeThresholds, FILTERED_CAL);
    setLayout(prev => ({
      ...prev, 
      title: `${props.location} - ${props.selectedAlt}<br>${titleDate}`, 
      yaxis: {...prev.yaxis, title: measure},
      xaxis: {...prev.xaxis, tickformat},
      shapes,
      font: {
        ...prev.font,
        size: globalLayoutSettings.fontSize
      }
    }));
  }, [props.location, props.selectedAlt, props.startDay, props.endDay, props.pickYear, measure, FILTERED_CAL, titleDate, shapeThresholds, globalLayoutSettings, tickformat]);

  const onScreenCap = (gd, e) => {
    Plotly.relayout(gd, {showlegend: true});
    Plotly.downloadImage(gd, {format: 'svg'});
    Plotly.relayout(gd, {showlegend: false});
  }

  const scenarios = React.useMemo(() => {
    return chartData.filter(t => t.chartType === 'scenario');
  }, [chartData]);

  const toggleTraces = (groupKey) => {
    setKeys(prev => prev.map(key => {
      if(key.group === groupKey){
        return {
          ...key,
          visible: !key.visible
        }
      } else {
        return key
      }
    }))
  };

  const config = {
    modeBarButtonsToRemove: ['toImage'],
    displaylogo: false,
    editable: false,
    modeBarButtonsToAdd: [
      {
        name: 'Capture',
        click: onScreenCap, 
        icon: {
          width: 1000,
          height: 1000,
          path: 'm500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z',
          transform: 'matrix(1 0 0 -1 0 850)'
        }
      }
    ]
  };

  const onDoubleClick= () => props.changeFilters({startDay: FILTERED_CAL[0].julian, endDay: FILTERED_CAL[FILTERED_CAL.length - 1].julian});

  const onRelayout = data => {
    if(data){

      if(data['xaxis.autorange']){
        return props.changeFilters({startDay: 1, endDay: 365});
      }


      let start = data['xaxis.range[0]'];
      let end = data['xaxis.range[1]'];

      if(start) start = start.split(" ")[0];
      if(end) end = end.split(" ")[0];

      let startDayFromResize = ALL_DAYS.find(el => el.calDate === start)?.julian;
      let endDayFromResize = ALL_DAYS.find(el => el.calDate === end)?.julian;

      if(startDayFromResize && endDayFromResize){
        return props.changeFilters({startDay: startDayFromResize, endDay: endDayFromResize});
      }
    }
    setLayout(prev => ({...prev, ...data}));
  }

  const chartPickerRef = React.useRef(null);
  const locationPickerRef = React.useRef(null);
  const altPickerRef = React.useRef(null);
  const dateRangeRef = React.useRef(null);
  const yAxisRef = React.useRef(null);
  const tracesRef = React.useRef(null);
  const multiYearRef = React.useRef(null);
  const thresholdRef = React.useRef(null);

  const helpItems = [chartPickerRef, locationPickerRef, altPickerRef, dateRangeRef, yAxisRef, tracesRef, multiYearRef, thresholdRef];


  return <div style={{display: 'flex', flex: 1, background: '#fff', padding: 8, height: 'calc(100% - 33px)', maxHeight: 'calc(100% - 33px)'}}>
    <div style={{width: '15%', display: props.thresholdInfo ? 'none' : 'block'}}>
      <div style={{marginBottom: 32}} id="chart-picker" ref={chartPickerRef}>
        <ChartPicker
          chartType={props.chartType} 
          onChange={(chartType) => props.changeFilters({chartType})} />
      </div>
      <div style={{marginBottom: 32}} >
        <div id="location-picker" ref={locationPickerRef}>
          <LocationPicker
            locationGroups={props.locationGroups} 
            toggleShowMapper={props.toggleShowMapper} 
            locations={props.locations} 
            location={props.location} 
            onChange={(location) => props.changeFilters({location})} />
        </div>
        <div id="alt-picker" ref={altPickerRef}>
          <AltPicker 
            alternatives={alternatives} 
            alternative={props.selectedAlt} 
            onChange={(selectedAlt) => props.changeFilters({selectedAlt})} />
        </div>
        <div id="date-picker" ref={dateRangeRef}>
          <DateRange 
            startCalDay={props.startCalDay} 
            hidden={dateRangePickerHidden} 
            toggleDateRangePickerHidden={toggleDateRangePickerHidden} 
            startDay={props.startDay} 
            endDay={props.endDay} 
            onChange={(startDay, endDay) => props.changeFilters({startDay, endDay})} />   
        </div>
      </div>
      <div id="y-axis-controls" ref={yAxisRef}>
        <YAxisControls 
          location={props.location} 
          state={layout.yaxis} 
          onChange={(changes) => setLayout(prev => ({...prev, yaxis: {...prev.yaxis, ...changes}}))} />
      </div>
    </div>
    
    {
      chartData.length > 0 ? (
        <div style={{position: 'relative', width: '70%'}}>
          <Plot
            style={style}
            onDoubleClick={onDoubleClick}
            onRelayout={onRelayout}
            layout={layout}
            config={config}
            useResizeHandler
            data={chartData} />
        </div>
      ) : <div className='fr jcc aic empty f1' style={{width: '70%', height: '100%'}}>
        <div style={{textAlign: 'center'}}>
          <Icon iconName="SearchIssue" styles={{root: {fontSize: 42}}} />
          <p style={{fontSize: 18}}>No data to display.</p>
        </div>
      </div>
    }
    <div style={{width: props.thresholdInfo ? '30%' : '15%', height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column'}}>
      {
        thresholdInfo 
        ? 
          <ThresholdSummary 
            {...thresholdInfo} 
            startCalDay={props.startCalDay}
            addThreshold={props.addThreshold}
            editThreshold={props.editThreshold} 
            setThresholdInfo={props.setThresholdInfo}
            changeFilters={props.changeFilters}
            thresholds={thresholds}
            startDay={startDay}
            endDay={endDay}
            FILTERED_CAL={FILTERED_CAL} 
            ALL_DAYS={ALL_DAYS} 
            onDismiss={props.onDismissThresholdSummary} />
        : 
        <>
          <div ref={tracesRef} id="traces-picker">
            <LookupKeyGroupPicker 
              alternative={props.selectedAlt} 
              pickYear={props.pickYear} 
              keys={keys} 
              allYearsVisible={props.allYearsVisible}
              onChange={toggleTraces} 
              pickYearVisible={props.pickYearVisible} 
              changeFilters={props.changeFilters} />
          </div>
          <div ref={multiYearRef} id="year-picker">
            <MultiYearPicker 
              YEARS={YEARS}
              {...minMax}
              singleYearEnabled
              startCalDay={startCalDay}
              pickYear={props.pickYear}
              pickYearVisible={props.pickYearVisible}
              currentlyPicked={props.pickYears}
              changeFilters={props.changeFilters}
              onClear={() => props.changeFilters({pickYears: []})}
              onChange={(year) => {
                props.changeFilters({pickYears: props.pickYears.includes(year) ? props.pickYears.filter(y => y !== year) : props.pickYears.concat(year).sort((a, b) => a-b)});
              }} />
          </div>
          <div id="threshold-picker" ref={thresholdRef}>
            <ThresholdPicker
              ALL_DAYS={ALL_DAYS} 
              addThreshold={props.addThreshold}
              editThreshold={props.editThreshold}
              FILTERED_CAL={FILTERED_CAL}
              startCalDay={startCalDay}
              setThresholdInfo={props.setThresholdInfo} 
              location={props.location} 
              startDay={props.startDay} 
              endDay={props.endDay} 
              thresholds={thresholds} 
              changeFilters={props.changeFilters} 
              scenarios={scenarios} />
          </div>
        </>
      }
    </div>
    <Help show={props.showHelp} items={helpItems} chartType={"Single Alternative"} onDismiss={props.onCloseHelp} />
  </div>
}