import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Plot from 'react-plotly.js';
import { Depths, ProgressIndicator, Text, getTheme, IconButton } from '@fluentui/react';
import Papa from 'papaparse';
import { testTaget, stdTestTaget } from './data/index';
import WeatherForecast from './WatherForecast';
// import Forecast from './Forecast';

const milkFloodBands = [
  {
    type: 'rect',
    y0: 18,
    y1: 20,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#fee68550',
    label: "Action stage",
    line: {
      width: 0,
      color: '#fee685'
    }
  },
  {
    type: 'rect',
    y0: 20,
    y1: 28,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#e5a00050',
    label: "Minor flood stage",
    line: {
      width: 0,
      color: '#e5a000',
    }
  },
  {
    type: 'rect',
    y0: 28,
    y1: 30,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#e5220750',
    label: "Moderate flood stage",
    line: {
      width: 0,
      color: '#e52207',
    }
  },
  {
    type: 'rect',
    y0: 30,
    y1: 32,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#d85bef50',
    label: "Major flood stage",
    line: {
      width: 0,
      color: '#d85bef',
    }
  },
];

const wolfFloodBands = [
  {
    type: 'rect',
    y0: 21,
    y1: 23,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#fee68550',
    label: "Action stage",
    line: {
      width: 0,
      color: '#fee685'
    }
  },
  {
    type: 'rect',
    y0: 23,
    y1: 26.5,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#e5a00050',
    label: "Minor flood stage",
    line: {
      width: 0,
      color: '#e5a000',
    }
  },
  {
    type: 'rect',
    y0: 26.5,
    y1: 27.5,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#e5220750',
    label: "Moderate flood stage",
    line: {
      width: 0,
      color: '#e52207',
    }
  },
  {
    type: 'rect',
    y0: 27.5,
    y1: 30,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#d85bef50',
    label: "Major flood stage",
    line: {
      width: 0,
      color: '#d85bef',
    }
  },
];

const yellowFloodBands = [
  {
    type: 'rect',
    y0: 17,
    y1: 19,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#fee68550',
    label: "Action stage",
    line: {
      width: 0,
      color: '#fee685'
    }
  },
  {
    type: 'rect',
    y0: 19,
    y1: 20,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#e5a00050',
    label: "Minor flood stage",
    line: {
      width: 0,
      color: '#e5a000',
    }
  },
  {
    type: 'rect',
    y0: 20,
    y1: 21.7,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#e5220750',
    label: "Moderate flood stage",
    line: {
      width: 0,
      color: '#e52207',
    }
  },
  {
    type: 'rect',
    y0: 21.7,
    y1: 24,
    x0: 0,
    x1: 1,
    yref:'y',
    xref: 'paper',
    fillcolor: '#d85bef50',
    label: "Major flood stage",
    line: {
      width: 0,
      color: '#d85bef',
    }
  },
];

function generateDateArray() {
  const startDate = new Date('${startDate}');
  let endDate = new Date(); // Today's date
  endDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate() + 1); // Move to the end of today
  const dateArray = [];

  for (let dt = new Date(startDate); dt < endDate; dt.setDate(dt.getDate() + 1)) {
    dateArray.push(dt.toISOString().split('T')[0]);
  }

  return dateArray;
}

const CURRENT_YEAR_DATES = generateDateArray();

// let DATE_ACC = {};


const generateNewDateAccumulator = () => {
  let newObject = {};
  CURRENT_YEAR_DATES.forEach(day => newObject[day] = []);
  return {...newObject};
}

const groupByDate = (data) => {
  let acc = generateNewDateAccumulator();
  data.forEach((entry) => {
    const date = entry.date.split('T')[0]; // Extract date portion
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(parseFloat(entry.value));
  });
  return acc;
};

const groupByDay = (data) => {
  let acc = generateNewDateAccumulator();
  data.forEach((entry) => {
    let date = entry.dateTime.split('T')[0]; // Extract date portion
    date = date.replace('2023', '2024');
    date = date.replace('2018', '2024');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(parseFloat(entry.value));
  });
  return acc;
};

const calculateDailyAverages = (dailyData) => {
  return Object.keys(dailyData).map(date => {
    let newDate = date.replace(date.substring(0, 4), '2024');
    const values = dailyData[date].filter(val => val !== -999999);
    const sum = values.reduce((total, value) => total + value, 0);
    let average = sum / values.length;
    if(average < 0) average = null;
    return { date: newDate, average };
  });
};

const parseFile = file => {
  return new Promise(resolve => {
    Papa.parse(file, {
      complete: res => resolve(res.data),
      download: true
    })
  })
};

const DailyUpdate = () => {
  const [wolfPointMedian, setWolfPointMedian] = useState(null);
  const [milkRiverMedian, setMilkRiverMedian] = useState(null);
  const [yellowstoneMedian, setYellowstoneMedian] = useState(null);
  const [dailyStageAverages, setDailyStageAverages] = useState(null);
  const [dailyAverageFlow, setDailyAverageFlow] = useState(null);
  const [dailyStageAverages2023, setDailyStageAverages2023] = useState(null);
  const [dailyAverageFlow2023, setDailyAverageFlow2023] = useState(null);
  const [dailyStageAverages2018, setDailyStageAverages2018] = useState(null);
  const [dailyAverageFlow2018, setDailyAverageFlow2018] = useState(null);
  const [milkFlow, setMilkFlow] = useState([]);
  const [milkStage, setMilkStage] = useState(null);
  const [yellowFlow, setYellowFlow] = useState(null);
  const [yellowStage, setYellowStage] = useState(null);
  const [peckFlow, setPeckFlow] = useState(null);
  const [peckStage, setPeckStage] = useState(null);
  const [peckResElevation, setPeckResElevation] = useState(null);
  const [peckResInflow, setPeckResInflow] = useState(null);
  const [peckResOutflow, setPeckResOutflow] = useState(null);

  const currentDate = new Date().toISOString().split('T')[0];

  const getDateFromDaysAgo = (daysAgo) => {
    var today = new Date();
    var pastDate = new Date(today);
    pastDate.setDate(today.getDate() - daysAgo);
  
    var year = pastDate.getFullYear();
    var month = String(pastDate.getMonth() + 1).padStart(2, '0'); // Adding 1 since getMonth() returns zero-based month
    var day = String(pastDate.getDate()).padStart(2, '0');
  
    return year + '-' + month + '-' + day;
  }

  const startDate = getDateFromDaysAgo(120);

  function getDaysSinceJanuary1() {
    // Get the current date
    const currentDate = new Date();
  
    // Get January 1st of the current year
    const januaryFirst = new Date(currentDate.getFullYear(), 0, 1);
  
    // Calculate the difference in milliseconds between the current date and January 1st
    const differenceInMilliseconds = currentDate - januaryFirst;
  
    // Convert milliseconds to days
    const daysSinceJanuary1 = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  
    return daysSinceJanuary1;
  }

  const daysInYear = getDaysSinceJanuary1();

  // const fortPeckDataCSV = await axios.get('https://water.usace.army.mil/a2w/CWMS_CRREL.cwms_data_api.get_report_json?p_location_id=2998030&p_parameter_type=Flow:Stor:Precip:Stage:Elev&p_last=65&p_last_unit=days&p_unit_system=EN&p_format=CSV');
  
  useEffect(() => {
    const fetchData = async () => {


      try {

        const fortPeckData = await parseFile('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/timeSeriesReport.csv');

        let elevationData = [];
        let inflowData = [];
        let outflowData = [];

        fortPeckData.forEach((record, index) => {
          if(index === 0 || (index === fortPeckData.length - 1)) return;
          elevationData.push({
            date: record[0],
            value: parseFloat(record[1])
          })
          inflowData.push({
            date: record[0],
            value: parseFloat(record[4])
          })
          outflowData.push({
            date: record[0],
            value: parseFloat(record[5])
          })
        });

        const fortPeckElev = groupByDate(elevationData);
        const fortPeckAverageElevation = calculateDailyAverages(fortPeckElev);
        const fortPeckInflow = groupByDate(inflowData);
        const fortPeckAverageInflow = calculateDailyAverages(fortPeckInflow);
        const fortPeckOutflow = groupByDate(outflowData);
        const fortPeckAverageOutflow = calculateDailyAverages(fortPeckOutflow);

        setPeckResElevation(fortPeckAverageElevation);
        setPeckResInflow(fortPeckAverageInflow);
        setPeckResOutflow(fortPeckAverageOutflow);

      } catch (error) {
        console.log(error);
      }

      try {
        let wolfPointMedianData = await parseFile('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/Missouri+River+Discharge+-+Median.csv');
        let milkRiverMedianData = await parseFile('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/Milk+River+Median+Discharge.csv');
        let yellowstoneMedianData = await parseFile('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/Yellowstone+River+Median+Discharge.csv');

        if(wolfPointMedianData){
          wolfPointMedianData.shift();
          wolfPointMedianData.pop();
          setWolfPointMedian(wolfPointMedianData.filter(el => !el[0].includes('-02-29')));
        }

        if(milkRiverMedianData){
          milkRiverMedianData.shift();
          milkRiverMedianData.pop();
          setMilkRiverMedian(milkRiverMedianData);
        }

        if(yellowstoneMedianData){
          yellowstoneMedianData.shift();
          yellowstoneMedianData.pop();
          setYellowstoneMedian(yellowstoneMedianData);
        }

        // const stageData = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06177000&parameterCd=00065&startDT=${startDate}&endDT=${currentDate}&siteStatus=all&format=json`);
        // const flowData = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06177000&parameterCd=00060&startDT=${startDate}&endDT=${currentDate}&siteStatus=all&format=json`);

        const stageData = await axios.get(`https://nwis.waterservices.usgs.gov/nwis/iv/?sites=06177000&startDT=2024-01-01T00:00:00.000-07:00&endDT=${currentDate}T23:59:59.999-06:00&parameterCd=00065&format=json`);



        const flowData = await axios.get(`https://nwis.waterservices.usgs.gov/nwis/iv/?sites=06177000&startDT=2024-01-01T00:00:00.000-07:00&endDT=${currentDate}T23:59:59.999-06:00&parameterCd=00060&format=json`);

        // console.log(flowData);


        const peckStageData = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06132000&agencyCd=USGS&parameterCd=00065&startDT=${startDate}T00:00:00.000-07:00&endDT=${currentDate}T23:59:59.999-07:00&siteStatus=all&format=json`);
        const peckFlowData = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06132000&agencyCd=USGS&parameterCd=00060&startDT=${startDate}T00:00:00.000-07:00&endDT=${currentDate}T23:59:59.999-07:00&siteStatus=all&format=json`);

        const flowData2023 = await axios.get('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/2023_Ft_Peck_Discharge.json');
        const stageData2023 = await axios.get('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/2023_Ft_Peck_Stage.json');

        const flowData2018 = await axios.get('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/2018_Ft_Peck_Discharge.json');
        const stageData2018 = await axios.get('https://hydroviz.s3.us-west-2.amazonaws.com/missouri-2024/2018_Ft_Peck_Stage.json');

        const yellowStageData = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06329500&parameterCd=00065&startDT=${startDate}&endDT=${currentDate}&siteStatus=all&format=json`);
        const yellowFlowData = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06329500&parameterCd=00060&startDT=${startDate}&endDT=${currentDate}&siteStatus=all&format=json`);

        const milkyNewLinkStage = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06174500&parameterCd=00065&startDT=${startDate}&endDT=${currentDate}&siteStatus=all&format=json`);
        const milkyNewLinkFlow = await axios.get(`https://waterservices.usgs.gov/nwis/iv/?sites=06174500&parameterCd=00060&startDT=${startDate}&endDT=${currentDate}&siteStatus=all&format=json`);
 
        // 2024 yellow
        const yellowStage = yellowStageData.data.value.timeSeries[0]?.values[0].value;
        const yellowFlow = yellowFlowData.data.value.timeSeries[0]?.values[0].value;
        
        // 2024 milk
        const milkStage = milkyNewLinkStage.data.value.timeSeries[0]?.values[0].value;
        const milkFlow = milkyNewLinkFlow.data.value.timeSeries[0]?.values[0].value;

        const peckStage = peckStageData.data.value.timeSeries[0]?.values[0].value;
        const peckFlow = peckFlowData.data.value.timeSeries[0]?.values[0].value;

        // 2024 W0lf
        const stage = stageData.data.value.timeSeries[0]?.values[0].value;
        const flow = flowData.data.value.timeSeries[0]?.values[0].value;

        console.log(flow);

        // 2023 Wolf
        const stage2023 = stageData2023.data.value.timeSeries[0]?.values[0].value;
        const flow2023 = flowData2023.data.value.timeSeries[0]?.values[0].value;

        // 2018 Wolf
        const stage2018 = stageData2018.data.value.timeSeries[0]?.values[0].value;
        const flow2018 = flowData2018.data.value.timeSeries[0]?.values[0].value;

        if(yellowStage){
          const dailyYellowStage = groupByDay(yellowStage);
          const yellowStageAverages = calculateDailyAverages(dailyYellowStage);
          setYellowStage(yellowStageAverages);
        } else {
          setYellowStage([]);
        }

        if(yellowFlow){
          const dailyYellowFlow = groupByDay(yellowFlow);
          const yellowFlowAverages = calculateDailyAverages(dailyYellowFlow);
          setYellowFlow(yellowFlowAverages);
        } else {
          setYellowFlow([]);
        }

        if(milkStage){
          const dailyMilkStage = groupByDay(milkStage);
          const milkStageAverages = calculateDailyAverages(dailyMilkStage);
          setMilkStage(milkStageAverages);
        } else {
          setMilkStage([]);
        }

        if(milkFlow){
          const dailyMilkFlow = groupByDay(milkFlow);
          const milkFlowAverages = calculateDailyAverages(dailyMilkFlow);
          setMilkFlow(milkFlowAverages);
        } else {
          setMilkFlow([]);
        }

        if(peckStage){
          const dailyPeckStage = groupByDay(peckStage);
          const peckStageAverages = calculateDailyAverages(dailyPeckStage);
          setPeckStage(peckStageAverages);
        } else {
          setPeckStage([]);
        }

        if(peckFlow){
          const dailyPeckFlow = groupByDay(peckFlow);
          const peckFlowAverages = calculateDailyAverages(dailyPeckFlow);
          setPeckFlow(peckFlowAverages);
        } else {
          setPeckFlow([]);
        }

        if(stage){
          const dailyStageData = groupByDay(stage);
          const stageAverages = calculateDailyAverages(dailyStageData);
          setDailyStageAverages(stageAverages);
        } else {
          setDailyStageAverages([]);
        }

        if(flow){
          const dailyFlowData = groupByDay(flow);
          const flowAverage = calculateDailyAverages(dailyFlowData);
          setDailyAverageFlow(flowAverage);
        } else {
          setDailyAverageFlow([]);
        }

        const dailyFlowData2023 = groupByDay(flow2023);
        const dailyStageData2023 = groupByDay(stage2023);

        const dailyStageData2018 = groupByDay(stage2018);
        const dailyFlowData2018 = groupByDay(flow2018);

        const stageAverages2023 = calculateDailyAverages(dailyStageData2023);
        const flowAverage2023 = calculateDailyAverages(dailyFlowData2023);

        const stageAverages2018 = calculateDailyAverages(dailyStageData2018);
        const flowAverage2018 = calculateDailyAverages(dailyFlowData2018);

        setDailyStageAverages2023(stageAverages2023);
        setDailyAverageFlow2023(flowAverage2023);

        setDailyStageAverages2018(stageAverages2018);
        setDailyAverageFlow2018(flowAverage2018);

      } catch (error) {
        console.error('Error fetching water data:', error.message);
      }
    };

    fetchData();
  }, []);

  const generateGaps = (inputData, log) => {

    let arr = [];

    let from = null;
    let to = null;

    inputData?.forEach((day, index, x) => {
      if(day.average === null){
        day.average = NaN;
      }
      if(isNaN(day.average)){
        if(!to){
          from = day.date;
        }
        if(from){
          to = day.date;
        }
        if(from && to && (!isNaN(x[index + 1]?.average))){
          arr.push({from, to});
          from = null;
          to = null;
        }
      }
    });

    const shapes = arr.map(gap => {
      return {
        type: 'rect',
        x0: gap.from,
        x1: gap.to,
        y0: 0,
        y1: 1,
        opacity: 0.48,
        fillcolor: getTheme().palette.themeLight,
        yref: 'paper',
        line: {
          width: 0
        }
      }
    });

    return shapes;
  }


  const getLastValue = arr => {
    let find = arr.slice().reverse().find(el => {
      return !isNaN(el.average);
    });
    return find.average;
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', gap: 32, padding: '120px 0px'}}>
      <div style={{background: `${getTheme().palette.themePrimary}50`, padding: 42, fontSize: 24, marginTop: -120, textAlign: 'center', borderBottom: `2px solid ${getTheme().palette.themePrimary}`}}>Gaps in data may indicate gage reading is impacted by ice</div>
      {/* WOLF POINT */}
      <div style={{width: '100%', maxWidth: 1200, margin: '0px auto'}}>
        <WeatherForecast />
        {/* <Forecast /> */}
        <Text styles={{root: {marginBottom: 16, marginTop: 16, width: '100%', textAlign: 'center', display: 'block'}}} variant='xxLarge'>
          Missouri River near Wolf Point MT
          <IconButton iconProps={{iconName: "Link"}} href='https://waterdata.usgs.gov/monitoring-location/06177000/#parameterCode=00065&showMedian=false' target='_blank' />
        </Text>
        {dailyAverageFlow ? (
          <Plot
            style={{boxShadow: Depths.depth16, width: '100%', marginBottom: 64}}
            data={[
              {
                x: dailyAverageFlow?.map(entry => entry.date),
                y: dailyAverageFlow?.map(entry => entry.average),
                type: 'scatter',
                mode: 'lines',
                marker: { color: 'red' },
                name: '2024',
                connectgaps: false
              },
              {
                x: dailyAverageFlow2023?.map(entry => entry.date),
                y: dailyAverageFlow2023?.map(entry => entry.average),
                type: 'scatter',
                mode: 'lines',
                marker: { color: getTheme().palette.neutralTertiary },
                name: '2023',
                line: {dash: 'dash', width: 1},
                connectgaps: false
              },
              {
                x: dailyAverageFlow2018?.map(entry => entry.date),
                y: dailyAverageFlow2018?.map(entry => entry.average),
                type: 'scatter',
                mode: 'lines',
                marker: { color: getTheme().palette.neutralTertiary },
                line: {dash: 'dot', width: 1},
                name: '2018',
                connectgaps: false
              },
              {
                x: wolfPointMedian?.map(arr => arr[0]),
                y: wolfPointMedian?.map(arr => parseFloat(arr[1])),
                type: 'scatter',
                mode: 'lines',
                marker: {color: getTheme().palette.orange},
                name: "Median",
                line: {dash: 'dot', width: 1}
              },
              {
                x: testTaget?.map(entry => entry.date),
                y: testTaget?.map(entry => entry.value),
                type: 'scatter',
                mode: 'lines',
                marker: { color: getTheme().palette.themePrimary},
                name: '2024 Proposed Target'
              },
              {
                x: stdTestTaget?.map(entry => entry.date),
                y: stdTestTaget?.map(entry => entry.value),
                type: 'scatter',
                mode: 'lines',
                visible: false,
                marker: { color: getTheme().palette.green},
                name: 'Std Test Target'
              }
            ]}
            layout={{
              title: 'Missouri River discharge near Wolf Point MT - 06177000',
              autosize: true,
              xaxis: {
                title: 'Date',
                type: 'date',
                showgrid: false
              },
              yaxis: {
                title: 'Discharge, cubic feet per second'
              },
              annotations: [
                {
                  x: currentDate,
                  y: 1,
                  yref: 'paper',
                  text: "Today",
                  showarrow: true,
                  arrowcolor: 'red',
                  arrowsize: 1
                }
              ],
              shapes: [
                {
                  type: 'line',
                  x0: currentDate,
                  x1: currentDate,
                  y0: 0,
                  y1: 1,
                  yref: 'paper',
                  line: {
                    color: 'red',
                    dash: 'dot',
                    width: 1
                  }
                },
                ...generateGaps(dailyAverageFlow)
              ]
            }}
          />
        ) : (
          null
        )}
        {dailyStageAverages ? (<div style={{boxShadow: Depths.depth16, width: '100%', marginBottom: 32, paddingBottom: 32, background: '#fff'}}>
            <Plot
              style={{}}
              data={[
                {
                  x: dailyStageAverages.map(entry => entry.date),
                  y: dailyStageAverages.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: 'red'},
                  name: '2024',
                  connectgaps: false
                },
                {
                  x: dailyStageAverages2023?.map(entry => entry.date),
                  y: dailyStageAverages2023?.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: getTheme().palette.neutralTertiary },
                  name: '2023',
                  line: {dash: 'dash', width: 1},
                  connectgaps: false
                },
                {
                  x: dailyStageAverages2018?.map(entry => entry.date),
                  y: dailyStageAverages2018?.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: getTheme().palette.neutralTertiary },
                  line: {dash: 'dot', width: 1},
                  name: '2018',
                  connectgaps: false
                }
              ]}
              layout={{
                title: 'Missouri River stage near Wolf Point MT - 06177000',
                autosize: true,
                xaxis: {
                  title: 'Date',
                  type: 'date',
                  showgrid: false
                },
                yaxis: {
                  title: 'Gage Height, Feet'
                },
                annotations: [
                  {
                    x: currentDate,
                    y: 1,
                    yref: 'paper',
                    text: "Today",
                    showarrow: true,
                    arrowcolor: 'red',
                    arrowsize: 1
                  }
                ],
                shapes: [
                  {
                    type: 'line',
                    x0: currentDate,
                    x1: currentDate,
                    y0: 0,
                    y1: 1,
                    yref: 'paper',
                    line: {
                      color: 'red',
                      dash: 'dot',
                      width: 1
                    }
                  },
                  ...wolfFloodBands,
                  ...wolfFloodBands.map(band => ({
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    y0: band.y0,
                    y1: band.y0,
                    xref: 'paper',
                    yref: 'y',
                    line: {
                      color: band.line.color,
                      width: 1
                    }
                  })),
                  ...generateGaps(dailyStageAverages)
                ]
              }}
            />
            <div style={{display: 'flex', justifyContent: 'center', gap: 4}}>
              {
                wolfFloodBands.map((band, i) => {
                  return <div key={i} style={{background: band.fillcolor, borderBottom: `2px solid ${band.line.color}`, width: 130, textAlign: 'center', fontSize: 12, boxShadow: Depths.depth4}}>
                    {band.y0} ft
                    <div>{band.label}</div>
                  </div>
                })
              }
            </div>
          </div>
        ) : (
          null
        )}
          
      </div>
      {/* MILK RIVER */}
      <div style={{width: '100%', maxWidth: 1200, margin: '0px auto'}}>
        <Text styles={{root: {marginBottom: 16, marginTop: 16, width: '100%', textAlign: 'center', display: 'block'}}} variant='xxLarge'>
          Milk River at Nashua MT
          <IconButton iconProps={{iconName: "Link"}} href='https://waterdata.usgs.gov/monitoring-location/06174500/#parameterCode=00065&period=P7D&showMedian=false' target='_blank' />
        </Text>
          {milkFlow ? (
            <Plot
              style={{boxShadow: Depths.depth16, width: '100%', marginBottom: 32}}
              data={[
                {
                  x: milkFlow?.map(entry => entry.date),
                  y: milkFlow?.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: getTheme().palette.themePrimary },
                  name: '2024',
                  connectgaps: false
                },
                {
                  x: milkRiverMedian?.map(arr => arr[0]).slice(0, daysInYear + 1),
                  y: milkRiverMedian?.map(arr => parseFloat(arr[1])).slice(0, daysInYear + 1),
                  type: 'scatter',
                  mode: 'lines',
                  marker: {color: getTheme().palette.orange},
                  name: "Median",
                  line: {dash: 'dot', width: 1}
                },
              ]}
              layout={{
                title: 'Milk River discharge at Nashua MT - 06174500',
                autosize: true,
                xaxis: {
                  title: 'Date',
                  type: 'date',
                  showgrid: false
                },
                yaxis: {
                  title: 'Discharge, cubic feet per second'
                },
                shapes: generateGaps(milkFlow),
                annotations: milkFlow.length === 0 && [
                  {
                      "text": "No data available (ice)",
                      "xref": "paper",
                      "yref": "paper",
                      "showarrow": false,
                      "font": {
                          "size": 28,
                          color: getTheme().palette.themePrimary
                      }
                  }
                ]
              }}
            />
          ) : (
            null
          )}
          {milkStage ? (<div style={{boxShadow: Depths.depth16, width: '100%', marginBottom: 64, background: '#fff', paddingBottom: 32}}>
              <Plot
                style={{}}
                data={[
                  {
                    x: milkStage?.map(entry => entry.date),
                    y: milkStage?.map(entry => entry.average),
                    type: 'scatter',
                    mode: 'lines',
                    marker: { color: getTheme().palette.themePrimary },
                    name: '2024',
                    connectgaps: false
                  }
                ]}
                layout={{
                  title: 'Milk River stage at Nashua MT - 06174500',
                  autosize: true,
                  xaxis: {
                    title: 'Date',
                    type: 'date',
                    showgrid: false
                  },
                  yaxis: {
                    title: 'Gage height, feet'
                  },
                  shapes: [
                    ...milkFloodBands,
                    ...milkFloodBands.map(band => ({
                      type: 'line',
                      x0: 0,
                      x1: 1,
                      y0: band.y0,
                      y1: band.y0,
                      xref: 'paper',
                      yref: 'y',
                      line: {
                        color: band.line.color,
                        width: 1
                      }
                    })),
                    ...generateGaps(milkStage)
                  ],
                  annotations: milkStage?.length === 0 && [
                    {
                        "text": "No data available (ice)",
                        "xref": "paper",
                        "yref": "paper",
                        "showarrow": false,
                        "font": {
                            "size": 28,
                            color: getTheme().palette.themePrimary
                        }
                    }
                  ]
                }}
              />
              <div style={{display: 'flex', justifyContent: 'center', gap: 4}}>
                {
                  milkFloodBands.map((band, i) => {
                    return <div key={i} style={{background: band.fillcolor, borderBottom: `2px solid ${band.line.color}`, width: 130, textAlign: 'center', fontSize: 12, boxShadow: Depths.depth4}}>
                      {band.y0} ft
                      <div>{band.label}</div>
                    </div>
                  })
                }
              </div>
            </div>
          ) : (
            null
          )}

      </div>



      {/* YELLOWSTONE */}
      <div style={{width: '100%', maxWidth: 1200, margin: '0px auto'}}>
        <Text styles={{root: {marginBottom: 16, marginTop: 16, width: '100%', textAlign: 'center', display: 'block'}}} variant='xxLarge'>
          Yellowstone River at Sidney MT
          <IconButton iconProps={{iconName: "Link"}} href='https://waterdata.usgs.gov/monitoring-location/06329500/#parameterCode=00065&period=P7D&showMedian=false' target='_blank' />
        </Text>
          {yellowFlow ? (
            <Plot
              style={{boxShadow: Depths.depth16, width: '100%', marginBottom: 32}}
              data={[
                {
                  x: yellowFlow?.map(entry => entry.date),
                  y: yellowFlow?.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: getTheme().palette.themePrimary },
                  name: '2024',
                  connectgaps: false
                },
                {
                  x: yellowstoneMedian?.map(arr => arr[0]).slice(0, daysInYear + 1),
                  y: yellowstoneMedian?.map(arr => parseFloat(arr[1])).slice(0, daysInYear + 1),
                  type: 'scatter',
                  mode: 'lines',
                  marker: {color: getTheme().palette.orange},
                  name: "Median",
                  line: {dash: 'dot', width: 1}
                },
              ]}
              layout={{
                title: 'Yellowstone River discharge at Sidney MT - 06174500',
                autosize: true,
                xaxis: {
                  title: 'Date',
                  type: 'date',
                  showgrid: false
                },
                yaxis: {
                  title: 'Discharge, cubic feet per second'
                },
                shapes: generateGaps(yellowFlow),
                annotations: yellowFlow.length === 0 && [
                  {
                      "text": "No data available (ice)",
                      "xref": "paper",
                      "yref": "paper",
                      "showarrow": false,
                      "font": {
                          "size": 28,
                          color: getTheme().palette.themePrimary
                      }
                  }
                ]
              }}
            />
          ) : (
            null
          )}
          {yellowStage ? (
            <div style={{boxShadow: Depths.depth16, width: '100%', marginBottom: 64, background: '#fff', paddingBottom: 32}}>
              <Plot
                style={{}}
                data={[
                  {
                    x: yellowStage?.map(entry => entry.date),
                    y: yellowStage?.map(entry => entry.average),
                    type: 'scatter',
                    mode: 'lines',
                    marker: { color: getTheme().palette.themePrimary },
                    name: '2024',
                    connectgaps: false
                  }
                ]}
                layout={{
                  title: 'Yellowstone River stage at Sidney MT - 06174500',
                  autosize: true,
                  xaxis: {
                    title: 'Date',
                    type: 'date',
                    showgrid: false
                  },
                  yaxis: {
                    title: 'Gage height, feet'
                  },
                  shapes: [
                    ...yellowFloodBands,
                    ...yellowFloodBands.map(band => ({
                      type: 'line',
                      x0: 0,
                      x1: 1,
                      y0: band.y0,
                      y1: band.y0,
                      xref: 'paper',
                      yref: 'y',
                      line: {
                        color: band.line.color,
                        width: 1
                      }
                    })),
                    ...generateGaps(yellowStage)
                  ],
                  annotations: yellowStage?.length === 0 && [
                    {
                        "text": "No data available (ice)",
                        "xref": "paper",
                        "yref": "paper",
                        "showarrow": false,
                        "font": {
                            "size": 28,
                            color: getTheme().palette.themePrimary
                        }
                    }
                  ]
                }}
              />
              <div style={{display: 'flex', justifyContent: 'center', gap: 4}}>
                {
                  yellowFloodBands.map((band, i) => {
                    return <div key={i} style={{background: band.fillcolor, borderBottom: `2px solid ${band.line.color}`, width: 130, textAlign: 'center', fontSize: 12, boxShadow: Depths.depth4}}>
                      {band.y0} ft
                      <div>{band.label}</div>
                    </div>
                  })
                }
              </div>
            </div>
          ) : (
            null
          )}
      </div>

      {/* RESERVOIR */}

      <div style={{margin: '0px auto', width: '100%', maxWidth: 1200}}>
        <Text styles={{root: {marginBottom: 16, marginTop: 16, width: '100%', textAlign: 'center', display: 'block'}}} variant='xxLarge'>
          Fort Peck Reservoir MT
          <IconButton iconProps={{iconName: "Link"}} href="https://water.usace.army.mil/a2w/f?p=100:1:0::::P1_LINK:2998030-CWMS" target="_blank" />
        </Text>
        <div>
          {peckResElevation ? (
            <Plot
              style={{boxShadow: Depths.depth16, marginBottom: 32, width: '100%'}}
              data={[
                {
                  x: peckResElevation.map(entry => entry.date),
                  y: peckResElevation.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: getTheme().palette.themePrimary },
                  name: '2024',
                  connectgaps: false
                }
              ]}
              layout={{
                title: 'Fort Peck Reservoir Elevation',
                autosize: true,
                xaxis: {
                  title: 'Date',
                  type: 'date',
                  showgrid: false
                },
                yaxis: {
                  title: 'Elevation, feet'
                },
                shapes: [
                  {
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    y0: 2227,
                    y1: 2227,
                    xref: 'paper',
                    line: {
                      color: 'red',
                      dash: 'dot',
                      width: 1
                    }
                  },
                  {
                    type: 'line',
                    x0: 0,
                    x1: 1,
                    y0: 2234,
                    y1: 2234,
                    xref: 'paper',
                    line: {
                      color: 'red',
                      dash: 'dot',
                      width: 1
                    }
                  },
                  {
                    type: 'line',
                    arrow_style: 'arrow-up',
                    x0: 1,
                    x1: 1,
                    y0: 2227,
                    y1: getLastValue(peckResElevation),
                    xref: 'paper',
                    line: {
                      color: getTheme().palette.themePrimary,
                      dash: 'dot',
                      width: 1
                    }
                  },
                  ...generateGaps(peckResElevation),
                ],
                annotations: [
                  {
                    text: "Min Spill Elevation",
                    xref: 'paper',
                    x: 0,
                    y: 2227,
                    bordercolor: '#333',
                    clicktoshow: true,
                    bgcolor: 'white',
                    showarrow: false,
                    font: {
                      size: 10,
                    }
                  },
                  {
                    text: "Bottom of flood",
                    xref: 'paper',
                    x: 0,
                    y: 2234,
                    bordercolor: '#333',
                    clicktoshow: true,
                    bgcolor: 'white',
                    showarrow: false,
                    font: {
                      size: 10,
                    }
                  },
                  {
                    text: `Spill storage: ${(getLastValue(peckResElevation) - 2227).toFixed(2)} ft`,
                    xref: 'paper',
                    x: 1,
                    xanchor: 'right',
                    yanchor: 'middle',
                    y: (2227 + getLastValue(peckResElevation)) / 2,
                    bordercolor: getTheme().palette.themePrimary,
                    showarrow: true,
                    visible: !isNaN(getLastValue(peckResElevation)),
                    bgcolor: getTheme().palette.themePrimary,
                    font: {
                      size: 12,
                      color: '#fff'
                    }
                  }
                ]
              }}
            />
          ) : (
            null

          )}
          {peckResInflow ? (
            <Plot
              style={{boxShadow: Depths.depth16, marginBottom: 32, width: '100%'}}
              data={[
                {
                  x: peckResInflow.map(entry => entry.date),
                  y: peckResInflow.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: getTheme().palette.themePrimary },
                  name: '2024',
                  connectgaps: false
                }
              ]}
              layout={{
                title: 'Fort Peck Reservoir Inflow',
                autosize: true,
                xaxis: {
                  title: 'Date',
                  type: 'date',
                  showgrid: false
                },
                yaxis: {
                  title: 'Dicharge, CFS'
                },
                shapes: generateGaps(peckResInflow, true),
                annotations: peckResInflow.length === 0 && [
                  {
                      "text": "No data available (ice)",
                      "xref": "paper",
                      "yref": "paper",
                      "showarrow": false,
                      "font": {
                          "size": 28,
                          color: getTheme().palette.themePrimary
                      }
                  }
                ]
              }}
            />
          ) : (
            null
          )}

          {peckResOutflow ? (
            <Plot
              style={{boxShadow: Depths.depth16, marginBottom: 32, width: '100%'}}
              data={[
                {
                  x: peckResOutflow.map(entry => entry.date),
                  y: peckResOutflow.map(entry => entry.average),
                  type: 'scatter',
                  mode: 'lines',
                  marker: { color: getTheme().palette.themePrimary },
                  name: '2024',
                  connectgaps: false
                }
              ]}
              layout={{
                title: 'Fort Peck Reservoir Outflow',
                autosize: true,
                xaxis: {
                  title: 'Date',
                  type: 'date',
                  showgrid: false
                },
                yaxis: {
                  title: 'Discharge, CFS'
                },
                shapes: generateGaps(peckResOutflow),
                annotations: peckResOutflow.length === 0 && [
                  {
                      "text": "No data available (ice)",
                      "xref": "paper",
                      "yref": "paper",
                      "showarrow": false,
                      "font": {
                          "size": 28,
                          color: getTheme().palette.themePrimary
                      }
                  }
                ]
              }}
            />
          ) : (
            null
          )} 
        </div>
      </div>
    </div>
  );
};

export default DailyUpdate;
