import React, { useContext } from 'react';
import Papa from 'papaparse';
import { NavLink, Route, Switch, Link as Anchor } from 'react-router-dom';
import _ from 'lodash';
import { stats } from './lib';
import { IconButton, Dialog, Label, Slider, ContextualMenu, getTheme, ActionButton, ContextualMenuItemType, DialogType, FontIcon, TooltipHost, Link, TextField, DialogFooter, DefaultButton, PrimaryButton, Icon, SpinnerSize, Spinner, DirectionalHint } from '@fluentui/react';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';
import Mapper from './Mapper';
import HoverInfo from './HoverInfo';
import AddThreshold from './AddThreshold';
import LegendTrace from './LegendTrace';
import AuthContext from './AuthContext';
import Admin from './Admin';
import ChartBuilderForm from './ChartBuilderForm';
import MultipleLocations from './MultipleLocations';
import SingleAlternative from './SingleAlternative';
import MultiAlt from './MultiAlt';
import MapForm from './MapForm';
import Por from './Por';
import PerformanceMeasures from './PerformanceMeasures';

import { countAbove, countBelow, countBetween, countEqual, rearrangeThresholds } from './thresholds/index';

import { ALL_DAYS as DEFAULT_CAL, buildCal } from './lib/cal';

import axios from 'axios';
import Settings from './Settings';
import HistoricalRecrod from './HistoricalRecord';
import MultiDimension from './MultiDimension';
import DailyUpdate from './DailyUpdate';

export const Plot = createPlotlyComponent(Plotly);

export const labelStyle = {root: {color: getTheme().palette.neutralPrimaryAlt, fontSize: 14, fontWeight: 500, padding: '4px'}};

export const CHART_TYPES = [
  {key: 'Multiple Alternatives', text: 'Multiple Alternatives'}, 
  {key: 'Single Alternative', text: 'Single Alternative'}, 
  {key: 'Period of Record', text: 'Period of Record'}, 
  {key: 'Multiple Locations', text: 'Multiple Locations'},
  {key: 'Continuous Time Series', text: "Continuous Time Series"},
  {key: 'Multiple Dimension', text: 'Multiple Dimension (Experimental)'},
];

const LOCATION_TEXT_LOOKUP = [
  {
    key: "Daisy Lake elevation",
    description: "Daily Daisy Lake Reservoir Elevations."
  },
  {
    key: 'Daisy Dam discharge',
    description: 'Daily discharge from Daisy Dam to Cheakamus River.'
  },
  {
    key: 'Plant discharge',
    description: 'Total daily flows to Cheakamus Generation Station and release into Squamish River.'
  },
  {
    key: 'Squamish discharge below CMS',
    description: 'Sum total of flows at the confluence of Cheakamus River and Squamish River. Includes Daisy Dam discharge, local inflow to Cheakamus River downstream of Daisy Dam, power flows and Squamish River flow.'
  },
  {
    key: 'CMS discharge near Brackendale',
    text: 'Total flow in Cheakamus River at Brackendale consisting of local inflow and Daisy Dam discharge.'
  }
]

export const LOOKUP_KEYS = [
  {key: 'max', text: 'Maximum', color: getTheme().palette.themeTertiary, group: 'min-max'}, 
  {key: 'q90', text: '90th %', color: getTheme().palette.themeSecondary, fill: 'tonexty', fillcolor: '#eff6fc', group: '10-90'}, 
  {key: 'q75', text: "75th %", color: getTheme().palette.themeDarker, fill: 'tonexty', fillcolor: '#c7e0f4', group: '25-75'},
  {key: 'median', text: "Median", color: '#ff8c00', fill: 'tonexty', fillcolor: '#71afe5', group: 'median'},
  {key: 'mean', text: "Mean (Average)", color: 'gold', fill: 'tonexty', fillcolor: '#71afe5', group: 'mean'},
  {key: 'q25', text: "25th %", color: getTheme().palette.themeDarker, fill: 'tonexty', fillcolor: '#71afe5', group: '25-75'},
  {key: 'q10', text: "10th %", color: getTheme().palette.themeSecondary, fill: 'tonexty', fillcolor: '#c7e0f4', group: '10-90'},
  {key: 'min', text: 'Minimum', color: getTheme().palette.themeTertiary, fill: 'tonexty', fillcolor: '#eff6fc', group: 'min-max'}
];

export const BASIC_LOOKUPS = [
  {key: 'min-max', name: 'Min - Max', color: getTheme().palette.themeLight, description: 'This range represents the minimum and maximum values for each day of the year from historical dataset.'}, 
  {key:'10-90', name: '10th - 90th %', color: getTheme().palette.themeTertiary, description: 'This range represents all the values above the 10th percentile, and below the 90th percentile. 80% of all records fall within this range.'}, 
  {key: '25-75', name: '25th - 75th %', color: getTheme().palette.themeSecondary, description: 'This range represents all the values above the 25th percentile, and below the 75th percentile. 50% of all records fall within this range.'}, 
  {key: 'median', name: 'Median', color: '#ff8c00', description: 'This line represents the middle point of all recorded values on a given day. 50% of records are above this value, 50% are below.'},
  {key: 'mean', name: 'Mean (Average)', color: 'gold', description: 'This line represents the mean (average) point of all recorded values on a given day.'}
];

export const PATTERNS = ["solid", "dot", "dash", "longdash", "dashdot", "longdashdot"];

function roundToTwo(num) {    
  return +(Math.round(num + "e+2")  + "e-2");
}

function buildAllData(data, lookupIndex, ignoreYears, ignoreValues, ignoreNegatives, autofill){

  let holder = [];
  let range;

  let dateChar = '-';

  if(data[0][0].includes('/')){
    dateChar = '/';
  }

  const monthNum = data[0][0].split(dateChar)[1]; 
  const dayNum = data[0][0].split(dateChar)[2];

  let { julian } = DEFAULT_CAL.find(el => {
    return parseFloat(monthNum) === el.monthNum && el.day === parseFloat(dayNum);
  });

  // let julian = 1;

  let day = julian;

  data.forEach((row, index) => {
    let year = parseFloat(row[0].split(dateChar)[0]);
    if(range && range.includes(year)) return;
    if(ignoreYears.includes(year)) return;
    let value = parseFloat(row[lookupIndex]);
    if(isNaN(value)) {
      value = null;
      if(autofill && (index !== 0)){
        value = holder[index - 1].value;
      }
    } else {
      value = roundToTwo(value);
    };

    // if the value we encounter is one we should ignore...
    if(ignoreValues.includes(value)) value = null;
    if(ignoreNegatives && value < 0) value = null;

    holder.push({
      date: row[0],
      year,
      month: parseFloat(row[0].split(dateChar)[1]),
      day: day,
      value: value
    })
    if(day === 365){
      day = 1;
    } else {
      day++;
    }
  });
  return holder;
}

function buildDaily(data){
  _.groupBy(data, 'day')
}

function buildStats(data){

  let groupedDaily = _.groupBy(data, 'day');

  let holder = {};

  Object.keys(groupedDaily).forEach(day => {
    
    let dailyData = groupedDaily[day].map(each => each.value).filter(v => !isNaN(v) && v !== null);

    holder[day] = {
      q10: stats.q10(dailyData),
      q25: stats.q25(dailyData),
      q50: stats.q50(dailyData),
      q75: stats.q75(dailyData),
      q90: stats.q90(dailyData),
      min: Math.min(...dailyData),
      max: Math.max(...dailyData),
      median: stats.median(dailyData),
      mean: stats.mean(dailyData),
    }
  });

  return holder;
}

function buildAnnualData(data){

  let temp = _.groupBy(data, 'year');

  Object.keys(temp).forEach(key => {
    // if the year wasnt pulled from data, we can add a placeholer and fill it with empty data
    if(!temp[key]) temp[key] = [];
    if(temp[key].length < 365){
      let notFound = DEFAULT_CAL.filter(el => !temp[key].map(el => el.day).includes(el.julian)).map(el => {
        return {
          date: `${el.monthNum}/${el.day}/${key}`,
          year: parseFloat(key),
          month: el.monthNum,
          day: el.julian,
          value: null
        }
      });
      let combined = [...temp[key], ...notFound];
      combined.sort((a, b) => a.day - b.day);
      temp[key] = combined;
      
    }
  })

  return temp;
}

function convert(data, startDay){

  const currentCal = buildCal(startDay);

  const { allData } = data;

  const rebuildAnnual = _.groupBy(allData, 'year');

  let annualDataNew = {};

  let allYears = Object.keys(rebuildAnnual);

  allYears.forEach((year, index) => {
    

    if(startDay !== 1){
      let prevYear = rebuildAnnual[year - 1];
      let nextYear = rebuildAnnual[year];

      // if they dont exist, start with an empty array
      if(!prevYear) prevYear = [];
      if(!nextYear) nextYear = [];

      // if they are missing call days, we need to add them
      if(prevYear.length < 365){
        let notFound = currentCal.filter(el => !prevYear.map(d => d.day).includes(el.julian))
        .map(el => ({...el, date: `${el.monthNum}/${el.day}/${year - 1}`, year: parseFloat(year - 1), month: el.monthNum, day: el.julian,value: null}));
        prevYear = [...prevYear, ...notFound];
        prevYear.sort((a, b) => a.day - b.day);
      }
      if(nextYear.length < 365){
        let notFound = currentCal.filter(el => !nextYear.map(d => d.day).includes(el.julian))
        .map(el => ({...el, date: `${el.monthNum}/${el.day}/${year}`, year: parseFloat(year), month: el.monthNum, day: el.julian,value: null}));
        nextYear = [...nextYear, ...notFound];
        nextYear.sort((a, b) => a.day - b.day);
      }

      // filter them out by date, prev year should include anything ahead of or on the start date
      // next year should be anything before the start date
      prevYear = prevYear.filter(el => el.day >= startDay);
      nextYear = nextYear.filter(el => el.day < startDay);

      let combined = [...prevYear, ...nextYear].map((e, index) => ({...e, dayLookup: index + 1}));

      annualDataNew[year] = combined;
    } else {
      let yearData = rebuildAnnual[year];
      if(!yearData) yearData = [];
      if(yearData.length < 365){
        let notFound = currentCal.filter(el => !yearData.map(d => d.day).includes(el.julian))
        .map(el => ({...el, date: `${el.monthNum}/${el.day}/${year}`, year: parseFloat(year), month: el.monthNum, day: el.julian,value: null}));

        yearData = [...yearData, ...notFound];

        yearData.sort((a, b) => a.day - b.day);
      }
      annualDataNew[year] = yearData.map((e, index) => ({...e, dayLookup: index + 1}));
    }
  });

  return {...data, annualData: annualDataNew};
    
}

export default function Hydraviz(props){

  const { alternatives=[], map, locationGroups } = React.useMemo(() => {
    return {
      alternatives: props.alternatives.filter(alt => !alt.hidden),
      map: props.map,
      locationGroups: props.locationGroups
    }
  }, [props]);

  const [master, setMaster] = React.useState([]);
  const [loadState, setLoadState] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showChartBuilder, toggleShowChartBuilder] = React.useState(false);
  const [startCalDay, setStartCalDay] = React.useState(1);
  const [charts, setCharts] = React.useState([]);
  const [globalLayoutSettings, setGloabalLayoutSettings] = React.useState({fontSize: 14});
  const [currentFocus, setCurrentFocus] = React.useState(null);
  const { user, signout, updateUser } = useContext(AuthContext);

  React.useEffect(() => {
    if(loading) return;
    if(props.defaultChart && props.enableDefaultChart){
      addChart(props.defaultChart);
    }
  }, [loading, props]);

  const sortedMaster = React.useMemo(() => {
    if(master.length === alternatives.length){
      let x = [];
      alternatives.forEach(alt => {
        let y = master.find(el => el.key === alt.key);
        x.push(y);
      });

      return x;
    }
    return master;
  }, [alternatives, master]);

  const ALL_DAYS = React.useMemo(() => {
    return buildCal(startCalDay);
  }, [startCalDay]);

  const YEARS = React.useMemo(() => {
    if(!sortedMaster || !sortedMaster.length) return [];
    let min, max;
    sortedMaster.forEach((alt, index) => {
      alt.data.forEach(set => {
        if(!min){
          min = set.minYear;
        }
        if(!max){
          max = set.maxYear;
        }
        if(min){
          if(set.minYear < min){
            min = set.minYear
          }
        }
        if(max){
          if(set.maxYear > max){
            max = set.maxYear
          }
        }
      })
    });
    return _.range(min, max + 1, 1);
  }, [sortedMaster]);

  const [allThresholds, setAllThresholds] = React.useState([]);

  const parseFiles = (files) => {

    if(files.length === 0){
      return setLoading(false);
    }
    setLoading(true);

    setLoadState(files.map(file => ({...file, loaded: false})));

    files.filter(file => !file.hidden)
    .forEach(({dataSource='', text='', key='', color, ignoreYears=[], ignoreValues=[], ignoreNegatives=false, autofill=false}, index, arr) => {
      new Promise((resolve, reject) => {

        return Papa.parse(dataSource, {
          header: false,
          worker: true,
          download: true,
          complete: (res) => {

            try{
              if(!res || !res.data) {
                return reject('Unable to load datasource: ', key);
              };
  
              // removes last item from array which is empty
              res.data.pop();
  
              let data = res.data.filter((row, index) => {
                let isLeap = row[0].includes('02-29') || row[0].includes('02/29');
                return !isLeap && row[0] !== '';
              });
  
              let meta = data.shift();
  
              // add the day lookup to each
              const ignore = ['Year', 'Month', 'Date', 'Day', "DATE"];
  
              let masterKey = [];
              
              const allLookups = meta.filter(col => !ignore.includes(col));
  
              allLookups.forEach(lookup => {
                let lookupIndex = meta.indexOf(lookup);
                let allData = buildAllData(data, lookupIndex, ignoreYears, ignoreValues, ignoreNegatives, autofill);
                let stats = buildStats(allData);
                let annualData = buildAnnualData(allData);
                
                let minYear = Math.min(...allData.map(({year}) => year).filter(year => annualData[year].some(el => !isNaN(el.value))));
                let maxYear = Math.max(...allData.map(({year}) => year).filter(year => annualData[year].some(el => !isNaN(el.value))));
                
                let dailyData = buildDaily(allData);
                masterKey.push({
                  alternative: text,
                  color: color,
                  location: lookup.split(':')[0],
                  measure: lookup.split(':')[1],
                  stats,
                  allData,
                  annualData,
                  dailyData,
                  minYear,
                  maxYear
                })
              });

              setLoadState(prev => prev.map(a => a.key === key ? {...a, loaded: true} : a));

              setTimeout(() => {
                resolve({key, alternative: text, data: masterKey.map(el => convert(el, startCalDay)), color});
              }, 1000);

            }
            catch(err){
              setLoadState(prev => prev.map(a => a.key === key ? {...a, loaded: true, error: true} : a));

              setTimeout(() => {
                resolve({key, alternative: text, data: [], color, error: true, errorMessage: "Unable to process data, check date format / file structure for errors"});
              }, 1000)
            }

          },
          error: (err) => {
            resolve(null);
          }
        })
      })
      .then(result => {
        if(!result){
          setLoading(false);
          return;
        };

        setMaster(prev => {
          let m;
          if(prev.find(el => el.key === result.key)){
            m = prev.map(el => el.key === result.key ? result : el);
          }
          m = prev.concat(result);

          return m;
        });


        setTimeout(() => setLoading(false), 1000);
        // setLoading(false);
      })
      .catch(err => {
        // console.log({err});
      })
    });

  }

  const loadSaved = () => {
    try{
      let savedCharts = JSON.parse(localStorage.getItem('savedCharts')) || [];
      setCharts(savedCharts);
    }catch(err){
      setCharts([]);
    }
  };

  const editThreshold = (id, data) => {
    axios.put(`/api/thresholds/${props.match.params.project_key}`, {id, data})
    .then(res => {
      if(res.data.success){
        setAllThresholds(prev => {
          let all = prev.map(t => {
            if(t.id === id){
              return {
                ...t,
                data
              }
            }
            return t
          })
          let rearagned = rearrangeThresholds(all, startCalDay);
          return rearagned;
        })
      }
    })
    .catch(err => {
      // console.log(err)
    });
  };

  const addThreshold = (data) => {
    axios.post(`/api/thresholds/${props.match.params.project_key}`, {data})
    .then(res => {
      if(res.data.success){
        setAllThresholds(prev => {
          let all = prev.concat(...res.data.threshold);
          let rearagned = rearrangeThresholds(all, startCalDay);
          return rearagned;
        })
      }
    })
    .catch(err => {
      // console.log('err: ', err);
    })
  };

  const loadThresholds = () => {
    axios.get(`/api/thresholds/${props.match.params.project_key}`)
    .then(res => {
      res.data.success && setAllThresholds(res.data.thresholds);
    })
    .catch(err => {
      setAllThresholds([]);
    })
  };

  const checkAuthority = () => {
    user && user.id && axios.get(`/api/projectusers/role/${props.match.params.project_key}/${user.id}`)
      .then(res => {
        if(res.data.success) updateUser({role: res.data.role});
      })
      .catch(err => {
        // console.log(err);
      })
  };

  React.useEffect(() => {
    checkAuthority();
    parseFiles(alternatives);
    loadThresholds();
    loadSaved();
    return () => {
      updateUser({role: undefined});
    }
  }, []);

  React.useEffect(() => {
    setMaster(prev => {
      if(!prev) return prev;
      return prev.map(el => {
        return {
          ...el,
          data: el.data.map(el => convert(el, startCalDay))
        }
      });
    });
    setAllThresholds(prev => {
      let fixed = rearrangeThresholds(prev, startCalDay)
      return fixed;
    });
    setCharts(prev => {
      const cal = buildCal(startCalDay);
      return prev.map(chart => {
        return {
          ...chart,
          startDay: cal[0].julian,
          endDay: cal[cal.length-1].julian
        }
      })
    })
  }, [startCalDay]);

  const locations = React.useMemo(() => {
    if(sortedMaster && sortedMaster.length){
      let locs = [];
      sortedMaster.forEach(v => {
        if(v.error) return;
        v.data.forEach(spot => {
          if(locs.find(el => el.key === spot.location)) return;
          let hasDescrption = LOCATION_TEXT_LOOKUP.find(el => el.key === spot.location);
          locs.push({
            measure: spot.measure,
            key: spot.location, 
            text: spot.location,
            subText: hasDescrption?.description || '',
          })
        })
      });
      return locs;
    }
    return [];
  }, [sortedMaster]);

  const generateChartTemplate = React.useCallback(() => {
    if(!alternatives.length || !locations) return;
    const cal = buildCal(startCalDay);
    return {
      thresholds: [], 
      startDay: cal[0].julian, 
      endDay: cal[cal.length - 1].julian, 
      location: locations[0]?.key, 
      chartType: 'Single Alternative', 
      pickYear: YEARS[YEARS.length - 1],
      pickYears: [],
      lookupKey: 'median',
      selectedAlt: alternatives[0].key, 
      selectedAlts: alternatives.map(a => a.key),
      selectedLocations: [], 
      altA: alternatives[0].key, 
      altB: alternatives[1]?.key || alternatives[0].key,
      pickYearVisible: true, 
      diffMethod: 'absolute'
    }
  }, [alternatives, locations, startCalDay, YEARS]);

  const addChart = React.useCallback((initalData) => {
    if(!alternatives.length) return;
    let {
      startDay=ALL_DAYS[0].julian, 
      endDay=ALL_DAYS[ALL_DAYS.length - 1].julian,
      location=locations?.[0]?.key,
      chartType='Single Alternative', 
      pickYear=YEARS[YEARS.length - 1],
      pickYears=[],
      lookupKey='median', 
      lookupKeys=['median'],
      selectedAlt=alternatives[0].key,
      selectedAlts=alternatives.map(a => a.key),
      selectedLocations=[],
      altA=alternatives[0].key, 
      altB=alternatives[1]?.key || alternatives[0].key, 
      pickYearVisible=true,
      allYearsVisible=true,
      diffMethod='absolute' 
    } = initalData;

    let locationThresholds = allThresholds.filter(t => t.location === location);

    let id = Date.now();
    setCharts(prev => prev.concat({
      id: id, 
      thresholds: locationThresholds.filter((el, index, arr) => !arr.find(t => t.key === el.key)), 
      startDay, 
      endDay, 
      location, 
      chartType, 
      pickYear,
      pickYears,
      lookupKey,
      lookupKeys,
      selectedAlt,
      selectedAlts,
      selectedLocations,
      altA,
      altB,
      pickYearVisible,
      allYearsVisible,
      diffMethod
    }));

    setCurrentFocus(`chart-${id}`);

  }, [locations, allThresholds, alternatives, ALL_DAYS, YEARS]);

  React.useEffect(() => {
    if(currentFocus){
      let el = document.getElementById(currentFocus);
      if(el) el.scrollIntoView();
    }
  }, [currentFocus]);

  const copyChart = React.useCallback((initialData) => {
    addChart(initialData);
  }, [addChart]);

  const deleteChart = React.useCallback((id) => {
    setCharts(prev => prev.filter(c => c.id !== id));
    setCurrentFocus(null);
  }, []);

  return loading || !ALL_DAYS ? (
      <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <div>
          <h1 style={{fontSize: 32, fontWeight: 'semibold'}}>Processing Data</h1>
          <p style={{fontSize: 20}}>This might take a moment....</p>
          {
            loadState.map((alt) => {
              return <div key={alt.key} style={{display: 'flex', alignItems: 'center', marginBottom: 8}}>
                <div style={{width: 32, height: 32}}>
                  {alt.loaded ? <IconButton iconProps={{iconName: "SkypeCircleCheck"}} style={{color: getTheme().palette.green, fontSize: 28}} /> : <Spinner size={SpinnerSize.large} />}
                </div>
                <span style={{marginLeft: 16, fontSize: 18}}>{alt.text}</span>
              </div>
            })
          }
        </div>
      </div>
    ) : (
      <>
        <nav className='ms-depth-8'>
          <Anchor to="/dashboard">
            <h1 className='logo'>Hydroviz</h1>
          </Anchor>
          <div className='links'>
            <NavLink hidden={props.onlyShowMapSurvey} exact to={props.match.url}>Charts</NavLink>
            <NavLink hidden={props.onlyShowMapSurvey} to={props.match.url + "/performance-measures"}>Performance Measures</NavLink>
            <NavLink hidden={!props.mapSurveyEnabled} to={props.match.url + "/map"}>Map</NavLink>
            {/* <NavLink hidden={props.onlyShowMapSurvey} to={props.match.url + "/settings"}>Settings</NavLink> */}
            <NavLink hidden={user?.role !== 1} to={props.match.url + "/admin"}>Admin</NavLink>
          </div>
          {
            user && user.id && <ActionButton 
              text={user.first} 
              menuProps={{
                items: [
                  // {key: 'accoutn', text: 'Account', onClick: () => props.history.push('/account')},
                  {key: 'signout', text: 'Sign Out', onClick: () => {
                    signout();
                    props.history.replace('/');
                  }}
                ]
              }} />
          }
        </nav>
        <Switch>

          <Route path={`${props.match.path}/settings`} render={(routeProps) => {
            return <Settings {...routeProps} startCalDay={startCalDay} setStartCalDay={setStartCalDay} />
          }}>
            
          </Route>
          {props.mapSurveyEnabled && <Route path={`${props.match.path}/map`} render={(props) => <MapForm {...props} user={user} />} />}
          <Route path={props.match.path} exact render={(props) => {
            return <>
              {
                charts.map((c) => <ChartArea
                    globalLayoutSettings={globalLayoutSettings}
                    setGloabalLayoutSettings={setGloabalLayoutSettings}
                    copyChart={copyChart}
                    locationGroups={locationGroups}
                    ALL_DAYS={ALL_DAYS}
                    alternatives={alternatives}
                    YEARS={YEARS}
                    addThreshold={addThreshold}
                    editThreshold={editThreshold}
                    map={map}
                    startCalDay={startCalDay}
                    allThresholds={allThresholds} 
                    key={c.id}
                    id={c.id}
                    deleteChart={deleteChart} 
                    locations={locations} 
                    master={sortedMaster} 
                    {...c} />)
              }
              {
                alternatives.length > 0 ? <section id='add-chart' className='fr fcol g12 jcc mauto'>
                  <div style={{maxWidth: 800, margin: '0px auto', width: '100%'}}>
                    <div className='fr fwrap' style={{marginBottom: 32}}>
                      <div className='picker-button f1' onClick={() => toggleShowChartBuilder(true)}>
                        Chart Builder
                      </div>
                      <div onClick={() => props.history.push(`${props.match.url}/performance-measures`)} className='picker-button f1'>
                        Performance Measures
                      </div>
                    </div>
                    <div className='fr fwrap'>
                      <div className='picker-button' onClick={() => addChart({chartType: 'Multiple Alternatives'})}>
                        New Multiple Alternative Chart
                      </div>
                      <div className='picker-button' onClick={() => addChart({chartType: 'Single Alternative'})}>
                        New Single Alternative Chart
                      </div>
                      <div className='picker-button' onClick={() => addChart({chartType: 'Multiple Locations'})}>
                        New Multiple Location Chart
                      </div>
                      <div className='picker-button' onClick={() => addChart({chartType: 'Period of Record'})}>
                        New Period of Record Chart
                      </div>
                      <div className='picker-button' onClick={() => addChart({chartType: 'Continuous Time Series'})}>
                        New Continuous Time Series Chart
                      </div>
                      <div className='picker-button' onClick={() => addChart({chartType: 'Multiple Dimension'})}>
                        New Multiple Dimension Chart
                      </div>
                    </div>
                  </div>
                </section> : <Empty {...props}/>
              } 

              <Dialog minWidth={600} maxWidth={800} hidden={!showChartBuilder} onDismiss={() => toggleShowChartBuilder(false)} modalProps={{isBlocking: false}} dialogContentProps={{title: 'Chart Builder', type: DialogType.close}}>
                <ChartBuilderForm 
                  startCalDay={startCalDay}
                  master={sortedMaster}
                  ALL_DAYS={ALL_DAYS} 
                  YEARS={YEARS}
                  onDismiss={() => toggleShowChartBuilder(false)} 
                  locations={locations}
                  alternatives={alternatives}
                  addChart={addChart} 
                  defaultState={generateChartTemplate()} />
              </Dialog>
            </>
          }}>
          </Route>
          <Route path={`${props.match.path}/performance-measures`} render={(routeProps) => {
              return <PerformanceMeasures 
                YEARS={YEARS} 
                apiEndpoint={props.match.params.project_key} 
                basePath={props.match.url}
                {...routeProps} 
                master={sortedMaster} 
                ALL_DAYS={ALL_DAYS} 
                locations={locations} />
            }}>
          </Route>
          <Route path={`${props.match.path}/admin`} render={(routeProps) => <Admin {...routeProps} updateProjectData={props.updateProjectData} setProjectProps={props.setProjectProps} parseFiles={parseFiles} locations={locations} YEARS={YEARS}/>} />
        </Switch>
        {/* </BrowserRouter> */}
      </>
    )
}

function CustomCal(props){
  const cal = buildCal(1);
  const groupByMonth = _.groupBy(cal, 'monthNum');
  const [showCal, toggleShowCal] = React.useState(false);

  return <React.Fragment>
      <ActionButton styles={{root: {border: 'none'}}} text="Water Year" iconProps={{iconName: "Calendar"}} onClick={() => toggleShowCal(prev => !prev)} />
      <Dialog minWidth={600} onDismiss={() => toggleShowCal(false)} hidden={!showCal} dialogContentProps={{title: "Select Calendar Date to Start Water Year", type: DialogType.close}}>
        <div style={{display: 'flex', flexWrap: 'wrap'}}>
        {
          Object.keys(groupByMonth).map(key => {
            return <div key={key} style={{width: '25%'}}>
              <p>{groupByMonth[key][0].month}</p>
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {
                  groupByMonth[key].map(d => {
                    let selected = d.julian === props.selectedDay;
                    let style = {
                      height: 24,
                      width: 24,
                      background: selected ? getTheme().palette.themePrimary : 'none',
                      color: selected ? getTheme().palette.white : 'inherit',
                      border: `1px solid ${getTheme().palette.neutralQuaternaryAlt}`,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer'
                    }
                    return <div 
                      style={style}
                      onMouseLeave={(e) => e.currentTarget.style.background = style.background}
                      onMouseOver={(e) => e.currentTarget.style.background = selected ? style.background : getTheme().palette.themeLight}
                      onClick={() => props.changeSelectedDay(d.julian)}
                      key={d.julian}>{d.day}</div>
                  })
                }
              </div>
            </div>
          })
        }
        </div>
      </Dialog>
    </React.Fragment>

}

function Glossary(props){
  const [showCal, toggleShowCal] = React.useState(false);

  return <React.Fragment>
      {/* <ActionButton styles={{root: {border: 'none'}}} text="Documentation" iconProps={{iconName: "TextDocument"}} onClick={() => toggleShowCal(prev => !prev)} /> */}
      <Dialog minWidth={800} maxWidth={1200} onDismiss={() => toggleShowCal(false)} hidden={!showCal} dialogContentProps={{title: "Glossary / Documentation", type: DialogType.close}}>
        <Link target="_blank" href="https://data.cnra.ca.gov/dataset/dayflow/resource/776b90ca-673e-4b56-8cf3-ec26792708c3/view/422fc1c9-fc05-4040-90a8-caf775d32937">Open Document In New Tab</Link>
        <div>
          <iframe title="Dayflow Documentation" width="752" height="600" src="https://data.cnra.ca.gov/dataset/dayflow/resource/776b90ca-673e-4b56-8cf3-ec26792708c3/view/422fc1c9-fc05-4040-90a8-caf775d32937" frameBorder="0"></iframe>
        </div>
      </Dialog>
    </React.Fragment>

}

function Empty(props){
  // return props.history.push(`${props.match.url}/admin`);
  return <div className='loading'>
    <div className='fr fcol aic'>
      <Icon iconName='Search' styles={{root: {fontSize: 24}}} />
      <p className='ms-fontSize-24'>No data found</p>
      <div>
        <DefaultButton className='mr3' text="Exit" onClick={() => props.history.push('/')} />
        <DefaultButton text="Admin" onClick={() => props.history.push(`${props.match.url}/admin`)} />
      </div>
    </div>
  </div>
}

function ChartArea(props){
  const { ALL_DAYS, startCalDay, alternatives=[], map, YEARS=[], locationGroups, globalLayoutSettings, setGloabalLayoutSettings } = props;
  
  const [state, setState] = React.useState(props);
  const [fullScreen, toggleFullScreen] = React.useState(false);
  const [showMapper, toggleShowMapper] = React.useState(false);
  const [thresholdInfo, setThresholdInfo] = React.useState(null);

  React.useEffect(() => {
    const esc = document.addEventListener('keydown', (e) => {
      if(e.key === 'Escape') {
        toggleFullScreen(false);
        setThresholdInfo(null);
      } 
    });
    return () => {
      setThresholdInfo(null);
      document.removeEventListener('keydown', esc);
    };
  }, []);

  React.useEffect(() => {
    const locationThresholds = props.allThresholds.filter(t => t.data.location === state.location);
    setState(prev => {
      return {
        ...prev,
        thresholds: locationThresholds
      }
    });
  }, [state.location, props.allThresholds]);

  const changeFilters = React.useCallback((changes) => setState(prev => ({...prev, ...changes})), []);

  const onDismissThresholdSummary = () => setThresholdInfo(null);

  const onCloseHelp = () => setState(prev => ({...prev, showHelp: false}));

  const innerContent = <>
    <ChartBuilder 
      {...state}
      onCloseHelp={onCloseHelp}
      fullScreen={fullScreen}
      alternatives={alternatives}
      YEARS={YEARS}
      ALL_DAYS={ALL_DAYS}
      thresholdInfo={thresholdInfo}
      onDismissThresholdSummary={onDismissThresholdSummary}
      setThresholdInfo={setThresholdInfo}
      globalLayoutSettings={globalLayoutSettings}
      startCalDay={startCalDay}
      master={props.master}
      toggleShowMapper={toggleShowMapper}
      locations={props.locations}
      locationGroups={locationGroups}
      changeFilters={changeFilters} />
    {
      showMapper && <Mapper
      currentLocation={state.location}
      map={map}
      onDismiss={() => toggleShowMapper(false)} 
      changeLocation={(location) => location && changeFilters({location})}/>
    }
    

    <div style={{display: 'flex', justifyContent: "space-between", padding: 4, borderTop: `1px solid ${getTheme().palette.neutralLight}`}}>
      <div>
        <IconButton iconProps={{iconName: 'Copy'}} onClick={() => props.copyChart(state)}/>
        <IconButton iconProps={{iconName: 'Delete'}} onClick={() => props.deleteChart(state.id)}/>
        <IconButton iconProps={{iconName: "FontDecrease"}} onClick={() => setGloabalLayoutSettings(prev => ({...prev, fontSize: prev.fontSize - 1}))} />
        <IconButton iconProps={{iconName: "FontIncrease"}} onClick={() => setGloabalLayoutSettings(prev => ({...prev, fontSize: prev.fontSize + 1}))} />
      </div>
      <DefaultButton styles={{root: {height: 'auto', border: 'none'}}} text="Help" onClick={() => setState(prev => ({...prev, showHelp: !prev.showHelp}))}/>
    </div>
  </>

  return <section className='chart-area' id={`chart-${props.id}`}>
    {innerContent}
  </section>
}

export function ThresholdSummary(props){

  const [editState, setEditState] = React.useState(null);
  const [showDelete, toggleShowDelete] = React.useState(false);
  const [password, setPassword] = React.useState('');

  const { threshold, scenarios, onDismiss, ALL_DAYS, FILTERED_CAL, startCalDay, startDay, endDay, editThreshold, addThreshold, thresholds=[] } = props;

  let { name, location, isStepped, isRange, pattern, color, value, upperRange, lowerRange, isWindow, startWindow, endWindow } = threshold.data;

  const table = React.useRef(null);
  const start = ALL_DAYS.find(d => d.dayLookup === startDay);
  const end = ALL_DAYS.find(d => d.dayLookup === endDay);
  let windowStart = ALL_DAYS.find(d => d.julian === startWindow);
  let windowEnd = ALL_DAYS.find(d => d.julian === endWindow);

  const deleteThreshold = (id) => {
    // if(!password) return;
    axios.delete(`/api/thresholds/${id}/${password}`)
    .then(res => {
      // console.log(res);
      if(res.data.success){
        props.changeFilters({thresholds: thresholds.filter(t => t.id !== id)});
        onDismiss();
        // props.setThresholdInfo(null);
      } else {
        throw Error();
      }
    })
    .catch(err =>{
      // console.log(err);
      alert("Unable to delete threshold");
      // setDeleteState(null);
      // setPassword('');
      // toggleDeleteHidden(true);
    })
  };

  if(editState){
    return <AddThreshold 
      hidden={false}
      setThresholdInfo={props.setThresholdInfo}
      startCalDay={startCalDay}
      location={location} 
      ALL_DAYS={ALL_DAYS}
      onDismiss={() => setEditState(null)}
      editState={editState}
      clearEditState={() => setEditState(null)}
      editThreshold={editThreshold}
      addThreshold={addThreshold} />
  }

  return <div>
    <div className='fr aic' style={{paddingBottom: 2}}>
      <h2 className='f1' style={{fontSize: 20}}>{name} - {location}</h2>
      <IconButton iconProps={{iconName: "Cancel"}} onClick={onDismiss} />
      
    </div>
    {
      showDelete ? (
        <div style={{padding: '16px', background: `${getTheme().palette.yellow}50`, border: `1px solid ${getTheme().palette.yellow}`, borderRadius: 4}}>
          <h2 style={{fontWeight: 600, fontSize: 16}}>Are you sure?</h2>
          <p>Enter your password to delete the threshold.</p>
          <TextField value={password} onChange={(e, v) => setPassword(v)} type='password' styles={{root: {marginBottom: 8}}}  />
          <div className='fr aic g8'>
            <DefaultButton text="Cancel" onClick={() => toggleShowDelete(false)} />
            <DefaultButton text="Submit" onClick={() => deleteThreshold(threshold.id)} />
          </div>
        </div>
      ) : null
    }
    <div className='mb3'>
      <LegendTrace pattern={pattern} color={color} />
    </div>
    <div hidden={isStepped || isRange} className='mb3'><span style={{fontWeight: 600}}>Threshold Value:</span> {value}</div>
    {
      isRange && <>
        <div className='mb3'>
          <span style={{fontWeight: 600}}>Upper Range: </span>{upperRange}
        </div>
        <div className='mb3'>
          <span style={{fontWeight: 600}}>Lower Range: </span>{lowerRange}
        </div>
      </>
    }

    {
      isWindow ? <div className='mb3'>
        <span style={{fontWeight: 600}}>Date Window: </span>
        {windowStart.date}  - {windowEnd.date}
      </div> : 
      <div className='mb3'>
        <span style={{fontWeight: 600}}>Date Range: </span>
        {start.month} {start.day} - {end.month} {end.day}
      </div>
    }

    <div style={{maxHeight: 500, overflowY: 'auto', padding: 8, border: `2px solid ${getTheme().palette.neutralLight}`, borderRadius: 4, marginBottom: 16}}>
      <table ref={table} style={{borderCollapse: 'collapse', width: '100%'}}>
        <thead>
          <tr>
            <th className='tal' style={{fontWeight: 600, borderBottom: `3px solid ${getTheme().palette.neutralLight}`}}>Trace</th>
            <th className='tac' style={{minWidth: 160, maxWidth: 240, fontWeight: 600, borderBottom: `3px solid ${getTheme().palette.neutralLight}`}}>
              Days {`<`} {isStepped ? 'Lower Range' : isRange ? `${lowerRange}` : value}
            </th>
            <th className='tac' style={{minWidth: 160, maxWidth: 240, fontWeight: 600, borderBottom: `3px solid ${getTheme().palette.neutralLight}`}}>
              Days {isRange ? `Between ${lowerRange} and ${upperRange}` : isStepped ? ' Equal to' : ` Equal to ${value}`}
            </th>
            <th className='tac' style={{minWidth: 160, maxWidth: 240, fontWeight: 600, borderBottom: `3px solid ${getTheme().palette.neutralLight}`}}>
              Days {`>`} {isStepped ? 'Upper Range' : isRange ? `${upperRange}` : value}
            </th>
          </tr>
        </thead>
        <tbody>
          {
            scenarios.map((scenario, index) => {
              if(scenario.ignoreThresholdCalcs) return null;
              return <tr key={index}>
                <td style={{borderBottom: `3px solid ${scenario.line.color}`}}>{scenario.name}</td>
                <td style={{borderBottom: `3px solid ${scenario.line.color}`}} className='tac'>{countBelow(scenario, threshold.data, 1, startCalDay, FILTERED_CAL)}</td>
                <td style={{borderBottom: `3px solid ${scenario.line.color}`}} className='tac'>{isRange ? countBetween(scenario, threshold.data, 1, startCalDay, FILTERED_CAL) : countEqual(scenario, threshold.data, 1, startCalDay, ALL_DAYS)}</td>
                <td style={{borderBottom: `3px solid ${scenario.line.color}`}} className='tac'>{countAbove(scenario, threshold.data, 1, startCalDay, FILTERED_CAL)}</td>
              </tr>
            })
          }
        </tbody>
      </table>
    </div>
    <div>
      <DefaultButton
        text="Edit"
        styles={{root: {marginRight: 8}}} 
        onClick={() => {
          setEditState(threshold);
        }} />
      <DefaultButton 
        text="Delete"
        onClick={() => {
          toggleShowDelete(true);
          
        }}/>
    </div>
  </div>
}

function ChartBuilder(props){
  console.log(props.chartType);
  switch(props.chartType){
    case 'Single Alternative': return <SingleAlternative {...props}/>
    case 'Multiple Dimension': return <MultiDimension {...props} />
    case 'Multiple Alternatives': return <MultiAlt {...props} />
    case 'Period of Record': return <Por {...props}/>
    case 'Multiple Locations': return <MultipleLocations {...props} />
    case 'Continuous Time Series': return <HistoricalRecrod {...props}/>
    default: return <p>Invalid Chart Type</p>
  }
};

export function ThresholdPicker(props){

  const { addThreshold, editThreshold } = props;
  
  const [hideAdd, toggleHideAdd] = React.useState(true);
  const [editState, setEditState] = React.useState(null);
  const [deleteHidden, toggleDeleteHidden] = React.useState(true);
  const [password, setPassword] = React.useState('');
  const [deleteState, setDeleteState] = React.useState(null);

  React.useEffect(() => {
    if(deleteHidden){
      setDeleteState(null);
      setPassword('');
    }
  }, [deleteHidden]);

  if(!hideAdd){
    return <AddThreshold 
    hidden={false}
    location={props.location}
    ALL_DAYS={props.ALL_DAYS}
    onDismiss={() => toggleHideAdd(true)}
    editState={editState}
    clearEditState={() => setEditState(null)}
    editThreshold={editThreshold}
    addThreshold={addThreshold} />
  }

  return <div style={{flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
    <div className='fr aic sb'>
      <Label styles={labelStyle}>Thresholds</Label>
      <div>
        <Link styles={{root: {marginRight: 8}}} onClick={() => props.changeFilters({thresholds: props.thresholds.map(t => ({...t, data: {...t.data, visible: true}}))})}>
          Show
        </Link>
        <Link onClick={() => props.changeFilters({thresholds: props.thresholds.map(t => ({...t, data: {...t.data, visible: false}}))})}>
          Hide
        </Link>
      </div>
    </div>
    {/* <Dialog hidden={deleteHidden} onDismiss={() => toggleDeleteHidden(true)} dialogContentProps={{title: 'Delete Threshold', subText: `If you are an admin, please enter your password to delete the threshold: ${deleteState?.data?.name}`}}>
      <TextField value={password} type='password' onChange={(e, val) => setPassword(val)} />
      <DialogFooter>
        <DefaultButton text="Cancel" onClick={() => toggleDeleteHidden(true)} />
        <PrimaryButton disabled={!deleteState} text="Delete" onClick={() => deleteThreshold(deleteState.id)} />
      </DialogFooter>
    </Dialog> */}
    <div style={{overflowY: 'auto'}}>
      <div style={{marginBottom: 8, display: 'flex', flexWrap: 'wrap'}}>
        {
          props.thresholds.map(t => {

            let style = {
              opacity: 1,
              cursor: 'pointer',
              userSelect: 'none',
              padding: '4px 8px',
              background: '#fff',
              position: 'relative',
              border: '1px solid #c8c6c4',
              marginBottom: 4,
              marginRight: 4,
              borderRadius: 4
            };

            if(!t.data.visible){
              style.opacity = 0.5;
            }

            return <div className='hover' style={style} key={t.id} 
            onClick={() => props.changeFilters({thresholds: props.thresholds.map(threshold => {
              if(threshold.id === t.id){
                return {
                  ...threshold,
                  data: {...threshold.data, visible: !threshold.data.visible }
                  
                }
              }
              return threshold;
            })})}>
              <TooltipHost content="Click for more info">
                <FontIcon 
                  iconName="Info"
                  style={{color: getTheme().palette.neutralPrimary, fontSize: 12, padding: `0px 4px`}}
                  onClick={(e) =>{
                    e.stopPropagation();
                    props.setThresholdInfo({threshold: t, scenarios: props.scenarios});
                  }} />
              </TooltipHost>
              {t.data.name}
              <div style={{width: '100%', height: '3px', position: 'absolute', bottom: 0, left: 0}}>
                <LegendTrace pattern={t.data.pattern} color={t.data.color} style={{position: 'absolute', borderRadius: 4}} />
              </div>
            </div>
          })
        }
      </div>
      <ActionButton text="Add Threshold" iconProps={{iconName: 'AddTo'}} onClick={() => toggleHideAdd(false)} />
    </div>
  </div>
}

export function LookupKeyGroupPicker(props){

  const grouped = React.useMemo(() => {
    return _.groupBy(props.keys, 'group');
  }, [props]);

  return <div style={{marginBottom: 16}}>
    <Label styles={labelStyle}>Traces</Label>
    <div>
      {
        BASIC_LOOKUPS.map(({key, name, color, description}) => {
          let style = {
            border: `1px solid #c8c6c4`,
            borderRadius: 4,
            marginBottom: 4,
            borderBottom: `3px solid ${color}`,
            opacity: 1,
            // margin: '0px 4px',
            padding: '4px 8px',
            background: name === 'Median' ? '#fff' : `${color}20`
          }
          if(grouped[key].every(i => !i.visible)){
            // style.borderBottom = `3px solid ${getTheme().palette.neutralLighter}`;
            style.opacity = 0.5;
          }
          return <div className='fr aic f1 hover' key={key} onClick={() => props.onChange(key)} style={{...style, cursor: 'pointer', userSelect: 'none'}}>
            <HoverInfo width={320} color={color} text={description} />
            <div>{name}</div>
          </div>
        })
      }
      <div 
        onClick={() => props.changeFilters({allYearsVisible: !props.allYearsVisible})} 
        className='fr aic f1' 
        style={{
          border: `1px solid #c8c6c4`,
          borderRadius: 4,
          marginBottom: 4,
          borderBottom: `3px solid ${getTheme().palette.themeTertiary}`, 
          opacity: props.allYearsVisible ? 1 : 0.5,
          userSelect: 'none',
          cursor: 'pointer',
          padding: '4px 8px',
          background: '#fff'
        }}>
        <div>All Years</div>
      </div>
    </div>
  </div>
}

export function LocationPicker(props){

  const [isOpen, toggleIsOpen] = React.useState(false);

  const ref = React.useRef(null);

  const onShowContextualMenu = (e) => {
    e.preventDefault();
    toggleIsOpen(true);
  };

  const onItemClick = (e, location) => {
    props.onChange(location.key);
    toggleIsOpen(false);
  };

  const onDismiss = () => toggleIsOpen(false);

  const items = React.useMemo(() => {
    if(!props.locationGroups) return props.locations.map(l => ({...l, text: `${l.text} (${l.measure})`}));
    try {
      let holder = [];
      props.locationGroups.forEach(group => {
        holder.push({key: group.name, text: group.name, itemType: ContextualMenuItemType.Header});
        let toInclude = group.includes;
        props.locations.forEach(location => {
          if(toInclude.includes(location.key)){
            holder.push(location);
          }
        })
      });

      // compare the holder to the actual props
      let missed = props.locations.filter(location => {
        let found = holder.find(el => el.key === location.key);
        return !found;
      });
      
      if(missed.length > 0){
        holder.push({key: 'missed', text: 'Other', itemType: ContextualMenuItemType.Header});
        missed.forEach(item => holder.push(item));
      }

      return holder;
    } catch (err){  
      return props.locations;
    }
  }, [props.locationGroups, props.locations]);

  return <div>
    <Label 
      styles={labelStyle}>{props.label || 'Location'}</Label>
    <div className='fr' ref={props.ref}>
      
      
      <div className='hover-label f1' ref={ref} onClick={onShowContextualMenu}>
        {
          !props.disableMap && <IconButton
            styles={{
              root: {height: 'auto', width: 'auto', padding: 0, background: `none !important`, borderRadius: 0, marginRight: 8}
            }} 
            iconProps={{iconName: "MapPin", styles: {root: {fontSize: 18}}}} 
            onClick={(e) => {
              e.stopPropagation();
              props.toggleShowMapper(true)
            }}/>
        }
        {props.location || 'Select a Location'}
      </div>
      <ContextualMenu
        items={items}
        hidden={!isOpen}
        target={ref}
        onItemClick={onItemClick}
        onDismiss={onDismiss}
      />
    </div>
  </div>
}

export function AltPicker(props){

  const { alternatives=[], location } = props;

  const [isOpen, toggleIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  const onShowContextualMenu = (e) => {
    e.preventDefault();
    toggleIsOpen(true);
  };

  return <div style={{minWidth: 100}}>
    <Label styles={labelStyle}>Alternative</Label>
    <div className='hover-label' ref={ref} onClick={onShowContextualMenu}>{props.alternative || "No Alternative Selected"}</div>
    <ContextualMenu
      items={alternatives.map(alt => {
        return {
          ...alt,
          iconProps: {iconName: 'StatusCircleInner', style: {color: alt.color}},
        }
      })}
      hidden={!isOpen}
      target={ref}
      onItemClick={(e, alt) => {
        props.onChange(alt.key);
        toggleIsOpen(false);
      }}
      directionalHint={DirectionalHint.bottomLeftEdge}
      // onItemClick={onHideContextualMenu}
      onDismiss={() => toggleIsOpen(false)}
    />
  </div>
}

export function LookupPicker(props){
  const [isOpen, toggleIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  const onShowContextualMenu = (e) => {
    e.preventDefault();
    toggleIsOpen(true);
  };

  return <div style={{minWidth: 100}}>
    <Label styles={labelStyle}>Data Type</Label>
    <div className='hover-label' ref={ref} onClick={onShowContextualMenu}>{props.lookupKey || 'No Data Type Selected'}</div>
    <ContextualMenu
      items={LOOKUP_KEYS.concat({key: 'pickYear', text: 'Pick Year'}).map(item => ({
        ...item, 
        iconProps: {
          iconName: item.text === props.lookupKey ? "BoxCheckmarkSolid" : "Checkbox",
        }
      }
      ))}
      hidden={!isOpen}
      target={ref}
      onItemClick={(e, option) => {
        props.onChange(option.key);
        toggleIsOpen(false);
      }}
      // onItemClick={onHideContextualMenu}
      onDismiss={() => toggleIsOpen(false)}
    />
  </div>
}

export function YearPicker(props){
  const { YEARS=[] } = props;
  const [isOpen, toggleIsOpen] = React.useState(false);
  const [year, setYear] = React.useState(props.year);
  const ref = React.useRef(null);

  React.useEffect(() => {
    const debounce = setTimeout(() => {
      let num = parseFloat(year);
      if(isNaN(num)) return;
      if(num >= YEARS[0] && num <= YEARS[YEARS.length - 1]){
        props.onChange(num);
        // setYear(num)
      }
    }, 200);
    return () => clearTimeout(debounce);
  }, [year, YEARS]);

  const onShowContextualMenu = (e) => {
    e.preventDefault();
    toggleIsOpen(true);
  };

  React.useEffect(() => {
    setYear(props.year);
  }, [props.year]);

  const nextDisabled = React.useMemo(() => {
    return year === YEARS[YEARS.length - 1];
  }, [YEARS, year]);
  
  const prevDisabled = React.useMemo(() => {
    return year === YEARS[0];
  }, [YEARS, year]);


  return <div style={{minWidth: 100}}>
    <Label styles={labelStyle}>Year</Label>
    <div ref={ref} className='fr'>
      <div className='hover-label f1' style={{justifyContent: "space-between"}}>
        <IconButton disabled={prevDisabled} styles={{root: {background: 'none', height: 'auto'}}} iconProps={{iconName: "ChevronLeftMed"}} onClick={() => setYear(props.year - 1)} />
        <span onClick={onShowContextualMenu}>
          {props.year}
        </span>
        <IconButton disabled={nextDisabled} styles={{root: {background: 'none', height: 'auto'}}} iconProps={{iconName: "ChevronRightMed"}} onClick={() => setYear(props.year + 1)} />
      </div>
    </div>
    <ContextualMenu
      items={[
        {
          key: 'slider', 
          onRender: () => {
            return <Slider 
              min={YEARS[0]} 
              max={YEARS[YEARS.length - 1]}
              showValue={false}
              value={year}
              onChange={(y) => setYear(y)} />
          }
        },
        {
          key: 'icons',
          onRender: () => {
            
            return <div className='fr aic jcc mb'>
              <IconButton disabled={year === YEARS[0]} onClick={() => setYear(year - 1)} iconProps={{iconName: "ChevronLeftMed"}} />
              <TextField 
                value={year}
                onChange={(e, newVal) => setYear(newVal)}
                styles={{field: {textAlign: 'center'}, root: {width: '160px'}}} />

              <IconButton disabled={year === YEARS[YEARS.length - 1]} onClick={() => setYear(year + 1)} iconProps={{iconName: "ChevronRightMed"}} />
            </div>
          }
        }
      ]}
      styles={{root: {width: 420}}}
      hidden={!isOpen}
      target={ref}
      onDismiss={() => toggleIsOpen(false)}
    />
  </div>
}

export function MultiAltPicker(props){

  const { alternatives=[] } = props;

  const toggleSelectedAlts = (e, option) => {
    let shouldAdd = !props.selectedAlts.includes(option.key);
    if(shouldAdd){
      props.changeFilters({selectedAlts: props.selectedAlts.concat(option.key)})
    } else {
      props.changeFilters({selectedAlts: props.selectedAlts.filter(altKey => option.key !== altKey)})
    }
  }

  const onDoubleClick = (e, alt) => {
    props.changeFilters({selectedAlts: [alt.key]});
  }

  return <div className='mb3'>
    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
      <Label styles={labelStyle}>
        Alternatives
      </Label>
      <span hidden={props.hideShowAll}>
        <Link styles={{root: {textDecoration: 'none !important'}}} onClick={() => props.changeFilters({selectedAlts: alternatives.map(alt => alt.key)})} style={{marginRight: 8}}>Show</Link>
        <Link styles={{root: {textDecoration: 'none !important'}}} onClick={() => props.changeFilters({selectedAlts: []})}>Hide</Link>
      </span>
    </div>
    <div style={{display: 'flex', flexWrap: 'wrap'}}>
      
      {
        alternatives.map((alt) => {
          let style = {
            padding: '4px 8px',
            borderRadius: 4,
            border: `1px solid #c8c6c4`,
            borderBottom: `2px solid ${alt.color}`, 
            opacity: props.selectedAlts.includes(alt.key) ? 1 : 0.3,
            background: '#fff',
            marginBottom: 4,
            marginRight: 4,
            cursor: 'pointer',
            userSelect: 'none'
          };
          return <div style={style} className='hover' key={alt.key} onDoubleClick={(e) => onDoubleClick(e, alt)} onClick={(e) => toggleSelectedAlts(e, alt)}>
            {alt.text}
          </div>
        })
      }
    </div>
  </div>
}

export function SingleAltPicker(props){

  const { alternatives=[] } = props;
  
  const [isOpen, toggleIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  const onShowContextualMenu = (e) => {
    e.preventDefault();
    toggleIsOpen(true);
  };

  const items = React.useMemo(() => {
    let holder = alternatives.map(a => ({
      ...a, 
      type: 'alternative', 
    }))
    return holder;
  }, [props]);

  return <div>
    <Label styles={labelStyle}>{props.label}</Label>
    <div className='hover-label' ref={ref} onClick={onShowContextualMenu}>
      {props.threshold ? props.threshold.name : props.altKey}
    </div>
    <ContextualMenu
      items={items}
      onItemClick={(e, option) => {
        if(option.type === 'alternative'){
          props.onChange(option.key)
        } else {
          props.onSetThreshold(option)
        }
      }}
      hidden={!isOpen}
      target={ref}
      onDismiss={() => toggleIsOpen(false)}
    />
  </div>
}

export function ChartPicker(props){
  const [isOpen, toggleIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  const onShowContextualMenu = (e) => {
    e.preventDefault();
    toggleIsOpen(true);
  };

  const items = React.useMemo(() => {
    if(props.disable){
      return CHART_TYPES.filter((el) => !props.disable.includes(el.text));
    }

    return CHART_TYPES;

  }, [CHART_TYPES, props.disable]);

  return <div ref={props.ref}>
    <Label styles={labelStyle}>Chart Type</Label>
    <div ref={ref} className='hover-label' onClick={onShowContextualMenu}>{props.chartType || "No Chart Type Selected"}</div>
    <ContextualMenu
      items={items}
      onItemClick={(e, option) => props.onChange(option.key)}
      // styles={{root: {width: 200}}}
      hidden={!isOpen}
      target={ref}
      directionalHint={DirectionalHint.bottomLeftEdge}
      onDismiss={() => toggleIsOpen(false)}
    />
  </div>
}

export function DateRangePicker(props){

  const { ALL_DAYS, startDay, endDay } = props;

  const [range, setRange] = React.useState([startDay, endDay]);
  // const [endDay, setEndDay] = React.useState(props.?endDay);

  React.useEffect(() => {
    setRange([startDay, endDay]);
  }, [startDay, endDay]);

  const [isOpen, toggleIsOpen] = React.useState(false);
  const ref = React.useRef(null);

  const onShowContextualMenu = (e) => {
    e.preventDefault();
    toggleIsOpen(true);
  };

  return <div style={{minWidth: 100}}>
    <Label styles={labelStyle}>Date Range</Label>
    <div className='fr aic'>
      <div className='hover-label' ref={ref} onClick={onShowContextualMenu}>{ALL_DAYS[range[0] - 1].month} {ALL_DAYS[range[0] - 1].day} - {ALL_DAYS[range[1] - 1].month} {ALL_DAYS[range[1] - 1].day}</div>
      <ContextualMenu
        items={[
          {
            key: 'date-range', 
            onRender: () => {
              return <div className='fr aic'>
                <span style={{width: 50}} className='mr2 ml2'>{ALL_DAYS[range[0] - 1].month} {ALL_DAYS[range[0] - 1].day}</span>
                <Slider
                  styles={{root: {width: 300}}} 
                  ranged 
                  min={1}
                  max={365}
                  showValue={false}
                  value={range[1]} 
                  lowerValue={range[0]}
                  onChange={(v, r) => setRange(r)}
                  onChanged={(e, value, range) => props.changeFilters({startDay: range[0], endDay: range[1]})} 
                />
                <span style={{width: 50}} className='mr2 ml2'>{ALL_DAYS[range[1] - 1].month} {ALL_DAYS[range[1] - 1].day}</span>
              </div>
            }
          },
          {
            itemType: ContextualMenuItemType.Divider,
            key: 'spacer',
          },
          {
            key: 'break',
            text: 'Pre-set Dates',
            itemType: ContextualMenuItemType.Header
          },
          {
            key: 'full-year',
            text: 'Full Year',
            onClick: () => {
              setRange([1,365]);
              props.changeFilters({startDay: 1, endDay: 365});
            }
          },
        ]}
        styles={{root: {width: 400}}}
        hidden={!isOpen}
        target={ref}
        onDismiss={() => toggleIsOpen(false)}
      />
      
    </div>
  </div>
}