import React, { useState } from "react";
import { DetailsList, SelectionMode, TextField, Dropdown, Sticky, PrimaryButton } from '@fluentui/react'
import Papa from "papaparse";
import axios from "axios";

function CsvEditor({files=[], onDrop, updateAltData}) {

  const [csvData, setCsvData] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [pickedAlt, setActiveAlt] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleFileDownload = () => {
    const csv = Papa.unparse(csvData);
    const blobData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

    const file = new File([blobData], `${pickedAlt.text}.csv`, {type: "text/csv", path: `${pickedAlt.text}.csv`, name: `${pickedAlt.text}.csv`});

    onDrop([file], (updatedUrl) => {
      setActiveAlt(prev => ({...prev, url: updatedUrl, dataSource: updatedUrl}));
      updateAltData({...pickedAlt, url: updatedUrl, dataSource: updatedUrl});
    })

  }

  const handleFileUpload = (file) => {


    Papa.parse(file, {
      header: true,
      worker: true,
      download: true,
      complete: (results) => {
        if(!results) return;
        // console.log(results);
        setCsvData(results.data);
        setHeaders(results.meta.fields);
        setErrorMessage(null);
      },
      error: (error) => {
        setErrorMessage(error.message);
      },
    });
  };

  const handleCellChange = (newValue, rowIndex, header) => {
    const newData = [...csvData];
    newData[rowIndex][header] = newValue;
    setCsvData(newData);
  };

  React.useEffect(() => {
    if(!pickedAlt) return;

    const split = pickedAlt.url.split('/');
    const p_key = split[split.length - 2];
    const file = split[split.length - 1].replace('.csv', '');

    axios.get(`/api/signed-url/${p_key}/${file}`, {headers: {token: localStorage.getItem('token')}})
    .then(result => {
      // console.log(result);
      handleFileUpload(result.data.signedUrl);
    })
    .catch(err => {
      setErrorMessage("Unable to access file");
    })


  }, [pickedAlt]);

  return (
    <div style={{maxHeight: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'column'}}>
      <div style={{display: 'flex', gap: 16}}>
        <Dropdown
          selectedKey={pickedAlt.key} 
          placeholder="Select an alternative to edit"
          styles={{root: {width: 440}}}
          options={files.map(f => ({key: f.text, text: f.text, url: f.dataSource}))} 
          onChange={(e, o) => setActiveAlt(o)} />

        <PrimaryButton disabled={!pickedAlt} text="Download CSV File" onClick={handleFileDownload} />
      </div>
      <div style={{flex: 1}}>
        {csvData.length > 0 && (
            <DetailsList 
              selectionMode={SelectionMode.none} 
              compact
              styles={{headerWrapper: {position: 'sticky', top: 0}}}
              items={csvData} 
              onRenderDetailsHeader={(props, defaultRender) => {
                return <Sticky>
                  {defaultRender(props)}
                  {/* <h1>HEADER</h1> */}
                </Sticky>
              }}
              onRenderItemColumn={(item, index, column) => {
                return <TextField value={item[column.fieldName]} borderless onChange={(e, newVal) => handleCellChange(newVal, index, column.fieldName)} />
              }}
              columns={headers.map(head => ({key: head, name: head, fieldName: head, minWidth: 100, maxWidth: 200, isResizable: false}))} />
        )}
      </div>
      {/* <input type="file" onChange={handleFileUpload} /> */}
      {errorMessage && <p>{errorMessage}</p>}
    </div>
  );
}

export default CsvEditor;