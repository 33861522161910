import React from 'react';
import { FontIcon, getTheme, TooltipHost } from '@fluentui/react';

export default function HoverInfo({text, color, width}){
  return <TooltipHost 
    closeDelay={240}
    content={text} 
    calloutProps={{
      isBeakVisible: false, 
      styles: {
        root: {border: color ? `4px solid ${color}` : 'none', maxWidth: width || 'auto', background: '#fff', boxShadow: `0px 0px 0px 100000px rgb(0,0,0,0.4)`}, 
      calloutMain: {padding: 8, background: '#fff'}},
    }} 
    tooltipProps={{
      styles: {subText: {fontSize: 16}}
    }}>
    <FontIcon style={{color: getTheme().palette.neutralPrimary, fontSize: 12, padding: `0px 4px`}} iconName="Info" />
  </TooltipHost>
}