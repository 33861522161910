import { DefaultButton, Dialog, DialogFooter, DialogType, Label, getTheme, ActionButton, CompoundButton } from '@fluentui/react';
import { labelStyle } from './Hydraviz';
import React from 'react';
import { buildCal } from './lib/cal';
import _ from 'lodash';

export default function DateRange(props){

  const [startDay, setStartDay] = React.useState(props.startDay);
  const [endDay, setEndDay] = React.useState(props.endDay);

  React.useEffect(() => {
    setStartDay(props.startDay);
    setEndDay(props.endDay);
  }, [props.startDay, props.endDay]);

  const onSelectDay = (jul) => {

    // if(startDay && (jul < startDay)){
    //   setStartDay(jul);
    //   setEndDay(null);
    //   return;
    // }

    if(!startDay){
      setStartDay(jul);
      return;
    }

    if(startDay && endDay){
      setStartDay(jul);
      setEndDay(null);
      return;
    }
    if(startDay && !endDay){
      setEndDay(jul);
      return;
    }
  }

  const cal = buildCal(props.startCalDay);

  const changeFilters = () => {
    if(startDay && endDay){
      props.onChange(startDay, endDay);
    } else {
      setStartDay(cal[0].julian);
      setEndDay(cal[cal.length - 1].julian);
      props.onChange(cal[0].julian, cal[cal.length - 1].julian);
    }
    // startDay && endDay && props.onChange(startDay, endDay);
    props.toggleDateRangePickerHidden(true);
  };

  

  const groupByMonth = _.groupBy(cal, 'month');

  const startDayText = React.useMemo(() => {
    if(!startDay) return '';
    return cal.find(d => d.julian === startDay).date;
  }, [startDay, cal]);

  const endDayText = React.useMemo(() => {
    if(!endDay) return '';
    return cal.find(d => d.julian === endDay).date;
  }, [endDay, cal]);

  const startDayIndex = React.useMemo(() => {
    if(!startDay) return null;
    return cal.findIndex(el => el.julian === startDay) + 1;
  }, [cal, startDay]);

  const endDayIndex = React.useMemo(() => {
    if(!startDay) return null;
    return cal.findIndex(el => el.julian === endDay) + 1;
  }, [cal, endDay]);

  return <React.Fragment>
    <>
      {
        props.onRender ? props.onRender({dateText: `${startDayText}  - ${endDayText}`, onClick: () => props.toggleDateRangePickerHidden(false)}) : (
          <div onClick={() => props.toggleDateRangePickerHidden(false)}>
            <Label styles={labelStyle}>Date Range</Label>
            <div className='fr aic hover-label'>
              { startDayText } - { endDayText }
            </div>
          </div>
        )
      }
    </>

    <Dialog hidden={props.hidden} styles={{main: {maxWidth: 'max-content !important'}}} onDismiss={() => changeFilters()} dialogContentProps={{title: 'Select Date Range', type: DialogType.close}}>
      <DefaultButton 
        text='Full Year'
        className='mb4' 
        onClick={() => {
          setStartDay(cal[0].julian);
          setEndDay(cal[cal.length-1].julian);
        }} />
      <div className='grid g12 col4'>
        {
          Object.keys(groupByMonth).map(month => {
            return <div key={month}>
              <div>
                <ActionButton
                  // styles={{root: {border: `1px solid ${getTheme().palette.neutralLight}`, padding: '4px 8px', marginBottom: 1, height: 'auto'}}}
                  text={month} onClick={() => {
                  setStartDay(groupByMonth[month][0].julian);
                  setEndDay(groupByMonth[month][groupByMonth[month].length - 1].julian);
                }} />
              </div>
              <div className='grid g4 col7'>
                {groupByMonth[month].map((day, index) => {

                  let style = {
                    color: 'inherit',
                    // // border: `1px solid ${getTheme().palette.neutralLight}`,
                    // padding: 2,
                    borderRadius: 4,
                    width: 22
                  }

                  if(day.julian === startDay || day.julian === endDay){
                    style.background = getTheme().palette.themePrimary;
                    style.color = '#fff';
                  }

                  if(startDayIndex && endDayIndex){
                    if(day.dayLookup > startDayIndex && day.dayLookup < endDayIndex){
                      style.background = getTheme().palette.themeLight;
                    }
                  }

                  return <div className='pointer fr jcc' style={style} onClick={() => onSelectDay(day.julian)} key={day.julian}>{day.day}</div>
                })}
              </div>
            </div>
          })
        }
      </div>
      <DialogFooter>
        <DefaultButton text="Close" onClick={() => changeFilters()} />
      </DialogFooter>
    </Dialog>
  </React.Fragment>
  



}