import { DefaultButton, Icon, IconButton, TeachingBubble, getTheme } from '@fluentui/react';
import React from 'react';
import { LOOKUP_KEYS } from './Hydraviz';


export default function Help({items=[], show=false, onDismiss=() => null, chartType="", lookupKey=""}){
  const [targetIndex, setTargetIndex] = React.useState(0);
  const [target, setTarget] = React.useState(null);
  const [headerText, setHeaderText] = React.useState("");

  React.useEffect(() => {
    setTarget(items[targetIndex]);
  }, [targetIndex, items]);

  const content = React.useMemo(() => {
    if(!target) return null;
    if(!target.current) return null;
    switch(target.current.id){
      case "lookup-picker":
        setHeaderText("Data Type");
        return <div>
          <p>Change the data type being presented by clicking the dropdown and selecting one of the options.</p>
          <p>Options include:</p>
          <ul>
            {LOOKUP_KEYS.concat({key: 'pick-year', text: "Pick Year"}).map(lookupItem => {
              return <li key={lookupItem.key}>{lookupItem.text} {lookupKey === lookupItem.text ? "<--- Currently selected" : ""}</li>
            })}
          </ul>
          <p>If the Data Type is set to "Pick Year" you will also be shown a control for the selected year.</p>
        </div>
      case "chart-picker":
        setHeaderText("Chart Type");
        return <div>
          <p>Change which type of chart is being displayed. Currently, the "{chartType}" is being displayed.</p>
        </div>
      case "location-picker":
        setHeaderText("Selected Location Control");
        return <div>
          <p>This controls which location the data is selected from. Select an option by clicking on the dropdown, and selecting one of the locations. Alternatively, open the map by clicking on the <Icon iconName='MapPin' /> icon and select a location (if available).</p>
        </div>
      case "locations-picker":
        setHeaderText("Selected Locations");
        return <div>
          <p>This controls which location the data is selected from for the left and right y-axis'. Select an option by clicking on the dropdown, and selecting one of the locations. You can select multiple locations to be placed on the same axis.</p>
          <p>Although it's not required, you might consider keeping locations with the same measurements on the same axis. For example, locations that measure elevation could go on the left axis, and locations that measure flow rates could go on the right axis.</p>
        </div>
      case "alt-picker":
        setHeaderText("Selected Alternative Control");
        return <div>
          <p>This controls the selected alternative for the current chart. This type of chart can view one alternative at a time.</p>

        </div>
      case "date-picker":
        setHeaderText("Date Range Control");
        return <div>
          <p>This controls what dates are visible on the current chart. By default, the chart will display from January 1 - December 31. Click the date range control to open the calendar and select a new date range.</p>
        </div>
      case "y-axis-controls":
        setHeaderText("Y Axis Controls");
        return <div>
          <p>This controls the y-axis of the currently displayed chart. If "Auto Range" is enabled, the charts y-axis will automatically scale to fit the data in the current view. If "Range Locked" you can set the min and max y-axis values manually.</p>
          <p>Registered users can save their y-axis settings, so next time you open the same chart, it will load your previously saved settings.</p>
        </div>
      case "traces-picker":
        setHeaderText("Trace Type Controls");
        return <div>
          <p>This controls the statistics or trace type that will be displayed on the chart.</p>
          <p>"Min - Max", "10th - 90th", and "25th - 75th" are shown as filled ranges.</p>
          <p>"Median", "Mean (Average)" and "All Years" will disply individual lines.</p>
          <p>Hover your cursor over the <Icon iconName='Info' /> icon to see the description of the trace type.</p>
        </div>
      case "year-picker":
        setHeaderText("Year Picker");
        return <div>
          <p>Toggle between individual years using the <Icon styles={{root: {background: '#fff', color: getTheme().palette.themePrimary, padding: 8, borderRadius: 4}}} iconName='ChevronLeftMed' /> and <Icon iconName='ChevronRightMed' styles={{root: {background: '#fff', color: getTheme().palette.themePrimary, padding: 8, borderRadius: 4}}} /> buttons.</p>
          <p>Click the <div className='hover-label' style={{display: 'inline-block', color: "#333", fontWeight: 600}}>Multi Year</div> button to select multiple years at the same time.</p>
        </div>
      case "multi-alt-picker":
        setHeaderText("Alternatives Picker");
        return <div>
          <p>Alternatives with data will appear in this list. Click an alternative to toggle it's visability on the chart. Double click an alternative to select the alternative and hide the others.</p>
        </div>
      case "threshold-picker":
        setHeaderText("Thresholds");
        return <div>
          <p>Thresholds are special indicators that we care about at a specific location. They might indiate a target we want to reach, or they might indicate a situation we want to avoid. Thresholds can be static values, ranges, or, they can take different values at different times of the year.</p>
          <p>Click the <Icon iconName='Info'/> icon for a breakdown of how the current chart elements compare to the threshold.</p>
        </div>
      case "scenario-picker":
        setHeaderText("Base / Comp Scenario");
        return <div>
          <p>Pick any alernative to act as either the "base" scenario or the "comp" scenario. Data that appears in the chart will reflect the diffence between the base and comp scenarios.</p>
        </div>
      case "diff-picker":
        setHeaderText("Diff Method");
        return <div>
          <p>The "Diff" method determines how the difference between the comp and base scenarios are caculated.</p>
          <p>The absolute method subtracts the base scenario from the comp scenario.</p>
          <div style={{background: getTheme().palette.themeTertiary, padding: 8, borderRadius: 4, border: `1px solid ${getTheme().palette.themeDark}`}}>
            Absolute Diff = Comp Senario - Base Scenario
          </div>
          <p>The relative method calculates the percentage change from the comp scenario to the base scenario.</p>
          <div style={{background: getTheme().palette.themeTertiary, padding: 8, borderRadius: 4, border: `1px solid ${getTheme().palette.themeDark}`}}>
            Relative Diff = (Comp Senario - Base Scenario) / Base Scenario
          </div>
        </div>
      default: return null;
    }
  }, [target]);

  if(!target || !show) return null;

  return <TeachingBubble 
    hasCloseButton 
    headline={headerText}
    isWide
    target={target} 
    styles={{root: {zIndex: 99999}}}
    onDismiss={onDismiss}
    secondaryButtonProps={targetIndex > 0 && {
      text: "Back",
      onClick: () => setTargetIndex(prev => prev - 1)
    }}
    primaryButtonProps={{
      text: targetIndex < items.length - 1 ? "Next" : "Close", 
      onClick: targetIndex < items.length - 1 ? () => setTargetIndex(prev => prev + 1) : () => onDismiss()
    }}>

    {content}

    <div style={{display: "flex", justifyContent: "center"}}>
      {
        items.map((item, i) => <IconButton key={i} styles={{root: {background: 'none !important'}}} iconProps={{iconName: i !== targetIndex ? "CircleRing" : "CircleFill", styles: {root: {color: 'white'}}}} onClick={() => setTargetIndex(i)} />)
      }
    </div>
    
  </TeachingBubble>
};