import React, { useState, useRef, useEffect } from 'react';
import RDS from 'react-dom/server';
import { IconButton, getTheme, Dropdown, PrimaryButton } from '@fluentui/react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
mapboxgl.accessToken = 'pk.eyJ1IjoiY29tcGFzc3JtIiwiYSI6ImNsMDJxcDZsMDBicDQzY3JuZXpzc2FyNW4ifQ.YnHPuPzdmyAIKNPrKg65uw';

const MAP_STYLES = [
  {
    key: 'mapbox://styles/compassrm/cl6l76w8f000u15ph9l8m5sry',
    text: 'Terrain'
  },
  {
    key: 'mapbox://styles/compassrm/clmi1wzuq019201pvfptc0l4x',
    text: 'Satellite'
  }
];


export default function Mapper(props){

  


  const mapContainer = useRef(null);
  // const map = useRef(null);
  const [lng, setLng] = useState(props.map.lng || 0);
  const [lat, setLat] = useState(props.map.lat || 0);
  const [zoom, setZoom] = useState(props.map.zoom || 10);
  const [pitch, setPitch] = useState(props.map.pitch || 0);
  const [bearing, setBearing] = useState(props.map.bearing || 0);
  const [map, setMap] = useState(null);
  const [activeStyle, setActiveStyle] = React.useState(MAP_STYLES[1].key);

  // const { LOCATIONS=[] } = props;
  const { markers=[] } = props.map;

  useEffect(() => {
    // if (map.current || !props.isOpen) return; // initialize map only once
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      center: [lng, lat],
      zoom: zoom,
      bearing: bearing,
      pitch: pitch
    });
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    setMap(map);
    return () => map.remove();
  }, []);

  useEffect(() => {
    map && map.setStyle(activeStyle);
  }, [map, activeStyle]);


  useEffect(() => {
    if (!map) return; // wait for map to initialize
    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
      setPitch(map.getPitch().toFixed(4));
      setBearing(map.getBearing().toFixed(4))

    });

    map.on('click', (e) => {
      // console.log(`A click event has occurred at ${e.lngLat}`);
    })

  }, [map]);

  useEffect(() => {
    if(map){
      markers.forEach(location => {
        let wrapper = document.createElement('div');
        let header = document.createElement('h4');
        let text = document.createElement('p');
        let button = document.createElement('div');

        header.innerHTML = location.name;
        text.innerHTML = location.text;
        button.innerHTML = RDS.renderToString(<PrimaryButton text="Open" />);
        wrapper.appendChild(header);
        wrapper.appendChild(text);
        if(location.location){
          wrapper.appendChild(button);
          button.onclick = () => {
            props.changeLocation(location.location);
            props.onDismiss();
          };
        };
        new mapboxgl.Marker({color: '#f44336'})
        .setLngLat([location.lng, location.lat])
        .setPopup(
          new mapboxgl.Popup()
          .setDOMContent(wrapper)
        )
        .addTo(map)
      })
    }
  },[map]);

  return <div onClick={() => props.onDismiss()} className='flex-center' style={{position: 'fixed', zIndex: 999999, top: 0, bottom: 0, left: 0, right: 0, background: 'rgba(0,0,0,0.5)'}}>
    <IconButton iconProps={{iconName: 'Cancel'}} styles={{root: {background: 'none !important', color: getTheme().palette.whiteTranslucent40, position: 'absolute', top: 20, right: 20}, rootHovered: {background: 'none', color: '#fff'}, iconHovered: {color: '#fff'}, iconChecked: {color: '#fff !important'}, icon: {fontSize: 42}}} />
    <div style={{width: '80vw', height: '80vh', background: '#eaeaea', position: 'relative'}} onClick={(e) => e.stopPropagation()}>
      <Dropdown styles={{root: {position: 'absolute', top: 8, left: 8, zIndex: 1}}} options={ MAP_STYLES } selectedKey={activeStyle} onChange={(e, o) => setActiveStyle(o.key)} />
      <div ref={mapContainer} style={{height: '100%', width: '100%'}} />
    </div>
  </div>
}