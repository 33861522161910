import React from 'react';
import { ChartPicker, SingleAltPicker, LocationPicker, Plot } from './Hydraviz';
import DateRange from './DateRange';
import ToggleButton from './ToggleButton';
import Plotly from 'plotly.js';
import { Icon, getTheme } from '@fluentui/react';
import _ from 'lodash';
import Help from './Help';

export default function Por(props){

  const [dateRangePickerHidden, toggleDateRangePickerHidden] = React.useState(true);

  const { ALL_DAYS, alternatives=[], YEARS=[], globalLayoutSettings } = props;
  
  const FILTERED_CAL = React.useMemo(() => ALL_DAYS.filter((d, index) => {
    let startIndex = ALL_DAYS.findIndex(d => d.julian === props.startDay);
    let endIndex = ALL_DAYS.findIndex(d => d.julian === props.endDay);
    return index >= Math.min(startIndex, endIndex) && index <= Math.max(startIndex, endIndex);
  }), [props.endDay, props.startDay, ALL_DAYS]);

  const chartData = React.useMemo(() => {

  // compares two alternatives

  let altAData;
  if(props.altA){
    altAData = props.master.find(a => a.key === props.altA).data.find(d => d.location === props.location);
  }

  if(props.selectedThreshold){
    altAData = {
      alternative: props.selectedThreshold.name,
      annualData: {}
    };
    YEARS.forEach(y => {
      altAData.annualData[y] = ALL_DAYS.map((d, index) => {
        if(props.selectedThreshold.isStepped){
          let { value } = props.selectedThreshold.steps.find(step => step.julian === d.julian);
          return {
            ...d,
            value
          }
        } else {
          return {...d, value: props.selectedThreshold.value};
        }
      })
    })
  }

  // bail out if we dont have the required data structure
  if(!altAData) return [];
  
  const altBData = props.master.find(a => a.key === props.altB).data.find(d => d.location === props.location);

  if(!altAData || !altBData) return [];

  // console.log(altAData, altBData);

  let [minA, minB, maxA, maxB] = [altAData.minYear, altBData.minYear, altAData.maxYear, altBData.maxYear];

  let range = _.range(Math.max(minA, minB), Math.min(maxA, maxB) + 1, 1);

  let z = [];
  let text = [];
  let zmin = 0;
  let zmax = 0;

  range.forEach(year => {
    let zholder = [];
    let textHolder = [];
    FILTERED_CAL.forEach(day => {

      if(!altAData.annualData[year] || !altBData.annualData[year]) return;
      
      let base = altAData.annualData[year][day.dayLookup - 1];
      let comp = altBData.annualData[year][day.dayLookup - 1];

      if(!base || !comp) return;

      if(props.diffMethod === 'absolute'){
        let diff = parseFloat(comp.value) - parseFloat(base.value);
        if(!isNaN(diff)){
          if(diff < zmin){
            zmin = diff;
          }
          if(diff > zmax){
            zmax = diff;
          }
        }
        if(isNaN(base.value) || isNaN(comp.value)){
          diff = null;
        }
        zholder.push(diff);
        // Date: ${day.month} ${day.day}, ${year}
        textHolder.push(`
        Date: ${day.month} ${day.day}, ${base.year}
          <br>
          ${altAData.alternative}: ${base.value}
          <br>
          ${altBData.alternative}: ${comp.value}
          <br>
          Absolute Difference: ${(diff).toFixed(2)}
        `);
      } else if(props.diffMethod === 'relative') {
        let diff = ((comp.value - base.value) / base.value) * 100;
        if(diff < zmin){
          zmin = diff;
        }
        if(diff > zmax){
          zmax = diff;
        }
        zholder.push(diff);
        textHolder.push(`
          Date: ${day.month} ${day.day}, ${year}
          <br>
          ${altAData.alternative}: ${base.value}
          <br>
          ${altBData.alternative}: ${comp.value}
          <br>
          Relative Difference: ${diff.toFixed(2)}%
        `);
      } else {
        if(comp.value === base.value) zholder.push(0);
        if(comp.value > base.value) zholder.push(1);
        if(comp.value < base.value) zholder.push(-1);
        textHolder.push(`
          Date: ${day.month} ${day.day}, ${year}
          <br>
          ${altAData.alternative}: ${base.value}
          <br>
          ${altBData.alternative}: ${comp.value}
        `)
      }
    })
    z.push(zholder);
    text.push(textHolder);
  });

  if(props.diffMethod === 'relative'){
    zmin = -100;
    zmax = 100;
  } else if(props.diffMethod === 'absolute') {
    let biggest = Math.max(Math.abs(zmin), Math.abs(zmax));
    zmin = (biggest * -1).toFixed(2);
    zmax = biggest.toFixed(2);
  } else {
    zmin = -1;
    zmax = 1;
  }

  return [
    {
      z: z,
      text: text,
      hoverinfo: 'text',
      x: FILTERED_CAL.map(day => `${day.calDate}`),
      y: range,
      type: 'heatmap',
      reversescale: true,
      zauto: false,
      zmin,
      zmax,
      colorbar: {
        tickvals: props.diffMethod === 'absolute' ? undefined : [-100, -75, -50, -25, 0, 25, 50, 75, 100],
        tickmode: props.diffMethod === 'absolute' ? 'auto' : "array",
        ticktext: props.diffMethod === 'relative' ? ['-100%', '-75%', '-50%', '-25%', '0%', '25%', '50%', '75%','100%'] : undefined,
      },
      colorscale: [
        ['0', 'rgb(90,138,198)'],
        ['0.05', 'rgb(107,150,204)'],
        ['0.1', 'rgb(123,161,210)'],
        ['0.15', 'rgb(139,173,216)'],
        ['0.2', 'rgb(155,184,221)'],
        ['0.25', 'rgb(171,195,227)'],
        ['0.3', 'rgb(188,207,233)'],
        ['0.35', 'rgb(204,218,238)'],
        ['0.4', 'rgb(220,230,244)'],
        ['0.45', 'rgb(236,241,250)'],
        ['0.5', 'rgb(255,255,255)'],
        ['0.55', 'rgb(251,237,240)'],
        ['0.6', 'rgb(251,222,225)'],
        ['0.65', 'rgb(250,207,210)'],
        ['0.7', 'rgb(250,193,195)'],
        ['0.75', 'rgb(250,178,181)'],
        ['0.8', 'rgb(249,163,166)'],
        ['0.85', 'rgb(249,149,151)'],
        ['0.9', 'rgb(248,134,136)'],
        ['0.95', 'rgb(248,119,121)'],
        ['1.0', 'rgb(248,105,107)'],
      ]
    }
  ]

  }, [props, FILTERED_CAL, ALL_DAYS, YEARS]);

  const measure = React.useMemo(() => {
    const { measure } = props.locations.find(loc => loc.key === props.location);
    return measure || '';
  }, [props.location, props.locations]);

  const tickformat = React.useMemo(() => {
    let numberOfDays = props.endDay - props.startDay;
    if(numberOfDays > 200){
      return '%b';
    }
    return '%b %d';
  }, [props.startDay, props.endDay]);

  const layout = React.useMemo(() => {
    return {
      autosize: true,
      title: `${props.location} ${measure} - ${props.selectedThreshold ? props.selectedThreshold.name : props.altA} vs. ${props.altB}<br>${ALL_DAYS[props.startDay-1].date} - ${ALL_DAYS[props.endDay-1].date}`,
      showlegend: false,
      hovermode: 'closest',
      legend: {orientation: 'h'},
      yaxis: {automargin: true, autorange: 'reversed', linewidth: 1, linecolor: '#333'},
      xaxis: {automargin: true, tickformat, nticks: 12, linewidth: 1, linecolor: '#333'},
      font: {
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
        size: globalLayoutSettings.fontSize
      }
    }
  }, [props.location, props.selectedThreshold, measure, props.altA, props.altB, props.startDay, props.endDay, ALL_DAYS, globalLayoutSettings, tickformat]);

  const style = React.useMemo(() => {
    return {width: '100%', height: '100%'};
  }, []);

  const chartPickerRef = React.useRef(null);
  const locationPickerRef = React.useRef(null);
  const dateRangeRef = React.useRef(null);
  const scenariosRef = React.useRef(null);
  const diffRef = React.useRef(null);

  const helpItems = [chartPickerRef, locationPickerRef, dateRangeRef, scenariosRef, diffRef];

  return <div style={{display: 'flex', flex: 1, background: '#fff', padding: 8, height: 'calc(100% - 33px)', maxHeight: 'calc(100% - 33px)'}}>
    <Help show={props.showHelp} items={helpItems} chartType={"Period of Record"} onDismiss={props.onCloseHelp} />
    <div style={{width: '15%', maxWidth: '15%'}}>
      <div style={{marginBottom: 32}} ref={chartPickerRef} id="chart-picker">
        <ChartPicker chartType={props.chartType} onChange={(chartType) => props.changeFilters({chartType})} />
      </div>
      <div id="location-picker" ref={locationPickerRef}>
        <LocationPicker locationGroups={props.locationGroups} toggleShowMapper={props.toggleShowMapper} locations={props.locations} location={props.location} onChange={(location) => props.changeFilters({location})} />
      </div>
      <div id="date-picker" ref={dateRangeRef}>
        <DateRange startCalDay={props.startCalDay} hidden={dateRangePickerHidden} toggleDateRangePickerHidden={toggleDateRangePickerHidden} startDay={props.startDay} endDay={props.endDay} onChange={(startDay, endDay) => props.changeFilters({startDay, endDay})} />
      </div>
    </div>
    
    {/* <div id={`${props.id}-plotwrapper`} className='ms-depth-4 bg-white f1'> */}
      {
        chartData.length > 0 ? <div className='f1' style={{position: 'relative', width: '70%', maxWidth: '70%'}}> 
          <Plot 
            data={chartData}
            layout={layout}
            useResizeHandler
            onDoubleClick={() => props.changeFilters({startDay: FILTERED_CAL[0].julian, endDay: FILTERED_CAL[FILTERED_CAL.length - 1].julian})}
            config={{
              modeBarButtonsToRemove: ['toImage'],
              displaylogo: false,
              modeBarButtonsToAdd: [{
                name: 'Capture',
                click: (gd, e) => {
                  Plotly.relayout(gd, {showlegend: true})
                  Plotly.downloadImage(gd);
                  Plotly.relayout(gd, {showlegend: false});
                }, 
                icon: {
                  width: 1000,
                  height: 1000,
                  path: 'm500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z',
                  transform: 'matrix(1 0 0 -1 0 850)'
                }
              }]
            }}
            style={style} />
        </div> 
        : 
        <div className='fr jcc aic empty f1' style={{width: '70%', maxWidth: '70%', height: '100%'}}>
          <div style={{textAlign: 'center'}}>
            <Icon iconName="SearchIssue" styles={{root: {fontSize: 42}}} />
            <p style={{fontSize: 18}}>No data to display.</p>
          </div>
        </div>
      }
    {/* </div> */}
    <div style={{width: '15%', maxWidth: '15%'}}>
      <div ref={scenariosRef} id="scenario-picker">
        <SingleAltPicker
          ALL_DAYS={ALL_DAYS}
          alternatives={alternatives} 
          label="Base Scenario" 
          thresholds={props.thresholds}
          location={props.location}
          canEditThreshold
          altKey={props.altA}
          threshold={props.selectedThreshold}
          changeFilters={props.changeFilters}
          onSetThreshold={(t) => props.changeFilters({selectedThreshold: t, altA: null})}
          onChange={(key) => props.changeFilters({altA: key, selectedThreshold: null})} />
        <SingleAltPicker
          ALL_DAYS={ALL_DAYS}
          alternatives={alternatives}
          label="Comp Scenario" 
          thresholds={[]} 
          altKey={props.altB}
          onChange={(key) => props.changeFilters({altB: key})} />
      </div>
      <div ref={diffRef} id="diff-picker">
        <ToggleButton 
          label="Diff Method" 
          activeKey={props.diffMethod} 
          buttons={[
            {key: 'absolute', text: 'Absolute', onClick: () => props.changeFilters({diffMethod: 'absolute'})}, 
            {key: 'relative', text: 'Relative', onClick: () => props.changeFilters({diffMethod: 'relative'})}
          ]} />
      </div>

    </div>
  </div>
}