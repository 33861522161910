import { Checkbox, DefaultButton, FontIcon, Label, PrimaryButton, TextField, getTheme } from '@fluentui/react';
import axios from 'axios';
import React from 'react';

import LoadingBlocker from './LoadingBlocker';

const ProjectWrapper = (props) => {

  const [loading, setLoading] = React.useState(true);
  const [rememberMe, setRememberMe] = React.useState(false);
  const [accessCode, setAccessCode] = React.useState('');
  const [authenticated, setAuth] = React.useState(false);
  const [projectProps, setProjectProps] = React.useState(null);
  const [projectNotFound, toggleProjectNotFound] = React.useState(false);
  const [showAccessCodeForm, toggleShowAccessCodeForm] = React.useState(false);

  const updateProjectData = React.useCallback((changes) => {
    setProjectProps(prev => {
      return {...prev, ...changes};
    })
  });


  const getProjectData = (project_key) => {

    setLoading(true);
    axios.get(`/api/project/${project_key}`, {headers: {token: localStorage.getItem('token'), remembermetoken: localStorage.getItem(`${project_key}-token`) || ''}})
    .then(res => {
      if(res.data.success){
        if(res.data.public){
          const { alternatives, map, title, mapSurveyEnabled, onlyShowMapSurvey, isDynamicLoading=true, defaultChart, enableDefaultChart=false } = res.data;
          setProjectProps({alternatives, map, title, mapSurveyEnabled, onlyShowMapSurvey, isDynamicLoading, defaultChart, enableDefaultChart});
          setAuth(true);
          setLoading(false);
        }
        if(res.data.requriesAccessCode){
          setAuth(false);
          setLoading(false);
          toggleShowAccessCodeForm(true);
        }
        if(res.data.authenticated){
          const { alternatives, map, title, mapSurveyEnabled, isDynamicLoading=true, defaultChart, enableDefaultChart=false } = res.data;
          setProjectProps({alternatives, map, title, mapSurveyEnabled, isDynamicLoading, defaultChart, enableDefaultChart});
          setAuth(true);
          setLoading(false);
        }
      } else {
        throw Error(res?.data?.message);
      }
    })
    .catch(err => {
      toggleProjectNotFound(true);
      setLoading(false);
    })
  };

  React.useEffect(() => {
    getProjectData(props.match.params.project_key);
  }, [props.match.params.project_key]);


  const openProject = () => {
    const { project_key } = props.match.params;
    if(!accessCode){
      return alert("Please enter access code");
    }
    axios.get(`/api/project/${project_key}/${accessCode}`, {headers: {rememberMe}})
    .then(res => {
      if(res.data.success){
        if(res.data.rememberMeToken){
          localStorage.setItem(`${project_key}-token`, res.data.rememberMeToken)
        }
        setProjectProps(res.data.data);
        setAuth(true);
      } else {
        setAccessCode('');
        alert("Invalid access code");
      }
    })
    .catch(err => {
      console.error(err.message);
    })
  }

  if(loading){
    return <LoadingBlocker />
  }

  if(authenticated){
    return React.cloneElement(props.children, {...projectProps, setProjectProps, updateProjectData});
  }

  if(projectNotFound){
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column'}}>
      {/* <div className='p2 ms-depth-8 bg-white signup-form'> */}
      <div style={{display: 'flex', alignItems: 'center'}}>
        <FontIcon iconName='SearchIssue' style={{fontSize: 82, marginRight: 48, color: getTheme().palette.neutralPrimary}}  />
        <div>
          <div style={{marginBottom: 32}}>
            <p style={{fontSize: 24}}>Unable to find project</p>
            {/* <p style={{fontSize: 24}}>Please enter the access code:</p> */}
          </div>
          <div>
            <DefaultButton text="Back" onClick={() => props.history.push('/dashboard')} />
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>

  }

  if(showAccessCodeForm){
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column'}}>
      {/* <div className='p2 ms-depth-8 bg-white signup-form'> */}
      <div style={{display: 'flex', alignItems: 'center'}}>
        <FontIcon iconName='AuthenticatorApp' style={{fontSize: 82, marginRight: 48, color: getTheme().palette.neutralPrimary}}  />
        <div>
          <div style={{marginBottom: 32}}>
            <p style={{fontSize: 24}}>This is a private project.</p>
            <p style={{fontSize: 24}}>Please enter the access code:</p>
            <TextField 
              autoComplete='off' 
              className='mb3'
              
              value={accessCode} 
              type="password" 
              onChange={(e, c) => setAccessCode(c)} />
            <Checkbox styles={{root: {marginTop: 16}}} label='Remember me on this device' checked={rememberMe} onChange={(e, checked) => setRememberMe(checked)} />
          </div>
          <div>
            <PrimaryButton className='mr3' text="Submit" onClick={openProject} />
            <DefaultButton text="Back" onClick={() => props.history.push('/dashboard')} />
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  }

  return <LoadingBlocker />

  // if(projectNotFound){
  //   return <div className='center'>
  //     <div className='p2 ms-depth-8 bg-white signup-form'>
  //       <p>Project not found</p>
  //       <PrimaryButton text="Go Back" onClick={() => props.history.push('/project')}/>
  //     </div>
  //   </div>
  // }

  // if(projectType === 'public'){
  //   return <div className='center'>
  //     <div className='p2 ms-depth-8 bg-white signup-form'>
  //       <TextField 
  //         autoComplete='off' 
  //         className='mb3' 
  //         value={accessCode} 
  //         type="password" 
  //         onChange={(e, c) => setAccessCode(c)} label="Access Code" />
  //       <PrimaryButton className='mr3' text="Submit" onClick={openProject} />
  //       <DefaultButton text="Back" onClick={() => props.history.push('/dashboard')} />
  //       {/* <PrimaryButton text="Go Back" onClick={() => props.history.push('/project')}/> */}
  //     </div>
  //   </div>
  // }

  // if(projectType === 'private'){
  //   return <div className='center'>
  //   <div className='p2 ms-depth-8 bg-white signup-form'>
  //     <p>Private project, please sign in</p>
  //     <PrimaryButton text="Go Back" onClick={() => props.history.push('/project')}/>
  //   </div>
  // </div>
  // }

  // return <p>Go back</p>


}

export default ProjectWrapper;