import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ActionButton, Depths, FontIcon, IconButton, Modal, getTheme } from '@fluentui/react';

function formatIsoDateString(isoDateString) {
  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  const date = new Date(isoDateString);
  const month = months[date.getMonth()];
  const day = date.getDate();

  return `${month} ${day.toString().padStart(2, '0')}`;
}

const WeatherForecast = () => {
  const [forecast, setForecast] = useState([]);
  const [fullScreenImageSrc, setFullScreenImageSrc] = React.useState(null);

  useEffect(() => {
    // NWS endpoint for grid points. Replace with the appropriate endpoint for Wolf Point, Montana
    const endpoint = 'https://api.weather.gov/gridpoints/GGW/83,107/forecast';

    axios.get(endpoint)
      // .then(response => response.json())
      .then(({data}) => {
        console.log(data);
        setForecast(data.properties.periods);
      })
      .catch(err => {
        console.log(err);
      });

    // axios.get('https://api.weather.gov/points/48.0914,-105.6413')
    //   .then(({data}) => {
    //     console.log(data);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
  }, []);

  const onClickImage = (e) => {
    console.log(e.target.src);
    setFullScreenImageSrc(e.target.src);
  }

  if (!forecast) {
    return <div>Loading forecast...</div>;
  }

  const timeStamp = Date.now();

  return (
    <div style={{padding: 8, boxShadow: Depths.depth16, background: '#fff', marginBottom: 32, width: '100%', maxWidth: 1200, width: '100%'}}>
      <Modal isOpen={fullScreenImageSrc} onDismiss={() => setFullScreenImageSrc(null)}>
        <IconButton iconProps={{iconName: "Cancel"}} styles={{root: {background: "#fff", position: "absolute", top: 16, right: 16, zIndex: 99999}}} onClick={() => setFullScreenImageSrc(null)} />
        <img src={fullScreenImageSrc} height={'100%'} />
      </Modal>
      <h2 style={{fontWeight: 600}}>7 Day Weather Forecast for Wolf Point, MT</h2>
      <div style={{display: 'flex', gap: 16}}>
        <div>
          <h3>48 Hour Precipitation</h3>
          <img onClick={onClickImage} src={`https://www.wpc.ncep.noaa.gov/qpf/d12_fill.gif?${timeStamp}`} style={{width: '100%', boxShadow: Depths.depth8}}/>
        </div>
        <div>
          <h3>72 Hour Precipitation Total</h3>
          <img onClick={onClickImage} src={`https://www.wpc.ncep.noaa.gov/qpf/d13_fill.gif?${timeStamp}`} style={{width: '100%', boxShadow: Depths.depth8}}/>
        </div>
        <div>
          <h3>5 Day Precipitation Total</h3>
          <img onClick={onClickImage} src={`https://www.wpc.ncep.noaa.gov/qpf/p120i.gif?${timeStamp}`} style={{width: '100%', boxShadow: Depths.depth8}} />
        </div>
        <div>
          <h3>7 Day Precipitation Total</h3>
          <img onClick={onClickImage} src={`https://www.wpc.ncep.noaa.gov/qpf/p168i.gif?${timeStamp}`} style={{width: '100%', boxShadow: Depths.depth8}} />
        </div>
      </div>
      <div style={{display: 'flex'}}>
        {forecast.slice(0, 14).map((day, index) => {
          const { temperature, isDaytime } = day;
          const startTime = formatIsoDateString(day.startTime);

          return (
            <div style={{flex: 1, borderRight: index === 13 ? 'none' : `2px solid ${getTheme().palette.neutralLight}`, padding: 4, textAlign: 'center', padding: 0}} key={index}>
              <div>
                <FontIcon style={{fontSize: 20, marginTop: 8, marginBottom: 8}} iconName={isDaytime ? 'Sunny' : "ClearNight"} />
              </div>
              <p style={{fontSize: 13}}>{startTime}</p>
              <p style={{textAlign: 'center', fontSize: 12}}>{temperature} F</p>
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default WeatherForecast;