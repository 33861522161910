import { Icon, Panel, PrimaryButton, TextField, Label, PanelType, ActionButton, DefaultButton, IconButton, Checkbox, Toggle, Dialog, FontIcon, getTheme, Depths, Image, ImageFit } from '@fluentui/react';
import React from 'react';
import Dropzone from 'react-dropzone';
import MapGL, { Marker, Popup } from 'react-map-gl';
import axios from 'axios';

import 'mapbox-gl/dist/mapbox-gl.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MAP_STYLES = [
  {
    key: 'mapbox://styles/compassrm/cl6l76w8f000u15ph9l8m5sry',
    text: 'Terrain'
  },
  {
    key: 'mapbox://styles/compassrm/clmi1wzuq019201pvfptc0l4x',
    text: 'Satellite'
  }
];

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
};

const initalItemState = {title: "", description: "", images: [], lat: null, lng: null, elevation: false, erosion: false, intakes: false, private: true};

const initialSteps = [
  // step 1 - privacy toggle;
  {
    id: 0,
    type: 'toggle',
    label: "Would you like this feedback to be private?",
    description: "If checked only Administrators will see this feedback.",
    value: true
  },
  {
    id: 3,
    type: "text",
    label: "What is your name?",
    description: "This information will not be displayed or shared externally. We will only use your name to contact you if we have questions about your feedback.",
    value: ""
  },
  {
    id: 1,
    type: 'coordinate',
    lat: null,
    lng: null,
  },
  {
    id: 2,
    type: 'text',
    label: "What do you call this location?",
    value: ''
  },
  {
    id: 4,
    type: "text",
    multiline: true,
    rows: 4,
    label: "What is the concern at this location?",
    description: "Provide a brief description of the problem you would like to see addressed at this location.",
    value: ""
  },
  {
    id: 5,
    type: 'checkbox',
    label: "Which monitoring activities would provide you with the information that you need?",
    options: ["Water elevation profiles", "Bank erosion monitoring", "Specific water intakes"],
    selectedOptions: []
  },
  {
    id: 6,
    type: 'file',
    accept: 'image/*',
    required: false,
    label: "Upload any relevant photo's from the location that can help us better understant the issues.",
    multiple: false,
    files: []
  }
]

export default function MapForm(props){

  const [items, setItems] = React.useState([]);
  const [mapStyle] = React.useState(MAP_STYLES[1].key);
  const [popup, setPopup] = React.useState(null);
  const [addingNewItem, setAddingNewItem] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState(initialSteps);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
  const [showList, setShowList] = React.useState(false);
  const [hoveredResponse, setHoveredResponse] = React.useState(null);

  const [editMarker, setEditMarker] = React.useState(null);

  // console.log(props.user);
  const role = React.useMemo(() => {
    if(!props.user) return null;
    let { role } = props.user;
    return role;
  }, [props.user]);

  React.useEffect(() => {
    axios.get(`/api/map-markers/${props.match.params.project_key}`, {headers: {token: localStorage.getItem('token')}})
    .then(res => {
      if(res.data.success) return setItems(res.data.data.map(el => ({...el, open: false})));
    })
    .catch(err => {
      // console.log(err);
    })
  }, []);

  const [viewState, setViewState] = React.useState({
    longitude: -105.7587634385,
    latitude: 48.0042058475124,
    zoom: 7.6,
    bearing: 0,
    pitch: 0
  });

  const tempMarkerSpot = React.useMemo(() => {
    let { lat, lng } = steps.find(el => el.type === 'coordinate');
    return {lat, lng};
  }, [activeStep, steps]);

  const onDrop = (acceptedFiles) => {

    const [file] = acceptedFiles;

    let {type, name} = file;

    name = name.replaceAll(' ', "-");
    name = name.replaceAll('(', "");
    name = name.replaceAll(')', "");
    name = name.replaceAll('+', "-");
    name = name.replaceAll('$', "");
    name = name.replaceAll('?', "");

    let newImage = {id: Date.now(), title: name, description: "", url: "", signedUrl: ""};

    axios.put(`/api/map-upload/${props.match.params.project_key}`, {contentType: type, name: name, path: props.match.params.project_key})
      .then(res => {
        if(res.data.success){
          const options = {
            headers: {
              'Content-Type': type
            }
          };
          return axios.put(res.data.url, file, options)
        } else {
          throw Error("Unable to upload")
        }
      })
      .then(res => {
        if(res.status === 200){
          const {url} = res.config;
          const [dataSource] = url.split('?');
          newImage.url = dataSource;
          return axios.get(`/api/signedurl-map/${props.match.params.project_key}/${name}`)
        } else {
          throw Error("Cannot get image")
        }
        // setUploading(false);
      })
      .then(res => {
        newImage.signedUrl = res.data.url;
        setSteps(prev => {
          return prev.map((step, index) => {
            if(index === activeStep){
              return {
                ...step,
                files: step.files.concat(newImage)
              }
            }
            return step
          })
        })
      })
      .catch(err => {
        // console.log(err);
        alert("Unable to upload photo");
      });
      
  }

  const onClickMap = React.useCallback((e) => {
    // if(!addingNewItem) return;
    const { lat, lng } = e.lngLat;


    if(editMarker && editMarker.data.data[activeStep].type === 'coordinate'){
      setEditMarker(prev => {
        return {
          ...prev,
          data: {
            ...prev.data,
            data: prev.data.data.map((el, i) => i === activeStep ? {...el, lat, lng} : el)
          }
        }
      })
    }

    if(steps[activeStep].type === 'coordinate'){
      setSteps(prev => {
        return prev.map((el, i) => {
          if(activeStep === i){
            return {...el, lat, lng};
          }
          return el;
        })
      })
    }
  }, [steps, activeStep, editMarker]);

  // const onDragMarker = (e, id) => {
  //   const { lat, lng } = e.lngLat;
  //   setItems(prev => {
  //     return prev.map(item => {
  //       if(item.id === id){
  //         return {...item, lat, lng};
  //       }
  //       return item;
  //     })
  //   })
  // }

  const onDragEditMarker = (e) => {
    const { lat, lng } = e.lngLat;
    setSteps(prev => {
      return prev.map(el => {
        if(el.type === 'coordinate'){
          return {...el, lat, lng};
        }
        return el;
      })
    })
  }

  const markers = React.useMemo(() => {
    return items.map(item => {
      const {id} = item;
      const { data } = item.data;
      const { value } = data.find(el => el.type === 'toggle');
      const { lat, lng } = data.find(el => el.type === 'coordinate');
      const color = id === hoveredResponse ? getTheme().palette.orange : getTheme().palette.themeTertiary
      if(value && (role !== 1)){
        return null;
      }
      return <Marker 
        key={`mark-${id}-${color}`} 
        longitude={lng} 
        onClick={
          (e) => {
            e.originalEvent.stopPropagation();
            setPopup(item);
          }
        } 
        latitude={lat}
        // color='blue'
        color={color}
      />
    });
  }, [items, role, hoveredResponse]);

  const onMove = ({viewState}) => {
    //  const { lat, lng, pitch, bearing, zoom} = viewState;
     setViewState(prev => ({...viewState}));
  }

  const saveLocation = () => {

    if(editMarker){
      axios.put(`/api/map-markers/${editMarker.project_key}`, editMarker, {headers: {token: localStorage.getItem('token')}})
      .then(res => {
        // console.log(res);
        if(res.data.success){
          setItems(prev => prev.map(p => p.id === res.data.data.id ? res.data.data : p));
          setEditMarker(res.data.data);
        }
      })
      .catch(err => {
        // console.log(err);
      })
    } else {
      axios.post(`/api/map-markers/${props.match.params.project_key}`, {data: {data: steps}})
      .then(res => {
        if(res.data.success){
          setShowSuccessMessage(true);
          setItems(prev => prev.concat(...res.data.data));
          setSteps(initialSteps);
          setActiveStep(0);
          setAddingNewItem(false);
        }
      })
      .catch(err => {
        // console.log(err);
      })
    }


  }

  const popupData = React.useMemo(() => {
    if(!popup) return null;

    const { lat, lng } = popup.data.data.find(el => el.type === 'coordinate');
    const {files=[]} = popup.data.data.find(el => el.type === 'file');
    const [isPrivate, name, coords, placeName, problem, activities] = popup.data.data;

    return {lat, lng, files, location: placeName.value, problem: problem.value, activities: activities.selectedOptions};

  }, [popup]);


  const step = React.useMemo(() => {
    return steps[activeStep];
  }, [steps, activeStep]);

  const nextDisabled = React.useMemo(() => {
    if(editMarker) return false;
    if(steps[activeStep].type === 'coordinate'){
      if(!steps[activeStep].lat || !steps[activeStep].lng) return true;
    }
    return false;
  }, [activeStep, steps, editMarker]);

  return <div style={{height: '100vh'}}>

    {
      showSuccessMessage && <Dialog hidden={false} onDismiss={() => setShowSuccessMessage(false)}>
        <div style={{textAlign: 'center'}}>
          <FontIcon iconName='SkypeCircleCheck' style={{fontSize: 48, color: getTheme().palette.green}} />
          <h1 style={{fontWeight: 600, fontSize: 32}}>Thank you!</h1>
          <p style={{fontSize: 22}}>Your feedback has been saved.</p>
          <p style={{fontSize: 18}}>Only administrators can see the information you submitted, so if you no longer see your marker, don't worry.</p>
          <p style={{fontSize: 18}}>To add more markers, click "Close".</p>
          <p style={{fontSize: 18}}>If you are finished, you can exit the application.</p>
          <DefaultButton text="Close" onClick={() => setShowSuccessMessage(false)} />
        </div>
      </Dialog>
    }

    <Panel isOpen={showList} type={PanelType.medium} isLightDismiss isBlocking={false} onDismiss={() => setShowList(false)} headerText='Responses'>
      {
        items.map((item, index) => {
          const { data } = item.data;
          return <div onMouseEnter={() => setHoveredResponse(item.id)} onMouseLeave={() => setHoveredResponse(null)} style={{padding: 8, boxShadow: Depths.depth8, margin: '8px 0px'}}>
            <IconButton onClick={() => setItems(prev => prev.map(i => i.id === item.id ? {...i, open: !i.open} : i))} iconProps={{iconName: item.open ? "ChevronUpMed" : "ChevronDownMed"}} />
            {data[0].value ? "Private response" : data[1]?.value || "Unnamed response"}
            {
              item.open && <div>
                <h3 style={{fontWeight: 600}}>Location Name</h3>
                <p>{data[3].value || "---"}</p>
                <h3 style={{fontWeight: 600}}>Concern</h3>
                <p>{data[4].value || "---"}</p>
                <h3 style={{fontWeight: 600}}>Monitoring activities needed</h3>
                {data[5].selectedOptions.length === 0 ? "---" : <ul>
                  {
                    data[5].selectedOptions.map((o, i) => <li key={i}>{o}</li>)
                  }
                </ul>}
                <h3 style={{fontWeight: 600}}>Attachments</h3>
                <ImageGallery images={data[6].files} />
              </div>
            }
          </div>
        })
      }

    </Panel>


    <Panel styles={{content: {paddingTop: 24}, commands: {zIndex: 99999, background: "#fff", paddingBottom: 12}}} headerText="Edit marker" isFooterAtBottom={true} onRenderFooter={() => {
      return <div style={{position: 'sticky', bottom: 0, padding: 24, background: '#fff'}}>
        <DefaultButton styles={{root: {marginRight: 8}}} text="Back" disabled={activeStep === 0} onClick={() => setActiveStep(prev => prev - 1)}/>
        { editMarker && activeStep !== editMarker.data.data.length - 1 && <DefaultButton disabled={nextDisabled} text="Next" onClick={() => setActiveStep(prev => prev + 1)}/>}
        { editMarker && activeStep === editMarker.data.data.length - 1 && <PrimaryButton text="Save" onClick={saveLocation}  />}
      </div>
    }} type={PanelType.medium} isBlocking={false} isOpen={editMarker} onDismiss={() => {
      setEditMarker(null);
      setActiveStep(0);
      }}>
      {
        editMarker ? (
          <Step 
            {...editMarker.data.data[activeStep]}
            onClickMap={onClickMap}
            onDrop={onDrop}
            onChange={(id, changes) => {
              setEditMarker(prev => ({
                ...prev,
                data: {
                  ...prev.data,
                  data: prev.data.data.map((el, i) => el.id === id ? {...el, ...changes} : el)
                }
              }))
            }} />
        ) : null
      }

    </Panel>

    <Panel styles={{content: {paddingTop: 24}, commands: {zIndex: 99999, background: "#fff", paddingBottom: 12}}} headerText="Create new map location" isFooterAtBottom={true} onRenderFooter={() => {
      return <div style={{position: 'sticky', bottom: 0, padding: 24, background: '#fff'}}>
        <DefaultButton styles={{root: {marginRight: 8}}} text="Back" disabled={activeStep === 0} onClick={() => setActiveStep(prev => prev - 1)}/>
        { activeStep !== steps.length - 1 && <DefaultButton disabled={nextDisabled} text="Next" onClick={() => setActiveStep(prev => prev + 1)}/>}
        { activeStep === steps.length - 1 && <PrimaryButton text="Save" onClick={saveLocation}  />}
      </div>
    }} type={PanelType.medium} isBlocking={false} isOpen={addingNewItem} onDismiss={() => {
      setAddingNewItem(false);
      setActiveStep(0);
      setSteps(initialSteps);
      }}>

      <Step 
        {...step}
        onClickMap={onClickMap}
        onDrop={onDrop}
        onChange={(id, changes) => {
          setSteps(prev => prev.map(s => s.id === id ? {...s, ...changes} : s));
        }} />

    </Panel>
    <MapGL
      mapStyle={mapStyle}
      onClick={onClickMap}
      onMove={onMove}
      viewState={viewState}
      mapboxAccessToken="pk.eyJ1IjoiY29tcGFzc3JtIiwiYSI6ImNsMDJxcDZsMDBicDQzY3JuZXpzc2FyNW4ifQ.YnHPuPzdmyAIKNPrKg65uw"
      style={{width: '100%', height: '100%'}}
    >
      {
        popup && <Popup longitude={popupData.lng} maxWidth={'max-content'} latitude={popupData.lat} onClose={() => setPopup(null)} anchor='top'>
          <div style={{minHeight: 200, minWidth: 200}}>
            <div style={{display: 'flex', alignItems: 'center'}}><Label styles={{root: {marginRight: 8}}}>Location:</Label> <span>{popupData.location}</span></div>
            <div style={{display: 'flex', alignItems: 'center'}}><Label styles={{root: {marginRight: 8}}}>Concern:</Label><span>{popupData.problem}</span></div>
            {popupData.activities.length ? <div style={{display: 'flex', alignItems: 'center'}}>
              <Label styles={{root: {marginRight: 8}}}>Monitoring activities needed:</Label><span>{popupData.activities.join(', ')}</span>
            </div> : null}
            <ImageGallery images={popupData.files} />
            {role === 1 && <ActionButton iconProps={{iconName: "Edit"}} text="Edit" onClick={() => {
              setPopup(null);
              setEditMarker(popup);
              }} />}
          </div>
        </Popup>
      }
      { markers }
      {
        tempMarkerSpot.lat && tempMarkerSpot.lng && <Marker 
          key="m1" 
          draggable
          onDragEnd={onDragEditMarker}
          latitude={tempMarkerSpot.lat} 
          longitude={tempMarkerSpot.lng} 
          color={getTheme().palette.themePrimary} />
      }
    </MapGL>
    <PrimaryButton className='ms-depth-16' text="Add New Locations" styles={{root: {position: 'fixed', bottom: 42, right: 36, fontSize: 18, padding: '24px 32px', display: addingNewItem ? 'none' : 'block'}}}  onClick={() => setAddingNewItem(true)} />
    {
      role === 1 && <PrimaryButton text="View Responses" styles={{root: {position: 'fixed', bottom: 42, left: 36, fontSize: 18, padding: '24px 32px'}}} onClick={() => setShowList(true)} />
    }
    
  </div>
}


export const ImageGallery = (props) => {

  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isFullScreen, toggleFullScreen] = React.useState(false);

  const {images=[]} = props;

  const wrapperStyle = React.useMemo(() => {
    if(isFullScreen){
      return {width: '100vw', height: '100vh', background: '#f3f2f1', padding: '12px 2px', position: 'fixed', zIndex: 9999999, top: 0, left: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'};
    }
    return {background: '#f3f2f1', padding: '12px 2px'};
  }, [isFullScreen]);

  if(!images.length){
    return null
  }

  const next = () => setCurrentIndex(prev => prev + 1);
  const last = () => setCurrentIndex(prev => prev - 1);

  return <div style={wrapperStyle}>
    <div style={{width: '100%', display: "flex", alignItems: 'center', justifyContent: "space-between"}}>
      <IconButton disabled={currentIndex === 0} onClick={last} iconProps={{iconName: "ChevronLeftMed"}} />
      {/* <img height={'100%'} src={images[currentIndex].signedUrl} /> */}
      <Image imageFit={ImageFit.contain} height={isFullScreen ? '70vh' : 200} width={isFullScreen ? '70vw' : 200} src={images[currentIndex].signedUrl} />
      <IconButton disabled={currentIndex === images.length - 1} onClick={next} iconProps={{iconName: "ChevronRightMed"}} />
    </div>
    <div>
      <div style={{textAlign: 'center'}}>
        <ActionButton text={isFullScreen ? "Close" : "Enlarge" }styles={{root: {height: 'auto', padding: 4}}} onClick={() => toggleFullScreen(prev => !prev)} />
      </div>
      <div style={{paddingLeft: 32, paddingRight: 32, textAlign: 'center'}}>
        {/* <p style={{fontWeight: 'semibold'}}>{images[currentIndex].title}</p> */}
        <p>{images[currentIndex].description}</p>
      </div>
    </div>
  </div>

}


const Step = (props) => {

  const { id, type, onChange, label, value, description, multiline, rows, lat, lng, options=[], selectedOptions=[], onDrop, files=[] } = props;
  
  switch(type){
    case 'toggle':
      return <div>
        <Toggle label={label} checked={value} onChange={(e, checked) => onChange(id, {value: checked})} />
        <p>{description}</p>
      </div>
    case 'text':
      return <div>
        <TextField multiline={multiline} rows={rows} label={label} description={description} value={value} onChange={(e, val) => onChange(id, {value: val})} />
      </div>
    case 'coordinate':
      return <div>
        <Label>Click anywhere on the map to set your location. To re-position, simply re-click on the map or drag the marker.</Label>
        <p>Latitude: {lat || "No set"}</p>
        <p>Longitude: {lng || "No set"}</p>
      </div>
    case 'checkbox':
      return <div>
        <Label>{label}</Label>
        {
          options.map((op, i) => {
            return <Checkbox
              key={i} 
              label={op} 
              styles={{root: {marginBottom: 8}}}
              checked={selectedOptions.includes(op)} 
              onChange={(e, checked) => checked ? 
                onChange(id, {selectedOptions: selectedOptions.concat(op)}) 
                : 
                onChange(id, {selectedOptions: selectedOptions.filter(el => el !== op)})}   />
          })
        }
      </div>
    case 'file':
      return <div>
        <Label>{label}</Label>

        {files.map((img, index) => {
          return <div key={index} style={{display: 'flex', gap: 16, marginBottom: 8}}>
            <img src={img.signedUrl} width={'33.33%'}></img>
            <div style={{width: '100%'}}>
              <TextField 
                label="Name" 
                value={img.title} 
                onChange={(e, title) => {
                  onChange(id, {files: files.map((x, y) => {
                    if(index === y){
                      return {...x, title}
                    }
                    return x
                  })})
                }}
              />
              <TextField 
                label="Date" 
                type='date' 
                value={img.date} 
                onChange={(e, date) => {
                  onChange(id, {files: files.map((x, y) => {
                    if(index === y){
                      return {...x, date}
                    }
                    return x
                  })})
                }}
              />
              <TextField 
                label="Description" 
                multiline rows={3} 
                value={img.description}
                onChange={(e, description) => {
                  onChange(id, {files: files.map((x, y) => {
                    if(index === y){
                      return {...x, description}
                    }
                    return x
                  })})
                }}
              />
              <ActionButton
                styles={{root: {fontSize: 12}}}
                text="Remove Image" 
                onClick={() => onChange(id, {files: files.filter((el, i) => i !== index)})} />
            </div>
          </div>
        })}
        <Dropzone 
          multiple 
          accept=".jpg, .png, image/jpeg, image/jpg" 
          onDrop={onDrop}>
          {({getInputProps, getRootProps, isDragActive}) => {
            return <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div style={{height: 64, width: 64, border: `1px solid`, borderRadius: 2, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 8}}>
                <Icon iconName='Camera' />
              </div>
            </div>
          }}
          
        </Dropzone>
      </div>
    default:
      return <p>Unkown question type.</p>
  }


}