import { resolveWindow } from "../SingleAlternative";

const rearangeThreshold = (stepsArray, startCalDay) => {
  let startOfYear = stepsArray.filter(el => el.julian < startCalDay);
  let endOfYear = stepsArray.filter(el => el.julian >= startCalDay);
  let merged = [...endOfYear, ...startOfYear];
  return merged.map(el => {
    let month = `${el.monthNum}`;
    let day = `${el.day}`;

    if(month.length === 1) month = "0" + month;
    if(day.length === 1) day = "0" + day;

    return {
      ...el,
      calDate: `${month}-${day}`
    }
  });
}

export const countAbove = (scenario, threshold, multiplier=1, startCalDay, ALL_DAYS) => {
  let window = [];
  if(!threshold.isStepped){
    if(threshold.isWindow){
      window = resolveWindow(ALL_DAYS, threshold);
    }
    let count = scenario.y.reduce((a, b, index) => {
      // if this date is not in the thresholds window, ignore it;
      if(threshold.isWindow && !window.map(el => el.calDate).includes(scenario.x[index])) return a;

      let compVal = parseFloat(threshold.value);
      
      if(threshold.isRange) compVal = parseFloat(threshold.upperRange);

      if(isNaN(compVal) || isNaN(parseFloat(b))) return a;

      if(b > compVal){
        return a += 1;
      } else {
        return a;
      }

    }, 0);
    return count * multiplier
  } else {
    let steps = rearangeThreshold(threshold.steps, startCalDay);
    let filtered = steps.filter(({calDate}) => scenario.x.some(day => day.includes(calDate)));
    let count = scenario.y.reduce((a, b, index) => {
    let compVal = parseFloat(filtered[index]?.value);
    if(isNaN(compVal) || isNaN(parseFloat(b))) return a;
      if(b > compVal){
        return a += 1;
      } else {
        return a;
      }
    }, 0);
    return count * multiplier;
  }
};

export const countEqual = (scenario, threshold, multiplier=1, startCalDay, ALL_DAYS) => {
  if(!threshold.isStepped){
    let window = [];
    if(threshold.isWindow){
      window = resolveWindow(ALL_DAYS, threshold);
    }
    let count = scenario.y.reduce((a, b, index) => {
      if(threshold.isWindow && !window.map(el => el.calDate).includes(scenario.x[index])) return a;
      let compVal = parseFloat(threshold.value);
      if(isNaN(parseFloat(b)) || isNaN(compVal)) return a;
      if(b === compVal){
        return a += 1;
      } else {
        return a;
      }
    }, 0);
    return count * multiplier
  } else {
    let filtered = rearangeThreshold(threshold.steps, startCalDay)
    .filter(({calDate}) => scenario.x.some(day => day.includes(calDate)));
    let count = scenario.y.reduce((a, b, index) => {
      let compVal = parseFloat(filtered[index].value);
      if(isNaN(parseFloat(b)) || isNaN(compVal)) return a;
      if(b === compVal){
        return a += 1;
      } else {
        return a;
      }
    }, 0);
    return count * multiplier;
  }
};

export const countBelow = (scenario, threshold, multiplier=1, startCalDay, ALL_DAYS) => {
  let window = [];
  if(!threshold.isStepped){
    if(threshold.isWindow){
      window = resolveWindow(ALL_DAYS, threshold);
    }
    let count = scenario.y.reduce((a, b, index) => {
      // if this date is not in the thresholds window, ignore it;
      if(threshold.isWindow && !window.map(el => el.calDate).includes(scenario.x[index])) return a;

      let compVal = parseFloat(threshold.value);

      if(threshold.isRange) compVal = parseFloat(threshold.lowerRange);

      if(isNaN(compVal) || isNaN(parseFloat(b))) return a;

      if(b < compVal){
        return a += 1;
      } else {
        return a;
      }

    }, 0);
    return count * multiplier;
  } else {
    let steps = rearangeThreshold(threshold.steps, startCalDay);
    if(threshold.isRange) steps = rearangeThreshold(threshold.stepsLower, startCalDay);
    let filtered = steps.filter(({calDate}) => scenario.x.some(day => day.includes(calDate)));
    let count = scenario.y.reduce((a, b, index) => {
      let compVal = parseFloat(filtered[index]?.value);
      if(isNaN(parseFloat(b)) || isNaN(compVal)) return a;
      if(b < compVal){
        return a += 1;
      } else {
        return a;
      }
    }, 0);
    return count * multiplier;
  }
};

export const countBetween = (scenario, threshold, multiplier=1, startCalDay, ALL_DAYS) => {
  let window = [];
  if(threshold.isRange){
    if(threshold.isStepped){
      let upperRange = rearangeThreshold(threshold.steps, startCalDay);
      let lowerRange = rearangeThreshold(threshold.stepsLower, startCalDay);
      let filteredUpper = upperRange.filter(({date}) => scenario.x.includes(date));
      let filteredLower = lowerRange.filter(({date}) => scenario.x.includes(date));
      let count = scenario.y.reduce((a, b, index) => {
        let compValUpper = parseFloat(filteredUpper[index].value);
        let compValLower = parseFloat(filteredLower[index].value);
        if(b <= compValUpper && b >= compValLower){
          return a+=1;
        } else {
          return a;
        }
      }, 0);
      return count * multiplier;
    } else {
      if(threshold.isWindow){
        window = resolveWindow(ALL_DAYS, threshold);
      }
      let count = scenario.y.reduce((a, b, index) => {
        if(threshold.isWindow && !window.map(el => el.calDate).includes(scenario.x[index])) return a;
        if(isNaN(parseFloat(b))) return a;
        if(b <= parseFloat(threshold.upperRange) && b >= parseFloat(threshold.lowerRange)){
          return a+=1;
        } else {
          return a;
        }
      }, 0);
      return multiplier * count;
    }
  } else {
    let count = scenario.y.reduce((a, b) => {
      if(isNaN(parseFloat(b))) return a;
      if(b <= threshold.upperRange && b >= threshold.lowerRange){
        return a+=1;
      } else {
        return a;
      }
    }, 0);
    return count * multiplier;
  }
};