import React from 'react';

import { AltPicker, ChartPicker, LOOKUP_KEYS, LookupPicker, YearPicker, labelStyle } from './Hydraviz';
import DateRange from './DateRange';
import { Label, getTheme, ContextualMenu, Icon } from '@fluentui/react';
import createPlotlyComponent from 'react-plotly.js/factory';
import Plotly from 'plotly.js';
import { DEFAULT_COLORS } from './lib';
import { trimSeries } from './lib/traces';
import Help from './Help';

const Plot = createPlotlyComponent(Plotly);

export default function MultipleLocations(props){

  const { ALL_DAYS, alternatives=[], globalLayoutSettings } = props;

  const [dateRangePickerHidden, toggleDateRangePickerHidden] = React.useState(true);

  const keyPicked = React.useMemo(() => {
    let found = LOOKUP_KEYS.concat([{key: 'pickYear', text: 'Pick Year'}]).find(k => k.key === props.lookupKey);
    return found.text;
  }, [props.lookupKey]);

  const measure = React.useMemo(() => {

    let unique = [];

    const measures = props.locations.filter(el => props.selectedLocations.includes(el.key)).map(i => i.measure);

    measures.forEach(entry => {
      if(unique.includes(entry)) return;
      unique.push(entry);
    })

    return unique.join(', ');


  }, [props.locations, props.selectedLocations]);

  const FILTERED_CAL = React.useMemo(() => ALL_DAYS.filter((d, index) => {
    let startIndex = ALL_DAYS.findIndex(d => d.julian === props.startDay);
    let endIndex = ALL_DAYS.findIndex(d => d.julian === props.endDay);
    return index >= Math.min(startIndex, endIndex) && index <= Math.max(startIndex, endIndex);
  }), [props.endDay, props.startDay, ALL_DAYS]);

  const chartData = React.useMemo(() => {


    const { selectedLocations=[], selectedLocationsY=[] } = props;

    const x = FILTERED_CAL.map((d) => `${d.calDate}`);
    
    let traces = [];
    
    props.master.forEach(trace => {
      if(props.selectedAlt !== trace.alternative) return;
      let colorIndex = 0;
      // first y axis
      selectedLocations.forEach((location, index) => {
        let dataSet = trace.data.find(entry => entry.location === location);
        if(!dataSet) return;
        const y = props.lookupKey === 'pickYear' ? trimSeries(FILTERED_CAL, dataSet.annualData[props.pickYear]) : FILTERED_CAL.map(d => dataSet.stats[d.dayLookup][props.lookupKey]);
        traces.push({
          key: `left-${trace.alternative}`,
          traceType: 'scenario',
          legendgroup: "Left Y Axis",
          name: `${location} ${dataSet.measure}`,
          line: {
            dash: 'solid'
          },
          type: 'scatter',
          mode: 'lines',
          hoverinfo: 'text',
          x,
          y,
          text: FILTERED_CAL.map((day, index) => `${trace.alternative} @ ${location}, ${day.date}<br>${y[index]} ${dataSet.measure}`)
        })
        if(colorIndex === DEFAULT_COLORS.length - 1){
          colorIndex = 0;
        } else {
          colorIndex += 1;
        }
      });

      // second y axis
      selectedLocationsY.forEach((location, index) => {
        let dataSet = trace.data.find(entry => entry.location === location);
        if(!dataSet) return;
        const y = props.lookupKey === 'pickYear' ? trimSeries(FILTERED_CAL, dataSet.annualData[props.pickYear]) : FILTERED_CAL.map(d => dataSet.stats[d.dayLookup][props.lookupKey]);
        traces.push({
          key: `right-${trace.alternative}`,
          traceType: 'scenario',
          name: `${location} ${dataSet.measure}`,
          legendgroup: "Right Y Axis",
          measure: dataSet.measure,
          line: {
            // color: DEFAULT_COLORS[5],
            dash: 'solid'
          },
          yaxis: 'y2',
          type: 'scatter',
          mode: 'lines',
          hoverinfo: 'text',
          x,
          y,
          text: FILTERED_CAL.map((day, index) => `${trace.alternative} @ ${location}, ${day.date}<br>${y[index]} ${dataSet.measure}`)
        })
        if(colorIndex === DEFAULT_COLORS.length - 1){
          colorIndex = 0;
        } else {
          colorIndex += 1;
        }
      });
    });

    return traces;
  }, [props, FILTERED_CAL]);

  const tickformat = React.useMemo(() => {
    let numberOfDays = props.endDay - props.startDay;
    // console.log(numberOfDays);
    if(numberOfDays > 200){
      return '%b';
    }
    return '%b %d';
  }, [props.startDay, props.endDay]);

  const layout = React.useMemo(() => {
    let l = {
      autosize: true,
      showlegend: true,
      hovermode: 'closest',
      title: `${props.selectedAlt}${keyPicked === 'Pick Year' ? '' : ` - ${keyPicked}`}<br>${FILTERED_CAL[0].date} - ${FILTERED_CAL[FILTERED_CAL.length - 1].date}${keyPicked === 'Pick Year' ? `, ${props.pickYear}` : ''}`,
      legend: {orientation: 'v'},
      yaxis: {automargin: true, title: measure, autorange: true},
      xaxis: {automargin: true, tickformat, nticks: 12},
      font: {
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
        size: globalLayoutSettings.fontSize
      }
    };

    if(chartData && chartData.some(el => el.yaxis === 'y2')){
      let y2 = chartData.filter(el => el.yaxis === 'y2');
      l.yaxis2 = {
        side: 'right',
        title: y2.map(el => el.measure).join(', '),
        overlaying: 'y'
      }
    }

    return l;


  }, [props.selectedAlt, props.startDay, props.endDay, keyPicked, props.pickYear, measure, ALL_DAYS, chartData, globalLayoutSettings, tickformat]);

  const onScreenCap = React.useCallback((gd, e) => {
    Plotly.downloadImage(gd, {format: 'svg'});
  }, []);

  const toggleLocation = location => {
    const isPicked = props.selectedLocations.includes(location);
    props.changeFilters({selectedLocations: isPicked ? props.selectedLocations.filter(el => el !== location) : props.selectedLocations.concat(location)});
  }

  const toggleLocationY = location => {
    let { selectedLocationsY=[] } = props;
    const isPicked = selectedLocationsY.includes(location);
    props.changeFilters({selectedLocationsY: isPicked ? selectedLocationsY.filter(el => el !== location) : selectedLocationsY.concat(location)});
  }

  const chartPickerRef = React.useRef(null);
  const lookupPickerRef = React.useRef(null);
  const dateRangeRef = React.useRef(null);
  const altPickerRef = React.useRef(null);
  const locationsPickerRef = React.useRef(null);

  const helpItems = [chartPickerRef, lookupPickerRef, dateRangeRef, altPickerRef, locationsPickerRef];

  return <div style={{display: 'flex', flex: 1, background: '#fff', padding: 8, height: 'calc(100% - 33px)', maxHeight: 'calc(100% - 33px)'}}>
    <Help show={props.showHelp} lookupKey={keyPicked} items={helpItems} chartType={"Multiple Locations"} onDismiss={props.onCloseHelp} />
    <div style={{flex: 2}}>
      <div ref={chartPickerRef} id="chart-picker" style={{marginBottom: 32}}>
        <ChartPicker chartType={props.chartType} onChange={(chartType) => props.changeFilters({chartType})} />
      </div>
      

      <div style={{marginBottom: 32}}>
        <div ref={lookupPickerRef} id="lookup-picker">
          <LookupPicker lookupKey={keyPicked} onChange={(key) => props.changeFilters({lookupKey: key})} />
          { keyPicked === 'Pick Year' && <YearPicker YEARS={props.YEARS} year={props.pickYear} onChange={(y) => props.changeFilters({pickYear: y})} />}
        </div>
        <div ref={dateRangeRef} id="date-picker">
          <DateRange startCalDay={props.startCalDay} hidden={dateRangePickerHidden} toggleDateRangePickerHidden={toggleDateRangePickerHidden} startDay={props.startDay} endDay={props.endDay} onChange={(startDay, endDay) => props.changeFilters({startDay, endDay})} />
        </div>

      </div>
      <div ref={altPickerRef} id="alt-picker" style={{marginBottom: 32}}>
        <AltPicker alternatives={alternatives} alternative={props.selectedAlt} onChange={(selectedAlt) => props.changeFilters({selectedAlt})} />
      </div>
      <div id="locations-picker" ref={locationsPickerRef}>
        <LocationsPicker label="Left Y Axis" selectedLocations={props.selectedLocations} locations={props.locations} toggleLocation={toggleLocation} />
        <LocationsPicker label="Right Y Axis" selectedLocations={props.selectedLocationsY} locations={props.locations} toggleLocation={toggleLocationY} />
      </div>
    </div>

      {
        props.selectedLocations.length === 0 ? 
        <div className='fr jcc aic empty f1' style={{flex: 10, height: '100%'}}>
          <div style={{textAlign: 'center'}}>
            <Icon iconName="ReplyAlt" styles={{root: {fontSize: 42}}} />
            <p style={{fontSize: 18}}>Select locations from the dropdowns on the left.</p>
          </div>
        </div>
        : 
        chartData.length > 0 ?
        <div className='f1' style={{position: 'relative', flex: 10}}>
          <Plot
            style={{width: '100%', height: '100%'}}
            onDoubleClick={() => props.changeFilters({startDay: FILTERED_CAL[0].julian, endDay: FILTERED_CAL[FILTERED_CAL.length - 1].julian})}
            layout={layout}
            useResizeHandler
            config={{
              modeBarButtonsToRemove: ['toImage'],
              displaylogo: false,
              modeBarButtonsToAdd: [{
                name: 'Capture',
                click: onScreenCap, 
                icon: {
                  width: 1000,
                  height: 1000,
                  path: 'm500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z',
                  transform: 'matrix(1 0 0 -1 0 850)'
                }}]
            }}
            data={chartData} />
          </div>
          :
          <div className='fr jcc aic empty f1' style={{flex: 10, height: '100%'}}>
            <div style={{textAlign: 'center'}}>
              <Icon iconName="SearchIssue" styles={{root: {fontSize: 42}}} />
              <p style={{fontSize: 18}}>No data to display.</p>
            </div>
          </div>

      }
      
  </div>
}

function LocationsPicker(props){

  const [showLocations, toggleShowLocations] = React.useState(false);
  const ref = React.useRef(null);

  const { selectedLocations=[], label } = props;

  const onClickItem = (e, item) => {
    e.preventDefault();
    props.toggleLocation(item.key);
  }

  const style = React.useMemo(() => {
    if(selectedLocations.length > 0) return;
    return {borderColor: getTheme().palette.yellow, background: `${getTheme().palette.yellow}20`};
  }, [selectedLocations]);

  return <div>
    <Label styles={labelStyle}>{label}</Label>
    <div style={{width: 'auto', maxWidth: '100%', ...style}} className='hover-label fr fwrap' onClick={() => toggleShowLocations(true)}>
      {
        selectedLocations.map((l, index) => {
          return <div key={l} style={{marginRight: 8}}>{l} {index !== selectedLocations.length - 1 ? ', ' : ''}</div>
        })
      }
      {
        selectedLocations.length === 0 && "Select Locations..."
      }
    </div>
    <div ref={ref}>
    </div>
    <ContextualMenu 
      hidden={!showLocations}
      onDismiss={() => toggleShowLocations(false)}
      target={ref}
      items={props.locations.map(loc => ({
        ...loc, 
        canCheck: true, 
        checked: selectedLocations.includes(loc.key), 
        onClick: onClickItem,
        onRender: (item) => {
          return <div style={{padding: 8, cursor: 'pointer'}} onClick={(e) => item.onClick(e, item)}>
            <Icon styles={{root: {color: item.checked ? 'green' : 'inherit', marginRight: 12}}} iconName={item.checked ? 'SkypeCircleCheck' : 'CircleRing'} />
            {item.text}
          </div>
        }
      }))} /> 
  </div>
}