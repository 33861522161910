export const MONTH_LOOKUP = {
  0: {name: 'Jan', days: 31, monthNum: 1, m: '01'},
  1: {name: 'Feb', days: 28, monthNum: 2, m: '02'},
  2: {name: 'Mar', days: 31, monthNum: 3, m: '03'},
  3: {name: 'Apr', days: 30, monthNum: 4, m: '04'},
  4: {name: 'May', days: 31, monthNum: 5, m: '05'},
  5: {name: 'Jun', days: 30, monthNum: 6, m: '06'},
  6: {name: 'Jul', days: 31, monthNum: 7, m: '07'},
  7: {name: 'Aug', days: 31, monthNum: 8, m: '08'},
  8: {name: 'Sep', days: 30, monthNum: 9, m: '09'},
  9: {name: 'Oct', days: 31, monthNum: 10, m: '10'},
  10: {name: 'Nov', days: 30, monthNum: 11, m: '11'},
  11: {name: 'Dec', days: 31, monthNum: 12, m: '12'}
};

export const ALL_DAYS = (() => {
  let arr = [];
  let counter = 1;
  for(let m = 0; m <= 11; m ++){
    for(let d = 1; d <= MONTH_LOOKUP[m].days; d++){
      arr.push({
        month: MONTH_LOOKUP[m].name,
        monthNum: MONTH_LOOKUP[m].monthNum,
        day: d,
        date: `${MONTH_LOOKUP[m].name} ${d}`,
        julian: counter,
        dayLookup: counter,
        calDate: `1999-${MONTH_LOOKUP[m].m}-${d < 10 ? `0${d}` : d}`
      });
      counter += 1;
    }
  }
  return arr;
})();

// by default, the cal starts on julian day 1 (jan 1)
// user can specify the start day to be any value between 1 and 365
// if another number is provided, console.error message and use default number (1)
// if the user supplies julianDay = 32, this means they want the cal to start on Feb 1.
// the first item in the array would be [feb-1, feb-2, feb-3 ... jan-29, jan-30, jan-31]
export const buildCal = (julianDay=1) => {
  // console.log('default cal: ', ALL_DAYS);
  if(julianDay === 1 || julianDay < 1 || julianDay > 365 || !julianDay){
    return ALL_DAYS;
  }
  let startChunk = ALL_DAYS.slice(julianDay - 1);
  let endChunk = ALL_DAYS.slice(0, julianDay - 1);
  return [...startChunk, ...endChunk].map((item, index) => ({...item, dayLookup: index + 1}));
};

export const DEFAULT_CAL = buildCal(1);