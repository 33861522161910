import { Depths, Link, MessageBar, MessageBarType, PrimaryButton, TextField, getTheme } from '@fluentui/react';
import axios from 'axios';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import AuthContext from './AuthContext';

// style={{padding: '16px', background: `${getTheme().palette.yellow}50`, border: `1px solid ${getTheme().palette.yellow}`, borderRadius: 4}}

export const MessageBarStyles = {root: {padding: '16px', borderRadius: 4, marginBottom: 16}, innerText: {fontSize: 16}, icon: {fontSize: 16}};

function SignUp(props){

  const [state, setState] = React.useState({first: '', last: '', email: '', password: '', confirmPassword: '', license: ''});

  const [errorMessage, setErrorMessage] = React.useState('');

  const { updateUser } = useContext(AuthContext);

  const attemptSignUp = () => {
    axios.post('/api/users/create', state)
    .then(response => {
      if(response.data.success){
        updateUser(response.data.user);
        props.history.push('/dashboard');
      } else {
        setErrorMessage(response.data.message);
      };
    })
    .catch(err => {
      setErrorMessage('Unable to process request')
    })
  }

  return <div className='center'>
    <div className='signup-form'>
      <h1 className='logo'>Sign Up</h1>
      {errorMessage && <MessageBar styles={MessageBarStyles} messageBarType={MessageBarType.error} onDismiss={() => setErrorMessage('')}>{errorMessage}</MessageBar>}
      <TextField 
        label='First Name' 
        value={state.first} 
        autoComplete="off" 
        onChange={(e, val) => setState(prev => ({...prev, first: val}))} />
      <TextField 
        label='Last Name' 
        value={state.last} 
        autoComplete="off" 
        onChange={(e, val) => setState(prev => ({...prev, last: val}))} />
      <TextField 
        label='Email' 
        value={state.email} 
        autoComplete="off" 
        onChange={(e, val) => setState(prev => ({...prev, email: val}))} />
      <TextField 
        label='Password' 
        value={state.password} 
        type='password'
        autoComplete="off" 
        onChange={(e, val) => setState(prev => ({...prev, password: val}))} />
      <TextField 
        label='Confirm Password'
        value={state.confirmPassword}
        type='password' 
        autoComplete="off"
        onChange={(e, val) => setState(prev => ({...prev, confirmPassword: val}))} />
      <TextField 
        label='License Key'
        // className='mb3' 
        styles={{root: {marginBottom: 16}}}
        value={state.license}
        type='text' 
        autoComplete="off"
        onChange={(e, val) => setState(prev => ({...prev, license: val}))} />
      <PrimaryButton 
        text="Submit"
        disabled={!Object.keys(state).every(key => state[key]) || (state.password !== state.confirmPassword)}
        className='mb3'
        onClick={attemptSignUp} />
      <div>
        <NavLink to='/signin'>
          <Link style={{fontSize: 13}}>
            Already Registered? Sign In
          </Link>
        </NavLink>
      </div>
    </div>
  </div>
}

export default SignUp