import React from 'react';
import MapGL, { Marker, NavigationControl, Popup, Layer, Source, useControl } from 'react-map-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ActionButton, getTheme, Dropdown, TextField, arraysEqual, IconButton, PrimaryButton, DefaultButton, Dialog, DialogType, Spinner, SpinnerSize } from '@fluentui/react';
import { v4 } from 'uuid';

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

import 'mapbox-gl/dist/mapbox-gl.css';
// import { uniqueId } from 'lodash';
import Dropzone from 'react-dropzone';
import axios from 'axios';

const MAP_STYLES = [
  {
    key: 'mapbox://styles/compassrm/cl6l76w8f000u15ph9l8m5sry',
    text: 'Terrain'
  },
  {
    key: 'mapbox://styles/compassrm/clmi1wzuq019201pvfptc0l4x',
    text: 'Satellite'
  }
];

export default function MapBuilder(props){

  const { onDrop } = props;

  const [state, setState] = React.useState(props);
  const [mapStyle, setMapStyle] = React.useState(MAP_STYLES[1].key);
  const [popup, setPopup] = React.useState(null);
  const [popupKey, setPopupKey] = React.useState(1);
  const [canAddMarker, toggleAddMaker] = React.useState(false);

  React.useEffect(() => {
    !popup && setPopupKey(prev => prev + 1);
  }, [popup]);

  const onMove = evt => setState(prev => ({
    ...prev, 
    lng: evt.viewState.longitude.toFixed(4),
    lat: evt.viewState.latitude.toFixed(4),
    zoom: evt.viewState.zoom.toFixed(2),
    bearing: evt.viewState.bearing.toFixed(2),
    pitch: evt.viewState.pitch.toFixed(2)
  }));

  const onDragMarker = (e, key) => {
    const { lat, lng } = e.lngLat;
    setState(prev => {
      return {
        ...prev,
        markers: prev.markers.map(m => m.key === key ? {...m, lat, lng} : m)
      }
    })
  };

  const markers = React.useMemo(() => {
    return state.markers.map(marker => {
      return <Marker key={marker.key} longitude={marker.lng} onClick={(e) => {
        e.originalEvent.stopPropagation();
        setPopup(marker);
      }} latitude={marker.lat} draggable onDragEnd={(e) => onDragMarker(e, marker.key)} color='#f44336'/>
    });
  }, [state.markers]);


  const layers = React.useMemo(() => {
    if(!state.layers) return [];
    return state.layers;
  }, [state.layers]);

  const sourceLayerComponents = React.useMemo(() => {
    return state.layers.map((lay, i) => {
      return <SourceLayer key={i} {...lay} />
    })
  }, [state.layers]);

  // console.log(layers);

  const setMapCenter = () => {
    const {bearing, zoom, pitch, lat, lng, markers } = state;
    props.editMap({bearing, zoom, pitch, lat, lng, markers});
  };

  const onMapClick = (e) => {

    if(canAddMarker){
      const { lngLat } = e;
      const newMarker = {
        key: v4(),
        text: '',
        name: '',
        location: '',
        lng: Number(lngLat.lng).toFixed(4),
        lat: Number(lngLat.lat).toFixed(4),
        color: '#f44336'
      }
      setPopup(newMarker);
      setState(prev => ({...prev, markers: prev.markers.concat(newMarker)}));
      toggleAddMaker(false);
    }
  };

  React.useEffect(() => {
    if(!arraysEqual(state.markers, props.markers)){
      props.editMap({markers: state.markers});
    }
  }, [state.markers, props.markers]);

  React.useEffect(() => {
    const to = setTimeout(() => {
      if(popup){
        setState(prev => ({
          ...prev,
          markers: prev.markers.map(m => {
            if(m.key === popup.key){
              return {
                ...m,
                ...popup
              }
            }
            return m
          })
        }))
      }
    }, 1000);
    return () => clearTimeout(to);
  }, [popup]);

  const [addLayerVisible, toggleAddLayerVisible] = React.useState(false);

  const [addLayerState, setAddLayerState] = React.useState({file: '', id: '', name: '', description: ''});

  const [uploading, setUploading] = React.useState(false);

  // const [layers, setLayers] = React.useState([]);

  const uploadLayer = () => {
    if(!addLayerState.file) return;
    
    setUploading(true);
    onDrop(
      [addLayerState.file], 
      (layerUrl, err) => {
        if(err || !layerUrl){
          alert(err);
        }
        else {
          const { layers=[] } = state;
          const updatedLayers = layers.concat({id: v4(), name: addLayerState.name, url: layerUrl, description: addLayerState.description})
          setState(prev => {
            return {...prev, layers: updatedLayers };
          });

          props.editMap({layers: updatedLayers});
          
          setAddLayerState({file: '', id: "", name: '', description: ''});
          toggleAddLayerVisible(false);
        }
        setUploading(false);
      },
      'application/geo+json'
    )
  }

  const [features, setFeatures] = React.useState({});

  // console.log({features});

  // const onUpdate = React.useCallback(e => {

  //   setFeatures(currFeatures => {
  //     console.log({currFeatures})
  //     const newFeatures = {...currFeatures};
  //     for (const f of e.features) {
  //       newFeatures[f.id] = f;
  //     }
  //     return newFeatures;
  //   });
  // }, []);

  // const onDelete = React.useCallback(e => {
  //   // console.log("DELETE");
  //   setFeatures(currFeatures => {
  //     const newFeatures = {...currFeatures};
  //     for (const f of e.features) {
  //       delete newFeatures[f.id];
  //     }
  //     return newFeatures;
  //   });
  // }, []);


  

  return <div className='fr' style={props.style  || {height: '700px', maxHeight: 'calc(100vh - 64px)'}}>

    <Dialog 
      hidden={!addLayerVisible} 
      dialogContentProps={{type: DialogType.close, title: "Add GIS Layer"}}
      modalProps={{isDarkOverlay: false}}
      onDismiss={() => toggleAddLayerVisible(false)}>
    
    {
      uploading && <Spinner size={SpinnerSize.large} label='Uploading...' />
    }
    
    <TextField label="Name" value={addLayerState.name} onChange={(e, v) => setAddLayerState(prev => ({...prev, name: v}))} />
    <TextField label="Description" rows={3} multiline autoAdjustHeight value={addLayerState.description} onChange={(e, v) => setAddLayerState(prev => ({...prev, description: v}))} />

    <TextField
      label='File'
      styles={{root: {marginBottom: 16}}}
      value={addLayerState?.file ? addLayerState.file.name : ''}
      readOnly
      onRenderSuffix={() => {
        return <Dropzone 
          disabled={uploading}
          maxFiles={1} 
          onDrop={(acceptedFiles) => setAddLayerState(prev => ({...prev, file: acceptedFiles[0]}))}>
          {({getInputProps, getRootProps, isDragActive}) => {
            return <div {...getRootProps()}>
              <input {...getInputProps()} />
              <IconButton styles={{root: {background: 'none !important'}}} iconProps={{iconName: 'CloudUpload'}} />
            </div>
          }}
        </Dropzone>
      }} />
    
      <PrimaryButton disabled={uploading} text="Add Layer to Map" onClick={() => uploadLayer()} />

    </Dialog>


    <div style={{padding: '8px 16px', width: 420}}>
      <div>
        <h3 style={{marginBottom: 16, fontSize: 18, display: 'flex', alignItems: "center", gap: 16}}>
          Map Center
          <PrimaryButton styles={{root: {height: 'auto', padding: '4px 8px'}}} text="Set as Default Center" onClick={setMapCenter} />
        </h3>
        <p>Left click and drag to re-position latitude and longitude. Right-click and drag to adjust pitch and bearing. Scroll to adjust zoom.</p>
        <div style={{background: '#fff', border: `1px solid ${getTheme().palette.neutralLight}`, borderBottom: 'none', borderRadius: 4, marginBottom: 16}}>
          <table style={{width: '100%', borderCollapse: 'collapse'}}>
            <tbody>
              <tr>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4, paddingLeft: 8}}>Latitude:</td>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4}}>{state.lat}</td>
              </tr>
              <tr>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4, paddingLeft: 8}}>Longitude:</td>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4}}>{state.lng}</td>
              </tr>
              <tr>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4, paddingLeft: 8}}>Zoom:</td>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4}}>{state.zoom}</td>
              </tr>
              <tr>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4, paddingLeft: 8}}>Bearing:</td>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4}}>{state.bearing}</td>
              </tr>
              <tr>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4, paddingLeft: 8}}>Pitch:</td>
                <td style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4}}>{state.pitch}</td>
              </tr>
            </tbody>
          </table>
        </div>
        
      </div>
      <div>
        <h3 style={{marginBottom: 16, fontSize: 18, display: 'flex', alignItems: 'center', gap: 16}}>
          Markers
          <DefaultButton styles={{root: {height: 'auto', padding: '4px 8px'}}} primary={!canAddMarker} text={canAddMarker ? "Cancel" : "Add Marker"} onClick={() => toggleAddMaker(prev => !prev)} />
        </h3>
        {
          canAddMarker && <div style={{padding: 8, background: getTheme().palette.themeLighter, border: `1px solid ${getTheme().palette.themePrimary}`, borderRadius: 4, marginBottom: 16, display: 'flex', alignItems: 'center', justifyContent: "space-between"}}>
            Click on the map to add a new marker
            <IconButton iconProps={{iconName: "Cancel"}} styles={{root: {background: 'none !important', padding: '0px 0px 0px 8px'}}} onClick={() => toggleAddMaker(false)} />
          </div>
        }
        <div style={{marginBottom: 16, background: '#fff', border: `1px solid ${getTheme().palette.neutralLight}`, borderBottom: 'none', borderRadius: 4}}>
          {
            state.markers.map(marker => {
              return <div key={marker.key} className='fr aic' style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4, paddingLeft: 8}}>
                <div className='f1'>{marker.name}</div>
                <IconButton iconProps={{iconName: popup && popup.key === marker.key ? 'Uneditable' : 'Edit'}} styles={{root: {height: 'auto', padding: 0, background: 'none !important'}}} onClick={() => {
                  if(popup && (popup.key === marker.key)){
                    setPopup(null);
                  } else {
                    setPopup(marker)
                  }
                }} />
              </div>
            })
          }
        </div>
        
      </div>
      {/* <div>
        <h3 style={{marginBottom: 16, fontSize: 18, display: 'flex', alignItems: 'center', gap: 16}}>
          Layers
          <PrimaryButton styles={{root: {height: 'auto', padding: '4px 8px'}}} text="Add Layer" onClick={() => toggleAddLayerVisible(prev => !prev)} />
        </h3>
        <div style={{marginBottom: 16, background: '#fff', border: `1px solid ${getTheme().palette.neutralLight}`, borderBottom: 'none', borderRadius: 4}}>
          {
            layers.map(layer => {
              return <div key={layer.id} style={{borderBottom: `1px solid ${getTheme().palette.neutralLight}`, padding: 4, paddingLeft: 8}}>
                {layer.name}
              </div>
            })
          }
        </div>
        
        
      </div> */}
    </div>
    <div className='f1' style={{position: 'relative'}}>
      <MapGL
        onClick={onMapClick}
        mapStyle={mapStyle}
        // onLoad={() => setInteractiveLayerIds(state.layers?.map((lay, i) => lay.id))}
        // interactiveLayerIds={interactiveLayerIds}
        // onMouseDown={onMapClick}
        mapboxAccessToken="pk.eyJ1IjoiY29tcGFzc3JtIiwiYSI6ImNsMDJxcDZsMDBicDQzY3JuZXpzc2FyNW4ifQ.YnHPuPzdmyAIKNPrKg65uw"
        style={{width: '100%', height: '100%'}}
        onMove={onMove}
        initialViewState={{
          longitude: state.lng,
          latitude: state.lat,
          zoom: state.zoom,
          pitch: state.pitch,
          bearing: state.bearing
        }}
      >
        <NavigationControl />
        {/* <DrawControls
          position="top-left"
          displayControlsDefault={false}
          controls={{
            polygon: true,
            trash: true
          }}
          defaultMode="draw_polygon"
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        /> */}
        <Dropdown styles={{root: {position: 'absolute', top: 8, left: 8, zIndex: 1}}} options={ MAP_STYLES } selectedKey={mapStyle} onChange={(e, o) => setMapStyle(o.key)} />
        {sourceLayerComponents}
        {markers}
        {
          popup && (<Popup style={{width: 320, minWidth: 320, maxWidth: 320}} key={popupKey} latitude={Number(popup.lat)} longitude={Number(popup.lng)} onClose={() => {
            setState(prev => ({
              ...prev,
              markers: prev.markers.map(m => {
                if(m.key === popup.key){
                  return {
                    ...m,
                    ...popup
                  }
                }
                return m
              })
            }))
            setPopup(null)
          }}>
            <TextField label='Name' value={popup.name} onChange={(e, val) => setPopup(prev => ({...prev, name: val}))} />
            <TextField label='Description' value={popup.text} rows={4} multiline onChange={(e, val) => setPopup(prev => ({...prev, text: val}))} />
            <Dropdown 
              label="Linked Location" 
              selectedKey={popup.location} 
              options={props.locations.concat({key: '', text: "None"})} 
              onChange={(e, o) => setPopup(prev => ({...prev, location: o.key}))} />
            <ActionButton 
              iconProps={{iconName: "Delete"}} 
              styles={{rootHovered: {color: `${getTheme().palette.redDark} !important`}, icon: {color: 'inherit !important'}}}
              text="Delete"
              onClick={() => {
                setState(prev => {
                  return {
                    ...prev,
                    markers: prev.markers.filter(m => m.key !== popup.key)
                  }
                });
                setPopup(null);
              }}
              />
          </Popup>)
        }
      </MapGL>
    </div>
  </div>
}

const DrawControls = (props) => {
  useControl(
    () => new MapboxDraw(props),
    ({map}) => {
      map.on('draw.create', props.onCreate);
      map.on('draw.update', props.onUpdate);
      map.on('draw.delete', props.onDelete);
    },
    ({map}) => {
      map.off('draw.create', props.onCreate);
      map.off('draw.update', props.onUpdate);
      map.off('draw.delete', props.onDelete);
    },
    {
      position: props.position
    }
  );

  return null;
}

const SourceLayer = (props) => {

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios.get(props.signedUrl)
    .then(res => {
      // console.log(res);
      setData(res.data)
    });
  }, []);

  const layerStyle = React.useMemo(() => {
    if(!data) return;
    if(data.features[0].geometry.type === 'MultiPolygon'){
      return {
        type: 'fill',
        paint: {
          'fill-color': 'red'
        }
      }
    }
    if(data.features[0].geometry.type === 'LineString'){
      return {
        type: 'line',
        paint: {
          'line-color': 'blue'
        }
      }
    }
  }, [data]);

  if(!data) return null;

  return <Source type="geojson" data={data}>
    <Layer 
      id={props.id} 
      {...layerStyle} />
  </Source>
}