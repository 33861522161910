import { Link, PrimaryButton, TextField, MessageBar, MessageBarType, Label } from '@fluentui/react';
import axios from 'axios';
import React, { useContext } from 'react';
import { Link as Anchor } from 'react-router-dom';
import AuthContext from './AuthContext';
import { labelStyle } from './Hydraviz';
import { MessageBarStyles } from './SignUp';

function SignIn(props){

  const [state, setState] = React.useState({email: '', password: ''});
  const [errorMessage, setErrorMessage] = React.useState("");

  const { updateUser } = useContext(AuthContext);

  const attemptSignIn = () => {
    axios.post('/api/users/signin', state)
    .then(response => {
      if(response.data.success){
        const { token } = response.headers;
        if(token) localStorage.setItem('token', token);
        updateUser(response.data.user);
        props.history.push('/dashboard');
      } else {
        setErrorMessage('Invalid credentials');
      };
    })
    .catch(err => {
      setErrorMessage('Unable to process request');
    })
  }

  return <div className='center'>
    <div className='signup-form'>
      <h1 className='logo'>Sign In</h1>
      {/* <h3>Sign In</h3> */}
      {errorMessage && <MessageBar styles={MessageBarStyles} messageBarType={MessageBarType.error} onDismiss={() => setErrorMessage('')}>{errorMessage}</MessageBar>}
      <Label styles={labelStyle}>Email Address</Label>
      <TextField 
        value={state.email}
        placeholder='Your email address'
        className='mb3'
        autoComplete="off" 
        onChange={(e, val) => setState(prev => ({...prev, email: val}))} />
      <Label styles={labelStyle}>Password</Label>
      <TextField
        value={state.password} 
        type='password'
        styles={{root: {marginBottom: 16}}}
        autoComplete="off" 
        onChange={(e, val) => setState(prev => ({...prev, password: val}))} />
      <PrimaryButton 
        text="Submit"
        disabled={!Object.keys(state).every(key => state[key])}
        style={{marginBottom: 32}}
        onClick={attemptSignIn} />
      <div className='fr sb'>
        <div className='mb2'>
          <Anchor to='/signup'>
            <Link style={{fontSize: 13}}>
              Not Registered? Sign Up
            </Link>
          </Anchor>
        </div>
        <Anchor to='/forgot-password'>
          <Link style={{fontSize: 13}}>
            Forgot Password
          </Link>
        </Anchor>
      </div>
    </div>
  </div>
}

export default SignIn