import { PrimaryButton, TextField, Link } from '@fluentui/react';
import React from 'react'

const ProjectSearch = props => {

  const [key, setKey] = React.useState('');

  const sumbit = () => {
    props.history.push(`${props.match.url}/${key}`);
  }

  return <div className='center'>
    <div className='signup-form'>
      <h1 className='home logo'>Enter Project Key</h1>
      <p className='ms-fontSize-18'></p>
      <div className='fr g16' style={{marginBottom: 32}}>
        <TextField autoComplete='off' styles={{root: {marginBottom: 16, flex: 1}}} type='text' name="key" value={key} onChange={(e, k) => setKey(k)} />
        <PrimaryButton className='mr3' text="Submit" onClick={sumbit} />
      </div>
      <Link onClick={() => props.history.push('/')}>Back</Link>
    </div>
  </div>
}

export default ProjectSearch;