const values = [
  7200,7200,7200,7200,7200,7200,7200,7200,7200,7200,7200,7200,7200,7200,7500,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,7800,9500,11200,12900,14600,16300,18000,18000,18000,16700,15400,14100,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,13400,14500,15600,16700,17800,18900,20000,21100,22200,22500,22500,21500,20500,19500,18500,17500,16500,15500,14500,13500,12500,9500,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,7600,7600,7600,7600,7600,7600,7600,7600,7600,7600,7600,7600,7600,7600,7600,7200,7200,7200];

  const stdTest = [7400,7400,7400,7400,7400,7400,7400,7400,7400,7400,7400,7400,7400,7400,7800,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,8200,9900,11600,13300,15000,16700,16700,16700,15400,14100,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,13000,14100,15200,16300,17400,18500,19600,20700,21800,22900,24000,25100,26200,27300,28400,29500,30600,30600,30600,29600,28600,27600,26600,25600,24600,23600,22600,21600,20600,17600,14600,11600,8600,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8000,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,8600,7900,7600,7300,6800,6800,6800,6800,6800,6700,6700,6700,6700,6700,6700,6700,6700,7500,7500,7500,7500,7500,7500];

// Function to add leading zeroes to date components if necessary
function pad(number) {
  if (number < 10) {
    return '0' + number;
  }
  return number;
}

// Function to generate date string in yyyy-mm-dd format
function formatDate(date) {
  return date.getFullYear() +
    '-' + pad(date.getMonth() + 1) +
    '-' + pad(date.getDate());
}

// Start date: March 1, 2024
const startDate = new Date('2024-03-01');
// End date: October 1, 2024
const endDate = new Date('2024-10-01');

// Array to store dates
const datesArray = [];

// Loop through dates and push formatted date strings into the array
for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
  datesArray.push(formatDate(date));
}

const testTaget = datesArray.map((date, index) => {
  return {date, value: values[index]};
});

const stdTestTaget = datesArray.map((date, index) => {
  return {date, value: stdTest[index]};
})

export {testTaget, stdTestTaget};