import { MessageBar, PrimaryButton, TextField, MessageBarType, SpinnerSize, Spinner } from '@fluentui/react';
import axios from 'axios';
import React from 'react';
import { MessageBarStyles } from './SignUp';

function ForgotPassword(props){
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const submit = () => {
    setLoading(true);
    axios.post(`/api/auth/forgot-password/${email}`)
    .then(res => {
      
      if(res.data.success){
        setMessage(`A reset link was sent to ${email}`);
        setEmail('');
      } else {
        setError(res.data.message);
        setEmail('');
      }
      setLoading(false);
    })
    .catch(err => {
      setError(err.message);
      setEmail('');
      setLoading(false);
    })
  }

  return <div className='center'>
    <div className='signup-form'>
      <h1 className='logo'>Forgot Password</h1>
      <p style={{fontSize: 18}}>Enter your email address to request a reset password link.</p>
      {
        error && <MessageBar
          styles={MessageBarStyles} 
          messageBarType={MessageBarType.error} 
          onDismiss={() => setError('')}>{error}
          </MessageBar>
      }
      {
        message && <MessageBar
          styles={MessageBarStyles} 
          messageBarType={MessageBarType.success} 
          onDismiss={() => setMessage('')}>{message}
          </MessageBar>
      }
      <TextField 
        autoComplete='off'
        label='Email' 
        value={email}
        styles={{root: {marginBottom: 16}}}
        onChange={(e, val) => setEmail(val)} />
      <PrimaryButton disabled={!email || loading} text={loading ? <Spinner /> : "Submit"} onClick={submit} />
    </div>
  </div>
}

export default ForgotPassword;