import { buildCal } from '../lib/cal';

export {
   countAbove,
   countBelow,
   countEqual,
   countBetween
} from './utils';

export const rearrangeThresholds = (thresholds, startCalDay) => {
   // console.log(thresholds, startCalDay);
   if(startCalDay === 1){
      return thresholds.map(threshold => {
         let { steps=buildCal(startCalDay), stepsLower=buildCal(startCalDay) } = threshold.data;
         return {
            ...threshold,
            data: {
               ...threshold.data,
               steps: steps.sort((a, b) => a.julian - b.julian),
               stepsLower: stepsLower.sort((a, b) => a.julian - b.julian)
            }
         }
      })
   } else {
      let holder = [];
      thresholds.forEach(threshold => {
         let { steps=buildCal(startCalDay), stepsLower=buildCal(startCalDay) } = threshold.data;
         if(threshold.data.isStepped){
            let firstHalf = steps.filter(el => el.julian >= startCalDay);
            let secondHalf = steps.filter(el => el.julian < startCalDay);
            let firstHalfLower = stepsLower.filter(el => el.julian >= startCalDay);
            let secondHalfLower = stepsLower.filter(el => el.julian < startCalDay);
            holder.push({...threshold, data: {...threshold.data, steps: [...firstHalf, ...secondHalf], stepsLower: [...firstHalfLower, ...secondHalfLower]} });
         } else {
            holder.push(threshold);
         }
      });
      // console.log(holder);
      return holder;
   }
}