import { PrimaryButton, Dialog, TextField, Dropdown, ContextualMenu, Label, DialogFooter, Pivot, PivotItem, getTheme, IconButton, DirectionalHint, DialogType, DefaultButton, Toggle, Slider, FontIcon, ActionButton, MessageBar } from '@fluentui/react';
import _, {  round } from 'lodash';
import React from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import DateRange from './DateRange';
import { LOOKUP_KEYS, Plot } from './Hydraviz';
import { ReactGrid } from "@silevis/reactgrid";
import "@silevis/reactgrid/styles.css";
import { ALL_DAYS } from './lib/cal';
import { stats as statistics } from './lib/stats';
import { evaluate } from 'mathjs';
import {v4 as uuid} from 'uuid';
import axios from 'axios';
import MultiAlt from './MultiAlt';

const resolveYWeight = (value, yLookup=[], defaultWeight=0) => {

  if(isNaN(value)){
    return 0;
  }

  let weight;

  yLookup.forEach(rule => {
    if(rule.type === 'range'){
      let upper = parseFloat(rule.upperRange);
      let lower = parseFloat(rule.lowerRange);
      if(value <= upper && value >= lower){
        weight = parseFloat(rule.weight);
      }
    }
    if(rule.type === 'greater'){
      let ruleValue = parseFloat(rule.value);
      if(value > ruleValue){
        weight = parseFloat(rule.weight);
      }
    }
    if(rule.type === 'less'){
      let ruleValue = parseFloat(rule.value);
      if(value < ruleValue){
        weight = parseFloat(rule.weight);
      }
    }
    if(rule.type === 'greater-equal'){
      let ruleValue = parseFloat(rule.value);
      if(value >= ruleValue){
        weight = parseFloat(rule.weight);
      }
    }
    if(rule.type === 'less-equal'){
      let ruleValue = parseFloat(rule.value);
      if(value <= ruleValue){
        weight = parseFloat(rule.weight);
      }
    }
    if(rule.type === 'equal'){
      let ruleValue = parseFloat(rule.value);
      if(value === ruleValue){
        weight = parseFloat(rule.weight);
      }
    }

    if(isNaN(weight)){
      weight = parseFloat(defaultWeight);
    };
    
  });

  return weight;

};


function checkValid(rule){
  switch(rule.type){
    case 'range':
      if(isNaN(parseFloat(rule.upperRange)) || isNaN(parseFloat(rule.upperRange))){
        return {isValid: false, message: "Upper range and lower range are not valid numbers"};
      }
      if(parseFloat(rule.upperRange) < parseFloat(rule.lowerRange)){
        return {isValid: false, message: "Lower range should be less than upper range"};
      }
      if(isNaN(parseFloat(rule.weight))) return {isValid: false, message: "Missing weight"};
      return {isValid: true, message: "Rule is valid"};
    case 'equal':
      if(isNaN(parseFloat(rule.value))){
        return {isValid: false, message: "Missing value"};
      }
      if(isNaN(parseFloat(rule.weight))) return {isValid: false, message: "Missing weight"};
      return {isValid: true, message: "Rule is valid"};
    case 'less':
      if(isNaN(parseFloat(rule.value))){
        return {isValid: false, message: "Missing value"};
      }
      if(isNaN(parseFloat(rule.weight))) return {isValid: false, message: "Missing weight"};
      return {isValid: true, message: "Rule is valid"};
    case 'greater':
      if(isNaN(parseFloat(rule.value))){
        return {isValid: false, message: "Missing value"};
      }
      if(isNaN(parseFloat(rule.weight))) return {isValid: false, message: "Missing weight"};
      return {isValid: true, message: "Rule is valid"};
    case 'less-equal':
      if(isNaN(parseFloat(rule.value))){
        return {isValid: false, message: "Missing value"};
      }
      if(isNaN(parseFloat(rule.weight))) return {isValid: false, message: "Missing weight"};
      return {isValid: true, message: "Rule is valid"};
    case 'greater-equal':
      if(isNaN(parseFloat(rule.value))){
        return {isValid: false, message: "Missing value"};
      }
      if(isNaN(parseFloat(rule.weight))) return {isValid: false, message: "Missing weight"};
      return {isValid: true, message: "Rule is valid"};
    default:
      return {isValid: true, message: "Rule is valid"};
  }
}

function arraymove(arr, fromIndex, toIndex) {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

const resolveDateText = (dateNum, dateMethod) => {
  return ALL_DAYS.find(d => d.julian === parseFloat(dateNum)).date;
}

const emptyPm = {
  name: '',
  description: '',
  startDay: 1,
  location: '',
  endDay: 365,
  daysInRow: null,
  excludeYears: [],
  startYear: null,
  endYear: null,
  direction: 'greater than',
  pmType: 'Frequency of Event',
  defaultScore: 0,
  defaultWeight: 0,
  dateWeights: [],
  dataWeights: [],
  ranges: [],
  value: null,
  lowerRange: undefined,
  upperRange: undefined,
  interval: '1000',
  yLookup: [],
  xLookup: {}
}

export default function PerformanceMeasures(props){
  const { YEARS=[] } = props;
  const [editState, setEditState] = React.useState(null);
  const [updateCount, setUpdateCount] = React.useState(0);

  const [performanceMeasures, setPerformanceMeasures] = React.useState([]);

  const onUpdatePm = (id, changes, password) => {
    if(changes.temporary){
      return setPerformanceMeasures(prev => {
        return prev.map(pm => {
          if(pm.id === id){
            return {
              ...pm,
              data: {...pm.data, ...changes}
            }
          }
          return pm
        })
      })
    }
    axios.put(`/api/pm/${id}`, {data: changes, password})
    .then(res => {
      setPerformanceMeasures(prev => {
        return prev.map(pm => {
          if(pm.id === id){
            return {
              ...pm,
              data: {...pm.data, ...changes}
            }
          }
          return pm
        })
      })
      setUpdateCount(prev => prev + 1);
    })
    .catch(err => {
      // console.log(err);
    })
  }

  const onDismiss = () => {
    setEditState(null);
  };


  React.useEffect(() => {
    axios.get(`/api/pms/${props.apiEndpoint}`)
    .then(res => {
      if(res.data.success){
        setPerformanceMeasures(res.data.pms)
      }
    })
    .catch(err => {
      // console.log(err);
    })
  }, []);

  const addPm = (data, password) => {
    let newPmData = {...data};
    axios.post(`/api/pms/${props.apiEndpoint}`, {data: newPmData, password})
    .then(res => {
      if(res.data.success){
        // this means it was saved to DB
        setPerformanceMeasures(prev => prev.concat(...res.data.pm))
      } else {
        // this adds a temp pm
        setPerformanceMeasures(prev => prev.concat({id: Date.now(), data: newPmData}))
      }
    })
    .catch(err => {
      // console.log('err: ', err);
    })
  };

  const onDelete = (id, password, isTemp, cb) => {
    if(isTemp){
      setPerformanceMeasures(prev => prev.filter(el => `${el.id}` !== `${id}`));
    } else {
      axios.delete(`/api/pms/${id}/${password}`)
      .then(res => {
        if(res.data.success){
          setPerformanceMeasures(prev => prev.filter(el => `${el.id}` !== `${id}`));
          cb();
        }
      })
      .catch(err => {
        // console.log(err);
      })
    }
  }

  return <>
    <Switch>
      <Route path={props.match.path} exact>
        <div className='pm-container'>
          <h1 className='page-header'>
            Performance Measures
          </h1>
          <div className='grid g16 mb4 col3'>
            {
              performanceMeasures?.map(pm => {
                const { data } = pm;
                return <Link key={pm.id} to={`${props.match.url}/view/${pm.id}`} style={{padding: '16px 24px', display: 'block'}} className='hover-label'>
                  <div>
                    <h2 className='ms-fontWeight-semibold'>{data.name}</h2>
                  </div>
                  <div>
                    <ActionButton text="Edit" iconProps={{iconName: "Edit"}} onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setEditState(pm);
                      props.history.push(`${props.match.url}/edit/${pm.id}`);
                      }} />
                  </div>
                </Link>
              })
            }
            <div onClick={() => props.history.push(`${props.match.url}/create`)} style={{padding: '16px 24px', display: 'block'}} className='hover-label add-project'>
              <h3 className='ms-fontWeight-semibold'>Create New Performance Measure</h3>
            </div>
          </div>
        </div>
      </Route>
      <Route path={`${props.match.path}/edit/:pmId`}>
        <PmBuilder 
          onDismiss={() => props.history.push(props.match.url)} 
          master={props.master} 
          withState={editState}
          setPerformanceMeasures={setPerformanceMeasures}
          onUpdate={onUpdatePm}
          onDelete={onDelete} 
          ALL_DAYS={props.ALL_DAYS} 
          locations={props.locations} 
          addPm={addPm} />
      </Route>
      <Route path={`${props.match.path}/create`}>
        <PmBuilder 
          onDismiss={() => props.history.push(props.match.url)} 
          master={props.master} 
          // withState={editState}
          setPerformanceMeasures={setPerformanceMeasures}
          onUpdate={onUpdatePm}
          onDelete={onDelete} 
          ALL_DAYS={props.ALL_DAYS} 
          locations={props.locations} 
          addPm={addPm} />
      </Route>
      <Route path={`${props.match.path}/view/:pmId`} render={(p) => {

        const found = performanceMeasures.find(el => `${el.id}` === p.match.params.pmId);
        return <div className='pm-container'>
          {
            editState ? (
              <PmBuilder 
                onDismiss={onDismiss} 
                master={props.master} 
                withState={editState} 
                onUpdate={onUpdatePm}
                onDelete={onDelete}
                ALL_DAYS={props.ALL_DAYS} 
                locations={props.locations} 
                addPm={addPm} />
            ) : (
              <PmBreakdown 
                setEditState={setEditState}
                found={found}
                locations={props.locations}
                ALL_DAYS={props.ALL_DAYS}
                YEARS={YEARS}
                path={props.match.path} 
                {...p} 
                performanceMeasures={performanceMeasures} 
                master={props.master} />
            )
          }
        </div>
      }}>
        
      </Route>
    </Switch>
  </>
}

function PmBreakdown(props){

  const { YEARS=[] } = props;
  const [yearDirection, setYearDirection] = React.useState('descending');
  const [foundPm, setFoundPm] = React.useState(props.found);
  const [showYearChart, setShowYearChart] = React.useState(null);

  React.useEffect(() => {
    setFoundPm(props.found);
  }, [props.found]);

  const data = React.useMemo(() => {
    let yearsSorted = yearDirection === 'ascending' ? YEARS : [].concat(YEARS).reverse();
    if(foundPm){

      let holder = [];
      
      props.master.forEach(alternative => {
        
        let found = alternative.data.find(el => el.location === foundPm.data.location);
        if(!found) return;
        const { allData, annualData, measure, stats } = found;
        const groupedByYear = _.groupBy(allData.filter(el => el.day >= foundPm.data.startDay && el.day <= foundPm.data.endDay), 'year');

        // calc algs for frequency events
        if(foundPm.data.pmType === 'Frequency of Event'){
          
          let {startYear, endYear, selectYears, direction, daysInRow, value } = foundPm.data;

          let groupedByYearWindows = {};


          if(direction === 'subceed' || direction === 'surpass'){

            let temp = {
              ...alternative,
              allData,
              annualData,
              measure,
              stats,
              location: foundPm.data.location,
              // groupedByYearWindows,
              statistics: {},
              calculations: {}
            };

            let allScores = [];

            Object.keys(groupedByYear).forEach(year => {
              let allChunks = [];
              let currentChunk = [];
              groupedByYear[year].forEach(day => {
                // let { value } = day;
                if(isNaN(day.value)) return;
                if(direction === 'surpass'){

                  if(day.value > parseFloat(value)){
                    currentChunk.push(day);
                  } else {
                    // if the chunk is long enough to be considered a chunk...
                    if(currentChunk.length && currentChunk.length >= parseFloat(daysInRow)) allChunks.push(currentChunk);
                    currentChunk = [];
                  }
                } else {
                  if(day.value < parseFloat(value)){
                    currentChunk.push(day);
                  } else {
                    // if the chunk is long enough to be considered a chunk...
                    if(currentChunk.length && currentChunk.length >= parseFloat(daysInRow)) allChunks.push(currentChunk);
                    currentChunk = [];
                  }
                }
              });
              temp.calculations[year] = {sum: allChunks.length};
              allScores.push(allChunks.length);
            });

            // all scores should be the total score of each year (40 year record set should have 40 values in array);
            

            temp.statistics = {
              min: allScores.length ? statistics.min(allScores) : '-',
              max: allScores.length ? statistics.max(allScores) : '-',
              mean: allScores.length ? statistics.mean(allScores) : '-',
              range: allScores.length ? statistics.range(allScores) : '-',
              sum: allScores.length ? statistics.sum(allScores) : '-',
              median: allScores.length ? statistics.median(allScores) : '-',
              midRange: allScores.length ? statistics.midrange(allScores) : '-',
              modes: allScores.length ? statistics.modes(allScores) : '-',
              variance: allScores.length ? statistics.variance(allScores) : '-',
              standardDeviation: allScores.length ? statistics.standardDeviation(allScores) : '-',
              meanAbsoluteDeviation: allScores.length ? statistics.standardDeviation(allScores) : '-',
              zScores: allScores.length ? statistics.zScores(allScores) : '-',
              q10: allScores.length ? statistics.quantile(allScores, 0.10) : '-',
              q90: allScores.length ? statistics.quantile(allScores, 0.90) : '-',
              q25: allScores.length ? statistics.quantile(allScores, 0.25) : '-',
              q75: allScores.length ? statistics.quantile(allScores, 0.75) : '-'
            }


            holder.push(temp);

            

          } else {
            Object.keys(groupedByYear).forEach(year => {
              if(selectYears && startYear && endYear){
                if(year < startYear || year > endYear) return;
              }
              let windows = [];
              for(let i = 0; i + parseFloat(foundPm.data.daysInRow) < groupedByYear[year].length; i++){
                let chunk = groupedByYear[year].slice(i, i + parseFloat(foundPm.data.daysInRow));
                windows.push(chunk);
              }
              groupedByYearWindows[year] = windows;
            });
  
            let temp = {
              ...alternative,
              allData,
              annualData,
              measure,
              stats,
              location: foundPm.data.location,
              groupedByYearWindows,
              calculations: {}
            };
    
  
            Object.keys(temp.groupedByYearWindows).forEach(year => {
              if(selectYears && startYear && endYear){
                if(year < startYear || year > endYear) return;
              }
              let empty = groupedByYear[year].every(({value}) => isNaN(parseFloat(value)));
              let sum;
              if(foundPm.data.direction === 'greater than'){
                sum = 0;
                temp.groupedByYearWindows[year].forEach((win) => {
                  if(win?.every(({value}) => parseFloat(value) >= parseFloat(foundPm.data.value))){
                    return sum += 1;
                  }
                });
              } else if(foundPm.data.direction === 'less than'){
                sum = 0;
                temp.groupedByYearWindows[year].forEach((win) => {
                  if(win?.every(({value}) => parseFloat(value) <= parseFloat(foundPm.data.value))){
                    return sum += 1;
                  }
                });
              } else {
                sum = 0;
                temp.groupedByYearWindows[year].forEach((win) => {
                  if(win?.every(({value}) => parseFloat(value) >= parseFloat(foundPm.data.lowerRange) && parseFloat(value) <= parseFloat(foundPm.data.upperRange))){
                    return sum += 1;
                  }
                });
              }
              let percentage = round(((sum / temp.groupedByYearWindows[year].length) * 100),1);
              temp.calculations[year] = {sum, percentage, empty};
            })
  
  
            let allScores = [];
  
            Object.keys(temp.calculations).forEach(year => {
              if(temp.calculations[year].empty) return;
              allScores.push(temp.calculations[year].sum);
            });
  
            temp.statistics = {
              min: allScores.length ? statistics.min(allScores) : '-',
              max: allScores.length ? statistics.max(allScores) : '-',
              mean: allScores.length ? statistics.mean(allScores) : '-',
              range: allScores.length ? statistics.range(allScores) : '-',
              sum: allScores.length ? statistics.sum(allScores) : '-',
              median: allScores.length ? statistics.median(allScores) : '-',
              midRange: allScores.length ? statistics.midrange(allScores) : '-',
              modes: allScores.length ? statistics.modes(allScores) : '-',
              variance: allScores.length ? statistics.variance(allScores) : '-',
              standardDeviation: allScores.length ? statistics.standardDeviation(allScores) : '-',
              meanAbsoluteDeviation: allScores.length ? statistics.standardDeviation(allScores) : '-',
              zScores: allScores.length ? statistics.zScores(allScores) : '-',
              q10: allScores.length ? statistics.quantile(allScores, 0.10) : '-',
              q90: allScores.length ? statistics.quantile(allScores, 0.90) : '-',
              q25: allScores.length ? statistics.quantile(allScores, 0.25) : '-',
              q75: allScores.length ? statistics.quantile(allScores, 0.75) : '-'
            }
  
            holder.push(temp);
          }
          
        }

        if(foundPm.data.pmType === 'Weighted Score - Rules'){

          let { ranges, startYear, endYear, selectYears, location } = foundPm.data;

          let temp = {
            ...alternative,
            allData,
            annualData,
            measure,
            stats,
            location: location,
            calculations: {}
          };

          let rangeTemp = [].concat(ranges).map(el => ({...el, annualScores: {}, annualSum: {}}));

          // let allScores = [];

          Object.keys(groupedByYear).forEach(year => {
            if(selectYears && startYear && endYear){
              if(year < startYear || year > endYear) return;
            }

            let allScores = [];

            // for each rule block we have...
            rangeTemp.forEach(range => {
            
              let { defaultScore=0, score, lowerRange, upperRange, type, startDay=1, endDay=365 } = range;


              // if the score is non a number, it's an equation
              // if its a number, we just add it
              if(isNaN(parseFloat(score))){
                score = score.toLowerCase().trim();
              } else {
                score = parseFloat(score);
              }

              let annualScores = [];
              // for each day of the year...
              groupedByYear[year].forEach((day, i) => {

                let isAfterStart = day.day >= startDay;
                let isBeforeEnd = day.day <= endDay;
                let isInRange = isAfterStart && isBeforeEnd;

                let value = parseFloat(day.value);
                
                if(isNaN(value) || !isInRange) return;
                // check if the value is between the upper and lower range...
                // if it is, add the score
                if(type === 'range'){
                  if(value > parseFloat(lowerRange) && value < parseFloat(upperRange)){
                    if(isNaN(score)){
                      score = score.toLowerCase();
                      let eq = evaluate(score.replace('x', value));
                      annualScores.push(eq);
                    } else {
                      annualScores.push(score);
                    }
                    return;
                  }
                }

                if(type === 'greater'){
                  
                  if(value > parseFloat(upperRange)){
                    if(isNaN(score)){
                      score = score.toLowerCase();
                      let eq = evaluate(score.replace('x', value));
                      annualScores.push(eq);
                    } else {
                      annualScores.push(score);
                    }
                    return;
                  }
                }

                if(type === 'less'){
                  if(value < parseFloat(upperRange)){
                    if(isNaN(score)){
                      score = score.toLowerCase();
                      let eq = evaluate(score.replace('x', value));
                      annualScores.push(eq);
                    } else {
                      annualScores.push(score);
                    }
                    return;
                  }
                }

                if(type === 'greater-equal'){
                  if(value >= parseFloat(upperRange)){
                    if(isNaN(score)){
                      score = score.toLowerCase();
                      let eq = evaluate(score.replace('x', value));
                      annualScores.push(eq);
                    } else {
                      annualScores.push(score);
                    }
                    return;
                  }
                }

                if(type === 'less-equal'){
                  if(value <= parseFloat(upperRange)){
                    if(isNaN(score)){
                      score = score.toLowerCase();
                      let eq = evaluate(score.replace('x', value));
                      annualScores.push(eq);
                    } else {
                      annualScores.push(score);
                    }
                    return;
                  }
                }

                if(type === 'equal'){
                  if(value === parseFloat(upperRange)){
                    if(isNaN(score)){
                      score = score.toLowerCase();
                      let eq = evaluate(score.replace('x', value));
                      annualScores.push(eq);
                    } else {
                      annualScores.push(score);
                    }
                    return;
                  }
                }

                annualScores.push(parseFloat(defaultScore));

              });

              annualScores = annualScores.filter(d => !isNaN(d));

              let sum = annualScores.reduce((a, b) => a + b, 0);
              allScores.push(sum);
              range.annualScores[year] = annualScores;
              range.annualSum[year] = sum;
            })


            let allSum = allScores.reduce((a, b) => a + b, 0);

            temp.calculations[year] = allSum;
          });

          let annuals = [];

          Object.keys(temp.calculations).forEach(y => {
            annuals.push(temp.calculations[y]);
          });

          temp.rangeTemp = rangeTemp;

          temp.statistics = {
            min: annuals.length ? statistics.min(annuals) : '-',
            max: annuals.length ? statistics.max(annuals) : '-',
            mean: annuals.length ? statistics.mean(annuals) : '-',
            range: annuals.length ? statistics.range(annuals) : '-',
            sum: annuals.length ? statistics.sum(annuals) : '-',
            median: annuals.length ? statistics.median(annuals) : '-',
            midRange: annuals.length ? statistics.midrange(annuals) : '-',
            modes: annuals.length ? statistics.modes(annuals) : '-',
            variance: annuals.length ? statistics.variance(annuals) : '-',
            standardDeviation: annuals.length ? statistics.standardDeviation(annuals) : '-',
            meanAbsoluteDeviation: annuals.length ? statistics.standardDeviation(annuals) : '-',
            zScores: annuals.length ? statistics.zScores(annuals) : '-',
            q10: annuals.length ? statistics.quantile(annuals, 0.10) : '-',
            q90: annuals.length ? statistics.quantile(annuals, 0.90) : '-',
            q25: annuals.length ? statistics.quantile(annuals, 0.25) : '-',
            q75: annuals.length ? statistics.quantile(annuals, 0.75) : '-'
          }

          holder.push(temp);
          // });
        }

        if(foundPm.data.pmType === 'Weighted Score - Matrix'){
          let { xLookup, yLookup, startYear, endYear, selectYears,location, defaultWeight } = foundPm.data;
          let temp = {
            ...alternative,
            allData,
            annualData,
            measure,
            stats,
            location: location,
            calculations: {}
          };

          yearsSorted.forEach(year => {
            if(selectYears && startYear && endYear){
              if(year < startYear || year > endYear) return;
            }
            let empty = !annualData[year] || annualData[year].every(({value}) => isNaN(parseFloat(value)));

            let thisYear = annualData[year] ? annualData[year].map(dailyData => {
              if(dailyData.value === null) return 0;
              let xWeight = xLookup[dailyData.dayLookup];
              let yWeight = resolveYWeight(dailyData.value, yLookup, defaultWeight);
              return xWeight * yWeight;
            }) : null;
            temp.calculations[year] = {sum: thisYear && thisYear.reduce((a, b) => a + b).toFixed(1), empty};
          });

          let allScores = [];

          Object.keys(temp.calculations).forEach(year => {
            if(temp.calculations[year].empty) return;
            allScores.push(parseFloat(temp.calculations[year].sum));
          });

          temp.statistics = {
            min: allScores.length ? statistics.min(allScores) : '-',
            max: allScores.length ? statistics.max(allScores) : '-',
            mean: allScores.length ? statistics.mean(allScores) : '-',
            range: allScores.length ? statistics.range(allScores) : '-',
            sum: allScores.length ? statistics.sum(allScores) : '-',
            median: allScores.length ? statistics.median(allScores) : '-',
            midRange: allScores.length ? statistics.midrange(allScores) : '-',
            modes: allScores.length ? statistics.modes(allScores) : '-',
            variance: allScores.length ? statistics.variance(allScores) : '-',
            standardDeviation: allScores.length ? statistics.standardDeviation(allScores) : '-',
            meanAbsoluteDeviation: allScores.length ? statistics.standardDeviation(allScores) : '-',
            zScores: allScores.length ? statistics.zScores(allScores) : '-',
            q10: allScores.length ? statistics.quantile(allScores, 0.10) : '-',
            q90: allScores.length ? statistics.quantile(allScores, 0.90) : '-',
            q25: allScores.length ? statistics.quantile(allScores, 0.25) : '-',
            q75: allScores.length ? statistics.quantile(allScores, 0.75) : '-'
          }

          holder.push(temp);
        }
      });

      return holder;
    }
    return []
  }, [foundPm, props.master, YEARS, yearDirection]);

  const yearsSorted = React.useMemo(() => {
    if(!foundPm) return;
    let arr = yearDirection === 'ascending' ? YEARS : [].concat(YEARS).reverse();
    if(foundPm.data.selectYears && foundPm.data.startYear && foundPm.data.endYear){
      arr = arr.filter(el => el >= foundPm.data.startYear && el <= foundPm.data.endYear);
    }
    return arr;
  }, [YEARS, yearDirection, foundPm]);

  if(data.length === 0) return <div>
    <p className='pm-name ms-fontWeight-semibold'>
      <Link to={`/project/${props.match.params.project_key}/performance-measures`}>
        <IconButton iconProps={{iconName: "Back"}} />
      </Link>
      Nothing found
    </p>
  </div>

  return <div>
    <div>
      <p className='pm-name ms-fontWeight-semibold'>
        <Link to={`/project/${props.match.params.project_key}/performance-measures`}>
          <IconButton iconProps={{iconName: "Back"}} />
        </Link>
        {foundPm?.data.name}
        <IconButton iconProps={{iconName: 'Edit'}} onClick={() => props.setEditState(foundPm)} />
      </p>
      {
        foundPm && (
          <div style={{paddingLeft: 32}}>
            <p className='pm-meta ms-fontWeight-semibold'>Location: {foundPm.data.location}</p>
            <p className='pm-meta ms-fontWeight-semibold'>Type: {foundPm.data.pmType}</p>
          </div>
        )
      }
      
    </div>
    <Pivot  styles={{itemContainer: {background: '#fff'}}}>
      
      {
        foundPm.data.pmType === 'Frequency of Event' && [
          <PivotItem key='table' headerText='By Year' className='ms-depth-4' style={{overflow: 'auto', maxHeight: 600}}>
            <table style={{borderCollapse: 'collapse', minWidth: '100%'}}>
              <thead>
                <tr>
                  <th style={{position: 'sticky', left: 0, zIndex: 9, top: 0, background: getTheme().palette.white, padding: '2px 8px 4px 8px', width: 'max-content'}}>
                    <div className='fr aic'>
                      Year
                      <div>
                        <IconButton onClick={() => setYearDirection('ascending')} styles={{root: {height: 'auto', padding: 1}}} iconProps={{iconName: 'ChevronUp'}} />
                        <IconButton onClick={() => setYearDirection('descending')} styles={{root: {height: 'auto', padding: 1}}} iconProps={{iconName: 'ChevronDown'}} />
                      </div>
                    </div>
                    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                  </th>
                  {
                    data.map((alt, i) => {
                      return <th style={{position: 'sticky', top: 0, background: '#fff', padding: '2px 8px 4px 8px', zIndex: 8}} key={i}>
                        <div className='rotated-th'>
                          <span className='rotated-th_label' style={{zIndex: 9}}>{alt.alternative}</span>
                        </div>
                        
                        <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: alt.color}}></div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  yearsSorted.map(year => {
                    let allVals = data.map(alt => alt.calculations[year]);
                    if(allVals.every(val => val === undefined || (val && val.empty))) return null;
                    return <>
                      <tr key={year} style={{background: year === showYearChart ? getTheme().palette.themeLight : ''}}>
                        <td style={{position: 'sticky', left: 0, background: '#fff', zIndex: 2, padding: '2px 10px 4px 8px', width: 120}}>
                          {year}
                          <IconButton onClick={() => setShowYearChart(prev => prev === year ? null : year)} iconProps={{iconName: "LineChart"}} styles={{root: {height: 'auto'}}} />
                          <div style={{position: 'absolute', bottom: 0, top: 0, right: 0, width: 2, background: `${getTheme().palette.themeLight}`}}></div>
                        </td>
                        {
                          data.map(alt => {
                            let vals = alt.calculations[year];
                            if(!vals || vals.empty) return <td className='tac' key={`${alt.alternative}-${year}`}>-</td>;
                            let { sum } = vals;
                            return <td className='tac' key={alt.alternative}>{
                              sum
                            }</td>
                          })
                        }
                      </tr>
                      {
                        year === showYearChart && <tr key={`chart-${year}`}>
                          <td>
                          </td>
                          <td height={500} colSpan={props.master.length}>
                            <MultiAlt 
                              readOnly={true}
                              lookupKey="pickYear" 
                              ALL_DAYS={props.ALL_DAYS}
                              startDay={foundPm.data.startDay}
                              endDay={foundPm.data.endDay}
                              thresholds={[{id: 'fake', data: {isRange: foundPm.data.direction === 'between', isStepped: false, upperRange: foundPm.data.upperRange, lowerRange: foundPm.data.lowerRange, isWindow: false, name: 'PM', visible: true, color: 'black', fill: false, pattern: 'dash', stepsLower: [], steps: [], value: foundPm.data.value}}]}
                              pickYear={year}
                              location={foundPm.data.location}
                              locations={props.locations}
                              globalLayoutSettings={{fontSize: 'inherit'}}
                              selectedAlts={props.master.map(alt => alt.key)} 
                              master={props.master} />
                          </td>
                        </tr>
                      }
                    </>
                  })
                }
              </tbody>
            </table>
          </PivotItem>,
          <PivotItem key='stats' className='ms-depth-4' headerText='Statistics' style={{overflow: 'auto', maxHeight: 600}}>
            <table style={{borderCollapse: 'collapse', minWidth: '100%'}}>
              <thead>
                <tr>
                  <th style={{textAlign: 'left', position: 'sticky', left: 0, zIndex: 3, top: 0, background: getTheme().palette.white, padding: '2px 8px 4px 8px'}}>
                    Statistic
                    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                  </th>
                  {
                    data.map((alt, i) => {
                      return <th style={{background: getTheme().palette.white, position: 'sticky', top: 0, padding: '2px 8px 4px 8px'}} key={i}>
                        {alt.alternative}
                        <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  LOOKUP_KEYS.concat({key: 'sum', text: 'Sum'}).map(({key, text}) => {
                    return <tr key={key}>
                      <td style={{position: 'sticky', left: 0, background: '#fff', zIndex: 2, padding: '2px 10px 4px 8px'}}>
                        {text}
                        <div style={{position: 'absolute', bottom: 0, top: 0, right: 0, width: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </td>
                      {
                        data.map((alt, i) => {
                          let num = parseFloat(alt.statistics[key]);
                          if(isNaN(num)){
                            return <td className='tac' key={i}>-</td>
                          }
                          return <td className='tac' key={i}>{round(alt.statistics[key], 2)}</td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </PivotItem>,
          <PivotItem key='box' className='ms-depth-4' headerText="Box Plot">
            <BoxPlot data={data} xAxisTitle="Flow Scenario" yAxisTitle="Frequency of Event" />
          </PivotItem>
        ]
      }

      {
        foundPm.data.pmType === 'Weighted Score - Rules' && [
          <PivotItem key='table' className='ms-depth-4' headerText="Table" style={{overflow: 'auto', maxHeight: 600}}>
            <table style={{borderCollapse: 'collapse', minWidth: '100%'}}>
              <thead style={{position: 'sticky', top: 0, background: '#fff', zIndex: 3}}>
                <tr>
                  <th></th>
                  {
                    data.map((alt, i) => {
                      return <th key={i} colSpan={foundPm.data.ranges.length + 1}>{alt.alternative}</th>
                    })
                  }
                  <th></th>
                </tr>
                <tr>
                  <th>
                    <div className='fr aic'>
                      Year
                      <div>
                        <IconButton onClick={() => setYearDirection('ascending')} styles={{root: {height: 'auto', padding: 1}}} iconProps={{iconName: 'ChevronUp'}} />
                        <IconButton onClick={() => setYearDirection('descending')} styles={{root: {height: 'auto', padding: 1}}} iconProps={{iconName: 'ChevronDown'}} />
                      </div>
                    </div>
                    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                  </th>
                  {
                    data.map(alt => {
                      return <>
                        {  
                          foundPm.data.ranges.map(range => {
                            return <th key={range.key}>
                              {range.name}
                              <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                            </th>
                          })
                        }
                        <th key={`${alt.alternative}-total`}>Total</th>
                      </>
                    })
                  }
                </tr>
              </thead>
              <tbody>
              {
                  yearsSorted.map(year => {
                    return <tr key={year}>
                      <td style={{position: 'sticky', left: 0, background: '#fff', zIndex: 2, padding: '2px 10px 4px 8px'}}>
                        {year}
                        <div style={{position: 'absolute', bottom: 0, top: 0, right: 0, width: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </td>
                      {
                        data.map(alt => {
                            return <>
                              {
                                alt.rangeTemp.map(range => {
                                  return <td key={range.key} className='tac'>{round(range.annualSum?.[year], 2)}</td>
                                })
            
                              }
                              <td className='tac' key={alt.alternative}>{round(alt.calculations?.[year], 2)}</td>
                            </>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </PivotItem>,
          <PivotItem key='sats' className='ms-depth-4' headerText="Statistics" style={{overflow: 'auto', maxHeight: 600}}>
            <table style={{borderCollapse: 'collapse', minWidth: '100%'}}>
              <thead>
                <tr>
                  <th style={{textAlign: 'left', position: 'sticky', left: 0, zIndex: 3, top: 0, background: getTheme().palette.white, padding: '2px 8px 4px 8px'}}>
                    Statistic
                    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                  </th>
                  {
                    data.map(alt => {
                      return <th style={{background: getTheme().palette.white, position: 'sticky', top: 0, padding: '2px 8px 4px 8px'}} key={alt.alternative}>
                        {alt.alternative}
                        <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  LOOKUP_KEYS.concat({key: 'sum', text: 'Sum'}).map(({key, text}) => {
                    return <tr key={key}>
                      <td style={{position: 'sticky', left: 0, background: '#fff', zIndex: 2, padding: '2px 10px 4px 8px'}}>
                        {text}
                        <div style={{position: 'absolute', bottom: 0, top: 0, right: 0, width: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </td>
                      {
                        data.map(alt => {
                          let num = parseFloat(alt.statistics[key]);
                          if(isNaN(num)){
                            return <td className='tac' key={alt.alternative}>-</td>
                          }
                          return <td className='tac' key={alt.alternative}>{round(alt.statistics[key], 2)}</td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </PivotItem>,
          <PivotItem key='chart' className='ms-depth-4' headerText="Charts">
            <BoxPlot data={data} processData={(data=[]) => {
              let holder = [];
              data.forEach(alt => {
                let y = [];
                Object.keys(alt.calculations).forEach(year => {
                  if(!alt.calculations[year]) return;
                  y.push(round(alt.calculations[year], 2));
                });
                holder.push({
                  name: alt.alternative,
                  marker: {
                    color: alt.color,
                  },
                  y,
                  type: 'box',
                  boxpoints: 'all'
                })
              });
              return holder;
            }} yAxisTitle="Performance" xAxisTitle="Flow Alternative" />
          </PivotItem>
        ]
      }

      {
        foundPm.data.pmType === 'Weighted Score - Matrix' && [
          <PivotItem key='table' headerText="Table" className='ms-depth-4' style={{overflow: 'auto', maxHeight: 600}}>
            <table style={{borderCollapse: 'collapse', minWidth: '100%'}}>
              <thead>
                <tr>
                  <th style={{position: 'sticky', left: 0, zIndex: 3, top: 0, background: getTheme().palette.white, padding: '2px 8px 4px 8px'}}>
                    <div className='fr aic'>
                      Year
                      <div>
                        <IconButton onClick={() => setYearDirection('ascending')} styles={{root: {height: 'auto', padding: 1}}} iconProps={{iconName: 'ChevronUp'}} />
                        <IconButton onClick={() => setYearDirection('descending')} styles={{root: {height: 'auto', padding: 1}}} iconProps={{iconName: 'ChevronDown'}} />
                      </div>
                    </div>
                    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                  </th>
                  {
                    data.map(alt => {
                      return <th style={{background: getTheme().palette.white, position: 'sticky', top: 0, padding: '2px 8px 4px 8px'}} key={alt.alternative}>
                        {alt.alternative}
                        <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  yearsSorted.map(year => {
                    let allVals = data.map(alt => alt.calculations[year]);
                    if(allVals.every(el => el.empty)) return null;
                    return <tr key={year}>
                      <td style={{position: 'sticky', left: 0, background: '#fff', zIndex: 2, padding: '2px 10px 4px 8px'}}>
                        {year}
                        <div style={{position: 'absolute', bottom: 0, top: 0, right: 0, width: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </td>
                      {
                        data.map(alt => {
                          let vals = alt.calculations[year];
                          if(!vals) return <td className='tac' key={`${alt.alternative}-${year}`}>-</td>;
                          let { sum, empty } = vals;
                          if(empty) return <td className='tac' key={alt.alternative}>-</td>
                          return <td className='tac' key={alt.alternative}>{
                            sum
                          }</td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </PivotItem>,
          <PivotItem key='sats' headerText="Statistics" className='ms-depth-4' style={{overflow: 'auto', maxHeight: 600}}>
            <table style={{borderCollapse: 'collapse', minWidth: '100%'}}>
              <thead>
                <tr>
                  <th style={{position: 'sticky', left: 0, zIndex: 3, top: 0, background: getTheme().palette.white, padding: '2px 8px 4px 8px', textAlign: 'left'}}>
                    Statistic
                    <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                  </th>
                  {
                    data.map(alt => {
                      return <th style={{background: getTheme().palette.white, position: 'sticky', top: 0, padding: '2px 8px 4px 8px'}} key={alt.alternative}>
                        {alt.alternative}
                        <div style={{position: 'absolute', bottom: 0, left: 0, right: 0, height: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </th>
                    })
                  }
                </tr>
              </thead>
              <tbody>
                {
                  LOOKUP_KEYS.concat({key: 'sum', text: 'Sum'}).map(({key, text}) => {
                    return <tr key={key}>
                      <td style={{position: 'sticky', left: 0, background: '#fff', zIndex: 2, padding: '2px 10px 4px 8px'}}>
                        {text}
                        <div style={{position: 'absolute', bottom: 0, top: 0, right: 0, width: 2, background: `${getTheme().palette.themeLight}`}}></div>
                      </td>
                      {
                        data.map(alt => {
                          let num = parseFloat(alt?.statistics?.[key]);
                          if(isNaN(num)){
                            return <td className='tac' key={alt.alternative}>-</td>
                          }
                          return <td className='tac' key={alt.alternative}>{round(alt.statistics[key], 2)}</td>
                        })
                      }
                    </tr>
                  })
                }
              </tbody>
            </table>
          </PivotItem>,
          <PivotItem key='chart' className='ms-depth-4' headerText="Charts">
            <BoxPlot data={data} yAxisTitle="Weighted Days" xAxisTitle="Flow Alternative" />
          </PivotItem>
        ]
      }
      
    </Pivot>
  </div>
}

function BoxPlot(props){
  const chartData = React.useMemo(() => {
    let data = [];
    if(props.processData) return props.processData(props.data);
    props.data.forEach(alt => {
      let y = [];
      Object.keys(alt.calculations).forEach(year => {
        if(alt.calculations[year].empty) return;
        y.push(alt.calculations[year].sum)
      });
      data.push({
        name: alt.alternative,
        marker: {
          color: alt.color,
        },
        y,
        type: 'box',
        boxpoints: 'all'
      })
    });

    return data;
    
  }, [props.data, props.processData]);

  return <Plot 
    data={chartData} 
    layout={{
      showlegend: false, 
      yaxis: {title: props.yAxisTitle}, 
      xaxis: {title: props.xAxisTitle}
    }}
    style={{width: '100%', height: '100%'}} />
}

function PmBuilder(props){

  const [state, setState] = React.useState(props.withState?.data || {...emptyPm, location: props.locations[0].key});

  const locationInfo = React.useMemo(() => {
    if(!state || !state.location || !props.locations.length) return;
    return props.locations.find(el => el.key === state.location);
  }, [state.location, props.locations]);

  const { measure } = React.useMemo(() => {
    if(locationInfo) return locationInfo;
    return {measure: ''};
  }, [locationInfo]);

  const [showDataLookupTable, toggleShowLookupTable] = React.useState(false);

  const [showPasswordDialog, toggleShowPasswordDialog] = React.useState(false);

  const [deleteHidden, toggleDeleteHidden] = React.useState(true);

  const [password, setPassword] = React.useState('');

  const [xLookup, setXLookup] = React.useState(() => {
    if(props.withState && props.withState.data && props.withState.data.xLookup){
      return props.withState.data.xLookup;
    } else {
      let template = {};
      ALL_DAYS.forEach(day => {
        template[day.julian] = 0.5;
      });
      return template;
    }
  });

  const [yLookup, setYLookup] = React.useState(() => {
    if(props.withState && props.withState.data && props.withState.data.yLookup){
      if(!_.isArray(props.withState.data.yLookup)) return [];
      return props.withState.data.yLookup;
    } else {
      return [];
    }
  });

  const [calHidden, toggleCalHidden] = React.useState(true);

  React.useEffect(() => {
    return () => {
      toggleShowPasswordDialog(false)
      setPassword('');
      props.onDismiss();
    }
  }, []);

  const minMax = React.useMemo(() => {
    if(state.location){
      let min, max;
      props.master.forEach(alt => {
        alt.data.forEach(dataSet => {
          if(dataSet.location === state.location){
            let { minYear, maxYear } = dataSet;
            if(!min && !max){
              min = minYear;
              max = maxYear;
              return;
            }
            if(minYear < min) min = minYear;
            if(maxYear > max) max = maxYear;
          }
        })
      });
      return [min, max];
    }
    return undefined
  }, [state.location, props]);

  const previewData = React.useMemo(() => {
    let minMaxRules = [];

    if(!yLookup.length || state.pmType !== 'Weighted Score - Matrix'){
      return [];
    }

    yLookup.forEach(rule => {
      if(rule.type === 'range'){
        minMaxRules.push(parseFloat(rule.lowerRange));
        minMaxRules.push(parseFloat(rule.upperRange));
      } else {
        minMaxRules.push(parseFloat(rule.value));
      }
    });
    minMaxRules = minMaxRules.filter(el => !isNaN(el));

    let floor = Math.min(...minMaxRules);
    let ceiling = Math.max(...minMaxRules);

    let interval = (ceiling - floor) / 100;

    let y; 
    
    if(isNaN(floor) || isNaN(ceiling) || isNaN(interval)){
      y = [];
    } else {
      y = _.range(floor, ceiling, interval);
    }

    if(!y.includes(ceiling)) y.push(ceiling);

    const z = y.map(value => {
      let yWeight = resolveYWeight(value, yLookup, state.defaultScore);
      return ALL_DAYS.map(({julian}) => {
        let xWeight = xLookup[julian];
        return xWeight * yWeight;
      })
    });

    return [
      {
        type: 'heatmap',
        colorscale: 'Blues', 
        reversescale: true,
        z: z,
        x: ALL_DAYS.map(el => el.date),
        y: y,
        zmin: 0,
        zmax: 1
      }
    ]
  }, [yLookup, xLookup, state]);

  const moveRangeDown = (index) => {
    setState(prev => ({...prev, ranges: arraymove([...prev.ranges], index, index + 1)}))
  };

  const moveRangeUp = (index) => {
    setState(prev => ({...prev, ranges: arraymove([...prev.ranges], index, index - 1)}))
  };

  const addRule = () => {
    setState(prev => {
      return {
        ...prev,
        ranges: prev.ranges.concat({
          key: uuid(),
          name: '',
          score: '',
          upperRange: '',
          lowerRange: '',
          startDay: 1,
          endDay: 365,
          type: 'range',
        })
      }
    })
  };

  const onUpdate = (x, y, weight) => {
    setXLookup(x);
    setYLookup(y);
    setState(prev => ({...prev, defaultWeight: weight}));
  };

  return <div className='pm-container'>
  <div>
    <h1 className='page-header'>Performance Measure Editor</h1>
    <Dropdown key="xasd" label='Performance Measure Type' selectedKey={state.pmType} onChange={(e, o) => setState(prev => ({...prev, pmType: o.key}))} options={[{key: 'Frequency of Event', text: 'Frequency of Event'}, {key: 'Weighted Score - Rules', text: 'Weighted Score - Rules'}, {key: 'Weighted Score - Matrix', text: 'Weighted Score - Matrix'}]} />
    <TextField label='Name' value={state.name} onChange={(e, name) => setState(prev => ({...prev, name}))} />
    <TextField label="Description" multiline rows={3} value={state.description} onChange={(e, description) => setState(prev => ({...prev, description}))} />


    {/* Form to build frequency of events params */}

    {
      state.pmType === 'Frequency of Event' && <div>
        <Label>Calculator Logic</Label>
        <div className='pm-builder-text hover-box hover-box-dark p1' style={{display: 'flex', flexWrap: 'wrap'}}>
          At the location:

          <Dropdown className='fr aic' selectedKey={state.location} styles={{root: {margin: '0px 8px'}, callout: {width: 'max-content'}}} placeholder='Select an option...' options={props.locations.map(o => ({key: o.key, text: o.text}))} onChange={(e, o) => setState(prev => ({...prev, location: o.key}))} />

          count amout of times where alternatives 
          <Dropdown className='fr aic' selectedKey={state.direction} styles={{root: {margin: '0px 8px', minWidth: 120}, callout: {width: 'max-content'}}} placeholder='Select an option...' options={[
            {key: 'greater than', text: 'are greater than'},
            {key: 'less than', text: 'are less than'},
            {key: 'between', text: 'are between'},
            {key: 'surpass', text: 'surpass'},
            {key: 'subceed', text: 'subceed'},
          ]} onChange={(e, o) => setState(prev => ({...prev, direction: o.key}))} />


          {
            state.direction !== 'between' ? (
              <TextField 
                styles={{root: {margin: '0px 8px'}}}
                className='fr aic' 
                value={state.value} 
                placeholder='-'
                suffix={measure} 
                onChange={(e, val) => setState(prev => ({...prev, value: val}))} />
            ) : (
              <>
                <TextField 
                  type="number" 
                  placeholder='Lower value' 
                  value={state.lowerRange}
                  styles={{root: {margin: '0px 8px'}}}
                  className='fr aic'  
                  suffix={measure}
                  onChange={(e, lowerRange) => setState(prev => ({...prev, lowerRange}))} />
                and
                <TextField 
                  type="number" 
                  placeholder='Upper value'
                  value={state.upperRange} 
                  suffix={measure}
                  styles={{root: {margin: '0px 8px'}}}
                  className='fr aic' 
                  onChange={(e, upperRange) => setState(prev => ({...prev, upperRange}))} />

              </>
            )
          }
          for
          <TextField 
            type="number" 
            placeholder='# of days'
            styles={{root: {margin: '0px 8px'}}}
            className='fr aic' 
            value={state.daysInRow} 
            onChange={(e, value) => setState(prev => ({...prev, daysInRow: value}))} />
          consecutive day(s) between the dates:
        
          <TextField
            styles={{root: {margin: '0px 8px'}}}
            className='fr aic'  
            value={`${resolveDateText(state.startDay)} - ${resolveDateText(state.endDay)}`}
            onClick={() => toggleCalHidden(false)}
            readOnly />
          <DateRange 
            hidden={calHidden} 
            toggleDateRangePickerHidden={(hidden) => toggleCalHidden(hidden)}
            startDay={state.startDay} 
            onRender={({dateText}) => {
              return null;
            }}
            endDay={state.endDay} 
            onChange={(startDay, endDay) => setState(prev => ({...prev, endDay: endDay, startDay: startDay}))} />


 
        </div>
        <div>
          <Label>Select Years</Label>
          <div className='hover-box hover-box-dark p1'>
          <Toggle 
            checked={state.selectYears}
            onChange={(e, checked) => setState(prev => ({...prev, selectYears: checked}))} />
          <p>{
            state.selectYears ? 'Only years within selected range included' : 'Entire period of record will be included'
          }</p>
          <p>{state.selectYears && !state.location && "Select a location...."}</p>
          {
            minMax && state.selectYears && <Slider 
              ranged
              lowerValue={state.startYear || minMax[0]}
              onChange={(val, range) => {
                setState(prev => ({...prev, startYear: range[0], endYear: range[1]}))
              }}
              value={state.endYear || minMax[1]}
              min={minMax && minMax[0]} 
              max={minMax && minMax[1]} />
          }
          </div>
          
        </div>
      </div>
    }


    {
      state.pmType === 'Weighted Score - Rules' && <div>
        <Dropdown label="Location" options={props.locations} selectedKey={state.location} onChange={(e, o) => setState(prev => ({...prev, location: o.key}))} />
        <div>
          <Label>Select Years</Label>
          <div className='hover-box hover-box-dark p1'>
          <Toggle 
            checked={state.selectYears}
            onChange={(e, checked) => setState(prev => ({...prev, selectYears: checked}))} />
          <p>{
            state.selectYears ? 'Only years within selected range included' : 'Entire period of record will be included'
          }</p>
          <p>{state.selectYears && !state.location && "Select a location...."}</p>
          {
            minMax && state.selectYears && <Slider 
              ranged
              lowerValue={state.startYear || minMax[0]}
              onChange={(val, range) => {
                setState(prev => ({...prev, startYear: range[0], endYear: range[1]}))
              }}
              value={state.endYear || minMax[1]}
              min={minMax && minMax[0]} 
              max={minMax && minMax[1]} />
          }
          </div>
        </div>
        <MessageBar styles={{root: {background: getTheme().palette.themeLighterAlt, padding: 16, marginTop: 16, marginBottom: 16, borderRadius: 4, border: `1px solid ${getTheme().palette.themePrimary}`}, text: {fontSize: 14}}}>
          Build you rules in order of priority. Rules are applied in <strong>sequential</strong> order from top to bottom.
        </MessageBar>

        {
          state.ranges.map((range, index) => {
            return <div key={range.key} className='hover-box hover-box-dark mb3 p1 fr g16'>
              <div className='fr fcol sb'>
                <div className='fr fcol'>
                  <IconButton onClick={() => moveRangeUp(index)} styles={{rootDisabled: {background: 'none'}}} disabled={index === 0} iconProps={{iconName: "ChevronUp"}} />
                  <IconButton onClick={() => moveRangeDown(index)} styles={{rootDisabled: {background: 'none'}}} disabled={index === state.ranges.length - 1} iconProps={{iconName: "ChevronDown"}} />
                </div>
                <IconButton 
                  iconProps={{iconName: "Delete"}} 
                  onClick={() => setState(prev => ({...prev, ranges: prev.ranges.filter(el => el.key !== range.key)}))} />
              </div>
              <div className='f1'>
                <TextField 
                  value={range.name}
                  label="Rule Name"
                  placeholder="Rule Name"
                  onChange={(e, name) => {
                    setState(prev => ({
                      ...prev, 
                      ranges: prev.ranges.map(r => {
                        if(r.key === range.key) return {...r, name};
                        return r;
                      })
                    })
                  )}} />

                <Dropdown 
                  label="Rule Type"
                  selectedKey={range.type}
                  // defaultSelectedKey={'range'}
                  options={[
                    {key: 'range', text: 'Range'}, 
                    {key: 'equal', text: "Equal to"}, 
                    {key: 'less', text: 'Less than'}, 
                    {key: 'greater', text: 'Greater than'}, 
                    {key: 'greater-equal', text: 'Greater than or equal to'}, 
                    {key: 'less-equal', text: "Less than or equal to"}
                  ]}
                  onChange={(e, o) => setState(prev => ({
                    ...prev, 
                    ranges: prev.ranges.map(r => {
                      if(r.key === range.key){
                        return {
                          ...r,
                          type: o.key
                        }
                      }
                      return r
                    })
                  }))} />

                <TextField 
                  value={range.score}
                  label="Rule Score"
                  // type='number'
                  placeholder="Enter score"
                  description='If the value from a day is passes the rule, the score will be accumulated.'
                  onChange={(e, score) => {
                    setState(prev => ({
                      ...prev, 
                      ranges: prev.ranges.map(r => {
                        if(r.key === range.key) return {...r, score};
                        return r;
                      })
                    })
                  )}} />
                
              </div>
              <div className='f1'>
                <TextField 
                  value={range.upperRange}
                  label={range.type !== "range" ? "Value" : "Upper Range"}
                  type='number'
                  placeholder={range.type !== "range" ? "Value" : "Upper Range"}
                  onChange={(e, upperRange) => {
                    setState(prev => ({
                      ...prev, 
                      ranges: prev.ranges.map(r => {
                        if(r.key === range.key) return {...r, upperRange};
                        return r;
                      })
                    })
                  )}} />
                {
                  range.type === 'range' && <TextField 
                  value={range.lowerRange}
                  label="Lower Range"
                  type='number'
                  placeholder="Lower Range"
                  onChange={(e, lowerRange) => {
                    setState(prev => ({
                      ...prev, 
                      ranges: prev.ranges.map(r => {
                        if(r.key === range.key) return {...r, lowerRange};
                        return r;
                      })
                    })
                  )}} />
                }
                
                  <DateRange 
                    hidden={calHidden !== range.key}
                    onRender={({dateText}) => {
                      return <TextField 
                        styles={{fieldGroup: {cursor: 'pointer'}, field: {cursor: 'pointer !important'}}}
                        iconProps={{iconName: 'Calendar'}} 
                        onClick={() => toggleCalHidden(range.key)}
                        value={dateText}
                        label='Date Range'
                      />
                    }}
                    toggleDateRangePickerHidden={(hidden) => toggleCalHidden(null)}
                    startDay={range.startDay} 
                    endDay={range.endDay} 
                    onChange={(startDay, endDay) => {
                      setState(prev => ({...prev, ranges: prev.ranges.map(r => {
                        if(r.key === range.key){
                          return {
                            ...r,
                            startDay,
                            endDay
                          }
                        }
                        return r
                      })}))
                    }} />
                </div>
            </div>
          })
        }

        <PrimaryButton text="Add Rule" onClick={addRule} />

        <TextField 
          label='Default Score'
          type='number'
          value={state.defaultScore} 
          onChange={(e, defaultScore) => setState(prev => ({...prev, defaultScore}))} 
          description='If the point being evaluated does not hit any of the ranged defined above, this score will be applied' />
        
      </div>
    }

    {
      state.pmType === 'Weighted Score - Matrix' && <div>
        <Dropdown className='mb3' label="Location" options={props.locations} selectedKey={state.location} onChange={(e, o) => setState(prev => ({...prev, location: o.key}))} />
        <div className='mb3'>
          <Label>Select Years</Label>
          <div className='hover-box hover-box-dark p1'>
          <Toggle 
            checked={state.selectYears}
            onChange={(e, checked) => setState(prev => ({...prev, selectYears: checked}))} />
          <p>{
            state.selectYears ? 'Only years within selected range included' : 'Entire period of record will be included'
          }</p>
          <p>{state.selectYears && !state.location && "Select a location...."}</p>
          {
            minMax && state.selectYears && <Slider 
              ranged
              lowerValue={state.startYear || minMax[0]}
              onChange={(val, range) => {
                setState(prev => ({...prev, startYear: range[0], endYear: range[1]}))
              }}
              value={state.endYear || minMax[1]}
              min={minMax && minMax[0]} 
              max={minMax && minMax[1]} />
          }
          </div>
        </div>

        

        {
          showDataLookupTable && <Dialog dialogContentProps={{type: DialogType.close}} onDismiss={() => toggleShowLookupTable(false)} hidden={false} minWidth={800} maxWidth={800}>
            <DataLookupTable 
              xLookup={xLookup}
              yLookup={yLookup}
              defaultWeight={state.defaultWeight}
              pmProps={state}
              onDismiss={() => toggleShowLookupTable(false)}
              onUpdate={onUpdate} />
          </Dialog>
        }
        {
          previewData && <div className='hover-box hover-box-dark' style={{paddingTop: 8}}>
            <PrimaryButton text="Edit Data Lookup Table" onClick={() => toggleShowLookupTable(prev => !prev)} />
            <HeatMap data={previewData} />
          </div>
        }
      </div>
    }


  </div>

  <Dialog hidden={!showPasswordDialog} onDismiss={() => toggleShowPasswordDialog(false)} dialogContentProps={{isBlocking: true, title: "Add Performance Measure", subText: "If you are an administrator, you can save performance measures. Otherwise, build temporary performance measure."}}>
    <TextField type="password" value={password} onChange={(e, val) => setPassword(val)} />
    <DialogFooter>
      <DefaultButton text="Temporary PM" onClick={() => {
        toggleShowPasswordDialog(false);
        setPassword('');
        if(!props.withState){
          props.addPm({...state, xLookup, yLookup, temporary: true}, password);
        } else {
          props.onUpdate(props.withState.id, {...state, xLookup, yLookup}, password);
        }
        props.onDismiss();
      }} />
      <DefaultButton primary text="Save PM" onClick={() => {
          if(!props.withState){
            props.addPm({...state, xLookup, yLookup}, password);
          } else {
            props.onUpdate(props.withState.id, {...state, xLookup, yLookup}, password);
          }
          props.onDismiss();
      }} />
    </DialogFooter>
  </Dialog>

  <Dialog hidden={deleteHidden} onDismiss={() => toggleDeleteHidden(true)} dialogContentProps={{isBlocking: true, title: "Delete Performance Measure", subText: "If you are an admin, enter your password to delete: " + state.name}}>
    <TextField type="password" value={password} onChange={(e, val) => setPassword(val)} />
    <DialogFooter>
      <DefaultButton text="Cancel" onClick={() => toggleDeleteHidden(true)} />
      <DefaultButton primary text="Delete" onClick={() => {
        props.onDelete(props.withState.id, password, false, () => props.onDismiss());
      }}  />
    </DialogFooter>
  </Dialog>

  <div style={{marginTop: 16, display: 'flex', justifyContent: 'space-between'}}>
    {
      props.withState && <DefaultButton text="Delete" onClick={() => {
        if(props.withState?.data?.temporary){
          props.onDelete(props.withState.id, '', true);
          props.onDismiss();
        } else {
          toggleDeleteHidden(false)
        }
      }} />
    }
    
    <div style={{alignSelf: 'flex-end'}}>
      <DefaultButton styles={{root: {marginRight: 8}}} text="Cancel" onClick={props.onDismiss} />
      <PrimaryButton 
        disabled={!state.name || !state.location} 
        text={props.withState ? "Edit" : "Build"} 
        onClick={() => {
          if(props.withState?.data?.temporary){
            props.onUpdate(props.withState.id, {...state, xLookup, yLookup});
            props.onDismiss();
          } else {
            setPassword('');
            toggleShowPasswordDialog(true);
          }
      }} />
    </div>
  </div>
  </div>

}

function DataLookupTable(props){

  const [state, setState] = React.useState(() => {
    let y = props.yLookup;
    if(!_.isArray(y)){
      y = [];
    }
    return {
      xLookup: props.xLookup, 
      yLookup: y,
      defaultWeight: props.defaultWeight
    }
  });

  const onDateCellsChanged = (changes) => {
    let updates = {};
    changes.forEach(({newCell, rowId}) => {
      updates[rowId] = newCell.value;
    });
    setState(prev => ({...prev, xLookup: {...prev.xLookup, ...updates}}));
  };

  const addRule = () => {
    setState(prev => ({...prev, yLookup: prev.yLookup.concat({
      key: uuid(),
      name: '',
      type: 'range',
      value: '',
      upperRange: '',
      lowerRange: '',
    })}))
  };

  const dateColumns = [
    {columnId: 'date', width: 150},
    {columnId: 'weight', width: 150}
  ];

  const dateRows = [
    {rowId: 'header', cells: [{type: 'header', text: 'Date'}, {type: 'header', text: 'Weight'}]},
    ...Object.keys(state.xLookup).map(date => {
      return {
        rowId: date,
        cells: [
          {type: 'text', text: resolveDateText(date, props.pmProps.dateInterval), nonEditable: true},
          {type: 'number', value: state.xLookup[date], hideZero: false}
        ]
      }
    })
  ];

  const moveRangeDown = (index) => {
    setState(prev => ({...prev, yLookup: arraymove([...prev.yLookup], index, index + 1)}))
  };

  const moveRangeUp = (index) => {
    setState(prev => ({...prev, yLookup: arraymove([...prev.yLookup], index, index - 1)}))
  };

  return <>
    <Pivot style={{root: {minHeight: '80vh'}}}>
      <PivotItem headerText='Dates'>
        <ReactGrid rows={dateRows} columns={dateColumns} enableFillHandle onCellsChanged={onDateCellsChanged} />
      </PivotItem>
      <PivotItem headerText='Data'>
        <p>Develop rule set for time series data. Rules will be applied sequentially top to bottom.</p>
        <p>Enter a default weight to be applied if no rules are matched.</p>
        <TextField 
          underlined
          autoComplete='off'
          className='mb3' 
          label='Default Weight:' 
          value={state.defaultWeight} 
          onChange={(e, val) => setState(prev => ({...prev, defaultWeight: val}))} />
        {
          state.yLookup.map((rule, i) => {
            let { isValid=false, message='' } = checkValid(rule);
            return <div className='p1 hover-box mb3' key={rule.key}>
              <p className='fr aic jcc mb2 mt2'>
                <FontIcon className='mr3' iconName={isValid ? "SkypeCircleCheck" : "WarningSolid"} style={{color: isValid ? getTheme().palette.green : getTheme().palette.orange}} />
                <span className='ms-fontSize-12'>{message}</span>
              </p>
              <div className='fr g12'>
                <div className='fr fcol sb'>
                  <div>
                    <IconButton styles={{root: {display: i === 0 ? 'none' : 'block'}}} iconProps={{iconName: 'ChevronUp'}} onClick={() => moveRangeUp(i)} />
                    <IconButton styles={{root: {display: i === state.yLookup.length - 1 ? 'none' : 'block'}}} iconProps={{iconName: 'ChevronDown'}} onClick={() => moveRangeDown(i)}/>
                  </div>
                  <IconButton iconProps={{iconName: 'Delete'}} onClick={() => setState(prev => ({...prev, yLookup: prev.yLookup.filter(el => el.key !== rule.key)}))} />
                </div>
                <div className='f1'>
                  <TextField 
                    underlined
                    value={rule.name} 
                    autoComplete='off'
                    label="Name:" 
                    onChange={(e, val) => setState(prev => ({...prev, yLookup: prev.yLookup.map(el => {
                      if(el.key === rule.key){
                        return {...el, name: val}
                      }
                      return el
                    })
                    }))} />
                  <Dropdown 
                    styles={{dropdown: {borderRadius: 0, border: 'none', outline: 'none', flex: 1, borderBottom: `3px solid ${getTheme().palette.neutralPrimary}`}, root: {display: 'flex'}, title: {border: 'none'}, label: {borderBottom: '3px solid ' + getTheme().palette.neutralPrimary, padding: '5px 8px 5px 12px'}}}
                    options={[
                      {key: 'range', text: 'Range'}, 
                      {key: 'equal', text: "Equal to"}, 
                      {key: 'less', text: 'Less than'}, 
                      {key: 'greater', text: 'Greater than'}, 
                      {key: 'greater-equal', text: 'Greater than or equal to'}, 
                      {key: 'less-equal', text: "Less than or equal to"}
                    ]} 
                    label="Rule Type:"
                    selectedKey={rule.type}
                    onChange={(e, o) => {
                      setState(prev => ({...prev, yLookup: prev.yLookup.map(el => {
                        if(el.key === rule.key){
                          return {...el, type: o.key}
                        }
                        return el
                      })}))
                    }} />
                </div>
                <div className='f1'>
                  {
                    rule.type === 'range' ? (
                      <>
                      <TextField 
                        underlined
                        autoComplete='off'
                        value={rule.upperRange} label="Upper Range:" onChange={(e, val) => setState(prev => ({...prev, yLookup: prev.yLookup.map(el => {
                        if(el.key === rule.key){
                          return {...el, upperRange: val}
                        }
                        return el
                      })}))} />
                      <TextField 
                        autoComplete='off'
                        underlined
                        value={rule.lowerRange} label="Lower Range:" onChange={(e, val) => setState(prev => ({...prev, yLookup: prev.yLookup.map(el => {
                        if(el.key === rule.key){
                          return {...el, lowerRange: val}
                        }
                        return el
                      })}))} />
                      </>

                    ) : <TextField 
                          underlined
                          autoComplete='off'
                          value={rule.value} 
                          label="Value:" 
                          onChange={(e, val) => setState(prev => ({...prev, yLookup: prev.yLookup.map(el => {
                          if(el.key === rule.key){
                            return {...el, value: val}
                          }
                          return el
                        })}))} />
                  }

                  <TextField 
                    underlined
                    autoComplete='off'
                    value={rule.weight} 
                    label="Weight:" 
                    onChange={(e, val) => setState(prev => ({...prev, yLookup: prev.yLookup.map(el => {
                    if(el.key === rule.key){
                      return {...el, weight: val}
                    }
                    return el
                  })}))} />
                </div>
              </div>

            </div>
          })
        }
        <PrimaryButton text="Add Rule" onClick={addRule}/>
      </PivotItem>
    </Pivot>
    <DialogFooter>
      <DefaultButton text="Cancel" onClick={() => props.onDismiss()} />
      <PrimaryButton text='Apply Weights' onClick={() => {
        props.onUpdate(state.xLookup, state.yLookup, state.defaultWeight);
        props.onDismiss();
      }} />
    </DialogFooter>
  </>
}

function HeatMap(props){
  return <Plot data={props.data} style={{width: '100%', height: 480, padding: 0}} aut config={{displayModeBar: false, responsive: true}} />
}

function HoverPicker(props){

  const [hovered, setHovered] = React.useState(false);
  const ref = React.useRef(null);

  // const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);


  return <>
    <ContextualMenu
      items={props.items}
      hidden={!hovered}
      directionalHint={DirectionalHint.bottomCenter}
      calloutProps={{onMouseLeave: onMouseLeave}}
      onItemClick={(e, item) => props.onChange(item.key)}
      target={ref}
      onDismiss={onMouseLeave}
    />
    <div 
      className='hover-to-change' 
      ref={ref} 
      style={{
        minWidth: props.minWidth || 'auto', 
        height: 'auto', 
        background: getTheme().palette.themeLighter, 
        marginRight: 4, 
        marginLeft: 4,
        paddingRight: 4,
        borderRadius: 2
      }}>
        <IconButton 
          onClick={() => setHovered(true)} 
          iconProps={{iconName: 'Edit'}} 
          styles={{root: {background: 'none !important', height: 'auto'}}} 
        />
        {props.text || " "}
    </div>
  </> 
}