import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { createTheme } from '@fluentui/react';

initializeIcons();

// const myTheme = createTheme({
//   palette: {
//     themePrimary: '#005eb8',
//     themeLighterAlt: '#f2f7fc',
//     themeLighter: '#cde1f4',
//     themeLight: '#a4c8ea',
//     themeTertiary: '#5597d4',
//     themeSecondary: '#176ec0',
//     themeDarkAlt: '#0055a5',
//     themeDark: '#00488c',
//     themeDarker: '#003567',
//     neutralLighterAlt: '#f3f3f3',
//     neutralLighter: '#efefef',
//     neutralLight: '#e5e5e5',
//     neutralQuaternaryAlt: '#d6d6d6',
//     neutralQuaternary: '#cccccc',
//     neutralTertiaryAlt: '#c4c4c4',
//     neutralTertiary: '#595941',
//     neutralSecondary: '#373725',
//     neutralPrimaryAlt: '#2f2f1f',
//     neutralPrimary: '#000000',
//     neutralDark: '#15150d',
//     black: '#0b0b07',
//     white: '#fafafa',
//   }});

ReactDOM.render(
  <React.StrictMode>
    {/* <ThemeProvider applyTo='body' theme={myTheme}> */}
      <App />
    {/* </ThemeProvider> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
