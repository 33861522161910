import React from 'react';
import {  ChartPicker, MultiAltPicker, LocationPicker, Plot, ThresholdPicker, ThresholdSummary } from './Hydraviz';
import Plotly from 'plotly.js';
import _ from 'lodash'
import { Icon } from '@fluentui/react';
import { YAxisControls } from './MultiAlt';
import Help from './Help';


var selectorOptions = {
  buttons: [
    {
      step: 'month',
      stepmode: 'backward',
      count: 1,
      label: '1m'
    }, 
    {
      step: 'month',
      stepmode: 'backward',
      count: 6,
      label: '6m'
    }, 
    {
      step: 'year',
      stepmode: 'backward',
      count: 1,
      label: '1y'
    }, 
    {
      step: 'year',
      stepmode: 'backward',
      count: 5,
      label: '5y'
    },
    {
      step: 'year',
      stepmode: 'backward',
      count: 10,
      label: '10y'
    },
    {
      step: 'all',
    }
  ]
};

export default function HistoricalRecord(props){

  const { ALL_DAYS, alternatives=[], startCalDay, editThreshold, addThreshold, globalLayoutSettings, thresholdInfo, startDay, endDay } = props;

  const measure = React.useMemo(() => {
    if(props.locations.length){
      const { measure } = props.locations.find(el => el.key === props.location);
      return measure;
    }
  }, [props.locations, props.location]);

  const [thresholds, setThresholds] = React.useState([]);

  const [layout, setLayout] = React.useState(() => {
    return {
      autosize: true,
      showlegend: false,
      hovermode: 'closest',
      // title: ,
      // shapes,
      title: {
        text: `${props.location}<br>Historical Record`,
      },
      margin: props.plotMargin || {b: 80, l: 80, pad: 0, r: 80, t: 100, autoexpand: true},
      legend: {orientation: 'v'},
      yaxis: {automargin: true, title: measure, autorange: true},
      xaxis: {
        automargin: true,
        // range: ['2010-01-01', '2015-12-31'],
        rangeselector: selectorOptions,
        rangeslider: {},
        type: 'date'
      },
      font: {
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
        size: 14
      }
    }
  });

  React.useEffect(() =>  setThresholds(props.thresholds), [props.thresholds]);

  const chartData = React.useMemo(() => {
    
    let traces = [];

    
    props.master.forEach(trace => {
      if(!props.selectedAlts.includes(trace.key)) return;

      let dataSet = trace.data.find(entry => entry.location === props.location);

      if(!dataSet) return;

      let x=[], y=[], text=[];

      let firstIndexOfData = dataSet.allData.findIndex(el => !isNaN(parseFloat(el.value)));

      if(firstIndexOfData !== 0){
        dataSet?.allData.slice(firstIndexOfData).forEach(day => {
          x.push(day.date);
          y.push(day.value);
          text.push(`${trace.alternative}, ${day.date}<br>${day.value} ${dataSet.measure}`);
        });
      } else {
        dataSet?.allData.forEach(day => {
          x.push(day.date);
          y.push(day.value);
          text.push(`${trace.alternative}, ${day.date}<br>${day.value} ${dataSet.measure}`);
        });
      }

      
      
      traces.push({
        key: trace.alternative,
        traceType: 'scenario',
        name: trace.alternative,
        line: {
          color: dataSet.color,
        },
        type: 'scatter',
        mode: 'lines',
        hoverinfo: 'text',
        x,
        y,
        text
      })
    });

    if(traces.length === 0){
      return []
    }

    const longestTrace = traces[0].x;

    traces.forEach(trace => {
      if(trace.x.length > longestTrace) longestTrace = trace.x;
    });

    thresholds.forEach(threshold => {
        let { isWindow, isRange, isStepped, steps, name, visible, color, fill, pattern, stepsLower, upperRange, lowerRange, value } = threshold.data;

        let y=[], x=[], text=[];

        if(isRange){

          // upper first
          y = _.fill([].concat(longestTrace), upperRange);
          text = _.fill([].concat(longestTrace), `${name}: ${upperRange} ${measure}`);
          x = longestTrace;
          traces.push({
            key: `${threshold.id}`,
            name: `${name}`,
            visible: visible,
            hoverinfo: 'text',
            x,
            y,
            text,
            showlegend: true,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          });

          //now lower
          y = _.fill([].concat(longestTrace), lowerRange);
          text = _.fill([].concat(longestTrace), `${name}: ${lowerRange} ${measure}`);;
          x = longestTrace;
          traces.push({
            key: `${threshold.id}`,
            name: `${name}`,
            visible: visible,
            hoverinfo: 'text',
            x,
            y,
            text,
            showlegend: true,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })
        

        } else {
          let y = _.fill([].concat(longestTrace), value);
          let text = _.fill([].concat(longestTrace), `${name}: ${value} ${measure}`);;
          let x = longestTrace;
          traces.push({
            key: `${threshold.id}`,
            name: `${name}`,
            visible: visible,
            hoverinfo: 'text',
            x,
            y,
            text,
            showlegend: true,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })
        }

    })

    return traces;
  }, [props.master, thresholds, measure, props.selectedAlts]);

  const scenarios = React.useMemo(() => {
    return chartData.filter(d => d.traceType === 'scenario');
  }, [chartData]);

  const resolveRange = React.useCallback(() => {
      let maxLength = 3650;
      let lastDay = chartData[0].x[chartData[0].x.length - 1];
      if(chartData[0].x.length < 3650) maxLength = chartData[0].x.length;
      let firstDay = chartData[0].x[chartData[0].x.length - maxLength];
      return [firstDay, lastDay];
  }, [chartData]);

  React.useEffect(() => {
    const range = resolveRange();

    setLayout({
      autosize: true,
      showlegend: false,
      hovermode: 'closest',
      title: {
        text: `${props.location}<br>Historical Record`,
      },
      margin: {b: 80, l: 80, pad: 0, r: 80, t: 100, autoexpand: true},
      legend: {orientation: 'v'},
      yaxis: {automargin: true, title: measure, autorange: true},
      xaxis: {
        automargin: true,
        range,
        rangeselector: selectorOptions,
        rangeslider: {},
        type: 'date'
      },
      font: {
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
        size: globalLayoutSettings.fontSize
      }
    })
  }, [measure, props.location, globalLayoutSettings]);

  const onScreenCap = React.useCallback((gd, e) => {
    Plotly.relayout(gd, {showlegend: true});
    Plotly.downloadImage(gd, {format: 'svg'});
    Plotly.relayout(gd, {showlegend: false});
  }, []);

  const onUpdate = (fig, graph) => {
    props.onChartDataUpdate && props.onChartDataUpdate(fig);
  }

  const chartConfig = {
    modeBarButtonsToRemove: ['toImage'],
    displaylogo: false,
    modeBarButtonsToAdd: [{
      name: 'Capture',
      click: onScreenCap, 
      icon: {
        width: 1000,
        height: 1000,
        path: 'm500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z',
        transform: 'matrix(1 0 0 -1 0 850)'
      }}]
  };

  const style = {width: '100%', height: '100%'};

  const chartPickerRef = React.useRef(null);
  const locationPickerRef = React.useRef(null);
  const yAxisRef = React.useRef(null);
  const multiAltRef = React.useRef(null);
  const thresholdRef = React.useRef(null);

  const helpItems = [chartPickerRef, locationPickerRef, yAxisRef, multiAltRef, thresholdRef];

  return <div style={{display: 'flex', flex: 1, background: '#fff', padding: 8, height: 'calc(100% - 33px)', maxHeight: 'calc(100% - 33px)'}}>
    <Help show={props.showHelp} items={helpItems} chartType={"Continuous Time Series"} onDismiss={props.onCloseHelp} />
    <div style={{width: '15%', maxWidth: '15%', display: thresholdInfo ? 'none' : 'block'}}>
      <div id="chart-picker" ref={chartPickerRef} style={{marginBottom: 32}}>
        <ChartPicker 
          chartType={props.chartType} 
          onChange={(chartType) => props.changeFilters({chartType})} />
      </div>
      <div style={{marginBottom: 32}}>
        <div id="location-picker" ref={locationPickerRef}>
          <LocationPicker 
            locationGroups={props.locationGroups} 
            toggleShowMapper={props.toggleShowMapper} 
            locations={props.locations} 
            location={props.location} 
            onChange={(location) => props.changeFilters({location})} />
        </div>
      </div>
      <div id="y-axis-controls" ref={yAxisRef}>
        <YAxisControls 
          location={props.location} 
          state={layout.yaxis} 
          onChange={(changes) => setLayout(prev => ({...prev, yaxis: {...prev.yaxis, ...changes}}))} />
      </div>
    </div>
    {
      chartData.length > 0 ? (
        <div style={{position: 'relative', width: '70%', maxWidth: '70%'}}>
          <Plot
            style={style}
            onUpdate={onUpdate}
            layout={layout}
            useResizeHandler
            config={chartConfig}
            data={chartData} />
        </div>
      ) : <div className='fr jcc aic empty f1' style={{width: '70%', maxWidth: '70%', height: '100%'}}>
        <div style={{textAlign: 'center'}}>
          <Icon iconName="SearchIssue" styles={{root: {fontSize: 42}}} />
          <p style={{fontSize: 18}}>No data to display.</p>
        </div>
      </div>
    }
    <div style={{width: thresholdInfo ? '30%' : '15%', maxWidth: thresholdInfo ? '30%' : '15%', height: '100%', maxHeight: '100%', display: 'flex', flexDirection: 'column'}}>
      {
        thresholdInfo ? (
          <ThresholdSummary 
            {...thresholdInfo} 
            startCalDay={props.startCalDay}
            addThreshold={props.addThreshold}
            editThreshold={props.editThreshold} 
            setThresholdInfo={props.setThresholdInfo}
            startDay={startDay}
            endDay={endDay}
            changeFilters={props.changeFilters}
            FILTERED_CAL={ALL_DAYS} 
            ALL_DAYS={ALL_DAYS} 
            onDismiss={props.onDismissThresholdSummary} />
        )
        :
        (
          <>
            <div ref={multiAltRef} id="multi-alt-picker">
              <MultiAltPicker 
                alternatives={alternatives} 
                selectedAlts={props.selectedAlts} 
                changeFilters={props.changeFilters} />
            </div>
            <div ref={thresholdRef} id='threshold-picker'>
              <ThresholdPicker
                ALL_DAYS={ALL_DAYS} 
                FILTERED_CAL={ALL_DAYS}
                startCalDay={startCalDay}
                setThresholdInfo={props.setThresholdInfo} 
                location={props.location} 
                startDay={props.startDay} 
                endDay={props.endDay} 
                editThreshold={editThreshold}
                addThreshold={addThreshold}
                thresholds={thresholds} 
                changeFilters={props.changeFilters} 
                scenarios={scenarios} />
            </div>
          </>
        )
      }
    </div>
    
  </div>
}