import { TextField, Dropdown, Label, PrimaryButton, Checkbox, Toggle, Slider, Panel, PanelType } from '@fluentui/react';
import { SwatchesPicker } from 'react-color';
import React from 'react';
import { PATTERNS } from './Hydraviz';
import { ReactGrid } from "@silevis/reactgrid";
import { ALL_DAYS, buildCal } from './lib/cal';
import "@silevis/reactgrid/styles.css";
import DateRange from './DateRange';

export const THRESHOLD_COLORS = [
  "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", 
  "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b",
  "#000000", "#323130", "#605e5c", // shades
];

export default function AddThreshold(props){

  // console.log(props);

  const { clearEditState, hidden, editState, onDismiss, editThreshold, location, addThreshold, ALL_DAYS } = props;

  const [key, setKey] = React.useState('');
  const [name, setName] = React.useState('');
  const [value, setValue] = React.useState('');
  const [upperRange, setUpperRange] = React.useState('');
  const [lowerRange, setLowerRange] = React.useState('');
  const [startWindow, setStartWindow] = React.useState(1);
  const [endWindow, setEndWindow] = React.useState(365);
  const [pattern, setPattern] = React.useState(PATTERNS[0]);
  const [color, setColor] = React.useState(THRESHOLD_COLORS[0]);
  const [opacity, setOpacity] = React.useState(0.2);
  const [fill, toggleFill] = React.useState(false);
  const [isRange, toggleIsRange] = React.useState(false);
  const [isStepped, toggleIsStepped] = React.useState(false);
  const [steps, setSteps] = React.useState(ALL_DAYS.map((d) => ({...d, value: ''})));
  const [stepsLower, setStepsLower] = React.useState(ALL_DAYS.map((d) => ({...d, value: ''})));
  const [isWindow, toggleIsWindow] = React.useState(false);
  const [isEditing, toggleIsEditing] = React.useState(false);
  const [dateRangePickerHidden, toggleDateRangePickerHidden] = React.useState(true);

  React.useEffect(() => {
    if(editState && !hidden){
      let { data } = editState;
      toggleIsEditing(true);
      setName(data.name);
      setValue(data.value);
      setColor(data.color);
      toggleIsStepped(data.isStepped);
      setKey(editState.id);
      toggleFill(data.fill);
      setOpacity(data.opacity || 0.2);
      setUpperRange(data.upperRange);
      setLowerRange(data.lowerRange);
      toggleIsWindow(data.isWindow);
      toggleIsRange(data.isRange);
      setPattern(data.pattern);
      setStartWindow(data.startWindow);
      setEndWindow(data.endWindow);
      setStepsLower(data.stepsLower.sort((a, b) => (a.julian - b.julian)) || ALL_DAYS.map((d) => ({...d, value: ''}).sort((a, b) => a.julian - b.julian)));
      setSteps(data.steps.sort((a, b) => a.julian - b.julian) || ALL_DAYS.map((d) => ({...d, value: ''})).sort((a, b) => a.julian - b.julian));
    }
    if(hidden){
      toggleIsEditing(false);
      clearEditState();
    }
  }, [clearEditState, hidden, editState]);


  React.useEffect(() => {
    const cal = buildCal(props.startCalDay);
    if(props.hidden){
      setKey('')
      setName('');
      setValue('');
      setLowerRange('');
      setUpperRange('');
      toggleFill(false);
      setOpacity(0.2);
      setColor(THRESHOLD_COLORS[0]);
      toggleIsWindow(false);
      setStartWindow(cal[0].julian);
      setEndWindow(cal[cal.length - 1].julian);
      toggleIsStepped(false);
      setStepsLower(ALL_DAYS.map((d) => ({...d, value: ''})).sort((a, b) => a.julian - b.julian));
      setSteps(ALL_DAYS.map((d) => ({...d, value: ''})).sort((a, b) => a.julian - b.julian));
    }
  }, [props.hidden, props.startCalDay]);


  const add = React.useCallback(() => {
    const newThreshold = {
      // key: Date.now(), 
      name, 
      value: parseFloat(value), 
      color, 
      location, 
      visible: true,
      fill,
      opacity,
      pattern,
      isRange,
      isWindow,
      startWindow,
      endWindow,
      lowerRange: parseFloat(lowerRange),
      upperRange: parseFloat(upperRange),
      steps,
      stepsLower,
      isStepped
    };
    addThreshold(newThreshold);
    onDismiss();
  }, [name, value, color, pattern, upperRange, lowerRange, isRange, location, addThreshold, onDismiss, fill, opacity, isWindow, startWindow, endWindow, steps, stepsLower, isStepped]);

  const edit = React.useCallback(() => {
    editThreshold(key, {...props.editState.data, name, value, color, pattern, isRange, lowerRange, upperRange, fill, opacity, startWindow, endWindow, steps, stepsLower, isStepped, isWindow});
    props.setThresholdInfo(prev => {
      return {
        ...prev,
        threshold: {
          ...prev.threshold,
          data: {
            ...prev.threshold.data,
            name, value, color, pattern, isRange, lowerRange, upperRange, fill, opacity, startWindow, endWindow, steps, stepsLower, isStepped, isWindow
          }
        }
      };
    });
    onDismiss();
  }, [key, name, value, color, pattern, isRange, lowerRange, upperRange, editThreshold, onDismiss, fill, opacity, startWindow, endWindow, steps, stepsLower, isStepped, isWindow, props.editState]);


  return <Panel type={PanelType.medium} headerText={isEditing ? "Edit Threshold" : "Create Threshold"} isOpen onDismiss={onDismiss} isLightDismiss isBlocking overlayProps={{styles: {root: {background: 'none'}}}}>
    <TextField autoComplete="off" label='Name' value={name} onChange={(e, name) => setName(name)}/>

    <Checkbox 
      className='mt2 mb2' 
      label="Has Date Window" 
      onRenderLabel={(props) => <Label>{props.label}</Label>} checked={isWindow} onChange={(e, checked) => toggleIsWindow(checked)} />
    
    <Checkbox 
      className='mt2 mb2' 
      label="Has Upper and Lower Range" 
      onRenderLabel={(props) => <Label>{props.label}</Label>} checked={isRange} onChange={(e, checked) => toggleIsRange(checked)} />
    
    <Checkbox 
      className='mt2 mb2'
      label='Has Multiple Values'
      onRenderLabel={(props) => <Label>{props.label}</Label>}
      checked={isStepped}
      onChange={(e, checked) => toggleIsStepped(checked)}
    />

    {
      (isRange && !isStepped) ? <>
        <TextField autoComplete="off" label='Upper Range Value' value={upperRange} type="number" onChange={(e, value) => setUpperRange(value)}/>
        <TextField autoComplete="off" label='Lower Range Value' value={lowerRange} type="number" onChange={(e, value) => setLowerRange(value)}/>
      </> : (!isStepped) ? <TextField autoComplete="off" label='Value' value={value} type="number" onChange={(e, value) => setValue(value)}/> : <div>
        <DataGrid steps={steps} stepsLower={stepsLower} setSteps={setSteps} setStepsLower={setStepsLower} isRange={isRange} isWindow={isWindow} startWindow={startWindow} endWindow={endWindow} />
      </div>
    }

    {


      isWindow && <DateRange 
        startCalDay={props.startCalDay}
        onRender={({dateText, onClick}) => {
          return <div onClick={onClick}>
            <Label>Date Range</Label>
            <TextField readOnly value={dateText} />
          </div>
        }}
        hidden={dateRangePickerHidden} 
        toggleDateRangePickerHidden={toggleDateRangePickerHidden} 
        startDay={startWindow} 
        endDay={endWindow} 
        onChange={(startDay, endDay) => {
          setStartWindow(startDay);
          setEndWindow(endDay);
        }} />
    }

    <Dropdown 
      label="Line Style" 
      selectedKey={pattern} 
      onChange={(e, o) => setPattern(o.key)} 
      options={PATTERNS.map(p => ({key: p, text: p}))} />
    
    <Toggle 
      checked={fill}
      disabled={!isRange}
      onChange={(e, filled) => toggleFill(filled)} onText='On' offText='Off' label="Fill" />

    <Slider label='Opacity' value={opacity} step={0.05} min={0.05} max={1} onChange={(value) => setOpacity(value)} />
    
    <Label>Color</Label>
    <div className='hover-box hover-box-dark' style={{marginBottom: 16}}>
      <SwatchesPicker
        color={color} 
        onChange={({hex}) => setColor(hex)} />
    </div>
    <PrimaryButton onClick={isEditing ? edit : add} text={isEditing ? "Edit Threshold" : "Add Threshold"} />
  </Panel>
}

export function DateRangeSlider(props){
  let [start, end] = props.days.filter(each => each.julian === props.startDay || each.julian === props.endDay);
  return <div>
    <Slider 
      label='Date Range'
      showValue={false} 
      min={1} 
      max={365} 
      ranged 
      value={props.endDay} 
      lowerValue={props.startDay} 
      onChange={(e, range) => props.onChange(range[0], range[1])}  />
    <div>{start.date} - {end.date}</div>
  </div>
}

function DataGrid(props){

  const changeCells = changes => {
    props.setSteps(prev => {
      return prev.map(d => {
        let match = changes.find(change => change.rowId === d.dayLookup && change.columnId === 'upper');
        if(match){
          return {...d, value: match.newCell.text};
        }
        return d;
      });
    })
    props.setStepsLower(prev => {
      return prev.map(d => {
        let match = changes.find(change => change.rowId === d.dayLookup && change.columnId === 'lower');
        if(match){
          return {...d, value: match.newCell.text};
        }
        return d;
      });
    })
  }

  // console.log(props);

  const rows = React.useMemo(() => {
    if(props.isRange){
      return [
        {rowId: 'header', cells: [{type: 'header', text: 'Date'}, {type: 'header', text: 'Lower Range'}, {type: 'header', text: 'Upper Range'}]},
        ...ALL_DAYS.map((day, index) => {
          return {
            rowId: day.dayLookup,
            cells: [
              {type: 'text', text: day.date, nonEditable: true},
              {type: 'number', value: parseFloat(props.stepsLower[index].value), hideZero: false},
              {type: 'number', value: parseFloat(props.steps[index].value), hideZero: false}
            ]
          }
        })
      ]
    } else {
      return [
        {rowId: 'header', cells: [{type: 'header', text: 'Date'}, {type: 'header', text: 'Value'}]},
        ...ALL_DAYS.map((day, index) => {
          return {
            rowId: day.dayLookup,
            cells: [
              {type: 'text', text: day.date, nonEditable: true},
              {type: 'number', value: parseFloat(props.steps[index].value), hideZero: false},
            ]
          }
        })
      ]
    }
  }, [props.isRange, props.steps, props.stepsLower]);

  const cols = React.useMemo(() => {
    if(props.isRange){
      return [
        {columnId: 'date', width: 150},
        {columnId: 'lower', width: 150},
        {columnId: 'upper', width: 150}
      ];
    } else {
      return [
        {columnId: 'date', width: 150},
        {columnId: 'upper', width: 150}
      ];
    }
  }, [props.isRange]);

  return <div style={{height: 300, overflow: 'auto'}}>
    <ReactGrid stickyTopRows={1} rows={rows} columns={cols} enableFillHandle onCellsChanged={changeCells} />
  </div>
}