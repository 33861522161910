import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ActionButton, Dialog, DialogFooter, DialogType, FontIcon, Link as Anchor, MessageBar, MessageBarType, PrimaryButton, TextField } from '@fluentui/react';
import AuthContext from './AuthContext';
import LoadingBlocker from './LoadingBlocker';

const emptyProject = {
  project_key: '',
  data: {
    title: '',
    alternatives: [],
    map: {
      markers: [],
      bearing: 0,
      lat: 49.2401,
      lng: -96.3040,
      pitch: 0,
      zoom: 2.87
    }
  },
  public: true,
  access_code: ''
};

function Dashboard(props){

  const [projects, setProjects] = React.useState([]);
  const [projectKey, setProjectKey] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [addProject, showAddProject] = React.useState(false);
  const [newProjectState, setState] = React.useState({...emptyProject})
  const { user, signout } = useContext(AuthContext);
  const [message, setMessage] = React.useState(null);

  React.useEffect(() => {
    if(!addProject) setState({...emptyProject});
  }, [addProject]);

  const getProjects = () => {
    // console.log('get projects')
    // if(!user) return props.history.push('/signin');
    setLoading(true);
    axios.get('/api/projects', {headers: {token: localStorage.getItem('token')}})
    .then(res => {
      // console.log(res);
      if(res.data.success){
        setProjects(res.data.projects)
        setLoading(false);
      } else {
        props.history.push('/signin');
      };
    })
    .catch(err => {
      // console.log(err);
      setLoading(false);
    })
  }

  useEffect(() => {
    getProjects();
  }, []);

  const createProject = () => {
    if(!localStorage.getItem('token')){
      const signInText = () => <div>Please <Link to="/signin"><Anchor>Sign in</Anchor></Link></div>
      return setMessage({type: MessageBarType.error, message: signInText()})
    };
    axios.post(`/api/projects/create`, newProjectState, {headers: {token: localStorage.getItem('token')}})
    .then(response => {
      if(response.data.success){
        props.history.push(`/project/${response.data.project_key}/admin`)
      } else {
        setMessage({type: MessageBarType.error, message: response.data.message});
      }
    })
    .catch(err => {
      // console.log(err);
      setMessage({type: MessageBarType.error, message: "Something went wrong"});
    })
  }

  return <div>
    <nav className='ms-depth-8'>
      <h1 className='logo'>Hydroviz</h1>
      {
        user && <ActionButton 
          text={user.first} 
          menuProps={{
            items: [
              // {key: 'profile', text: 'Profile'}, 
              {key: 'signout', text: 'Sign Out', onClick: () => {
                signout();
                props.history.replace('/');
              }}
            ]
          }} />
      }
    </nav>
    {
      loading ? <LoadingBlocker /> : <div className='dashboard'>
        <h1 style={{marginTop: 96, marginBottom: 32, fontSize: 24, fontWeight: 600}}>Open Project By Key</h1>
        <div className='fr g16'>
        <TextField placeholder='Enter project key' styles={{root: {fontSize: 18, width: 400}}} onChange={(e, v) => setProjectKey(v)} />
        <PrimaryButton disabled={!projectKey} text="Open" onClick={() => props.history.push(`/project/${projectKey}`)} />
        </div>
        <h1 style={{marginTop: 96, marginBottom: 32, fontSize: 24, fontWeight: 600}}>My Projects</h1>
        <div className='grid grid-gap-16 grid-col-4'>
          {
            projects.map(p => {
              return <Link key={p.id} to={`/project/${p.project_key}`} className='hover-label p2 bg-white block'>
                  <p className='project-title'>{p.data.title || 'Unnamed Project'}</p>
                  <p className='project-key'>{p.project_key}</p>
              </Link>
            })
          }
          <div className='hover-label p2 add-project fr aic' onClick={() => showAddProject(true)}>
            <FontIcon iconName='AddTo' />
            <p className='project-title' style={{marginLeft: 16}}>Create Project</p>
          </div>
        </div>
        {/* <div class="ocean">
          <div class="wave"></div>
          <div class="wave"></div>
          <div class="wave"></div>
        </div> */}
      </div>
    } 
    <Dialog hidden={!addProject} dialogContentProps={{title: "Create Project", type: DialogType.close}} onDismiss={() => showAddProject(false)}>
      {
        message && <MessageBar messageBarType={message.type} onDismiss={() => setMessage(null)}>{message.message}</MessageBar>
      }
      <TextField 
        label='Title' 
        value={newProjectState.data.title} 
        onChange={(e, val) => setState(prev => ({...prev, data: {...prev.data, title: val}}))} />

      <TextField 
        label='Project Key'
        description='This is a unique key used to identify your project. It must be all lowercase, and have no spaces.'
        value={newProjectState.project_key}
        onChange={(e, val) => setState(prev => ({...prev, project_key: val.toLowerCase().trim()}))} />

      <DialogFooter>
        <PrimaryButton text="Create Project" disabled={!newProjectState.project_key || !newProjectState.data.title} onClick={createProject} />
      </DialogFooter>
    </Dialog>
  </div>
}

export default Dashboard