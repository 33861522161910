import { Dialog, DialogFooter, DialogType, PrimaryButton, TextField, Dropdown, MessageBar, MessageBarType, IconButton, PersonaSize, Toggle, Link, Persona, ActionButton, Spinner, SpinnerSize, Label, DefaultButton, ScrollablePane, Depths, getTheme, Panel, PanelType, DefaultFontStyles, Stack, Icon } from '@fluentui/react';
import axios from 'axios';
import React from 'react';
import AuthContext from './AuthContext';
import LoadingBlocker from './LoadingBlocker';
import { DEFAULT_COLORS }  from './lib';
import Dropzone from 'react-dropzone';
import MapBuilder from './MapBuilder';
import { SwatchesPicker } from 'react-color';
import CsvEditor from './CSVEditor';
import { Switch, Route, NavLink as Linky, Redirect } from 'react-router-dom';
import { MessageBarStyles } from './SignUp';
import { AltPicker, ChartPicker, LocationPicker, MultiAltPicker, LookupPicker, LOOKUP_KEYS, YearPicker } from './Hydraviz';

function Admin(props){

  const [state, setState] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [addUserHidden, toggleAddUserHidden] = React.useState(true);
  const [addAlt, setAddAlt] = React.useState({key: '', text: '', dataSource: '', color: DEFAULT_COLORS[0], description: '', autofill: false});
  const [addAltHidden, toggleAddAlternative] = React.useState(true);
  const [addUserEmail, setAddUserEmail] = React.useState('');
  const [addUserRole, setAddUserRole] = React.useState(1);
  const [addUserError, setAddUserError] = React.useState('');
  const [editUserError, setEditUserError] = React.useState('');
  const [editUser, setEditUser] = React.useState(null);
  const [editAlt, setEditAlt] = React.useState(null);
  const [uploading, setUploading] = React.useState(false);
  const [uploadMessage, setUploadMessage] = React.useState(null);
  const [flashMessage, setFlashMessage] = React.useState(null);

  const messageBar = React.useMemo(() => {
    if(!state) return null;
    const { access_code } = state;
    if(state.public && !access_code) return {message: "Anyone can access the project. Consider adding an access code to prevent unautorized access.", messageType: MessageBarType.warning};
    if(state.public && access_code) return {message: "Only people with the access code can enter the project.", messageType: MessageBarType.warning};
    if(!state.public) return {message: "Only registered users who have been invited can access the project.", messageType: MessageBarType.warning};
    return null;
  }, [state]);

  const downloadAlt = (alt) => {
    const split = alt.dataSource.split('/');
    const key = split[split.length - 1];
    axios.get(`/api/signedurl/${props.match.params.project_key}/${key}`, {headers: {token: localStorage.getItem('token')}})
    .then(res => {
      if(res.data.success){
        window.open(res.data.url, '_blank');
      }
    })
  };

  const updateAltData = (updatedAlt) => {

    const updateAlts = state.data.alternatives.map(alt => {
      if(alt.key === updatedAlt.key){
        return {...alt, ...updatedAlt}
      }
      return alt;
    })

    rebuildAltLinks(updateAlts);

  }

  const rebuildAltLinks = (alternatives) => {
    axios.all(
      alternatives.map(alt => {
        const split = alt.dataSource.split('/');
        const key = split[split.length - 1];
        return axios.get(`/api/signedurl/${props.match.params.project_key}/${key}`, {headers: {token: localStorage.getItem('token')}});
      })
    )
    .then(results => {
      const altUrls = results.map(({data}) => data.url);
      let altsWithSignedUrls = alternatives.map((alt, i) => ({...alt, dataSource: altUrls[i]}));
      props.updateProjectData({alternatives});
      setState(prev => ({...prev, data: {...prev.data, alternatives}}));
      applyChanges({...state, data: {...state.data, alternatives}});
      props.parseFiles(altsWithSignedUrls);
    })
  }
  
  const onDrop = React.useCallback((acceptedFiles, cb, overrideContentType) => {

    const [file] = acceptedFiles;

    let { name, type } = file;

    setUploading(true);

    name = name.replaceAll(' ', "-");
    name = name.replaceAll('(', "");
    name = name.replaceAll(')', "");
    name = name.replaceAll('+', "-");
    name = name.replaceAll('$', "");
    name = name.replaceAll('?', "");

    axios.put(`/api/upload/${props.match.params.project_key}`, {contentType: overrideContentType || type, name: name.replace(' ', '_'), path: props.match.params.project_key}, {headers: {token: localStorage.getItem('token')}})
    .then(res => {
      if(res.data.success){
        const options = {
          headers: {
            'Content-Type': type
          }
        };
        return axios.put(res.data.url, file, options)
      } else {
        throw Error("Unable to upload")
      }
    })
    .then(res => {
      if(res.status === 200){
        const {url} = res.config;
        const [dataSource] = url.split('?');
        cb && cb(dataSource, null);
        setUploadMessage({messageType: MessageBarType.success, message: "Dataset updated successfully"});
      } else {
        
        cb && cb(null, "Unable to upload file");
        setUploadMessage({messageType: MessageBarType.error, message: "Unable to upload dataset"});
      }
      setUploading(false);
    })
    .catch(err => {
      setUploadMessage({messageType: MessageBarType.error, message: "Unable to upload dataset"});
      cb(null, "Unable to upload file");
      setUploading(false);
    });
  }, []);

  const { user } = React.useContext(AuthContext);

  const getProjectData = () => {
    if(!user) return props.history.push('/signin');
    axios.get(`/api/admin/project/${props.match.params.project_key}`, {headers: {token: localStorage.getItem('token')}})
    .then(response => {
      // console.log({response});
      if(response.data.success){
        setState(response.data.data);
        return axios.get(`/api/projectusers/${response.data.data.id}`)
      } else {
        setLoading(false);
        setErrorMessage(response.data.message);
      }
    })
    .then(response => {
      if(response.data.success) setUsers(response.data.users);
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
      // setErrorMessage('An error occurred');
    })
  }

  React.useEffect(() => {
    getProjectData();
  }, []);

  React.useEffect(() => {
    if(!addUserHidden) setAddUserEmail('');
  }, [addUserHidden]);

  const onAddAlt = () => {

    onDrop([addAlt.file], (dataUrl, err) => {
      if(!dataUrl) return;
      const {text, description, color, autofill} = addAlt;
      const newAlt = {text, key: text, description, color, dataSource: dataUrl, autofill};
      const updated = {
        ...state,
        data: {
          ...state.data,
          alternatives: state.data.alternatives.concat(newAlt)
        }
      };
      applyChanges(updated);
      setState(updated);
      rebuildAltLinks(updated.data.alternatives);
      setAddAlt({key: '', text: '', description: '', color: DEFAULT_COLORS[0], file: null, dataSource: ''});
    });
  }

  const onAddUser = React.useCallback(() => {
    if(users.some(el => el.email === addUserEmail)){
      return setAddUserError("This user is already a member of the project");
    }
    user && axios.post(`/api/project/${props.match.params.project_key}/users/add`, {email: addUserEmail, project_id: state.id, role: addUserRole}, {headers: {token: localStorage.getItem('token')}})
    .then(res => {
      if(res.data.success){
        setUsers(prev => prev.concat(res.data.user));
        toggleAddUserHidden(true);
      } else {
        throw Error(res.data.message)
      }
    })
    .catch(err => {
      setAddUserError(err.message);
    })
  }, [state, addUserEmail, user, addUserRole]);

  const onEditUser = () => {
    if(user?.id === editUser.id) return setEditUserError('Cannot edit your own privilages');
    user && axios.put(`/api/project/${props.match.params.project_key}/users/edit`, {user_id: editUser.id, project_id: state.id, role: editUser.role}, {headers: {token: localStorage.getItem('token')}})
    .then(response => {
      if(response.data.success){
        setUsers(prev => prev.map(u => u.id === editUser.id ? {...u, ...editUser} : u));
        setEditUser(null);
      }
    })
    .catch(err => {
    })
  }

  const applyChanges = React.useCallback((updated) => {
    // console.log(updated); 
    axios.put(`/api/project/${props.match.params.project_key}/${updated.id}/update`, {...updated}, {headers: {token: localStorage.getItem('token')}})
    .then(res => {
      if(!res.data.success){
        setErrorMessage(res.data.message);
      }
      setFlashMessage("Changes saved");
      // alert("Changes saved");
    })
    .catch(err => {
      setErrorMessage()
    })
  }, []);


  const deleteAlternative = (key) => {
    if(!key) return;
    const updated = {
      ...state, 
      data: {
        ...state.data, 
        alternatives: state.data.alternatives.filter(alt => {
          return alt.key !== key;
        })
      }
    };
    setState(updated);
    setEditAlt(null);
    applyChanges(updated);
    props.updateProjectData({alternatives: updated.data.alternatives});
  }

  const applyAltChanges = () => {
    if(!editAlt) return;
    const updated = {
      ...state, 
      data: {
        ...state.data, 
        alternatives: state.data.alternatives.map(alt => {
          if(alt.key === editAlt.key) {
            return {
              ...editAlt,
              key: editAlt.text
            };
          }
          return alt
        })
      }
    };
    setState(updated);
    applyChanges(updated);
    rebuildAltLinks(updated.data.alternatives);
    setEditAlt(null);
  };

  const editMap = (changes) => {
    const updated = {...state, data: {...state.data, map: {...state.data.map, ...changes}}};
    applyChanges(updated);
    props.updateProjectData({map: updated.data.map});
  }

  function arraymove(arr, fromIndex, toIndex) {
    var element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
    props.updateProjectData({alternatives: arr});
}

  const moveDown = (index) => {
    // console.log('move this down: ', index);
    arraymove(state.data.alternatives, index, index + 1);
  }

  const moveUp = (index) => {
    // console.log('move this up: ', index);
    arraymove(state.data.alternatives, index, index - 1);
  }

  const editAltNameErrorMessage = React.useMemo(() => {
    if(!state || !editAlt) return '';
    // console.log(editAlt);
    if(state.data.alternatives.find(el => el.text === editAlt.text && el.key !== editAlt.key)) return 'That name already exists';
    return '';
  }, [state, editAlt]);

  const editAlternative = (key, changes, parse) => {

    const updated = {
      ...state, 
      data: {
        ...state.data, 
        alternatives: state.data.alternatives.map(alt => {
          if(alt.key === key) return {...alt, ...changes};
          return alt;
        })
      }
    };
    setState(updated);
    setEditAlt(null);
    applyChanges(updated);

    if(parse)props.parseFiles(updated.data.alternatives);

    props.updateProjectData({alternatives: updated.data.alternatives});

  }

  const onDropAddAlt = (acceptedFiles) => {
    setAddAlt(prev => ({...prev, file: acceptedFiles[0]}));
  }

  const keyPicked = React.useMemo(() => {
    if(!state?.data?.defaultChart?.lookupKey) return null;

    let found = LOOKUP_KEYS.concat([{key: 'pickYear', text: 'Pick Year'}]).find(k => k.key === state.data?.defaultChart?.lookupKey);
    return found.text;

  }, [state?.data?.defaultChart?.lookupKey]);

  if(loading) return <LoadingBlocker />

  return <div style={{display: 'flex', flexDirection: 'row', height: 'calc(100vh - 48px)', marginTop: 48}}>
    <div style={{flex: 2, padding: 16}}>
      <Stack tokens={{childrenGap: 8}}>
        <Linky className='hover-label' to={`${props.match.url}/details`}>Details</Linky>
        <Linky className='hover-label' to={`${props.match.url}/default-chart`}>Default Chart</Linky>
        <Linky className='hover-label' to={`${props.match.url}/alternatives`}>Alternatives</Linky>
        <Linky className='hover-label' to={`${props.match.url}/members`}>Members</Linky>
        <Linky className='hover-label' to={`${props.match.url}/data-editor`}>Data Editor</Linky>
        <Linky className='hover-label' to={`${props.match.url}/map`}>Map Builder</Linky>
      </Stack>
    </div>
    <div style={{flex: 10, overflow: 'auto'}}>
      <Switch>
        <Route 
          path={`${props.match.url}/details`}
          render={() => {
          return <div style={{height: '100%', overflowY: 'auto', margin: '0px 32px', maxWidth: 600}}>
          <h1 className='page-header'>Project Details</h1>
          {messageBar && <MessageBar styles={{...MessageBarStyles, root: {...MessageBarStyles.root, border: `1px solid ${getTheme().palette.yellow}`}}} messageBarType={messageBar.messageType}>{messageBar.message}</MessageBar>}
          <div style={{display: 'flex', gap: 12}}>
            <div style={{flex: 1}}>
              <TextField 
                className='mb3'
                label='Title'
                value={state.data.title}
                onChange={(e, val) => setState(prev => ({...prev, data: {...prev.data, title: val}}))} />
              <TextField
                label="Key"
                disabled
                onRenderDescription={() => <div className='ms-fontSize-small'>Your project will be accessible at <Link href={`https://hydroviz.ca/${state.project_key}`}>https://hydroviz.ca/project/{state.project_key}</Link> </div>}
                className='mb3'
                value={state.project_key}
                readOnly />
              <Toggle 
                onChange={(e, checked) => setState(prev => ({...prev, public: checked}))}
                checked={state.public} 
                label="Public" />
              <TextField
                label="Access Code"
                description='All visitors must enter this code to access the project'
                className='mb3'
                value={state.access_code}
                onChange={(e, val) => setState(prev => ({...prev, access_code: val}))} />
              <Toggle 
                label="Show Map Survey"
                className='mb3'
                checked={state.data.mapSurveyEnabled}
                onChange={(e, checked) => setState(prev => ({...prev, data: {...prev.data, mapSurveyEnabled: checked}}))} />
              <Toggle 
                label="Only Show Map Survey"
                className='mb3'
                checked={state.data.onlyShowMapSurvey}
                onChange={(e, checked) => setState(prev => ({...prev, data: {...prev.data, onlyShowMapSurvey: checked}}))} />
            </div>


          </div>
          <p style={{fontSize: 10, color: "#605e5c"}}>All other navigation controls will be hidden.</p>

          <PrimaryButton styles={{root: {marginTop: 32}}} text="Save Changes" onClick={() => applyChanges(state)} />
          </div>

        }} />

        <Route path={`${props.match.url}/default-chart`} render={() => {
          return <div style={{height: '100%', overflowY: 'auto', margin: '0px 32px', maxWidth: 600}}>
            <h1 className='page-header'>Project Details</h1>
            <Toggle 
              checked={state.data?.enableDefaultChart} 
              label="Default Chart"
              onChange={(e, checked) => setState(prev => ({...prev, data: {...prev.data, enableDefaultChart: checked}}))} />
            <ChartPicker 
              chartType={state.data?.defaultChart?.chartType}
              disable={["Period of Record", "Multiple Locations", "Continuous Time Series", "Multiple Dimension"]}
              onChange={(chartType) => setState(prev => ({...prev, data: {...prev.data, defaultChart: {...prev.data.defaultChart, chartType}}}))} />
            <LocationPicker 
              disableMap 
              location={state.data?.defaultChart?.location} 
              locations={props.locations} 
              onChange={(location) => setState(prev => ({...prev, data: {...prev.data, defaultChart: {...prev.data.defaultChart, location}}}))} />

            {state.data?.defaultChart?.chartType === 'Multiple Alternatives' && (
              <>
                <MultiAltPicker 
                  alternatives={state.data?.alternatives}
                  hideShowAll 
                  selectedAlts={state.data?.defaultChart?.selectedAlts || []}
                  changeFilters={(data) => setState(prev => ({...prev, data: {...prev.data, defaultChart: {...prev.data.defaultChart, ...data}}}))} />
                <LookupPicker 
                  lookupKey={keyPicked} 
                  onChange={(key) => {
                    if(key === 'pickYear' && props.YEARS.length){
                      setState(prev => ({...prev, data: {...prev.data, defaultChart: {...prev.data.defaultChart, lookupKey: key, pickYear: props.YEARS[props.YEARS.length - 1]}}}));
                    } else {
                      setState(prev => ({...prev, data: {...prev.data, defaultChart: {...prev.data.defaultChart, lookupKey: key}}}))
                    }
                  }} />
              </>
            )
            }
            {
              state.data?.defaultChart?.chartType === 'Single Alternative' && (
                <AltPicker 
                  alternatives={state.data?.alternatives} 
                  alternative={state.data?.defaultChart?.selectedAlt} 
                  onChange={(selectedAlt) => setState(prev => ({...prev, data: {...prev.data, defaultChart: {...prev.data.defaultChart, selectedAlt}}}))} />
                )
            }
            {
              
              keyPicked === 'Pick Year' && 
                <YearPicker
                  YEARS={props.YEARS} 
                  year={state.data?.defaultChart?.pickYear} 
                  onChange={(y) => setState(prev => ({...prev, data: {...prev.data, defaultChart: {...prev.data.defaultChart, pickYear: y}}}))} />
            }
            <PrimaryButton styles={{root: {marginTop: 32}}} text="Save Changes" onClick={() => applyChanges(state)} />
          </div>
        }} />

        <Route 
          path={`${props.match.url}/members`}
          render={() => {
            return <div style={{height: '100%', overflowY: 'auto', margin: '0px 32px', maxWidth: 600}}>
            <h1 className='page-header'>Members</h1>
            <div style={{marginBottom: 32}}>
              {
                users.map(u => {
                  return <div key={u.id} style={{display: 'flex', alignItems: 'center', padding: 8, background: '#fff', border: `1px solid ${getTheme().palette.neutralTertiaryAlt}`, borderRadius: 4, marginBottom: 8}}>
                    <Persona 
                      text={u.first + ' ' + u.last}
                      styles={{root: {marginBottom: 0}}}
                      size={PersonaSize.size24}
                      secondaryText={u.role === 1 ? "Admin" : u.role === 2 ? "Collaborator" : "Read Only"} />
                    <IconButton 
                      iconProps={{iconName: 'Edit'}}
                      styles={{root: {background: 'none !important'}}} 
                      disabled={u.id === user?.id} 
                      onClick={() => setEditUser(u)}  />
                  </div>
                })
              }
            </div>
            <PrimaryButton text="Add Member" onClick={() => toggleAddUserHidden(false)} />
          </div>
        }} />


        <Route path={`${props.match.url}/alternatives`} render={() => {

          return <div style={{height: '100%', overflowY: 'auto', margin: '0px 32px', maxWidth: 600}}>
            <h1 className='page-header'>Alternatives</h1>
            <>
              {
                state.data.alternatives.map((alt, index) => {
                  return <div key={`key-${index}`} style={{display: 'flex', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <IconButton iconProps={{iconName: "ChevronUpMed"}} onClick={() => moveUp(index)} disabled={index === 0} styles={{root: {fontSize: 10, height: 'auto', background: 'none !important'}}} />
                      <IconButton iconProps={{iconName: "ChevronDownMed"}} onClick={() => moveDown(index)} disabled={index === state.data.alternatives.length - 1} styles={{root: {fontSize: 10, height: 'auto', background: 'none !important'}}} />
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', padding: 8, marginLeft: 16, background: '#fff', borderRadius: 4, border: `1px solid ${getTheme().palette.neutralTertiaryAlt}`, marginBottom: 8, justifyContent: 'space-between'}}>
                      {/* <Persona text={alt.text} initialsColor={alt.color || DEFAULT_COLORS[index]} size={PersonaSize.size24} /> */}
                      <div style={{display: 'flex', alignItems: "center"}}>
                        <Icon iconName='ToggleFilled' styles={{root: {color: alt.color}}} style={{marginRight: 8}} />
                        <span>{alt.text}</span>
                      </div>
                      <div>
                        <ActionButton text="Download" iconProps={{iconName: 'CloudDownload'}} onClick={() => downloadAlt(alt)}/>
                        <ActionButton text="Visibility" iconProps={{iconName: alt.hidden ? 'Hide3' : 'CheckMark'}} onClick={() => editAlternative(alt.key, {hidden: !alt.hidden}, alt.hidden ? true : false)} />
                        <ActionButton text="Edit" iconProps={{iconName: "Edit"}} onClick={() => setEditAlt(alt)} />
                      </div>
                    </div>
                  </div>
                })
              }
            </>
            <div style={{ marginTop: '2rem'}}>
              
              <PrimaryButton 
                text="Add Alternative" 
                onClick={() => {
                  setAddAlt({key: '', text: '', file: null, color: DEFAULT_COLORS[0], description: '', autofill: false})
                  toggleAddAlternative(false);
                }} />
            </div>
          </div>
        }}/>
        

        <Route 
          path={`${props.match.url}/data-editor`} 
          render={(props) => {
            return <div style={{height: '100%', maxHeight: '100%', display: 'flex', flexDirection: "column", maxWidth: '100%', margin: '0px 32px'}}>
              <h1 className='page-header'>Data Editor</h1>
              <div style={{flex: 1}}>
                <CsvEditor files={state.data.alternatives} onDrop={onDrop} updateAltData={updateAltData} />
              </div>
            </div>
          }} />

        <Route 
          path={`${props.match.url}/map`} 
          render={() => {
            return <>
            <MapBuilder {...state.data.map} onDrop={onDrop} style={{height: '100%', width: "100%"}} locations={props.locations} editMap={(updates) => editMap(updates)} />
          </>
          }} />
        <Redirect to={`${props.match.url}/details`} />
      </Switch>

    </div>
    <FlashMessage message={flashMessage} onDismiss={() => setFlashMessage(null)} />

    {errorMessage && <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setErrorMessage('')}>{errorMessage}</MessageBar>}    
    

    <Dialog hidden={addUserHidden} dialogContentProps={{title: 'Add User', onDismiss: () => toggleAddUserHidden(true), type: DialogType.close}}>
      {addUserError && <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setAddUserError('')}>{addUserError}</MessageBar>}
      <TextField 
        label="Email" 
        type='email' 
        autoComplete='off'
        value={addUserEmail} 
        onChange={(e, val) => setAddUserEmail(val)} />
      <Dropdown 
        label="Role"
        selectedKey={addUserRole}
        onChange={(e, o) => setAddUserRole(o.key)} 
        options={[{key: 1, text: "Admin"}, {key: 2, text: "Collaborator"}, {key: 3, text: "Read Only"}]} />
      <DialogFooter>
        <PrimaryButton text="Submit" onClick={onAddUser}/>
      </DialogFooter>
    </Dialog>

    <Panel isOpen={!addAltHidden} headerText="Add Alternative" type={PanelType.medium} isLightDismiss onDismiss={() => toggleAddAlternative(true)}>
      {
        uploading && <div className='fr aic jcc'>
          <Spinner size={SpinnerSize.medium} label="Uploading dataset..." />
        </div>
      }
      {
        uploadMessage && <MessageBar messageBarType={uploadMessage.messageType} onDismiss={() => setUploadMessage(null)}>{uploadMessage.message}</MessageBar>
      }
      {/* {addUserError && <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setAddUserError('')}>{addUserError}</MessageBar>} */}
      {/* <div style={{...DefaultFontStyles.xLarge, marginBottom: 16}}>Add New Alternative</div> */}
      <TextField 
        label="Name" 
        autoComplete='off'
        onGetErrorMessage={(val) => {
          return val ? state.data.alternatives.some(alt => alt.text === val) ? "Alternative names must be unique." : "" : ""
        }}
        value={addAlt.text} 
        onChange={(e, val) => setAddAlt(prev => ({...prev, text: val}))} />
      <TextField 
        label="Description"
        autoComplete='off'
        value={addAlt.description} 
        multiline
        rows={4}
        onChange={(e, val) => setAddAlt(prev => ({...prev, description: val}))} />
      <TextField
        label='Data Source'
        value={addAlt?.file ? addAlt.file.name : ''}
        readOnly
        onRenderSuffix={() => {
          return <Dropzone accept=".csv, application/vnd.ms-excel, text/csv" maxFiles={1} onDrop={onDropAddAlt}>
            {({getInputProps, getRootProps, isDragActive}) => {
              return <div {...getRootProps()}>
                <input {...getInputProps()} />
                <IconButton styles={{root: {background: 'none !important'}}} iconProps={{iconName: 'CloudUpload'}} />
              </div>
            }}
          </Dropzone>
        }} />
      <Label>Trace Color</Label>
        <div style={{paddingTop: 8, border: `1px solid ${getTheme().palette.neutralTertiary}`, borderRadius: 4}}>
          <SwatchesPicker 
              height={360}
              color={addAlt?.color} 
              onChange={(col) => {
                setAddAlt(prev => ({...prev, color: col.hex}))
              }} />
        </div>
      <Toggle checked={addAlt.autofill} label="Auto Fill" onChange={(e, checked) => setAddAlt(prev => ({...prev, autofill: checked}))}/>
      <p>Auto fills missing data to the most recently found value.</p>
      <DialogFooter>
        <PrimaryButton disabled={!addAlt.file || !addAlt.text || state.data.alternatives.some(alt => alt.text === addAlt.text)} text="Submit" onClick={onAddAlt}/>
      </DialogFooter>
    </Panel>

    <Dialog hidden={!editUser} dialogContentProps={{title: 'Edit User', onDismiss: () => setEditUser(null), type: DialogType.close}}>
      {editUserError && <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setEditUserError('')}>{editUserError}</MessageBar>}
      <TextField 
        label="Email" 
        type='email' 
        value={editUser?.email} 
        readOnly
        disabled />
      <Dropdown 
        label="Role"
        selectedKey={editUser?.role}
        onChange={(e, o) => setEditUser(prev => ({...prev, role: o.key}))} 
        options={[{key: 1, text: "Admin"}, {key: 2, text: "Collaborator"}, {key: 3, text: "Read Only"}]} />
      <DialogFooter>
        <PrimaryButton text="Submit" onClick={onEditUser}/>
      </DialogFooter>
    </Dialog>

    <Panel isOpen={editAlt} headerText='Edit Alternative' type={PanelType.medium} onDismiss={() => setEditAlt(null)}>
      {
        uploading && <div className='fr aic jcc'>
          <Spinner size={SpinnerSize.medium} label="Uploading dataset..." />
        </div>
      }
      {
        uploadMessage && <MessageBar messageBarType={uploadMessage.messageType} onDismiss={() => setUploadMessage(null)}>{uploadMessage.message}</MessageBar>
      }
      <TextField
        label='Title'
        className='mb3'
        value={editAlt?.text}
        errorMessage={editAltNameErrorMessage}
        onChange={(e, val) => setEditAlt(prev => ({...prev, text: val}))} />
      <TextField 
        label="Description"
        autoComplete='off'
        value={editAlt?.description} 
        multiline
        rows={4}
        className='mb3'
        onChange={(e, val) => setEditAlt(prev => ({...prev, description: val}))} />
      <TextField
        label='Data Source'
        className='mb3'
        value={editAlt?.dataSource ? editAlt.dataSource.split('/')[editAlt.dataSource.split('/').length - 1] : ''}
        readOnly
        onRenderSuffix={() => {
          return <Dropzone disabled={uploading} accept=".csv, application/vnd.ms-excel, text/csv" maxFiles={1} onDrop={(acceptedFiles) => onDrop(acceptedFiles, (url, err) => url && setEditAlt(prev => ({...prev, dataSource: url})))}>
            {({getInputProps, getRootProps, isDragActive}) => {
              return <div {...getRootProps()}>
                <input {...getInputProps()} />
                <IconButton styles={{root: {background: 'none !important'}}} iconProps={{iconName: 'CloudUpload'}} />
              </div>
            }}
          </Dropzone>
        }}  
        onChange={(e, val) => setEditAlt(prev => ({...prev, dataSource: val}))} />
      <Label>Trace Color</Label>
      <div style={{paddingTop: 8, border: `1px solid ${getTheme().palette.neutralTertiary}`, borderRadius: 4, marginBottom: 16}}>
        <SwatchesPicker 
          color={editAlt?.color} 
          height={'360'}
          onChange={(col) => setEditAlt(prev => ({...prev, color: col.hex}))} />
        {/* {
          DEFAULT_COLORS.map(color => {
            if(color === editAlt?.color){
              return <FontIcon key={color} style={{color, fontSize: 28, margin: 2, cursor: 'pointer'}} iconName={"SkypeCircleCheck"} />
            }
            return <FontIcon key={color} onClick={() => setEditAlt(prev => ({...prev, color}))} style={{color, fontSize: 28, margin: 2, cursor: 'pointer'}} iconName={"CircleFill"} />
          })
        } */}
      </div>
      <Toggle checked={editAlt?.autofill} label="Auto Fill" onChange={(e, checked) => setEditAlt(prev => ({...prev, autofill: checked}))}/>
      <p>Auto fills missing data to the most recently found value.</p>
      <div style={{display: 'flex', gap: 12, justifyContent: 'flex-end'}}>
        <DefaultButton text="Delete" onClick={() => deleteAlternative(editAlt?.key)} />
        <PrimaryButton disabled={editAltNameErrorMessage} text="Apply Changes" onClick={applyAltChanges} />
      </div>
    </Panel>
  </div>
}

export default Admin;

function FlashMessage({message='', timeout=3000, onDismiss}){
  React.useEffect(() => {
    const wait = setTimeout(() => {
      onDismiss();
    }, timeout);
    return () => clearTimeout(wait);
  }, [message]);

  if(message){
    return <div 
      className='ms-depth-32 ms-motion-fadeIn' 
      style={{padding: 24, position: 'fixed', top: '24px', right: '24px', background: '#fff', zIndex: 999999, boxShadow: Depths.depth64, borderRadius: 4, border: `1px solid ${getTheme().palette.neutralSecondary}`}}>{message}</div>
  }
  return null;
}