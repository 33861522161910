import React from 'react';

export default function LegendTrace(props){
  switch(props.pattern){
    case 'solid':
      // return <div style={{width: '100%', height: 2, background: props.color}}></div>
      return <svg height='3px' width="100%" style={props.style}>
        <line x1="0" y1="0" x2="100%" y2="0" stroke={props.color} strokeWidth="100%"/>
      </svg>
    case 'dash':
      return <svg height='3px' width="100%" style={props.style}>
      <line x1="0" y1="0" x2="100%" y2="0" stroke={props.color} strokeWidth="100%" strokeDasharray="9"/>
    </svg>
    case 'longdash':
      return <svg height='3px' width="100%" style={props.style}>
      <line x1="0" y1="0" x2="100%" y2="0" stroke={props.color} strokeWidth="100%" strokeDasharray="18"/>
    </svg>
    case 'dot':
      return <svg height='3px' width="100%" style={props.style}>
      <line x1="0" y1="0" x2="100%" y2="0" stroke={props.color} strokeWidth="100%" strokeDasharray="3"/>
    </svg>
    case 'dashdot':
      return <svg height='3px' width="100%" style={props.style}>
      <line x1="0" y1="0" x2="100%" y2="0" stroke={props.color} strokeWidth="100%" strokeDasharray="9 4 3 4"/>
    </svg>
    case 'longdashdot':
      return <svg height='3px' width="100%" style={props.style}>
        <line x1="0" y1="0" x2="100%" y2="0" stroke={props.color} strokeWidth="100%" strokeDasharray="18 4 3 4"/>
      </svg>
    default: return null;
  }
}