import React from 'react';
import { LOOKUP_KEYS, ChartPicker, MultiAltPicker, YearPicker, LocationPicker, ThresholdPicker, Plot, LookupPicker, labelStyle, ThresholdSummary } from './Hydraviz';
import Plotly from 'plotly.js';
import DateRange from './DateRange';
import { processShapes, resolveWindow } from './SingleAlternative';
import { trimSeries } from './lib/traces';
import { ActionButton, Icon, Label, Spinner, TextField } from '@fluentui/react';
import AuthContext from './AuthContext';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import Help from './Help';

export default function MultiAlt(props){

  const { ALL_DAYS, startCalDay, alternatives=[], YEARS=[], editThreshold, addThreshold, globalLayoutSettings, thresholdInfo, startDay=1, endDay=365, lookupKey='pickYear', location='', locations=[], readOnly=false } = props;

  const [dateRangePickerHidden, toggleDateRangePickerHidden] = React.useState(true);

  
  const keyPicked = React.useMemo(() => {
    let found = LOOKUP_KEYS.concat([{key: 'pickYear', text: 'Pick Year'}]).find(k => k.key === props.lookupKey);
    return found.text;
  }, [lookupKey]);

  const [thresholds, setThresholds] = React.useState([]);

  React.useEffect(() =>  setThresholds(props.thresholds || []), [props.thresholds]);

  const measure = React.useMemo(() => {
    if(locations.length){
      const measure = locations.find(el => el.key === props.location);
      return measure ? measure.measure : "No Measure Found";
    }
  }, [locations, location]);

  const FILTERED_CAL = React.useMemo(() => ALL_DAYS.filter((d, index) => {
    let startIndex = ALL_DAYS.findIndex(d => d.julian === props.startDay);
    let endIndex = ALL_DAYS.findIndex(d => d.julian === props.endDay);
    return index >= Math.min(startIndex, endIndex) && index <= Math.max(startIndex, endIndex);
  }), [endDay, startDay, ALL_DAYS]);

  const chartData = React.useMemo(() => {

    const x = FILTERED_CAL.map((d) => `${d.calDate}`);
    
    let traces = [];
    
    props.master.forEach(trace => {
      if(!props.selectedAlts.includes(trace.alternative)) return;

      let dataSet = trace.data.find(entry => entry.location === props.location);

      if(!dataSet) return;

      let y = [];
      if(props.lookupKey === 'pickYear'){
        
        if(!dataSet.annualData[props.pickYear]) return;

        y = trimSeries(FILTERED_CAL, dataSet.annualData[props.pickYear]);

        // y = dataSet.annualData[props.pickYear].filter((v, i) => i + 1 >= props.startDay && i + 1 <= props.endDay).map(({value}) => value);

      } else {
        y = FILTERED_CAL.map(d => dataSet.stats[d.julian][props.lookupKey]);
      } 
      
      
      traces.push({
        key: trace.alternative,
        traceType: 'scenario',
        name: trace.alternative,
        line: {
          color: dataSet.color,
        },
        type: 'scatter',
        mode: 'lines',
        hoverinfo: 'text',
        x,
        y,
        text: FILTERED_CAL.map((day, index) => `${trace.alternative}, ${day.date}<br>${y[index]}`)
      })
    });

    if(traces.length === 0){
      return []
    }
    
    let startIndex = FILTERED_CAL[0].dayLookup;
    let endIndex = FILTERED_CAL[FILTERED_CAL.length - 1].dayLookup ;

    thresholds.forEach(threshold => {

      let { isWindow, isRange, isStepped, steps, name, visible, color, fill, pattern, stepsLower, upperRange, lowerRange, value } = threshold.data;

      let y = [];
      let text = [];
      let x = [];

      if(isWindow){
        x = resolveWindow(FILTERED_CAL, threshold.data).map(d => d.calDate);
      } else {
        x = FILTERED_CAL.map((d) => d.calDate);
      }

      let firstHalfSteps = [].concat(...steps.filter(el => el.julian >= FILTERED_CAL[0].julian));
      let secondHalfSteps = [].concat(...steps.filter(el => el.julian < FILTERED_CAL[0].julian));
      let firstHalfStepsLower = [].concat(...stepsLower.filter(el => el.julian >= FILTERED_CAL[0].julian));
      let secondHalfStepsLower = [].concat(...stepsLower.filter(el => el.julian < FILTERED_CAL[0].julian));
      steps = [...firstHalfSteps, ...secondHalfSteps];
      stepsLower = [...firstHalfStepsLower, ...secondHalfStepsLower];
      
      if(isRange){
        if(isStepped){
          // upper first
          y = steps.slice(startIndex - 1, endIndex).map(({value}) => parseFloat(value));

          traces.push({
            key: `${threshold.id}-up`,
            name: `${name} Upper`,
            visible: visible,
            hoverinfo: 'name',
            x,
            y,
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })

          //  now lower

          y = stepsLower.slice(startIndex - 1, endIndex).map(({value}) => parseFloat(value));

          traces.push({
            key: `${threshold.id}-low`,
            name: `${name} - Lower`,
            visible: visible,
            hoverinfo: 'name',
            x,
            y,
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          })

          return;

        } else {
          //  upper first
          y = x.map((d) => upperRange);
          text = x.map((d) => `${name}, ${x[0]} - ${x[x.length - 1]}<br>${lowerRange} - ${upperRange} ${measure}`);
  
          traces.push({
            key: threshold.id,
            name: `${name} <br> ${lowerRange} - ${upperRange}`,
            visible: visible,
            x,
            y,
            text,
            hoverinfo: 'text',
            showlegend: false,
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          });
  
          // then lower
          y = x.map((d) => lowerRange);
          text = x.map((d) => `${name}, ${x[0]} - ${x[x.length - 1]}<br>${lowerRange} - ${upperRange} ${measure}`);
          traces.push({
            key: threshold.id,
            name: `${name}`,
            visible: visible,
            x,
            y,
            fill: fill ? 'tonexty' : false,
            text,
            hoverinfo: 'text',
            mode: 'lines',
            line: {
              color: color,
              width: fill ? 0 : 2,
              dash: pattern
            }
          });
          return;
        }
      } else {
        if(isStepped){

          y = steps.filter(({dayLookup}) => dayLookup >= startIndex && dayLookup <= endIndex).map(({value}) => parseFloat(value));
          text = steps.map(({date, value}) => `${name}, ${date}<br>${value} ${measure}`);

        } else {

          y = x.map((d) => value);
          text = x.map((d) => `${name}, ${d}<br>${value} ${measure}`);

        }
      }
      
      traces.push({
        key: threshold.id,
        name: name,
        visible: visible,
        x,
        y,
        text,
        hoverinfo: 'text',
        mode: 'lines',
        line: {
          color: color,
          width: 2,
          dash: pattern
        }
      })
    });

    return traces
  }, [props, thresholds, measure, ALL_DAYS, FILTERED_CAL]);

  const scenarios = React.useMemo(() => {
    return chartData.filter(d => d.traceType === 'scenario');
  }, [chartData]);

  const shapeThresholds = React.useMemo(() => {
    return thresholds.filter(el => el.isWindow && el.isRange);
  }, [thresholds]);

  const tickformat = React.useMemo(() => {
    let numberOfDays = props.endDay - props.startDay;
    // console.log(numberOfDays);
    if(numberOfDays > 200){
      return '%b';
    }
    return '%b %d'
  }, [props.startDay, props.endDay]);

  const [layout, setLayout] = React.useState({
    autosize: true,
    showlegend: false,
    hovermode: 'closest',
    plot_bgcolor: '#fff',
    paper_bgcolor: '#fff',
    // shapes,
    title: {
      text: `${keyPicked === 'Pick Year' ? keyPicked + ' ' + props.pickYear : keyPicked} - ${props.location}<br>${ALL_DAYS[props.startDay-1].date} - ${ALL_DAYS[props.endDay-1].date}`,
    },
    margin: props.plotMargin || {b: 80, l: 80, pad: 0, r: 80, t: 100, autoexpand: true},
    legend: {orientation: 'v'},
    yaxis: {automargin: true, title: measure, autorange: true, linewidth: 1, linecolor: '#333', ticklen: 5},
    xaxis: {automargin: true, tickformat, nticks: 12, linewidth: 1, linecolor: '#333', ticklen: 5},
    font: {
      family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
      size: globalLayoutSettings.fontSize
    }
  });

  React.useEffect(() => {
    setLayout(prev => ({
      ...prev,
      autosize: true,
      showlegend: false,
      hovermode: 'closest',
      plot_bgcolor: '#fff',
      paper_bgcolor: '#fff',
      // shapes,
      title: {
        ...prev.title,
        text: `${keyPicked === 'Pick Year' ? keyPicked + ' ' + props.pickYear : keyPicked} - ${props.location}<br>${ALL_DAYS[props.startDay-1].date} - ${ALL_DAYS[props.endDay-1].date}`,
      },
      margin: props.plotMargin || {b: 80, l: 80, pad: 0, r: 80, t: 100, autoexpand: true},
      legend: {orientation: 'v'},
      yaxis: {
        ...prev.yaxis,
        automargin: true, title: measure
      },
      xaxis: {
        ...prev.xaxis,
        automargin: true,
        tickformat
      },
      font: {
        ...prev.font,
        family: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        // 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue'`,
        size: globalLayoutSettings.fontSize
      }
    }))
  }, [props.startDay, props.endDay, keyPicked, props.pickYear, measure, props.location, ALL_DAYS, props.plotMargin, globalLayoutSettings, tickformat]);

  // console.log(layout.xaxis);

  React.useEffect(() => {
    const shapes = processShapes(shapeThresholds, ALL_DAYS);
    setLayout(prev => ({...prev, shapes}));
  }, [shapeThresholds, ALL_DAYS]);

  const onScreenCap = React.useCallback((gd, e) => {
    Plotly.relayout(gd, {showlegend: true});
    Plotly.downloadImage(gd, {format: 'svg'});
    Plotly.relayout(gd, {showlegend: false});
  }, []);

  const onDoubleClick = () => props.changeFilters({startDay: FILTERED_CAL[0].julian, endDay: FILTERED_CAL[FILTERED_CAL.length - 1].julian});

  const chartConfig = {
    modeBarButtonsToRemove: ['toImage'],
    displaylogo: false,
    modeBarButtonsToAdd: [{
      name: 'Capture',
      click: onScreenCap, 
      icon: {
        width: 1000,
        height: 1000,
        path: 'm500 450c-83 0-150-67-150-150 0-83 67-150 150-150 83 0 150 67 150 150 0 83-67 150-150 150z m400 150h-120c-16 0-34 13-39 29l-31 93c-6 15-23 28-40 28h-340c-16 0-34-13-39-28l-31-94c-6-15-23-28-40-28h-120c-55 0-100-45-100-100v-450c0-55 45-100 100-100h800c55 0 100 45 100 100v450c0 55-45 100-100 100z m-400-550c-138 0-250 112-250 250 0 138 112 250 250 250 138 0 250-112 250-250 0-138-112-250-250-250z m365 380c-19 0-35 16-35 35 0 19 16 35 35 35 19 0 35-16 35-35 0-19-16-35-35-35z',
        transform: 'matrix(1 0 0 -1 0 850)'
      }}]
  };

  const onUpdate = (fig) => {
    props.onChartDataUpdate && props.onChartDataUpdate(fig);
  };

  const onRelayout = data => {


    if(data){

      if(data['xaxis.autorange']){
        return props.changeFilters({startDay: 1, endDay: 365});
      }


      let start = data['xaxis.range[0]'];
      let end = data['xaxis.range[1]'];

      if(start) start = start.split(" ")[0];
      if(end) end = end.split(" ")[0];

      let startDayFromResize = ALL_DAYS.find(el => el.calDate === start)?.julian;
      let endDayFromResize = ALL_DAYS.find(el => el.calDate === end)?.julian;

      if(startDayFromResize && endDayFromResize){
        return props.changeFilters({startDay: startDayFromResize, endDay: endDayFromResize});
      }
    }
    setLayout(prev => ({...prev, ...data}));
  }
  
  const chartStyle = {width: '100%', height: '100%', background: '#fff'};

  const chartPickerRef = React.useRef(null);
  const locationPickerRef = React.useRef(null);
  const lookupPickerRef = React.useRef(null);
  const dateRangeRef = React.useRef(null);
  const yAxisRef = React.useRef(null);
  const multiAltRef = React.useRef(null);
  const thresholdRef = React.useRef(null);

  const helpItems = [chartPickerRef, locationPickerRef, lookupPickerRef, dateRangeRef, yAxisRef, multiAltRef, thresholdRef];

  return <div style={{display: 'flex', flex: 1, background: '#fff', padding: 8, height: 'calc(100% - 33px)', maxHeight: 'calc(100% - 33px)', maxWidth: '100%'}}>
    <Help show={props.showHelp} lookupKey={keyPicked} items={helpItems} chartType={"Multiple Alternatives"} onDismiss={props.onCloseHelp} />
    <div style={{width: '15%', display: props.thresholdInfo || readOnly ? 'none' : 'block'}}>
      <div style={{marginBottom: 32}} ref={chartPickerRef} id="chart-picker">
        <ChartPicker chartType={props.chartType} onChange={(chartType) => props.changeFilters({chartType})} />
      </div>
      <div style={{marginBottom: 32}}>
        <div ref={locationPickerRef} id="location-picker">
          <LocationPicker 
            locationGroups={props.locationGroups} 
            toggleShowMapper={props.toggleShowMapper} 
            locations={props.locations} 
            location={props.location} 
            onChange={(location) => props.changeFilters({location})} />
        </div>
        <div ref={lookupPickerRef} id="lookup-picker">
          <LookupPicker 
            lookupKey={keyPicked} 
            onChange={(key) => props.changeFilters({lookupKey: key})} />
        </div>
        { 
          keyPicked === 'Pick Year' && <div id="year-picker">
            <YearPicker 
              YEARS={YEARS} 
              year={props.pickYear} 
              onChange={(y) => props.changeFilters({pickYear: y})} />
          </div>
        }
        <div ref={dateRangeRef} id='date-picker'>
          <DateRange 
            hidden={dateRangePickerHidden} 
            toggleDateRangePickerHidden={toggleDateRangePickerHidden} 
            startDay={props.startDay} 
            endDay={props.endDay} 
            onChange={(startDay, endDay) => props.changeFilters({startDay, endDay})} />
        </div>
      </div>
      <div ref={yAxisRef} id="y-axis-controls">
        <YAxisControls
          isMultiAlt 
          location={props.location} 
          state={layout.yaxis} 
          onChange={(changes) => setLayout(prev => ({...prev, yaxis: {...prev.yaxis, ...changes}}))} />
      </div>
    </div>
    {
      chartData.length > 0 ? (
        <div style={{position: 'relative', width: readOnly ? '100%' : '70%'}}>

          <Plot
            style={chartStyle}
            onRelayout={onRelayout}
            onUpdate={onUpdate}
            onDoubleClick={onDoubleClick}
            layout={layout}
            useResizeHandler
            config={chartConfig}
            data={chartData} />
        </div>
      ) : <div className='fr jcc aic empty f1' style={{width: '75%', height: '100%'}}>
        <div style={{textAlign: 'center'}}>
          <Icon iconName="SearchIssue" styles={{root: {fontSize: 42}}} />
          <p style={{fontSize: 18}}>No data to display.</p>
        </div>
      </div>
    }
    <div style={{width: props.thresholdInfo ? '30%' : '15%', height: '100%', maxHeight: '100%', display: readOnly ? 'none' : 'flex', flexDirection: 'column'}}>
      {
        thresholdInfo ? (
          <ThresholdSummary 
            {...thresholdInfo} 
            startCalDay={props.startCalDay}
            addThreshold={props.addThreshold}
            editThreshold={props.editThreshold} 
            setThresholdInfo={props.setThresholdInfo}
            changeFilters={props.changeFilters}
            startDay={startDay}
            endDay={endDay}
            FILTERED_CAL={FILTERED_CAL} 
            ALL_DAYS={ALL_DAYS} 
            onDismiss={props.onDismissThresholdSummary} />
        )
        :
        (
          <>
            <div ref={multiAltRef} id="multi-alt-picker" style={{maxHeight: '50%', overflowY: 'auto'}}>
              <MultiAltPicker alternatives={alternatives} selectedAlts={props.selectedAlts} changeFilters={props.changeFilters} />
            </div>
            <div ref={thresholdRef} id='threshold-picker' style={{maxHeight: '50%', overflowY: "auto"}}>
              <ThresholdPicker
                ALL_DAYS={ALL_DAYS} 
                FILTERED_CAL={FILTERED_CAL}
                startCalDay={startCalDay}
                setThresholdInfo={props.setThresholdInfo} 
                location={props.location} 
                startDay={props.startDay} 
                endDay={props.endDay} 
                editThreshold={editThreshold}
                addThreshold={addThreshold}
                thresholds={thresholds} 
                changeFilters={props.changeFilters} 
                scenarios={scenarios}/>
            </div>
          </>
        )
      }
    </div>
    
  </div>
}

export const YAxisControls = ({state, onChange, location, isMultiAlt}) => {
  
  const { user } = React.useContext(AuthContext);
  const { project_key } = useParams();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if(!user) {
      if(isMultiAlt){
        if(location === 'Missouri River Flow @ Wolf Point'){
          return onChange({autorange: false, range: ['0', '30000']});
        }
      }
      return
    };
    axios.get(`/api/user-settings/${project_key}/${location}`, {headers: {token: localStorage.getItem('token')}})
    .then(response => {
      if(response.data.success){
        return onChange(response.data.data.yaxis);
      } else {
        if(isMultiAlt){
          if(location === '*Missouri River Flow @ Wolf Point (1701.31)'){
            return onChange({autorange: false, range: ['0', '30000']});
          }
        }
        return onChange({autorange: true});
      }
    })
    .catch(err => {
      return onChange({autorange: true});
    })
  }, [location, user, project_key]);

  const saveAxisSettings = React.useCallback(() => {
    
    setLoading(true);

    axios.post(`/api/user-settings/${project_key}/${location}`, {yaxis: state}, {headers: {token: localStorage.getItem('token')}})
    .then(res => {
      setLoading(false);
    })
    .catch(err => {
      setLoading(false);
    })

  }, [state, user, project_key]);
  
  return <div>
    <Label styles={labelStyle}>Y Axis Controls</Label>
    <ActionButton iconProps={{iconName: state.autorange ? "UnlockSolid" : "LockSolid"}} text={state.autorange ? "Auto Range" : "Range Locked"} onClick={() => onChange({autorange: !state.autorange})} />
    {
      state.range && state.range.length && !state.autorange && (
        <>
          <div style={{marginBottom: 4}}>
            <TextField className='hover-label' label='Max' value={state.range[1]} type='number' underlined styles={{wrapper: {border: 'none !important'}, label: {height: 'auto'}, fieldGroup: {height: 'auto'}}}  onChange={(e, val) => onChange({range: state.range.map((r, i) => i === 1 ? val : r)})} />
          </div>
          <TextField className='hover-label' label="Min" value={state.range[0]} underlined styles={{wrapper: {border: 'none !important'}, label: {height: 'auto'}, fieldGroup: {height: 'auto'}}} type='number' onChange={(e, val) => onChange({range: state.range.map((r, i) => i === 0 ? val : r)})}  />
        </>
      )
    }
    {
      user && user.id && <div>
        <ActionButton iconProps={{iconName: "Save"}} onRenderIcon={(props, defaultRenderer) => {
          return loading ? <Spinner /> : defaultRenderer(props);
        }} text="Save Axis Settings" onClick={saveAxisSettings} />
      </div>
    }
  </div>
}