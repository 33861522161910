const asc = arr => arr.sort((a, b) => a - b);

const sum = arr => arr.reduce((a, b) => a + b, 0);

const quantile = (arr, q) => {
  const sorted = asc(arr);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (sorted[base + 1] !== undefined) {
      return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
      return sorted[base];
  }
};

const q25 = arr => quantile(arr, .25);

const q50 = arr => quantile(arr, .50);

const q75 = arr => quantile(arr, .75);

const q10 = arr => quantile(arr, .10);

const q90 = arr => quantile(arr, .90);

const q5 = arr => quantile(arr, .05);

const q95 = arr => quantile(arr, .95);

const q20 = arr => quantile(arr, 0.2);

const q80 = arr => quantile(arr, 0.8);

const median = arr => q50(arr);

const mean = arr => sum(arr) / arr.length;

export const stats = {
  q5,
  q95,
  q25,
  q50,
  q75,
  q10,
  q90,
  q20,
  q80,
  median,
  mean
}

export const DEFAULT_COLORS = [
  "#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3", "#03a9f4", "#00bcd4", "#009688", 
  "#4caf50", "#8bc34a", "#cddc39", "#ffeb3b", "#ffc107", "#ff9800", "#ff5722", "#795548", "#607d8b",
];

export const wetYearTextLookup = {
  "W": "Wet Year",
  "AN": "Above Normal Year",
  "BN": "Below Normal Year",
  "D": "Dry Year",
  "C": "Critical Year"
}

export const wetYearLookup = {
  1983: "W",
  1984: "W",
  1985: "D",
  1986: "W",
  1987: "D",
  1988: "C",
  1989: "D",
  1990: "C",
  1991: "C",
  1992: "C",
  1993: "AN",
  1994: "C",
  1995: "W",
  1996: "W",
  1997: "W",
  1998: "W",
  1999: "W",
  2000: "AN",
  2001: "D",
  2002: "D",
  2003: "AN",
  2004: "BN",
  2005: "AN",
  2006: "W",
  2007: "D",
  2008: "C",
  2009: "D",
  2010: "BN",
  2011: "W",
  2012: "BN",
  2013: "D",
  2014: "C",
  2015: "C",
  2016: "BN",
  2017: "W",
  2018: "BN",
  2019: "W",
  2020: "D"
}
