import React from 'react';
import { AltPicker, ChartPicker, LocationPicker, YearPicker, labelStyle } from "./Hydraviz";
import { Label, Slider, TextField } from '@fluentui/react';

import Plot from 'react-plotly.js';
import DateRange from './DateRange';
import ToggleButton from './ToggleButton';
import { ALL_DAYS } from './lib/cal';

import { interpolateCividis, interpolateGreens, interpolateReds } from 'd3-scale-chromatic';
import _ from 'lodash';

function normalize(min, max, fixZero, cb) {
  var delta = max - min;
  if(delta === 0) return function(val, i){
    cb && cb(0.5, i)
    return 0.5;
  }
  return function (val, i) {
    if(val === 0 && fixZero){
      cb && cb(0, i);
      return 0;
    } 
      cb && cb((val - min) / delta, i);
      return (val - min) / delta;
  };
}

export default function MultiDimension(props){

  const { locations=[], pickYear, alternatives=[], selectedAlt, startDay, endDay } = props;

  const [showFlashMessage, setFlashMessage] = React.useState(() => {
    const messageSeen = localStorage.getItem('multi-dimension-message-seen');
    if(messageSeen) return false;
    return true;
  });

  const [dateRangePickerHidden, toggleDateRangePickerHidden] = React.useState(true);

  const [proxFilter, setProxFilter] = React.useState(0.5);

  const [viewMethod, setViewMethod] = React.useState('3d');

  const [picked, setPicked] = React.useState(() => {
    if(locations.length > 2){
      return {
        x: locations[0].key, 
        y: locations[1].key, 
        z: locations[2].key,
        xMin: 0,
        xMax: 100,
        yMin: 0,
        yMax: 100,
        zMin: 0,
        zMax: 100
      }
    }
    return {
      x: '', 
      y: '', 
      z: '',
      xMin: 0,
      xMax: 100,
      yMin: 0,
      yMax: 100,
      zMin: 0,
      zMax: 100
    }
  });

  const { xMin, xMax, yMin, yMax, zMin, zMax } = picked;

  const boxOutline = React.useMemo(() => {
    if(!isNaN(xMin) && !isNaN(xMax) && !isNaN(yMin) && !isNaN(yMax) && !isNaN(zMin) && !isNaN(zMax)){
      return [
        // first box follows the min X axis and works around other axis'
        {
          x: [xMin, xMin, xMin, xMin, xMin],
          y: [yMin, yMax, yMax, yMin, yMin],
          z: [zMin, zMin, zMax, zMax, zMin],
          mode: "lines",
          line: {
            color: "#000",
            width: 4
          },
          type: 'scatter3d',
          // fill: 'tonext'
        },
        // second box follows the max X axis
        {
          x: [xMax, xMax, xMax, xMax, xMax],
          y: [yMin, yMax, yMax, yMin, yMin],
          z: [zMin, zMin, zMax, zMax, zMin],
          mode: "lines",
          line: {
            color: "#000",
            width: 4
          },
          type: 'scatter3d'
        },
        // connect 
        {
          x: [xMin, xMax],
          y: [yMin, yMin],
          z: [zMin, zMin],
          mode: "lines",
          line: {
            color: "#000",
            width: 4
          },
          type: 'scatter3d'
        },

        {
          x: [xMin, xMax],
          y: [yMax, yMax],
          z: [zMin, zMin],
          mode: "lines",
          line: {
            color: "#000",
            width: 4
          },
          type: 'scatter3d'
        },

        {
          x: [xMin, xMax],
          y: [yMax, yMax],
          z: [zMax, zMax],
          mode: "lines",
          line: {
            color: "#000",
            width: 4
          },
          type: 'scatter3d'
        },

        {
          x: [xMin, xMax],
          y: [yMin, yMin],
          z: [zMax, zMax],
          mode: "lines",
          line: {
            color: "#000",
            width: 4
          },
          type: 'scatter3d'
        }

      ]
    } else {
      return [];
    }
  }, [xMin, xMax, yMin, yMax, zMin, zMax]);

  const { x, y, z } = picked;

  const historicalData = React.useMemo(() => {

    if(viewMethod !== 'historical') return;

    if(!x || !y || ! z || !selectedAlt) return [];

    const { xMin, xMax, yMin, yMax, zMin, zMax } = picked;

    const { data } = props.master.find(el => el.alternative === selectedAlt);

    const xLocationData = data.find(el => el.location === x); 
    const yLocationData = data.find(el => el.location === y); 
    const zLocationData = data.find(el => el.location === z);

    if(!xLocationData || !yLocationData || !zLocationData) return [];

    const years = Object.keys(xLocationData.annualData);

    let inside = {
      z: [],
      y: years,
      x: ALL_DAYS.map(el => el.date),
      prox: [],
      mode: 'markers',
      colorscale: [[0, 'blue'], [0.00000000000000000000000000001, interpolateReds(0.00000000000000000000000000001)], [1, interpolateReds(1)]],
      type: 'heatmap'
    };

    years.forEach(pickYear => {

      let x = [];
      let y = [];
      let z = [];

      xLocationData.annualData[pickYear]?.forEach((day, i) => {

        let xVal = xLocationData.annualData[pickYear][i].value;
        let yVal = yLocationData.annualData[pickYear][i].value;
        let zVal = zLocationData.annualData[pickYear][i].value;

        let xInRange = ((xVal >= xMin) && (xVal <= xMax));
        let yInRange = ((yVal >= yMin) && (yVal <= yMax));
        let zInRange = ((zVal >= zMin) && (zVal <= zMax));

        if(
          xInRange
          &&
          yInRange
          &&
          zInRange
        ){
          x.push(0);
          y.push(0);
          z.push(0);

        } else {
  
          let xDiff = zInRange ? 0 : Math.abs(Math.min(Math.abs(xMin - xVal), Math.abs(xMax - xVal)));
          let yDiff = yInRange ? 0 : Math.abs(Math.min(Math.abs(yMin - yVal), Math.abs(yMax - yVal)));
          let zDiff = zInRange ? 0 : Math.abs(Math.min(Math.abs(zMin - zVal), Math.abs(zMax - zVal)));

          x.push(xDiff);
          y.push(yDiff);
          z.push(zDiff);
  
        }
      });

      let minXDiff = Math.min(...x, 0);
      let maxXDiff = Math.max(...x);
      let minYDiff = Math.min(...y, 0);
      let maxYDiff = Math.max(...y);
      let minZDiff = Math.min(...z, 0);
      let maxZDiff = Math.max(...z);

      let normXDiff = x.map(normalize(minXDiff, maxXDiff));
      let normYDiff = y.map(normalize(minYDiff, maxYDiff));
      let normZDiff = z.map(normalize(minZDiff, maxZDiff));

      let proxSums = x.map((el, i) => {
        return normXDiff[i] + normYDiff[i] + normZDiff[i];
      });

      const proxyMax = Math.max(...proxSums);
      const proxyMin = Math.min(...proxSums);

      const proxyNorm = proxSums.map(normalize(proxyMin, proxyMax, false));

      inside.z.push(proxyNorm);

    });

    return [inside];


  }, [x, y, z, pickYear, selectedAlt, props.master, xMin, xMax, yMin, yMax, zMin, zMax, startDay, endDay, viewMethod]);

  const [scatterData, setScatterData] = React.useState([]);
  
  React.useEffect(() => {

    if(viewMethod !== '3d') return;

    if(!x || !y || ! z || !selectedAlt) return [];

    const to = setTimeout(() => {
      const {xMin, xMax, yMin, yMax, zMin, zMax } = picked;
  
      const { data } = props.master.find(el => el.alternative === selectedAlt);
  
      const xLocationData = data.find(el => el.location === x); 
      const yLocationData = data.find(el => el.location === y); 
      const zLocationData = data.find(el => el.location === z);
  
      if(!xLocationData || !yLocationData || !zLocationData) return [];
        
      let outside = {
        x: [],
        y: [],
        z: [],
        xDiff: [],
        yDiff: [],
        zDiff: [],
        prox: [],
        text: [],
        // name: "Traces",
        mode: 'markers',
        hoverinfo: 'x+y+z+text',
        marker: {
          // colorscale: 'Greens',
          size: 12,
          symbol: 'circle',
          opacity: 1,
          cmin: 0,
          cmax: 1,
          cmid: 0.5
        },
        type: 'scatter3d'
      };
        
      xLocationData.annualData[pickYear]?.forEach((el, i) => {
  
        if((el.dayLookup < startDay) || (el.dayLookup > endDay)) return;

        outside.text.push(el.date);

        let xVal = xLocationData.annualData[pickYear][i].value;
        let yVal = yLocationData.annualData[pickYear][i].value;
        let zVal = zLocationData.annualData[pickYear][i].value;
  
        let xInRange = (xVal >= xMin) && (xVal <= xMax);
        let yInRange = (yVal >= yMin) && (yVal <= yMax);
        let zInRange = (zVal >= zMin) && (zVal <= zMax);
  
        if(
          xInRange
          &&
          yInRange
          &&
          zInRange
        ){
          outside.x.push(xVal);
          outside.y.push(yVal);
          outside.z.push(zVal);
          outside.xDiff.push(0);
          outside.yDiff.push(0);
          outside.zDiff.push(0);
        } else {
          // if the value is within any of the ranges, score zero
          // otherwise, calculate how close they are to either the min or the max
          let xDiff = xInRange ? 0 : Math.abs(Math.min(Math.abs(xMin - xVal), Math.abs(xMax - xVal)));
          let yDiff = yInRange ? 0 : Math.abs(Math.min(Math.abs(yMin - yVal), Math.abs(yMax - yVal)));
          let zDiff = zInRange ? 0 : Math.abs(Math.min(Math.abs(zMin - zVal), Math.abs(zMax - zVal)));
  
          // push values into their holder arrays
          outside.x.push(xVal);
          outside.y.push(yVal);
          outside.z.push(zVal);
          outside.xDiff.push(xDiff);
          outside.yDiff.push(yDiff);
          outside.zDiff.push(zDiff);
        }
      });
      
      // find the min and max diff's for each of the axis
      let minXDiff = Math.min(...outside.xDiff, 0);
      let maxXDiff = Math.max(...outside.xDiff);
      let minYDiff = Math.min(...outside.yDiff, 0);
      let maxYDiff = Math.max(...outside.yDiff);
      let minZDiff = Math.min(...outside.zDiff, 0);
      let maxZDiff = Math.max(...outside.zDiff);
  
      // now normalize them
      let normXDiff = outside.xDiff.map(normalize(minXDiff, maxXDiff));
      let normYDiff = outside.yDiff.map(normalize(minYDiff, maxYDiff));
      let normZDiff = outside.zDiff.map(normalize(minZDiff, maxZDiff));
  
      // add them to the chart object for fun
      outside.normXDiff = normXDiff;
      outside.normYDiff = normYDiff;
      outside.normZDiff = normZDiff;

      let proxSums = normXDiff.map((el, i) => {
        return normXDiff[i] + normYDiff[i] + normZDiff[i];
      });

      const proxyMax = Math.max(...proxSums);
      const proxyMin = Math.min(...proxSums);

      const proxyNorm = proxSums.map(normalize(proxyMin, proxyMax, false));

      outside.proxyNorm = proxyNorm;

      // outside.marker.color = proxyNorm.map(el => el <= proxFilter ? el === 0 ? "blue" : interpolateReds(el) : '#00000000');

      outside.marker.color = proxyNorm.map(el => interpolateReds(el));
      
      setScatterData([outside]);

    }, 200)

    return () => clearTimeout(to);

  }, [x, y, z, pickYear, selectedAlt, props.master, xMin, xMax, yMin, yMax, zMin, zMax, startDay, endDay, viewMethod, proxFilter]);


  const chartData = React.useMemo(() => {
    return viewMethod === '3d' ? [...scatterData, ...boxOutline]
    :
    historicalData;
  }, [scatterData, boxOutline, viewMethod, historicalData]);

  const [xLabel, yLabel, zLabel] = React.useMemo(() => {
    // console.log(locations);
    let xloc = locations.find(el => el.key === x);
    let yloc = locations.find(el => el.key === y);
    let zloc = locations.find(el => el.key === z);

    return [xloc?.measure, yloc?.measure, zloc?.measure];

  }, [x, y, z]);

  const onChangeYear = (year) => props.changeFilters({pickYear: year});

  const hoveredRef = React.useRef();

  const [hoverDate, setHoverDate] = React.useState(null);

  const monthLookupObj = _.groupBy(ALL_DAYS, 'month');

  const onHover = (e, a, b) => {
    // console.log({e, a, b});
    // setHoverDate(e.points[0].text);
  }

  return <div style={{display: 'flex', flex: 1, background: '#fff', padding: 8, height: 'calc(100% - 33px)', maxHeight: 'calc(100% - 33px)'}}>
    <div style={{width: '20%', overflowY: 'auto', maxHeight: '100%'}}>
      <div style={{marginBottom: 32}}>
        <ChartPicker chartType={props.chartType} onChange={(chartType) => props.changeFilters({chartType})} />
      </div>
      <AltPicker 
        alternatives={alternatives} 
        alternative={selectedAlt} 
        onChange={(selectedAlt) => props.changeFilters({selectedAlt})} />
      <LocationPicker 
        toggleShowMapper={props.toggleShowMapper} 
        locations={locations} 
        location={picked.x} 
        label="X Axis" 
        onChange={(location) => setPicked(prev => ({...prev, x: location}))} />
      <div className='fr' style={{gap: 8, marginTop: 8}}>
        <div className='hover-label' style={{padding: 0}}>
          <TextField prefix='Min' borderless value={picked.xMin} onChange={(e, val) => setPicked(prev => ({...prev, xMin: val}))} />
        </div>
        <div className='hover-label' style={{padding: 0}}>
          <TextField prefix='Max' borderless value={picked.xMax} onChange={(e, val) => setPicked(prev => ({...prev, xMax: val}))} />
        </div>
      </div>
      <LocationPicker 
        toggleShowMapper={props.toggleShowMapper} 
        locations={locations} 
        location={picked.y} 
        label="Y Axis" 
        onChange={(location) => setPicked(prev => ({...prev, y: location}))} />
      <div className='fr' style={{gap: 8, marginTop: 8}}>
        <div className='hover-label' style={{padding: 0}}>
          <TextField prefix='Min' borderless value={picked.yMin} onChange={(e, val) => setPicked(prev => ({...prev, yMin: val}))} />
        </div>
        <div className='hover-label' style={{padding: 0}}>
          <TextField prefix='Max' borderless value={picked.yMax} onChange={(e, val) => setPicked(prev => ({...prev, yMax: val}))} />
        </div>
      </div>
      <LocationPicker 
        toggleShowMapper={props.toggleShowMapper} 
        locations={locations} 
        location={picked.z} 
        label="Z Axis" 
        onChange={(location) => setPicked(prev => ({...prev, z: location}))} />
      <div className='fr' style={{gap: 8, marginTop: 8}}>
        <div className='hover-label' style={{padding: 0}}>
          <TextField prefix='Min' borderless value={picked.zMin} onChange={(e, val) => setPicked(prev => ({...prev, zMin: val}))} />
        </div>
        <div className='hover-label' style={{padding: 0}}>
          <TextField prefix='Max' borderless value={picked.zMax} onChange={(e, val) => setPicked(prev => ({...prev, zMax: val}))} />
        </div>
      </div>

      {
        viewMethod === '3d' ? (
          <>
            <YearPicker {...props} year={props.pickYear} onChange={onChangeYear} />
            <DateRange {...props} hidden={dateRangePickerHidden} toggleDateRangePickerHidden={toggleDateRangePickerHidden} onChange={(startDay, endDay) => props.changeFilters({startDay, endDay})} />
          </>
        ) : null
      }
      <Label styles={labelStyle}>Proximity Filter</Label>
      <Slider value={proxFilter} onChange={(v) => setProxFilter(v)} min={0} max={1} step={0.01} />
    </div>
    <div style={{width: '80%', display: 'flex', flexDirection: 'column', position: 'relative'}}>
      <div style={{position: 'absolute', top: 0, left: 16, zIndex: 9999}}>
        <ToggleButton 
          label="View Method" 
          activeKey={viewMethod} 
          buttons={[
            {key: '3d', text: '3D Plot', onClick: () => setViewMethod('3d')}, 
            {key: 'historical', text: 'Historical', onClick: () => setViewMethod('historical')}
          ]} />
      </div>

      <Plot
        style={{height: '100%', width: '100%'}}
        layout={{showlegend: false, scene: {xaxis: {title: `(X) ${xLabel}`}, yaxis: {title: `(Y) ${yLabel}`}, zaxis: {title: `(Z) ${zLabel}`}}}}
        onHover={onHover}
        useResizeHandler
        data={chartData} />

        {
          viewMethod === '3d' && (
            <div style={{margin: '0px auto', position: 'absolute', bottom: 0, width: '100%', zIndex: 99999999}}>
              <div>
                <span ref={hoveredRef}></span>
              </div>
              <div style={{display: 'flex', flexDirection: 'row'}}>      
                {
                  Object.keys(monthLookupObj).map(month => {
                    let width = `${100 * (monthLookupObj[month].length / 365)}%`;
                    return <div key={month} style={{width}}>{month}</div>
                  })
                }
              </div>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {
                  chartData && chartData[0] && chartData[0].marker && chartData[0].marker.color.map((val, i) => {
                      
                      return <Hoverable isHovered={chartData[0].text[i] === hoverDate} key={i} val={val} />
                  })
                }
              </div>
            </div>
          )
        }
    </div>
  </div>
}

const Hoverable = (props) => {
  return <div style={{height: 32, background: props.val, flex: 1}}></div>
}