import { Dropdown, getTheme } from '@fluentui/react';
import { groupBy } from 'lodash';
import React from 'react';
import { ALL_DAYS, MONTH_LOOKUP } from './lib/cal';

export default function Settings(props){

  const groupedByMonth = groupBy(ALL_DAYS, 'month');

  return <div className='pm-container'>
    <h1 className='page-header'>Settings</h1>
    <Dropdown
      label='Calendar Start Day' 
      selectedKey={props.startCalDay}
      onRenderItem={(p) => {
        return <div className='p1 grid g12 mb4 col4'>
          {
            Object.keys(MONTH_LOOKUP).map(month => {
              return <div>
                <h4>{MONTH_LOOKUP[month].name}</h4>
                <div className='grid g4 col7'>
                  {
                    groupedByMonth[MONTH_LOOKUP[month].name].map(day => {
                      let selected = day.julian === props.startCalDay;
                      return <div style={{background: selected ? getTheme().palette.themePrimary : 'none', color: selected ? 'white' : 'inherit', textAlign: 'center', borderRadius: 4, cursor: 'pointer'}} onClick={() => props.setStartCalDay(day.julian)} key={day.julian}>{day.day}</div>
                    })
                  }
                </div>
              </div>
            })
          }
        </div>
      }} 
      options={[{key: props.startCalDay, text: ALL_DAYS.find(day => day.julian === props.startCalDay).date}]} />
  </div>
}