import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from './AuthContext';

export default function Home(props){

  const { user } = useContext(AuthContext);

  React.useEffect(() => {
    if(user){
      if(user && user.id) props.history.push('/dashboard');
    }
  }, [user]);

  return <div className='home center'>
    <div className='signup-form'>
      <h1 className='home logo'>Hydroviz</h1>
      <div className='fr g12'>
        <Link className='f1' to='/project'>
          <div className='hover-label ms-fontSize-24' style={{padding: '16px 24px', textAlign: 'center', fontWeight: 600, display: 'block'}}>
            Open Project
          </div>
        </Link>
        <Link className='f1' to="/signin">
          <div className='hover-label ms-fontSize-24' style={{padding: '16px 24px', textAlign: 'center', fontWeight: 600, display: 'block'}}>
            Sign In
          </div>
        </Link>       
      </div>
    </div>
  </div>
}